/** campaign form **/
.campaign-page {
    padding-top: 10px;
}

.campaign-page .tab-listing ul li:first-child a {
    padding-left: 0px;
    padding-right: 0px;
}

.campaign-page .form-submit {
    margin-top: 0px;
    margin-bottom: 10px;
}


/** campaign form **/

/** upload assets form **/
.asset-tab-wrapper .MuiExpansionPanelDetails-root {
    padding: 0;
}

.asset-tab-wrapper .MuiExpansionPanel-root {
    margin: 0 !important;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #EBEBEB;
}

.asset-tab-wrapper .MuiExpansionPanel-root {
    margin: 0;
}

.asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
    font-size: 24px;
    color: #177BC9;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    font-family: "Raleway", sans-serif;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content, .asset-tab-wrapper .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-root {
    padding: 30px;
    min-height: 1px;
}

.tab-content.asset-tab-wrapper {
    padding: 30px;
    background: #fff;
    padding-bottom: 40px;
}

.asset-tab-content {
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.asset-tab-content form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.asset-tab-left {
    width: 32%;
    margin-right: 2%;
}

.asset-tab-right {
    width: 66%;
    border: 1px solid #EBEBEB;
}

.tab_field {
    margin-bottom: 20px;
}

.asset-table-head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #F5F7FA;
}

.asset-table-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #EBEBEB;
}

.asset-table-head>div h5, .asset-table-body>div p {
    margin: 0;
    line-height: 1;
    color: #646464;
}

.asset-table-head>div {
    padding: 10px;
}

.asset-tab-wrapper .MuiExpansionPanel-root.Mui-expanded+.MuiExpansionPanel-root.Mui-expanded {
    margin-top: -10px;
}

.MuiExpansionPanel-root.Mui-expanded:before {
    opacity: 1 !important;
}

.MuiExpansionPanel-root.Mui-expanded:after {
    opacity: 1 !important;
}

.MuiExpansionPanel-rounded:first-child {
    border-radius: 0;
}

.asset-tab-wrapper .MuiExpansionPanel-root+.MuiExpansionPanel-root {
    margin-top: 40px !important;
}

.asset-tab-wrapper .MuiExpansionPanel-root:before {
    display: none;
}

.asset-tab-wrapper .Mui-expanded .MuiExpansionPanelSummary-root {
    min-height: 1px;
    border-bottom: 1px solid #EBEBEB;
}

.asset-table-body>div {
    padding: 24px 20px 13px;
}

.asset-table-width-1 {
    width: 11%;
}

.asset-table-width-17 {
    width: 17%;
}

.asset-table-width-22 {
    width: 22%;
}


.asset-table-width-2 {
    width: 24%;
}

.asset-table-width-3 {
    width: 60%;
}

.asset-table-body p, .asset-table-body a {
    font-family: Raleway, sans-serif;
}

.asset-table-body .asset-table-width-1 {
    text-align: center;
}

.small-ic {
    display: inline-block;
    vertical-align: middle;
    margin-right: 11px;
}

.small-ic:last-child {
    margin-right: 0;
}

.asset-table-body:last-child {
    border-bottom: none;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 3px;
    background: #646464;
    transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    margin-right: 26px;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
    height: 24px;
    width: 3px;
    right: 10px;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content.Mui-expanded:after {
    opacity: 0;
}


.asset-table-width-3 a {
    color: #177BC9;
    text-decoration: underline;
}

.asset-table-width-4 {
    width: 14.5%;
}

.asset-table-width-5 {
    width: 85.5%;
}

.asset-link {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px 0 5px;
}

.asset-link a {
    color: #177BC9;
    text-decoration: underline;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-left: 25px;
    margin-top: -5px;
}

.asset-link a img {
    position: absolute;
    top: 2px;
    left: 0;
}

.asset-tab-right:only-child {
    width: 100%;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 130px;
    padding-left: 40px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    width: 433px;
    min-width: 433px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    width: 466px;
    min-width: 466px;
    padding-left: 50px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(4) {
    width: 151px;
    min-width: 151px;
    padding-left: 48px;
    text-align: center;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(5) {
    padding-left: 38px;
    width: 240px;
    min-width: 240px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-body {
    padding: 32px 12px 26px 12px;
}


.MuiTableRow-root .MuiTableCell-body.yellow,
.MuiTableRow-root .MuiTableCell-body.purple,
.MuiTableRow-root .MuiTableCell-body.blue {
    text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.yellow span,
.MuiTableRow-root .MuiTableCell-body.purple span,
.MuiTableRow-root .MuiTableCell-body.blue span {
    background: #FFF3CD;
    border-radius: 2px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #856404;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding: 3px 5px;
    min-width: 36px;
}

.MuiTableRow-root .MuiTableCell-body.purple span {
    color: #175961;
    background: #D1ECF1;
}

.MuiTableRow-root .MuiTableCell-body.blue span {
    color: #028689;
    background: #02ABAE33;

}

.upload-asset-main {
    background: #fff;
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.asset-left {
    width: 46.5%;
    padding: 20px 0 0;
    margin-right: 7.5%;
}

.asset-right {
    width: 46%;
    margin-bottom: 0;
    position: relative;
}


.asset-full {
    width: 100%;
    background: #F5F7FA;
    border: 1px solid #EBEBEB;
    padding: 30px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.asset-img {
    width: 100%;
    background: #F5F7FA;
    border: 1px solid #EBEBEB;
    padding: 30px;
}

.asset-img img {
    display: block;
}

.asset-left label {
    display: block;
    line-height: 1;
    margin-bottom: 15px;
}

.asset-left input[type="text"] {
    margin-bottom: 30px;
}



.left-or-text {
    position: absolute;
    top: 20px;
    left: -11.5%;
    bottom: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 42px;
    z-index: 1;
}

.left-or-text:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #CCCCCC;
    width: 1px;
    margin: 0 auto;
    z-index: -1;
}

.left-or-text span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #CCCCCC;
    text-transform: uppercase;
    background: #fff;
    padding: 10px;
}

.tab-content .field-right select {
    color: #CCCCCC;
}

.tab-content .field-right select:focus {
    color: #646464;
}

@media (max-width: 1700px) {
    .asset-table-body>div {
        padding: 10px 0px 10px 5px;
    }

    .asset-table-body>div p, .asset-table-body a {
        font-size: 15px;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
    }

    .small-ic {
        margin-right: 10px;
    }

    .asset-table-width-1 {
        width: 12%;
    }

    .asset-table-width-3 {
        width: 59%;
    }

    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 22px;
    }

    .asset-tab-content {
        padding: 30px 20px;
    }
}

@media (max-width: 1500px) {
    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(4) {
        padding-left: 20px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
        padding-left: 20px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
        width: 340px;
        min-width: 340px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
        width: 320px;
        min-width: 320px;
    }

    .upload-asset-main {
        padding: 0px;
    }

    .asset-full, .asset-img {
        padding: 20px;
    }
}

@media (max-width: 1440px) {
    .tab-content.asset-tab-wrapper {
        padding: 20px;
        padding-bottom: 40px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-root {
        padding: 25px 20px;
    }

    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 20px;
    }

    .asset-table-width-2 {
        width: 30%;
    }

    .asset-table-width-3 {
        width: 53%;
    }

    .asset-tab-wrapper .MuiExpansionPanel-root+.MuiExpansionPanel-root {
        margin-top: 30px !important;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before {
        width: 20px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        height: 20px;
        right: 8px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        margin-right: 20px;
    }

    .asset-left {
        width: 45%;
        margin-right: 11%;
    }

    .asset-right {
        width: 44%;
    }

    .left-or-text {
        left: -19%;
    }
}

@media (max-width: 1199px) {
    .main-sec-wrap.active-main .asset-tab-left {
        width: 100%;
        margin: 0;
    }

    .main-sec-wrap.active-main .asset-tab-right {
        width: 100%;
    }

    .tab_field {
        margin-bottom: 10px;
    }

    .asset-tab-content {
        padding: 20px;
    }

    .asset-table-width-4 {
        width: 20%;
    }

    .asset-table-width-5 {
        width: 80%;
    }


    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-body {
        padding: 25px 12px 20px 12px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:first-child {
        padding-left: 20px;
    }

    .campaign-page {
        padding-top: 40px;
    }

    .upload-asset-main {
        padding: 20px;
        margin-top: 25px;
    }

    .asset-left {
        padding: 10px 0 0;
    }

    .asset-right .form-file-input {
        margin-top: 10px;
    }

    .form_field .input-file-trigger {
        font-size: 16px;
        height: 150px;
    }

    .form_field .input-file-trigger img {
        max-width: 15px;
    }

    .asset-left input[type="text"] {
        margin-bottom: 20px;
    }


    .left-or-text span {
        font-size: 15px;
    }

    .left-or-text {
        top: 15px;
        bottom: 0;
    }

    .asset-full, .asset-img {
        padding: 10px;
    }
}

@media (max-width: 991px) {
    .asset-tab-left {
        width: 100%;
        margin: 0;
    }

    .asset-tab-right {
        width: 100%;
    }


    .campaign-page {
        padding-top: 25px;
    }

}

@media (max-width: 767px) {
    .asset-table-body>div p, .asset-table-body a {
        font-size: 12px;
    }

    .asset-table-body .asset-table-width-3 {
        padding-right: 10px;
    }

    .asset-table-body>div {
        padding: 15px 0px 10px 15px;
    }

    .asset-table-head>div {
        padding: 15px 20px;
    }



    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 18px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-root {
        padding: 20px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before {
        height: 2px;
        width: 18px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        height: 18px;
        width: 2px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        margin-right: 14px;
    }

    .campaign-page .tab-listing ul li:first-child a {
        padding-left: 4px;
        padding-right: 2px;
    }

    .upload-asset-main {
        padding: 30px 20px;
    }

    .asset-left {
        width: 100%;
        margin: 0 0 50px;
        padding: 0;
    }

    .asset-right {
        width: 100%;
    }

    .left-or-text {
        top: -40px;
        left: 0;
        right: 0;
        width: 100%;
        bottom: auto;
    }

    .left-or-text:after {
        height: 1px;
        width: 100%;
        margin: auto;
    }

    .asset-full {
        margin-top: 30px;
    }

    .asset-button {
        margin-top: 30px;
    }

}

@media (max-width: 639px) {
    .campaign-page .tab-listing ul li:first-child a {
        padding-left: 4px;
        padding-right: 2px;
    }

    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 16px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-root {
        padding: 16px 25px 14px 15px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        height: 16px;
        right: 7px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before {
        width: 16px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        margin-right: 10px;
    }

    .asset-tab-content {
        padding: 15px;
    }

    .tab-content.asset-tab-wrapper {
        padding: 15px;
        padding-bottom: 30px;
    }

    .asset-tab-wrapper .MuiExpansionPanel-root+.MuiExpansionPanel-root {
        margin-top: 20px !important;
    }

    .asset-table-head {
        min-width: 400px;
    }

    .asset-tab-right {
        overflow: auto;
    }

    .asset-table-body {
        min-width: 400px;
    }

    .asset-table-head>div {
        padding: 15px;
    }

    .asset-table-width-1 {
        width: 15%;
    }

    .asset-table-width-3 {
        width: 50%;
    }

    .asset-table-width-4 {
        width: 25%;
    }

    .asset-table-width-5 {
        width: 75%;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-body {
        padding: 21px 12px 15px 12px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:first-child {
        min-width: 80px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
        width: 160px;
        min-width: 160px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
        width: 160px;
        min-width: 160px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(5) {
        width: 190px;
        min-width: 190px;
    }


    .left-or-text span {
        font-size: 14px;
    }

    .form_field .input-file-trigger {
        font-size: 12px;
        height: 100px;
    }

    .form_field .input-file-trigger img {
        max-width: 13px;
    }

    .asset-full {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .asset-button {
        margin-top: 20px;
    }

    .upload-asset-main {
        margin-top: 15px;
    }


}

@media (max-width: 374px) {

    .asset-full, .asset-img {
        padding: 10px;
    }

    .upload-asset-main {
        padding: 20px 10px;
    }
}