:root {
  --button-border-radius_50: 50px;
  --hover-button-bg-color: #cccccc;
  --white-color: #ffffff;
  --white-background-color: #f5f7fa;
  --font-size_10: 10px;
  --font-family: "Barlow", sans-serif;
  --font-family-new: "Barlow", sans-serif;
  --font-family-new-lora: "Lora", sans-serif;
  --hover-font-color: #fff;
  --main-black-color: #000000;
  --main-grey-color: #636363;
  --bark-grey-color: #4b4c4c;

  --main-blue-color: #007b88;
  --light-blue-color: #005e68;
  --dark-blue-color: #043b41;
  --blue-color: #1073c6;
  --border-color: #dddddd;
  --nav-hover-color: #367E88;
}

#iframe video {
  min-width: 0px !important;
}

:host(.media-document.video.mac) {
  min-width: 0px !important;
}


.app-main .display-none {
  display: none;
}

.app-main .login-page-section {
  height: 75% !important;

}

.app-main .subscription-submenu {
  display: block;
}

.app-main .pratice-submenu {
  display: block;
}

.app-main .subscription-li {

  padding-left: 80px;
}

.app-main .subscription-li a {
  padding: 20px 50px !important;
  padding-right: 30px !important;
}

.second-step {
  display: flex;
  display: block;
  text-align: center;
}

.second-step li a.active {
  color: red !important;
  /* Change to your desired active navlink color */
}

.second-step a:focus svg path,
.second-step a:hover svg path {
  fill: #636363 !important;
  fill: var(--main-grey-color) !important;
}


.app-main {
  height: 100%;
  width: 100%;
}

@media print {
  .hide-on-print {
    display: none;
  }
}

.app-main .footer-links a {
  text-decoration: underline;
  color: white;
  font-size: 13px;
  font-family: "Lora";
}

.app-main .footer-link-main {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
}

.app-main .foot-links a {
  font-family: "Lora";
  text-decoration: underline;
  color: #fff;
  font-size: 14px;
}

.app-main .fc-theme-standard td,
.fc-theme-standard th {
  border: 0;
  padding: 5px;
}

.app-main .fc .fc-daygrid-day-frame {
  height: 75%;
  text-align: center;
  flex-direction: row-reverse !important;
  border: 1px solid #ebebeb;
}

.app-main .stripes-lines {
  background: repeating-linear-gradient(135deg,
      #ebebeb,
      #f9f9f9 4px);
  height: 100%;
}

.app-main .daygrid-day-top {
  margin-top: 0px;
  margin-bottom: 0;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  color: #636363;
  color: var(--main-grey-color);
  font-weight: bold;
  cursor: pointer;
}

.app-main .daygrid-day-top-active {
  background: #449fe6;
  color: #ffffff;
  color: var(--white-color);
}

.app-main .login-from {
  width: 30%;
  margin-left: 13%;
  margin-top: 30px;
}

.app-main .dayDate-dote {
  margin-top: 0.8em;
  margin-left: 0.3em;
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: #FF0000;
  border-radius: 50%;
  vertical-align: middle;
  font-weight: bold;
  cursor: pointer;
}

.app-main .dayDate-dote-active {
  background: #449fe6;
}

.app-main .fc .fc-daygrid-day-number {
  padding: 0;
}

.app-main .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 8em;
}

.app-main .fc-h-event {
  border: 0;
  background-color: #ffffff;
  background-color: var(--white-color);
}

.app-main .fc-h-event .fc-event-main {
  color: #636363;
  color: var(--main-grey-color);
}

.app-main .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0;
}

.app-main ::selection {
  background: #043b41;
  background: var(--dark-blue-color);
  color: #ffffff;
  color: var(--white-color);
}

.app-main ::-moz-selection {
  background: #043b41;
  background: var(--dark-blue-color);
  color: #ffffff;
  color: var(--white-color);
}

.app-main a:focus,
a:hover {
  color: #043b41;
  color: var(--dark-blue-color);
}

.app-main input[type="date"],
.app-main input[type="email"],
.app-main input[type="password"],
.app-main input[type="search"],
.app-main input[type="tel"],
.app-main input[type="text"],
.app-main input[type="number"],
.app-main select,
.app-main textarea {
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  color: #636363;
  color: var(--main-grey-color);
  background-color: #f5f7fa;
  background-color: var(--white-background-color);
  line-height: 20px;
  padding: 15px 20px 15px;
  border-radius: 10px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main input[type="text"]:disabled {
  background-color: #ffffff;
  background-color: var(--white-color);
}

.app-main input[type="date"]:focus,
.app-main input[type="email"]:focus,
.app-main input[type="password"]:focus,
.app-main input[type="search"]:focus,
.app-main input[type="tel"]:focus,
.app-main input[type="text"]:focus,
.app-main textarea:focus {
  color: #636363;
  color: var(--main-grey-color);
}

.app-main textarea {
  height: 280px;
  resize: none;
  border: 1px solid #fcfcfc;
  padding: 10px 15px 10px;
}

.app-main .title-content-select {
  border-radius: 3px;
  border: 1px solid #cccccc;
  border: 1px solid var(--hover-button-bg-color);
  padding: 8px;
  font-size: 13px;
  text-overflow: ellipsis;
  background-color: #ffffff;
  background-color: var(--white-color);
}

.app-main select {
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
  background-position: center right 13px;
  background-repeat: no-repeat;
  background-size: 12px;
  padding-right: 30px;
  color: #cccccc;
  color: var(--hover-button-bg-color);
}

.app-main .select1 {
  background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
  padding: 0 30px 0 3px;
  color: #043b41;
  color: var(--dark-blue-color);
  font-size: 14pt;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

.select-style {
  width: 15%;
}

.app-main select::-ms-expand {
  display: none;
}

.app-main select option,
.app-main select:focus {
  color: #636363;
  color: var(--main-grey-color);
  font-size: 12pt;
}

.app-main input::-moz-placeholder {
  opacity: 1 !important;
}

.app-main input:-moz-placeholder {
  opacity: 1 !important;
}

.app-main input::-webkit-input-placeholder {
  color: #cccccc !important;
  color: var(--hover-button-bg-color) !important;
  opacity: 1 !important;
}

.app-main input:-ms-input-placeholder {
  opacity: 1 !important;
}

.app-main textarea::-moz-placeholder {
  opacity: 1 !important;
}

.app-main textarea:-moz-placeholder {
  opacity: 1 !important;
}

.app-main textarea::-webkit-input-placeholder {
  color: #cccccc !important;
  color: var(--hover-button-bg-color) !important;
  opacity: 1 !important;
}

.app-main textarea:-ms-input-placeholder {
  opacity: 1 !important;
}

.app-main .btn {
  transition: all 0.5s;
  font-size: 14px;
  background: #e83352;
  color: #ffffff;
  color: var(--white-color);
  border-radius: 2px;
  font-weight: 700;
  padding: 25px 20px 25px;
}

.app-main .btn:focus,
.app-main .btn:hover {
  box-shadow: none;
  color: #ffffff;
  color: var(--white-color);
  background: #212529;
}

.app-main .login_page {}

.app-main .login_page .main {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.app-main .rdw-link-modal {
  height: 270px
}


.app-main .rdw-link-modal-btn {
  background: #007b88;
  background: var(--main-blue-color);
  background: linear-gradient(180deg, #007b88 0, #005e68 50%, #043b41 100%);
  background: linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  margin: 1%;
  height: 40px;
}

.app-main .button-field {
  position: relative;
  z-index: 1;
  background: #007b88;
  background: var(--main-blue-color);
  /* background: -moz-linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  background: -webkit-linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  background: linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%); */
  background: linear-gradient(90deg, rgb(0, 123, 136) 4%, rgb(0, 94, 104) 100%);
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
}

.app-main .button-field :hover {
  background: #16416f;
}

.app-main .button-field-status-redcolor {
  position: relative;
  z-index: 1;
  background: #ff0000;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
}

.app-main .disable-btn {
  background: #cccccc;
  background: var(--hover-button-bg-color);
  pointer-events: none;
}

.app-main .color-disable {
  font-weight: 700;
  background: #636363;
  background: var(--main-grey-color);
  pointer-events: none;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 18px;
  -webkit-appearance: none;
  color: #ffffff;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main .button-field a {
  font-weight: 700;
  color: #fff;
  color: var(--hover-font-color);
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 16px 20px;
  font-size: 18px;
}

.app-main .button-field.fullview-btn {
  display: inline-block;
  vertical-align: middle;
  margin-top: -10px;
}

.app-main .button-field.button-field-country {
  display: block;
  float: right;
  margin-top: 1%;
}

.app-main .right-continue-btn .button-field.next-step-btn {
  min-width: 150px;
  margin-left: 0;
}

.app-main .right-continue-btn .button-field.next-step-btn:last-child {
  margin-left: auto;
}

.app-main .sec-left .button-field {
  display: inline-block;
  vertical-align: middle;
  min-width: 150px;
  width: 150px;
}

.app-main .sec-left .button-field button {
  width: 100%;
}

.app-main label {
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

/* .app-main p {
font-size: 16px;
margin-bottom: 20px;
font-weight: 400;
line-height: 1.4;
font-family: var(--font-family-new);
} */

.app-main h1 {
  font-size: 48px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  margin-bottom: 20px;
  color: #4b4c4c;
  color: var(--bark-grey-color);
}

.app-main .h1 {
  font-size: 36px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  margin-bottom: 20px;
  color: #4b4c4c;
  color: var(--bark-grey-color);
}

.app-main h2 {
  font-size: 40px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  margin-bottom: 20px;
  color: inherit;
}

.app-main h3 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main h4 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main h5 {
  font-size: 16px;
  margin-bottom: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main h6 {
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main button,
html .app-main input[type="button"],
.app-main input[type="reset"],
.app-main input[type="submit"] {
  font-weight: 700;
  color: #ffffff;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 150px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 16px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-main .fileuploadbutton {
  font-weight: 700;
  color: #ffffff;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 18px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-main .button-field:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #636363;
  background: var(--main-grey-color);
  border-radius: 50px;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  padding: 0px 0px;
}

.app-main .inner-screen {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  background: var(--hover-font-color);
  box-shadow: 25px 25px 99px rgba(10, 10, 10, 0.16);
  width: 100%;
  min-height: 100vh;
}

.app-main .theme-option div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-main .theme-option {
  font-weight: 550;
  color: #043b41;
  color: var(--dark-blue-color);
  line-height: 1.2;
  font-size: 11pt;
  margin: 0px 5px 0px 5px;
  align-self: center;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  /* cursor: pointer; */
  /* width: 57%; */
}

.app-main .MuiSvgIcon-fontSizeLarge {
  font-size: 1em;
}

.app-main .theme-option-location {
  /* width: 25%; */
  /* text-align: end; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-main .form-field {
  margin-bottom: 30px;
  position: relative;
}

.app-main .form-field:last-child {
  margin-bottom: 0;
}

.app-main .form-field label {
  margin-bottom: 0;
  display: block;
}

.app-main .form-field input {
  width: 100%;
}

.app-main .left-column {
  width: 50%;
  padding: 80px;
}

.app-main .logo {
  margin-bottom: 59px;
  display: flex;
}

.app-main .logo img {
  display: block;
  vertical-align: middle;
  height: 50px;
}

.app-main .logo a img {
  display: block;
}

.app-main .left-column h1 {
  margin-bottom: 40px;
}

.app-main .user-field {
  background-image: url(/static/media/user-field.449ff928.svg);
  background-repeat: no-repeat;
  background-position: center right 18px;
}

.app-main .pwd-field {
  background-image: url(/static/media/lock-ic.d52f2d96.svg);
  background-repeat: no-repeat;
  background-position: center right 18px;
}

.app-main .custom-checkbox {
  position: relative;
}

.app-main .custom-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -999999px;
}

.app-main .custom-checkbox input[type="checkbox"]+label {
  position: relative;
  padding-left: 31px;
  line-height: 23px;
  cursor: pointer;
}

.app-main .custom-checkbox input[type="checkbox"]+label:after,
.app-main .custom-checkbox input[type="checkbox"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.app-main .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAAZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkSoWkOQAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.3s ease-in-out;
  background-size: 14px;
  border-color: transparent;
}

.app-main .box-body .custom-checkbox input[type="checkbox"]+label:after,
.sec-left .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAA////////////////////////////////600+wgAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-color: #043b41;
  background-color: var(--dark-blue-color);
  background-size: 12px;
}


.app-main .custom-checkbox input[type="checkbox"]:checked+label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.app-main .bottom-links {
  padding-top: 40px;
}

.app-main .bottom-links ul {
  margin: 0;
}

.app-main .bottom-links ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #043b41;
  color: var(--dark-blue-color);
  padding-right: 27px;
}

.app-main .bottom-links ul li:last-child {
  padding-right: 0;
}

.app-main .bottom-links ul li a {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}

.app-main .bottom-links button {
  font-size: 16px;
  background: 0 0;
  padding: 0;
  line-height: 1;
  color: #043b41;
  color: var(--dark-blue-color);
  text-transform: none;
  font-weight: 400;
  text-decoration: underline;
  min-width: 1px;
}

.app-main .bottom-links button:hover,
.app-main .bottom-links button:focus {
  color: #636363;
  color: var(--main-grey-color);
}

.app-main .right-column {
  width: 50%;
  /* background: #0a5c7f;
  background: -moz-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: -webkit-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: linear-gradient(180deg, #0a5c7f 0, #1588ba 100%); */
  color: #fff;
  color: var(--hover-font-color);
  padding: 90px 20px 40px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.app-main .col-head {
  text-align: center;
  padding: 50px 40px;
  position: absolute;
  top: 50px;
  z-index: 5;
}

.app-main .col-head h2 {
  text-transform: uppercase;
  font-size: 36px;
}

.app-main .col-head p {
  font-size: 20px;
  padding: 0 32px;
  line-height: 1.55;
}

.app-main .image-slider {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: auto;
}

.app-main .image-slider .slick-dots {
  bottom: 20px;
}

.app-main .image-item {
  padding: 10px 2px 100px;
}

.app-main .image-item img {
  display: block;
  margin: 0 auto;
  max-height: 355px;
}

.app-main .error-msg {
  display: block;
  color: #dc3545;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-right: 50px;
}

.app-main .error-msg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url(/static/media/alert-ic.eb2ffdf6.svg);
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
}

.app-main .slick-slider {
  /* display: flex; */
}

.app-main .slick-slider .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0 5px;
}

.app-main .slick-slider .slick-dots li button {
  padding: 0;
  background: #ffffff;
  background: var(--white-color);
  opacity: 0.5;
  min-width: 1px;
  width: 10px;
  height: 10px;
  transition: all 0.5s ease-in-out;
}

.app-main .slick-slider .slick-dots li button:before {
  display: none;
}

.app-main .slick-slider .slick-dots .slick-active button {
  width: 25px;
  opacity: 1;
}

.app-main .error input {
  /* border-color: #dc3545; */
  background-image: none !important;
}

.app-main .error select {
  /* border-color: #dc3545; */
  background-image: none !important;
}

.app-main .custom-radio {
  position: relative;
}

.app-main .custom-radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: -999999px;
}

.app-main .field-right .custom-radio {
  display: inline-block;
  vertical-align: middle;
}

.app-main .loc-content-main .location-inner-content .location-name .custom-radio label:hover,
.app-main .loc-content-main .location-inner-content .location-name .custom-radio label:active,
.app-main .loc-content-main .location-inner-content .location-name .custom-radio input[type="radio"]+label:hover,
.app-main .loc-content-main .location-inner-content .location-name .custom-radio input[type="radio"]+label:active {
  font-weight: 700;
}

.app-main .custom-radio input[type="radio"]+label {
  position: relative;
  padding-left: 40px;
  visibility: visible !important;
  line-height: 30px;
  cursor: pointer;
  font-weight: 400;
}

.app-main .custom-radio input[type="radio"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 100%;
}

.app-main .custom-radio input[type="radio"]+label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border: 6px solid #043b41;
  border: 6px solid var(--dark-blue-color);
  border-radius: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.app-main .custom-radio input[type="radio"]:checked+label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.app-main .custom-radio+.custom-radio {
  margin-left: 40px;
}

.app-main .MuiBackdrop-root {
  background: rgba(75, 76, 76, 0.85);
}

.app-main .popup-box {
  min-width: 550px;
  max-width: 700px;
  margin: auto;
  background: #ffffff;
  background: var(--white-color);
  border-radius: 5px;
  margin: 20px 0;
}

.app-main .box-heading {
  background: #0a5c7f;
  background: linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  text-align: center;
  padding: 15px 15px;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.app-main .box-heading img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}

.app-main .box-heading h2 {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  color: #ffffff;
  color: var(--white-color);
  font-size: 25px;
}

.app-main .close-btn {
  background: 0 0;
  min-width: 1px;
  padding: 0;
  line-height: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  width: 35px;
  margin: 0;
}

.app-main .box-heading .close-btn img {
  margin: 0 auto;
  display: block;
  padding: 10px;
}

.app-main .box-content {
  padding: 15px;
  text-align: center;
}

.app-main .box-content form {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 650px;
  /* text-align: left; */
}

.app-main .multiple-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.app-main .multiple-field input[type="text"] {
  border-radius: 4px;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  text-align: center;
}

.app-main .verify-otp-popup .box-content form {
  text-align: center;
}

.app-main .box-content p {
  font-size: 24px;
  margin-bottom: 26px;
}

.app-main .box-content a {
  color: #043b41;
  color: var(--dark-blue-color);
  border-bottom: 1px solid #043b41;
  border-bottom: 1px solid var(--dark-blue-color);
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  line-height: 1;
}

.app-main .main-sec-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 75px;
  min-height: 100vh;
  padding-top: 62px;
  transition: all 0.5s ease-in-out;
}

.app-main .content-heading-view {
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  display: flex;
  margin-top: 12px;
}

.app-main .content-heading-view-adbilder {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.app-main .main-sec-wrap.active-main {
  padding-left: 250px;
}

.app-main .main-content {
  width: 100%;
}

.app-main .content-right-main {
  padding: 35px 56px;
}

.app-main .content-right-heading {
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  font-weight: 550;
  color: #636363;
  color: var(--main-grey-color);
  line-height: 1.2;
  padding-left: 5px;
  padding-top: 13px;
  font-size: 18pt;
  width: 100%;
}

.app-main .content-right-heading-adbilder {
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  font-weight: 550;
  color: #636363;
  color: var(--main-grey-color);
  line-height: 1.2;
  font-size: 18pt;
  margin-top: -15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-main .form_field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.app-main .form_field label {
  font-weight: 700;
  display: block;
}

.app-main .field-left {
  width: 25%;
  padding-right: 15px;
}

.app-main .field-right {
  width: 75%;
  margin-bottom: 0px;
  position: relative;
}

.app-main .form-submit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-main .form-submit .button-field input {
  min-width: 196px;
}

.app-main .form-submit .button-field+.button-field {
  margin-left: 0px;
}

.app-main .head-top-data {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
  padding: 5px 0px;
  text-align: end;
  color: white;
  font-size: 13px;
  width: 100%;
  padding: 3px 20px;
}

.app-main .bootom-sec .header-nav {
  display: none;
}

.app-main .bootom-sec {
  width: 100%;
  margin-top: auto;
}

.app-main .not_found {
  justify-content: center;
  width: 100%;
}

.app-main .main-sec-wrap {
  padding-top: 62px;
  /* padding: 80px 190px;
text-align: center; */
}

.app-main .bootom-sec .header-nav li a {
  font-size: 12px;
  /* padding: 14px 10px; */
}

.app-main .bootom-sec .header-nav ul li svg {
  max-width: 15px;
  margin-right: 5px;
  margin-top: -3px;
}

.app-main .custom-border-table {
  margin-top: 30px;
}

.app-main .title-wrap {
  margin-bottom: -20px;
}

.app-main .title-wrap1 {
  margin-bottom: -5px;
}

.app-main .site-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.12);
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background: #fff;
  background: var(--hover-font-color);
}

.app-main .header-left {
  width: 18%;
  padding: 0px 25px 0px 10px;
}

.app-main .site-logo {
  display: inline-block;
  vertical-align: middle;
  padding-left: 44px;
}

.app-main .site-logo a {
  display: inline-block;
  max-width: 205px;
  width: 136px;
}

.app-main .site-logo a img {
  display: block;
}

.app-main .header-right {
  width: 82%;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.app-main .header-nav {
  padding: 0 15px;
}

.app-main .header-nav ul {
  margin: 0;
}

.app-main .header-nav ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding-right: 60px;
}

.app-main .header-nav ul li a.active {
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  font-weight: 600 !important;
}

/* .app-main .header-nav ul li div a.active {
  color: var(--nav-hover-color); !important;
} */

.submenu-active {
  /* color: var(--nav-hover-color) !important; */
  display: block !important;
  background-color: #f5f7fa;

}

.supply-active {
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  display: block !important;
  background-color: #f5f7fa;

}

.second-step a.active svg path,
.second-step a.active svg path {
  fill: #367E88 !important;
  fill: var(--nav-hover-color) !important;
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  display: block !important;
  background-color: #f5f7fa;
}


.pratice-submenu-active {
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  display: block !important;
  background-color: #f5f7fa;

}

.active-dropdown {
  cursor: pointer !important;
}

.app-main .header-nav ul li:last-child {
  padding-right: 0;
}

.app-main .header-nav ul li a {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #636363;
  color: var(--main-grey-color);
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  text-transform: capitalize;
  position: relative;
  padding: 11px 0;
}

.app-main .header-nav ul li a:hover,
.app-main .header-nav ul li a:focus {
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  font-weight: 600;
}

.app-main .header-nav ul li div:hover,
.app-main .header-nav ul li div:focus {
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  font-weight: 600;
}

.app-main .header-nav ul li div:hover a,
.app-main .header-nav ul li div:focus {
  color: #367E88 !important;
  color: var(--nav-hover-color) !important;
  font-weight: 600;
}

.app-main .header-nav-no ul li a:hover,
.app-main .header-nav-no ul li a:focus {
  color: #367E88;
  color: var(--nav-hover-color);
}

/* .app-main .header-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: var(--nav-hover-color);;
  transition: all 0.5s ease-in-out;
}

.app-main .header-nav ul li div :after  {
  content: "";
  position: absolute !important;
  bottom: 0 !important;
  left: 0;
  width: 0;
  height: 3px;
  background: var(--nav-hover-color); !important;
  transition: all 0.5s ease-in-out !important;
} */

.app-main .header-nav-no ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  background: #636363;
  background: var(--main-grey-color);
  transition: all 0.5s ease-in-out;
}


.app-main .header-nav-no ul li a:hover svg path,
.app-main .header-nav-no ul li a:focus svg path {
  fill: #ccc !important;
}

.app-main .header-nav ul li a:hover:after,
.app-main .header-nav ul li a:focus:after {
  width: 100%;

}

.app-main .header-nav ul li div:hover:after,
.app-main .header-nav ul li div:focus:after {
  width: 100% !important;

}

.app-main .header-nav ul li .icon-status {
  top: 28px;
  left: 19px;
}

.app-main .header-nav ul li a svg path {
  transition: all 0.5s ease-in-out;
}

.app-main .header-nav ul li a:hover svg path,
.app-main .header-nav ul li a:focus svg path {
  fill: #367E88 !important;
  fill: var(--nav-hover-color) !important;
}

.app-main .header-nav ul li div:hover svg path,
.app-main .header-nav ul li div:focus svg path {
  fill: #367E88;
  fill: var(--nav-hover-color);
}

.app-main .header-nav ul li svg {
  display: inline-block;
  vertical-align: middle;
  /* margin-right: 11px; */
  color: #636363 !important;
  color: var(--main-grey-color) !important;
}

.app-main .account-info {
  padding: 20px;
  cursor: pointer;
}

.app-main .MuiButton-label {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  padding: 0 23px 0 0;
  position: relative;
}

.app-main .MuiButton-label:after {
  content: "";
  position: absolute;
  top: -32px;
  right: 5px;
  bottom: 0;
  background: url(/static/media/dropdown-arrow.edeff98c.svg) no-repeat center center;
  width: 15px;
  background-size: 15px;
}

.app-main .MuiButton-label {
  align-items: center;
}

.app-main .MuiButton-label img {
  margin-right: 8px;
}

.app-main .MuiButtonBase-root.MuiButton-text {
  padding: 0;
}

.app-main .MuiButton-root:hover,
.app-main .MuiButton-root:focus {
  background-color: transparent !important;
}

.app-main .right-icons {
  height: 31px;
  padding: 4px 20px;
  border-left: 2px solid #dddddd;
  margin-right: 10px;
}

.app-main .right-icons ul {
  margin: 0;
}

.app-main .right-icons ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding-right: 25px;
}

.app-main .right-icons ul li:last-child {
  padding-right: 0;
}

.app-main .right-icons ul li a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.app-main .right-icons ul li a img {
  max-width: 24px;
  display: block;
}

.app-main .icon-status {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background: #367E88;
  background: var(--nav-hover-color);
  border-radius: 100%;
  position: absolute;
  top: -7px;
  right: -6px;
  color: #ffff;
  text-align: center;
  line-height: 13px;
  font-size: 8px;
  border: 1px solid #fff;
  border: 1px solid var(--hover-font-color);
  font-weight: 500;
}

.app-main .left-img {
  width: 40px;
}

.app-main .right-content {
  width: calc(100% - 40px);
  padding-left: 14px;
  position: relative;
}

.app-main .right-content p {
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main .right-content p strong {
  font-weight: 500;
}

.app-main .right-content p small {
  color: #cccccc;
  font-size: 14px;
  display: block;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
}

.app-main .noti-time {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  color: #cccccc;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  position: absolute;
  top: 0;
  right: 0;
}

/* .sidebar { overflow: hidden; } */

.app-main .hide-scroll .site-header {
  width: calc(100% - 17px);
}

.app-main .hide-scroll {
  overflow: hidden;
  margin-right: 17px;
}

.app-main .mac_device {
  margin-right: 0;
}

.app-main .mac_device.hide-scroll {
  margin-right: 0;
}

.app-main .mac_device .site-header {
  width: 100%;
}

.app-main .mac_device.hide-scroll .site-header {
  width: 100%;
}

/*  left-sidebar  */

.app-main .left-sidebar {
  background: rgb(10, 92, 127);
  background: linear-gradient(180deg, rgba(10, 92, 127, 1) 0%, rgba(21, 136, 186, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0a5c7f", endColorstr="#1588ba", GradientType=1);
  width: 75px;
  padding: 25px 0 0;
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 0;
  top: 85px;
  height: calc(100vh - 85px);
  overflow-y: auto;
  z-index: 10;
}

.app-main .left-sidebar.active {
  left: 0px;
  width: 250px;
}

.app-main .humburger-menu {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 0;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 15px;
}

.app-main .humburger-menu .menu-text {
  text-align: end;
  color: #cccccc;
  font-size: x-small;
  margin-bottom: 2px;
  font-weight: bold;
}

.app-main .humburger-menu span {
  display: block;
  background: #cccccc;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  margin-left: auto;
}

.app-main .humburger-menu span:last-child {
  margin-bottom: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content span {
  display: block;
  width: 100%;
}

.app-main .left-sidebar nav {
  overflow: hidden;
  position: relative;
  left: -250px;
  width: 250px;
  transition: all 0.5s ease-in-out;
}

.app-main .left-sidebar.active nav {
  left: 0px;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root a {
  padding-left: 50px;
  border: none;
}

.app-main .left-sidebar ul {
  margin: 0;
}

.app-main .left-sidebar li {
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 16px;
  margin-left: 0px;
}

.app-main .left-sidebar li a,
.app-main .left-sidebar .MuiExpansionPanelSummary-content span {
  display: block;
  width: 100%;
  line-height: 1;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.app-main .left-sidebar li a:hover,
.app-main .left-sidebar li a:focus,
.app-main .left-sidebar .MuiExpansionPanelSummary-content span:hover,
.app-main .left-sidebar .MuiExpansionPanelSummary-content span:focus {
  color: #fff;
  color: var(--hover-font-color);
  font-weight: 600;
  /* text-decoration: underline; */
}

.app-main .left-sidebar li a:hover,
.app-main .left-sidebar li a:focus,
.app-main sup {
  display: inline-block;
  text-decoration: none;
}

.app-main .left-sidebar li a img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 26px;
  opacity: 0.7;
  transition: all 0.5s ease-in-out;
}

.app-main .left-sidebar li a:hover img,
.app-main .left-sidebar li a:focus img {
  opacity: 1;
}

.app-main .left-sidebar .MuiPaper-root.MuiExpansionPanel-root {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  box-shadow: none;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 1px;
  padding: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0;
  padding: 0;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root {
  padding: 0;
  flex-wrap: wrap;
  /* background: rgba(10, 92, 127, 0.5); */
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content {
  margin: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-root {
  min-height: 1px;
  padding: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content span {
  position: relative;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root>div {
  width: 100%;
}

.app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
  padding-left: 30px;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
  padding: 0px 0px 0px 45px;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
  position: relative;
  z-index: 1;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:after {
  content: "";
  position: absolute;
  top: -2px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  text-decoration: underline;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:hover:after,
.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:focus:after {
  opacity: 1;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  display: block;
  line-height: 1;
  width: 100%;
  padding: 10px 0px 10px;
  transition: border 0.5s ease-in-out;
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  -moz-transition: border 0.5s ease-in-out;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:hover span,
.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:focus span {
  border-color: transparent;
  transition: none;
}

.app-main .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
  color: #fff;
  color: var(--hover-font-color);
  padding: 10px;
}

.app-main .left-sidebar .MuiCollapse-container.MuiCollapse-entered {
  margin-top: 0px;
}

.app-main .left-sidebar .subparent-nav .MuiCollapse-container.MuiCollapse-entered {
  margin-top: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-root.active {
  /* background: #01afb0; */
  color: #fff;
  color: var(--hover-font-color);
  font-weight: bold;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active {
  /* background: #01afb0; */
  color: #fff;
  color: var(--hover-font-color);
  font-weight: bold;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active:after {
  opacity: 1;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active span {
  border-color: transparent;
  transition: none;
}

/*     Resource     */

.app-main .main_tab_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: white;
  padding: 40px 40px 0px;

}

.app-main .box-feed-desc {
  white-space: break-spaces;
  overflow-y: scroll;
  max-height: 150px;
  text-overflow: ellipsis;
  margin: 10px 5px 0;
}

.app-main .box-feed-desc::-webkit-scrollbar {
  display: none;
}

.app-main .content-box-feed {
  background: #fff;
  background: var(--hover-font-color);
  padding: 5px;
  margin-right: 1.6%;
  border: 2px solid #ebebeb;
  border-radius: 7px;
  position: relative;
  margin-bottom: 35px;
  width: 250px;
  /* height: 300px; */
}

.app-main .content-box {
  background: #fff;
  background: var(--hover-font-color);
  margin-right: 2.6%;
  border: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 35px;
  width: 255px;
  /* height: 300px; */
}

.app-main .landing-page-content-box {
  width: 205px;
  margin-bottom: 25px;
  margin-right: 1%;
  border-radius: 10px 10px 0 0
}

.app-main .content-box-calendar {
  /* background: var(--hover-font-color); */
  /* border: 1px solid #ebebeb; */
  position: relative;
  margin-bottom: 5px;
  /* width: 110px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.app-main .two-col-sec-calendar {
  background: #fcfcfc;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}

.app-main .free-asset-content-box {
  height: 315px;
  /* width: 250px; */
  align-items: center;
  justify-content: center;
}

.app-main .one-col-content {
  background: #fff;
  background: var(--hover-font-color);
  border: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.app-main .one-col-content-box {
  background: #fff;
  background: var(--hover-font-color);
  border: 2px solid #043b41;
  border: 2px solid var(--dark-blue-color);
  position: relative;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.app-main .content-box:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-main .content-box-calendar:after {
  content: "";
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-main .content-box:hover:after,
.app-main .content-box:focus:after {
  opacity: 1;
}

.app-main .content-box-calendar:hover:after,
.app-main .content-box-calendar:focus:after {
  opacity: 1;
}

.app-main .content-box:nth-child(3n) {
  margin-right: 2.6%;
}

.app-main .ecp_resource .tab-listing ul li {
  padding-right: 30px;
}

.app-main .box-feed-image {
  background-repeat: no-repeat;
  border-bottom: 1px solid #ebebeb;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  height: 230px;
  display: grid;
  align-content: space-around;
  align-items: center;
}

.app-main .box-image {
  background-repeat: no-repeat;
  border-bottom: 1px solid #ebebeb;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  padding: 17% 15px;
  height: 300px;
  display: grid;
  align-content: space-evenly;
  align-items: center;
}

.app-main .landing-page-box-image {
  background-repeat: no-repeat !important;
  background-origin: padding-box !important;
  height: 200px !important;
  border: 4px solid #000000 !important;
  border-radius: 10px !important;
}

.app-main .free-asset-image-box {
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: cover;
  height: 255px;
  position: relative;
}

.app-main .box-image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  background: #0a0a0a;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.app-main .button-hover {
  opacity: 0;
}

.app-main .content-box:hover .box-image:after,
.app-main .content-box:focus .box-image:after {
  opacity: 0.7;
}

.app-main .content-box:hover .button-hover,
.app-main .content-box:focus .button-hover {
  opacity: 1;
}

.app-main .box-image-calendar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  background: #0a0a0a;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  /* z-index: -1; */
}

.app-main .content-box-calendar:hover .box-image-calendar:after,
.app-main .content-box-calendar:focus .box-image-calendar:after {
  opacity: 0.7;
}

.app-main .content-box-calendar:hover .button-hover,
.app-main .content-box-calendar:focus .button-hover {
  opacity: 1;
}

.app-main .hover-button-calendar {
  padding: 65% 1% 1% 1%;
}

.app-main .calendar-image-box {
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: 160px;
  height: 165px;
  width: 165px;
  /* position: relative; */
  display: grid;
  justify-items: center;
}

.app-main .calendar-hover-button {
  font-weight: 500;
  color: #ffffff;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 100px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 14px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-main .calendar-grid-main {
  /* display: grid;
  height: 1225px;
  grid-template-columns: 13.95% 13.93% 13.91% 13.89% 13.95% 13.95% 13.91%;
  grid-template-rows: 200px 200px 200px 200px 200px 200px;
  grid-gap: 5px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.app-main .box-content-info p {
  color: rgba(100, 100, 100, 0.6);
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  margin-bottom: 5px;
}

.app-main .box-content-info h4 {
  margin: 0 0 0px;
  white-space: pre;
  max-height: 50px;
  overflow: clip;
}

.app-main .box-label-feed {
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.app-main .box-lable {
  font-size: 16px;
}

.app-main .box-content-info {
  padding: 20px 20px 22px;
}

.app-main .box-content-info p:last-child {
  margin-bottom: 0;
}

.app-main .box-image .button-field {
  display: table;
  margin: 0 auto 15px;
}

.app-main .box-image .button-field:last-child {
  margin-bottom: 0;
}

.app-main .MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.5) !important;
}

/*        Clinic       */

.app-main .tab-listing ul {
  margin: 0 0 -19px 0;
}

.app-main .tab-listing ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #636363;
  color: var(--main-grey-color);
  font-weight: 500;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  text-transform: uppercase;
  padding-right: 30px;
}

.app-main .MuiTableRow-root .MuiTableCell-head.center {
  text-align: center;
}

.app-main .tab-content {
  padding: 0px 0 0;
}

.app-main .tab-content .form-main {
  margin-top: 0;
}

.app-main .tab-listing {
  padding: 0 0 20px 0;
}

.app-main .tab-listing ul li a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  padding-bottom: 10px;
  padding-right: 0;
}

.app-main .tab-listing ul li a:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 3px;
  background: #1073c6;
  background: var(--blue-color);
  width: 0;
  transition: all 0.5s ease-in-out;
}

.app-main .tab-listing ul li a:hover,
.app-main .tab-listing ul li a:focus,
.app-main .tab-listing ul .active {
  color: #1073c6;
  color: var(--blue-color);
  font-weight: 700;
}

.app-main .tab-listing ul .active:after,
.app-main .tab-listing ul li a:hover:after,
.app-main .tab-listing ul li a:focus:after {
  width: 100%;
}

.app-main .tab-content .field-left {
  width: 23%;
}

.app-main .tab-content .field-right {
  width: 77%;
}

.app-main .tab-content .field-right-image {
  width: 100%;
}

.app-main .tab-content .form-main form {
  max-width: 843px;
}

.app-main .table-link a {
  color: #043b41;
  color: var(--dark-blue-color);
  text-decoration: underline;
}

.app-main .table-main-wrap {
  text-align: right;
}

.app-main .tab-listing+.table-main-wrap {
  margin: 0;
}

.app-main .table-link {
  padding-bottom: 20px;
}

.app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
  min-width: 80px;
}

.app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 200px;
}

.app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 175px;
}

.MuiTableRow-root .MuiTableCell-root:last-child {
  min-width: 105px;
}

.app-main .MuiTableRow-root th.MuiTableCell-root {
  font-weight: 700;
}

.app-main .data-popup-box {
  min-width: 1120px;
  max-width: 1120px;
  margin: 160px 0;
}

.app-main .data-popup-box .box-heading {
  padding: 38px 15px;
}

.app-main .data-popup-box .box-heading .close-btn img {
  padding: 0;
  width: 20px;
}

.app-main .data-popup-box .close-btn {
  top: 30px;
  right: 24px;
}

.app-main .data-popup-box .box-content form {
  max-width: 845px;
}

.app-main .data-popup-box .box-content {
  padding: 50px 40px;
}

.app-main .data-popup-box .box-content form .field-right {
  width: 77%;
}

.app-main .data-popup-box .box-content form .field-left {
  width: 23%;
}

.app-main .data-popup-box .form-submit {
  justify-content: center;
}

.app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 133px;
}

.app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 135px;
}

.app-main .MuiTableRow-root th.MuiTableCell-root {
  border-bottom-color: transparent;
  border-bottom-width: 0;
}

.app-main .tab-listing .slick-next {
  position: absolute;
  right: 0;
  top: -15px;
  bottom: 0;
  height: auto;
  margin: 0;
  -webkit-transform: none;
          transform: none;
  display: none !important;
}

.app-main .tab-listing .slick-next:before {
  display: none;
}

.app-main .tab-listing .slick-next:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url(/static/media/right-arrow.57d8ae5a.svg);
  background-repeat: no-repeat;
  background-position: center right 30px;
  width: 80px;
  background-color: #f5f7fa;
  background-color: var(--white-background-color);
  background-size: 7px;
  display: none;
}

.app-main .tab-listing .slick-next.slick-disabled {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.app-main .tab-listing .slick-prev {
  display: none !important;
}

.app-main .tab-listing .slick-slide {
  /* width: auto !important; */
  padding-right: 13px;
}

.app-main .tab-listing .slick-track {
  margin-left: 0;
  display: flex;
  opacity: 20 !important;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
          transform: translate3d(0px, 0px, 0px) !important;
  width: auto !important;
}

.app-main .content-heading-dropdown {
  direction: ltr;
  justify-content: flex-end;
  /* align-items: flex-end; */
  display: flex;
  margin-top: 5%;
  width: 48.5%;
}

/*        product-selection        */

.app-main .three-block-sec {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-right: 4px;
  /* padding-left: 65px; */
}

.app-main .block-col {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 8px;
  width: 25%;

  margin-right: 2.05%;
  margin-bottom: 2.05%;
  text-align: center;
}

.app-main .block-col:nth-child(3n) {
  margin-right: 0;
}

.app-main .hover_link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 15px 15px;
}

.app-main .hover_link:hover,
.app-main .hover_link:focus {
  background: #01afb0;
  color: #fff;
  color: var(--hover-font-color);
  border-radius: 8px;
}

.app-main .block-col p {
  margin: 0 0 7px;
  font-size: 13px;
  white-space: pre;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  color: rgba(100, 100, 100, 0.6);
  letter-spacing: 0.5px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  height: 60px;
}

.app-main .hover_link:hover p,
.app-main .hover_link:focus p {
  color: rgba(255, 255, 255, 0.7);
}

.app-main .block-col img {
  display: block;
  margin: 0 auto 0px;
  width: 80px;
  /* height: 75px; */
  padding: 10px;
}

.app-main .block-col h2 {
  font-weight: 500;
  margin: 0;
  font-size: 25px;
  bottom: 0;
}

.app-main .block-col.active .hover_link {
  background: #01afb0;
  color: #fff;
  color: var(--hover-font-color);
}

.app-main .block-col.active .hover_link p {
  color: rgba(255, 255, 255, 0.7);
}

.app-main .DraftEditor-editorContainer {
  /* height: 280px !important; */
  max-height: 280px !important;
  overflow: auto, im !important;
}

.app-main .two-col-sec {
  background: #fff;
  background: var(--hover-font-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}

.app-main .two-col-sec p {
  font-size: 15px;
  letter-spacing: 0;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  line-height: 1.4;
  margin-bottom: 15px;
}

.app-main .textparagraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  line-height: 2;
  color: #334455;
}

.app-main .textheading {
  font-size: 22px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  line-height: 2;
  color: #334455;
}

.app-main .textlist {
  letter-spacing: 0;
  line-height: 0;
}

.app-main .two-col-sec p:last-child {
  margin-bottom: 0;
}

.app-main .sec-left {
  width: 62%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 0px;
}

.app-main .sec-right {
  align-self: flex-start;
  width: 38%;
  /* padding: 10px 0 0 50px; */
}

.app-main .sec-right-calender {
  align-self: flex-start;
  width: 38%;
  /* padding: 10px 0 0 50px; */
}

.app-main .sec-right img {
  display: block;
  margin: 0 auto;
}

.app-main .two-col-sec h3 {
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  font-weight: 400;
  font-size: 19px;
}

.app-main .confirmation-message {
  font-size: 27px;
  text-align: center;
}

.app-main .two-col-sec p+h3 {
  margin-top: 56px;
  margin-bottom: 21px;
}

.app-main .two-col-sec li {
  /* font-size: 24px; */
}

.app-main .two-col-sec ul {
  margin: -10 0 50px;
}

.app-main .two-col-sec ul li {
  list-style: none;
  display: block;
  position: relative;
  padding-left: 26px;
  line-height: 1.4;
  /* margin-bottom: 20px; */
  font-size: 15px;
  text-align: justify;
}

.app-main .two-col-sec ul li:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 1px;
  width: 5px;
  height: 5px;
  background: #4b4c4c;
  border-radius: 100%;
}

.app-main .detail-welcome {
  margin-top: 28px;
  padding-bottom: 12px;
  margin-bottom: 40px;
}

.app-main .detail-welcome .sec-right {
  padding-top: 9px;
}

.app-main .full-field label {
  display: block;
  margin-bottom: 15px;
}

.app-main .full-field {
  margin-bottom: 2px;
}

.app-main .full-field p small {
  font-size: 14px;
  color: #55555580;
}

.app-main .full-field p {
  line-height: 1;
  margin-top: 22px;
}

.app-main .blue-link {
  text-align: center;
  margin-top: 40px;
}

.app-main .blue-link a {
  color: #043b41;
  color: var(--dark-blue-color);
  font-size: 24px;
  text-decoration: underline;
  display: inline-block;
  vertical-align: middle;
}

.app-main .blue-link a:hover,
.app-main .blue-link a:focus {
  text-decoration: none;
}

.app-main .full-field.budget_detail_field {
  max-width: 650px;
}

/*        adbreak         */

.app-main .page-img .next-step-btn {
  margin-top: 30px;
}

.app-main .page-img .back-btn {
  margin-top: 30px;
}

.app-main .full-page-wrap h1 {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 169px);
}

.app-main .page-img {
  margin-top: 15px;
}

.app-main .white-box-wrapper {
  padding-top: 57px;
}

.app-main .white-box-content {
  background: #fff;
  background: var(--hover-font-color);
  padding: 40px;
  margin-top: 15px;
}

.app-main .step-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  counter-reset: section;
  margin-top: 48px;
  margin-bottom: 10px;
}

.app-main .step-col h3 {
  line-height: 1.25;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: #ccc;
}

.app-main .step-col h3 span {
  display: block;
}

.app-main .right-continue-btn {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}

.app-main .step-col {
  position: relative;
  counter-increment: section;
  max-width: 90px;
  margin: 0 5.3%;
}

.app-main .step-col:first-child {
  margin-left: 0;
}

.app-main .step-col:last-child {
  margin-right: 0;
}

.app-main .step-col:after {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  color: #ccc;
  border-radius: 100%;
  margin: 0 auto;
  /* content: counter(section); */
  text-align: center;
  background: #ebebeb;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.app-main .active-step:after {
  content: "";
  background-image: url(/static/media/blue-check-ic.b5e9de8d.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.app-main .active-step:before {
  background-color: #043b41 !important;
  background-color: var(--dark-blue-color) !important;
}

.app-main .active-step h3 {
  color: #043b41;
  color: var(--dark-blue-color);
}

.app-main .step-col.active:after {
  background: #043b41;
  background: var(--dark-blue-color);
  color: #ffffff;
}

.app-main .active-step h3,
.app-main .step-col.active h3 {
  color: #043b41;
  color: var(--dark-blue-color);
}

.app-main .white-box-image {
  text-align: center;
  margin-top: 39px;
  margin-bottom: 37px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 38px 0 46px;
  align-items: flex-start;
}

.app-main .white-box-image img {
  display: block;
  margin: 0 auto;
}

.app-main .step-col:before {
  content: "";
  height: 3px;
  position: absolute;
  top: -36px;
  margin: auto 0;
  background: #ebebeb;
  width: 260px;
  left: 61%;
}

.app-main .step-col:last-child:before {
  display: none;
}

.app-main .content_col_557 {
  max-width: 559px;
}

.app-main .content_img_col {
  border: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
}

.app-main .content_col_small {
  margin-right: 4.5%;
  width: 12.32%;
}

.app-main .content_col_middle {
  margin-right: 4.5%;
  width: 22.95%;
}

.app-main .content_col_large {
  width: 55.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
}

.app-main .content_img_row {
  margin-bottom: 36px;
  margin-right: 13.2%;
  display: flex;
  flex-wrap: wrap;
}

.app-main .content_img_row1 {
  border: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
}

.app-main .row-300-width {
  width: 41.35%;
}

.app-main .row-250-width {
  width: 34.51%;
  margin-right: 0;
}

.app-main .content_img_row:first-child,
.app-main .content_img_row:last-child {
  width: 100%;
  margin-right: 0;
}

.app-main .content-info h6 {
  color: #ccc;
  margin-bottom: 0px;
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  text-align: left;
  font-size: 18px;
}

.app-main .content_col_small .content-info h6 {
  margin-bottom: 7px;
  width: 100%;
  font-size: 16px;
}

.app-main .content-info p {
  color: #ccc;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  text-align: right;
  font-size: 18px;
}

.app-main .content_col_small .content-info p {
  width: 100%;
  text-align: left;
}

.app-main .image_content {
  width: 100%;
}

.app-main .content-info {
  padding: 5px 10px;
  margin-top: auto;
  width: 100%;
}

.app-main .row-320-width {
  max-width: 44.1%;
}

.app-main .content_img_col:only-child {
  margin: 0 auto;
}

.app-main .image_content img {
  width: 100%;
}

.app-main .cart-list-content {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1%;
}

.app-main .cart-label-heading {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-main .cart-label-subdata {
  color: #0A0A0A;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-main .cart-label-location {
  color: #636363;
  color: var(--main-grey-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-main .cart-label-subdata-link {
  color: #043b41;
  color: var(--dark-blue-color);
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 5px;
}

.app-main .cart-price-main {
  width: 100%;
  margin-top: 1%;
  align-items: center;
}

.app-main .cart-art-proof-link-icon {
  color: #043b41;
  color: var(--dark-blue-color);
  font-size: 13px;
  text-align: center;
}

.app-main .cart-art-proof-link {
  color: #043b41;
  color: var(--dark-blue-color);
  font-size: 13px;
  cursor: pointer;
}

.app-main .cart-total-div {
  margin-top: 2%;
  justify-content: space-between;
  display: flex;
}

.app-main .cart-total-div-button {
  justify-content: end;
}

.app-main .cart-subtotal-text {
  color: #000000;
  color: var(--main-black-color);
  font-size: 18px;
  font-weight: 700;
}

.app-main .cart-fund-text {
  color: #000000;
  color: var(--main-black-color);
  font-size: 14px;
  font-weight: 400;
}

.app-main .cart-fund-amount {
  color: #000000;
  color: var(--main-black-color);
  font-size: 14px;
  font-weight: 700;
}

.app-main .cart-total-text {
  color: #043b41;
  color: var(--dark-blue-color);
  font-size: 18px;
  font-weight: 700;
}

.app-main .cart-terms-conditions {
  color: #043b41;
  color: var(--dark-blue-color);
  font-size: 14px;
  font-weight: 400;
  text-align: end;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}

.app-main .check-input-coop {
  accent-color: #043b41;
  accent-color: var(--dark-blue-color);
  height: 18px;
  width: 18px;
}

.app-main .red-input {
  accent-color: #043b41;
  accent-color: var(--dark-blue-color);
}

.app-main .Suggetion-wrap {
  position: absolute;
  top: 60;
  right: 0;
  left: 0;
  z-index: 10;
  border: 2px solid #007b88;
  border: 2px solid var(--main-blue-color);
  border-radius: 10px;
  padding: 10px;
  color: #636363;
  color: var(--main-grey-color);
  background-color: #fff;
  background-color: var(--hover-font-color);
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-main .DirectMail-file-label {
  color: #043b41;
  color: var(--dark-blue-color);
}

.app-main .DirectMail-download-file-label {
  color: #043b41;
  color: var(--dark-blue-color);
  display: flex;
  justify-content: end;
  margin-bottom: 2%;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.app-main .arrow-popup .submenu {
  margin: 0 0 0 200px;
}

.app-main .arrow-popup.notification h6 {
  font-size: 16px;
  color: #043b41;
  color: var(--dark-blue-color);
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  margin: 0;
  border-bottom: 1px solid #f5f7fa;
  border-bottom: 1px solid var(--white-background-color);
  padding: 23px 16px 19px;
}

.app-main .logo-icon-location {
  border: 2px solid #dddddd;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  padding: 2px;
  margin: 0 10px 2% 0;
  height: 24px;
}

.MuiOutlinedInput-multiline {
  padding: 0px !important;
}

.app-main .dotted-border-list {
  display: flex;
  width: 60%;
  border-bottom: 4px dotted rgb(135, 214, 238);
  color: rgb(15, 86, 140);
  padding: 3% 0px;
}

.app-main .dotted-border-main {
  width: 99%;
  border-top: 4px dotted #87d6ee;
  border-bottom: 4px dotted #87d6ee;
  margin: 2% 0px 2%;
  padding: 1% 0px;
  text-align: center;
  display: grid;
}

.app-main .dotted-border-main label {
  font-size: 23px;
  font-weight: 700;
  color: #0f568c;
}

.app-main .field-right-img {
  position: absolute;
  width: 50px;
  align-items: center;
  height: 91%;
  margin: 2px 3px 0 0;
  justify-content: center;
  right: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmDBcGJAtmQJSEAAACoUlEQVRo3u3WXUhTYRgH8OepOXM1zahgJEhgoV54kxcZIYGIDhYqHidYqNW8GNZFuTAxG8MikGHkB+REcWKiHh1YxMoPJGLpRRfeFIF0Eazsg9BzzmKibk8XMstIzpz7uOj93Zyb933O83/Oew4HgGEYhmEY5j+FcgtSrpeVtZ5OSJB6fU34TqOJdcPBUl/e20yZi4vuBzx/Y87r3W6dQq6QZwI/+Rf1egDFXbjT1xfrYMHyfMZ5TK6qAgCAuf7+7dbtkSu0/Hbktemj3U6jkENXrdZYB5P1nDrgRXv78vDwQt2V7YMHyL4CfyJCTLpYnmAVOzvhKZ3HbqMx1nk3e+uBI+Do7RW5kc46l8EAAIBIJLdP9gRsmRYSCQPDXlNibS0A8gA2W8yDl8NBOGe3i1xGh+SqqQk2eEgD2DIIId0v1RmN8Bj205mBgegnJzOYeV60fe2SThkMABa0oN+/0zIhDWDDxg0FHXlS3dXVkE8WbBwainjuaWqCeIdDEL9lSp6KCoCXaMH19VDr7WIAATyv1/t8wmhy64GHlZV0mxqxZXw87MmNVA8pTqeY7SlRPtt98IAdfQSDU8aZSalMmoRGdfrYGHB4H2p0upDLZdBNfDQxIcypssWFoiIAO1pwZSVc3YbhBPyNH7Xg6qqQD/ek96WlgSe34zIEX9A0NaXOgRY8XFwc7uARHMDWQaiOe0kq5zh6BW5on5mR3dYDH7DZ5VIW0NF9XEmJ3J/cbkXgFfg3TZfujZlUqp9+Va3a5nTiLUgFKTd3c4EJtHBpdlaRtXLC972g4EfRk7P16ZIU6b6iNoCAQ9oL19oKExN9tJa1Oj85SXZKAy4uDk/Gda8dy8sThMHBhoalpWj3FQPatLbC+PjfV4ZhGIZhGCaqfgFQ+gYOxw0/5QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMi0yM1QwNjozNjoxMSswMDowMJAvdEEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTItMjNUMDY6MzY6MTErMDA6MDDhcsz9AAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEyLTIzVDA2OjM2OjExKzAwOjAwtmftIgAAAABJRU5ErkJggg==);
  background-position: center;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-color: #f5f7fa;
  background-color: var(--white-background-color);
  z-index: 1;
  display: flex;
}

.app-main .field-right-text {
  position: absolute;
  width: 50px;
  align-items: center;
  height: 100%;
  justify-content: center;
  right: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #0f568c;
  z-index: 1;
  display: flex;
  color: rgb(255, 255, 255);
  font-size: 25px;
}

.app-main .calculator-main-tabView {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 12px;
  text-align: center;
  border: 1px solid #2d88d7;
  border-radius: 5px;
  margin-left: 15px;
}

.app-main .calculator-tabView-active {
  width: 100%;
  background: #2d88d7;
  padding: 3px 0px;
  border-radius: 3px;
  color: #fff;
  color: var(--hover-font-color);
  font-weight: 600;
}

.app-main .calculator-tabView-deActive {
  width: 100%;
  background: #fff;
  background: var(--hover-font-color);
  padding: 3px 0px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.app-main .location-red-main-toggle {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 12px;
  text-align: center;
  border: 1px solid #ff0000;
  border-radius: 5px;
  margin-left: 15px;
}

.app-main .location-red-toggle-active {
  width: 100%;
  background: #ff0000;
  padding: 3px 0px;
  border-radius: 3px;
  color: #fff;
  color: var(--hover-font-color);
  font-weight: 600;
}

.app-main .location-red-toggle-deActive {
  width: 100%;
  background: #fff;
  background: var(--hover-font-color);
  color: #ff0000;
  padding: 3px 0px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.app-main .library-list-main {
  margin-top: 5px;
  padding: 15px 5px;
  display: grid;
  max-height: 675px;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-columns: 210px 210px;
  grid-gap: 4px 6px;
  box-shadow: none;
}

.app-main .flagged-icons-main {
  position: absolute;
  right: 5px;
  bottom: 0px;
  padding-left: 5px;
  background: #ffffff;
  background: var(--white-color);
}

.app-main .email-send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px !important;
  min-width: 100px !important;
  width: 100px !important;
}

.my-facebook-button-class {
  background: #4267b2 !important;
  border-radius: 5px !important;
  height: 40px !important;
  text-align: center !important;
  width: 200px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.my-instagram-button-class {
  background: #f09433 !important;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;

  border-radius: 5px !important;
  color: white !important;
  height: 40px !important;
  text-align: center !important;
  width: 200px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* oder card css  dashboard */


.app-main .react-google-flight-datepicker .icon-calendar {
  width: 0px;
  height: 0px;
  margin-right: 0px;
}

.app-main .react-google-flight-datepicker .date-picker-input svg {
  min-width: 0px;
  min-height: 0px;
}

/* .app-main .react-google-flight-datepicker {
  width: 310px;
} */

.app-main .react-google-flight-datepicker .dialog-header .date-picker-input {
  width: 60% !important;
}

.react-google-flight-datepicker .change-date-group .change-date-button {
  min-width: 5px;
}

.react-google-flight-datepicker .dialog-date-picker {
  top: 60px !important;
  left: -500px !important;
  z-index: 99 !important;
}

.react-google-flight-datepicker .change-date-group {
  width: 40px !important;
}

.content-right-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.content-right-data {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.report-select-box {
  width: 40%;
  margin: 0px 5px;
}

.location-select-box {
  width: 23%;
  margin: 0 10px;
}

.date-piker {
  padding: 0px !important;
  margin-left: 5px;
}

.react-google-flight-datepicker .date-picker-input {
  height: 39px !important;
}

.card-main {
  display: flex;
  justify-content: space-between;
  overflow: hidden !important;
  margin: 0px 5px;
}

.paid-card {
  width: 32%;
}

.card-item {
  background-color: #ffffff;
  background-color: var(--white-color);
  padding: 15px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.card-item:hover {
  background-color: #00010110;
}

.paid-chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paid-chart-inner {
  width: 70%;
}

.paid-chart-data {
  width: 100% !important;
}

.paid-chart-tabbedCarts {
  width: 30%;
}

.social-chart-main {
  display: flex;
  justify-content: flex-start;
}













.app-main .login-header-gradient-line {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
  padding: 17px;
  width: 100%
}

.app-main .login-logo-new {
  display: flex;
  padding: 10px 13%;
  margin-bottom: 0px;
  width: 100%
}

.app-main .login-page-background {
  border-top: 1px solid #3f90db21;
  background-image: url(/static/media/background.5b8c0102.png);
  width: 100%;
  position: relative;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover
}

.app-main .login-header-text {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 5%
}

.app-main .login-text-h2 {
  margin: 15px 0 0 0;
  color: #16416F;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  font-weight: 700;
  font-size: 36px
}

.app-main .login-text-h3 {
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  font-weight: 600;
  font-size: 25px;
}

.app-main .login-text-h5 {
  margin: 0;
  color: #16416F;
  font-family: "Barlow", sans-serif;
  font-family: var(--font-family-new);
  font-weight: 500;
  font-size: 20px
}

.app-main .login-footer-gradient-line {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  width: 100%;
}

.app-main .login-footer-text {
  margin: 0px;
  color: #e5e1e1;
  font-size: 10px;
}

.app-main .free-asset-main-content-box {
  background-color: #ffffff;
  background-color: var(--white-color);
  margin-top: 8%;
}

.app-main .free-asset-tile-heading {
  color: #16416f;
  font-size: 23px;
  font-weight: bold;
  margin: 0px 25px;
  font-family: "Barlow", sans-serif,;
  font-family: var(--font-family-new),
}























































@media (min-width: 1720px) {
  .app-main .main {
    max-width: 1240px;
    margin: 0 auto;
  }
}

@media (max-width: 1700px) {
  .app-main .step-col {
    margin: 0 3.5%;
  }

  .app-main .step-col:before {
    width: 175px;
  }

  .app-main .white-box-image {
    padding: 0;
  }

  /* .card-item {
    width: 22vw;
  } */
}

@media (min-width: 1501px) and (max-height: 800px) {
  .app-main .login_page {

    height: calc(100vh);
  }

  .app-main .col-head {
    padding: 0 20px;
  }

  .app-main .inner-screen {
    min-height: 100vh;
  }
}

@media (max-width: 1500px) {
  .app-main .login_page {}

  .app-main .login_page .main {
    min-height: calc(100vh - 80px);
  }

  .app-main .left-column {
    padding: 40px;
  }

  .select-style {
    width: 12%;
  }

  .app-main .main {
    /* width: 100%; */

    margin: 0 auto;
  }

  .app-main .right-column {
    padding: 60px 20px 40px;
  }

  .app-main .col-head {
    padding: 0;
  }

  .app-main .col-head p {
    padding: 0 35px;
    font-size: 18px;
  }

  .app-main .col-head h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  .app-main .image-item {
    padding: 0px 32px 100px;
  }

  .app-main h1,
  .app-main .h1 {
    font-size: 40px;
  }

  .app-main h2 {
    font-size: 32px;
  }

  /* 1500pxx */
  .app-main .confirmation-message {
    font-size: 22px;
    text-align: center;
  }

  .app-main .box-heading {
    padding: 15px 0px;
  }

  .app-main .content-right-main {
    /* padding: 18px 25px; */
  }

  .app-main .main-sec-wrap.active-main {
    padding-left: 250px;
  }

  .app-main .content-right-heading {
    margin: 0 0 10px;
  }

  .app-main .content-right-heading-adbilder {
    margin-top: -5px;
  }

  .app-main .content-heading-view {
    margin-top: 17px;
  }

  .app-main .content-heading-view-adbilder {
    margin-top: 20px;
  }

  .app-main .right-icons ul li {
    padding-right: 15px;
  }

  .app-main .right-icons ul li img {
    max-width: 28px;
    max-height: 23px;
  }

  .app-main .header-nav ul li {
    padding-right: 50px;
  }

  .app-main .MuiButton-label img {
    max-width: 50px;
  }

  /*     Resource    1500px */
  .app-main .ecp_resource .tab-listing ul li {
    padding-right: 25px;
    font-size: 16px;
  }

  .app-main .ecp_resource .tab-listing ul li:last-child {
    padding-right: 0;
  }

  .app-main .main_tab_content {
    padding: 30px 30px 0px;
  }

  .app-main .box-image .button-field {
    margin-bottom: 10px;
  }

  .app-main .box-image {
    padding: 25% 15px;
    height: 300px;
  }

  .app-main .free-asset-image-box {
    height: 255px;
  }

  .app-main .box-content-info {
    padding: 10px 10px;
  }

  .app-main .box-content-info-calendar {
    padding: 0px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 100px; */
  }

  /*    clinic     */
  .app-main .tab-listing ul li {
    font-size: 16px;
    padding-right: 18px;
  }

  .app-main .form-main {
    padding: 45px 40px;
  }

  .app-main .tab-listing .slick-slide {
    padding: 0 13px 10px 0;
  }

  /*      product-selection     1500px */
  .app-main .three-block-sec {
    padding-right: 0;
  }

  .app-main .hover_link {
    padding: 10px 10px;
  }

  .app-main .block-col h2 {
    font-size: 23px;
  }

  .app-main .block-col p {
    font-size: 14px;
  }

  .app-main .two-col-sec p {
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 1.4;
  }

  .app-main .sec-left {
    width: 61%;
  }

  .app-main .sec-right {
    width: 38.5%;
  }

  .app-main .sec-right-calender {
    width: 42.5%;
  }

  .app-main .two-col-sec ul li,
  .app-main .blue-link a,
  .app-main .two-col-sec h3 {
    font-size: 15px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 40px;
  }

  .app-main .blue-link {
    margin-top: 30px;
  }

  /*          adbreak       1500px */
  .app-main .step-col h3 {
    font-size: 16px;
    width: 80px;
  }

  .app-main .step-col:before {
    width: 146px;
  }

  .app-main .white-box-content {
    padding: 25px 25px;
  }

  .app-main .white-box-image {
    padding: 0;
  }

  .app-main .content_img_row {
    margin-bottom: 20px;
  }

  .app-main .content_img_row {
    margin-right: 10%;
  }

  .app-main .row-300-width {
    width: 44%;
  }

  .app-main .row-250-width {
    width: 42%;
    margin-right: 0;
  }

  .app-main .content-info h6 {
    font-size: 14px;
    width: 65%;
  }

  .app-main .content-info p {
    font-size: 15px;
    width: 35%;
  }

  .app-main .content-info {
    padding: 5px 10px;
  }

  .app-main .content_col_small {
    width: 13%;
    margin-right: 2%;
  }

  .app-main .content_col_middle {
    width: 25%;
    margin-right: 2%;
  }

  .app-main .content_img_col.content_col_large {
    width: 58%;
  }

  .app-main .content_col_small .content-info h6 {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .app-main .content_col_small .content-info p {
    font-size: 13px;
  }

  .app-main .image-slider {
    /* height: 525px; */
  }

  .app-main .hover-button-calendar {
    padding: 60% 3% 1% 1%;
  }

  /* calendar 1500px*/
  .app-main .calendar-image-box {
    background-size: 155px;
    width: 160px;
    height: 160px;
  }

  .app-main .free-asset-main-content-box {
    margin-top: 10%;
  }

  .app-main .login-text-h2 {
    margin: 0;
  }
}



@media (max-width: 1366px) {
  .app-main .main-sec-wrap {
    padding-top: 73px;
  }

  .app-main .header-left {
    padding: 10px 20px 10px 10px;
  }

  .app-main .header-nav ul li {
    padding-right: 40px;
  }

  .app-main .right-icons {
    padding: 4px 15px 4px;
  }

  .app-main .account-info {
    padding: 0px 0px;
  }

  .app-main .header-nav ul li a {
    padding: 11px 0;
  }

  .app-main #simple-popover .MuiPaper-root.MuiPopover-paper {
    top: 51px !important;
    margin-left: 0px;

  }

  .app-main #theme-popover .MuiPaper-root.MuiPopover-paper {
    top: 172px !important;
    margin-left: 15px;
  }

  .app-main #location-popover .MuiPaper-root.MuiPopover-paper {
    top: 172px !important;
    margin-left: 15px;
  }

  .app-main .left-sidebar {
    height: calc(100vh - 90px);
    top: 90px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 45px;
  }

  .app-main .sec-left {
    padding-right: 20px;
    padding-left: 0;
    padding-top: 0;
  }

  /* .app-main .two-col-sec ul li,
.app-main .blue-link a,
.app-main .two-col-sec h3 {
  font-size: 15px;
  line-height: 1.4 !important;
  margin: 0;
} */
}

@media (max-width: 1199px) {
  .app-main .app-main .login_page {}

  .app-main .app-main .login_page .main {
    min-height: calc(100vh - 100px);
  }

  .app-main .image-slider {
    /* height: 475px; */
  }

  .app-main .left-column {
    padding: 40px 40px;
  }

  .app-main .left-column h1 {
    margin-bottom: 20px;
  }

  .app-main .logo {
    margin-bottom: 40px;
  }

  .app-main .logo img {
    height: 50px;
  }

  .app-main .user-field {
    background-size: 15px;
    background-position: center right 15px;
  }

  .app-main .pwd-field {
    background-size: 13px;
    background-position: center right 15px;
  }

  .app-main .bottom-links {
    padding-top: 30px;
    margin-left: 0;
  }

  .app-main .right-column {
    padding: 40px 30px;
  }

  .app-main .col-head p {
    font-size: 20px;
    padding: 0;
  }

  .app-main .image-item {
    padding: 10px 20px 80px;
  }

  .app-main .error-msg {
    font-size: 14px;
    padding-right: 35px;
  }

  .app-main .error-msg:after {
    background-size: 15px;
    width: 15px;
    height: 15px;
    right: 15px;
  }

  .app-main .col-head h2 {
    font-size: 26px;
  }

  .app-main .button-field a {
    font-size: 16px;
    line-height: 18px;
    min-width: 150px;
    padding: 14px 20px;
  }

  .app-main .button-field.button-field-country {
    margin-top: -4px;
  }

  .app-main .form-submit .button-field input {
    min-width: 175px;
  }

  .app-main .form-field {
    margin-bottom: 20px;
  }

  .app-main .form-field label {
    margin-bottom: 10px;
  }

  .app-main h1,
  .app-main .h1 {
    font-size: 38px;
  }

  .app-main h2 {
    font-size: 34px;
  }

  /* 1199pxx */
  .app-main .confirmation-message {
    font-size: 25px;
    text-align: center;
  }

  .app-main input[type="date"],
  .app-main input[type="email"],
  .app-main input[type="password"],
  .app-main input[type="search"],
  .app-main input[type="tel"],
  .app-main input[type="text"],
  .app-main select,
  .app-main textarea {
    padding: 15px;
  }

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    padding: 17px 25px 17px;
    font-size: 16px;
    line-height: 5px;
  }

  .app-main .popup-box {
    min-width: 530px;
    max-width: 60px;
  }

  .app-main .box-heading img {
    margin-right: 20px;
  }

  .app-main .box-content p {
    font-size: 20px;
    margin-bottom: 17px;
  }

  .app-main .multiple-field {
    margin-top: 15px;
  }

  .app-main .box-content {
    padding: 20px 80px;
  }

  .app-main .box-content form {
    max-width: 100%;
  }

  .app-main .main-sec-wrap.active-main {
    padding-left: 250px;
  }

  .app-main .content-right-main {
    padding: 40px 25px;
  }

  .app-main .content-right-heading {
    font-size: 19pt;
    margin-top: -5px;
  }

  .app-main .content-right-heading-adbilder {
    font-size: 19pt;
    margin-top: -15px;
  }

  .app-main .content-heading-view {
    margin-top: 0px;
  }

  .app-main .content-heading-dropdown {
    margin-top: -1px;
  }

  .app-main .theme-option {
    font-size: 10pt;
  }

  .app-main .site-logo {
    padding-left: 10px;
  }

  .app-main .site-logo a {
    max-width: 110px;
    width: 95px;
  }

  .app-main .MuiButton-label {
    font-size: 16px;
  }

  /* 1199px */
  .app-main .right-content p {
    font-size: 14px;
  }

  .app-main .header-nav ul li a {
    font-size: 16px;
  }

  .app-main .header-nav ul li svg {
    margin-right: 5px;
    max-width: 20px;
  }

  .app-main .header-nav ul li {
    padding-right: 22px;
  }

  .app-main .right-icons {
    padding: 1px 0px 0 8px;
  }

  .app-main .right-icons:last-child {
    padding: 0;
    margin-right: 10px;
  }

  .app-main .account-info {
    padding: 0px 0px;
  }

  .app-main .header-nav {
    padding-right: 10px;
  }

  .app-main .header-left {
    padding: 0px;
    width: 10%;
  }

  .app-main .header-right {
    width: 90%;
  }

  .app-main .header-nav ul li .icon-status {
    left: 15px;
  }

  .app-main .MuiButton-label img {
    max-width: 38px;
  }

  .app-main .left-sidebar {
    top: 91px;
    height: calc(100vh - 90px);
  }

  .app-main .left-sidebar li {
    font-size: 16px;
  }

  .app-main .left-sidebar li a {
    padding: 16px 30px;
  }

  .app-main .left-sidebar li a img {
    margin-right: 20px;
    max-width: 20px;
    margin-top: -3px;
  }

  .app-main .subparent-nav {
    margin-bottom: 10px;
  }

  .app-main .left-sidebar .subparent-nav .MuiCollapse-container.MuiCollapse-entered {
    margin-top: 0;
  }

  .app-main .left-sidebar.active {
    width: 250px;
  }

  .select-style {
    width: 17%;
  }

  .app-main .humburger-menu {
    margin-bottom: 10px;
  }

  .app-main .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
    padding: 10px;
  }

  .app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 30px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 6px;
  }

  .app-main .subparent-nav:last-child {
    margin-bottom: 0;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 45px;
    padding-right: 0px;
  }

  .app-main .left-sidebar nav {
    width: 250px;
  }

  /*     Resource     1199px*/
  .app-main .content-box-feed,
  .app-main .content-box-feed:nth-child(3n) {
    width: 275px;
    margin-right: 4%;
    margin-bottom: 30px;
  }

  .app-main .content-box-feed:nth-child(2n) {
    margin-right: 0;
  }

  .app-main .content-box,
  .app-main .content-box:nth-child(3n) {
    width: 300px;
    margin-right: 4%;
    margin-bottom: 30px;
  }

  .app-main .content-box:nth-child(2n) {
    margin-right: 0;
  }

  /*    Cliinic    */
  .app-main .data-popup-box {
    min-width: 800px;
    max-width: 800px;
    margin: 60px 0;
  }

  .app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 190px;
  }

  .app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root {
    min-width: 130px;
  }

  .app-main .tab-listing .slick-slide {
    padding-right: 10px;
  }

  /*        product-selection      1199px  */
  .app-main .hover_link {
    padding: 30px 15px;
  }

  .app-main .block-col h2 {
    font-size: 30px;
  }

  .app-main .three-block-sec {
    padding-right: 0;
    margin-top: 30px;
  }

  .app-main .block-col {
    width: 48.47%;
  }

  .app-main .block-col:nth-child(3n) {
    margin-right: 2.05%;
  }

  .app-main .block-col:nth-child(2n) {
    margin-right: 0;
  }

  .app-main .sec-left {
    width: 55%;
    padding-right: 40px;
  }

  .app-main .sec-right {
    width: 45%;
  }

  /* .app-main .sec-right-calender {
    width: 45%;
  } */

  .app-main .two-col-sec {
    margin-top: 20px;
    padding: 30px;
  }

  .app-main .two-col-sec p {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .app-main .two-col-sec ul li,
  .app-main .blue-link a,
  .app-main .two-col-sec h3 {
    font-size: 15px;
  }

  .app-main .two-col-sec ul li {
    margin-bottom: 15px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .app-main .two-col-sec ul {
    margin-bottom: 40px;
  }

  .app-main .blue-link {
    margin-top: 20px;
  }

  .app-main .full-field p small {
    font-size: 12px;
  }

  .app-main .full-field p {
    margin-top: 17px;
  }

  /*         adbreak        1199px */
  .app-main .full-page-wrap h1 {
    width: calc(100% - 150px);
  }

  .app-main .step-col h3 {
    font-size: 13px;
  }

  .app-main .step-col {
    margin: 0 1.9%;
  }

  .app-main .step-col:before {
    width: 111px;
  }

  .app-main .content_img_col.content_col_large {
    width: 100%;
    margin-top: 30px;
  }

  .app-main .content_col_small {
    width: 19%;
    margin-right: 4%;
  }

  .app-main .content_col_middle {
    width: 33%;
  }

  /* calendar  1199px*/
  .app-main .calendar-image-box {
    background-size: 126px;
    width: 131px;
    height: 131px;
  }

  .app-main .daygrid-day-top {
    margin-bottom: 0;
    height: 1.6em;
  }

  .app-main .calendar-grid-main {
    grid-template-columns: 13.80% 13.80% 13.80% 13.83% 13.83% 13.80% 13.80%;
    grid-template-rows: 160px 160px 160px 160px 160px 160px;
  }

  .app-main .library-list-main {
    grid-template-columns: 210px;
  }

  .content-right-header {
    flex-wrap: wrap;
    align-items: center;
  }

  .content-right-data {
    flex-wrap: wrap;
  }

  .report-select-box {
    width: 100%;
    margin-bottom: 10px;
  }

  .location-select-box {
    width: 100%;

    margin: 0px 5px 10px 5px;
  }

  .date-piker-box {
    width: 100%;
  }

  .date-piker {
    margin-right: 5px !important;
  }

  .react-google-flight-datepicker {
    width: 100%;
  }

  .react-google-flight-datepicker .dialog-date-picker {
    left: 0px !important;
  }
}


@media (min-width: 992px) and (max-height: 810px) {

  /* .app-main .header-nav ul li a{
    font-size: 16px;
  } */
  /* .app-main .logo {
    margin-bottom: 40px;
  } */

  .app-main .left-column h1 {
    margin-bottom: 30px;
  }

  .app-main .right-column {
    padding: 30px 20px 30px;
  }

  .app-main .image-item {
    padding-bottom: 60px;
  }

  .app-main .bottom-links {
    padding-top: 30px;
  }

  .app-main .logo img {
    height: 50px;
  }

  .app-main .form-field {
    margin-bottom: 20px;
  }

  .app-main .app-main .login_page input[type="text"] {
    padding: 15px 20px;
  }

  .app-main .login_page h1,
  .app-main .h1 {
    font-size: 30px;
  }

  .app-main .left-column {
    padding: 25px;
  }
}

@media (max-width: 991px) {
  .app-main .login-from {
    width: 40% !important;
  }

  .app-main .login_page {
    padding: 0;
    background-image: none !important;
  }

  .app-main .login_page .main {
    min-height: calc(100vh - 100px);
  }

  .app-main .inner-screen {
    box-shadow: none;
    min-height: 100vh;
  }

  .app-main .left-column {
    display: flex;
    flex-direction: column;
    padding: 80px 40px;
  }

  .app-main .left-column h1 {
    margin-bottom: 40px;
  }

  .app-main .error input::-moz-placeholder {
    opacity: 0 !important;
  }

  .app-main .error input:-moz-placeholder {
    opacity: 0 !important;
  }

  .app-main .error input::-webkit-input-placeholder {
    opacity: 0 !important;
  }

  .app-main .error input:-ms-input-placeholder {
    opacity: 0 !important;
  }

  .app-main .middle-content {
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 20px;
  }

  .app-main .right-column {
    padding: 86px 30px 75px;
  }

  .app-main .col-head {
    text-align: left;
  }

  .app-main .col-head h2 {
    padding-right: 17px;
    margin-bottom: 17px;
  }

  .app-main .col-head h2 {
    font-size: 32px;
  }

  .app-main .col-head p {
    font-size: 16px;
  }

  .app-main .right-column {
    align-items: inherit;
  }

  .app-main .image-slider {
    margin-top: 0;
  }

  .app-main .image-slider .slick-slider {
    height: 100%;
  }

  .app-main .right-continue-btn .button-field.next-step-btn {
    min-width: 151px;
  }

  .app-main .button-field.button-field-country {
    margin-top: 0;
  }

  .app-main .form-submit .button-field input {
    min-width: 143px;
  }

  .app-main .sec-left .button-field {
    min-width: 145px;
    width: 145px;
    margin-top: 5px;
  }

  .app-main .form-field {
    margin-bottom: 30px;
  }

  .app-main h1,
  .app-main .h1 {
    font-size: 36px;
  }

  .app-main h2 {
    font-size: 22px;
    line-height: 1.2;
  }

  /* 991pxx */
  .app-main .confirmation-message {
    font-size: 22px;
    text-align: center;
  }

  .app-main h3 {
    font-size: 24px;
  }

  .app-main h4 {
    font-size: 16px;
  }

  .app-main h5 {
    font-size: 14px;
  }

  .app-main h6 {
    font-size: 14px;
  }

  .app-main input[type="date"],
  .app-main input[type="email"],
  .app-main input[type="password"],
  .app-main input[type="search"],
  .app-main input[type="tel"],
  .app-main input[type="text"],
  .app-main select,
  .app-main textarea {
    padding: 14px 15px;
  }

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    padding: 11px 23px;
    min-width: 143px;
  }

  .app-main .main-sec-wrap {
    padding-left: 0px;
    padding-top: 80px;
  }

  .app-main .main-sec-wrap.active-main {
    padding-left: 0px;
  }

  /*       verify otp     991px */
  .app-main .popup-box {
    min-width: 480px;
    max-width: 550px;
  }

  .app-main .box-heading {
    padding: 21px 15px;
  }

  .app-main .box-heading>img {
    margin-right: 20px;
    max-width: 47px;
  }

  .app-main .box-content p {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .app-main .multiple-field {
    margin-top: 0;
  }

  .app-main .multiple-field input[type="text"] {
    margin: 0 5px;
    width: 49px;
    height: 49px;
  }

  .app-main .box-content form {
    width: 62%;
  }

  /* verify otp */
  /* forgot username */
  .app-main .forgot-popup-box {
    padding-bottom: 50px;
  }

  .app-main .changepwd-popup-box {
    padding-bottom: 50px;
  }

  /* forgot username */
  .app-main .bootom-sec .header-nav {
    display: block;
    background: #ffffff;
    background: var(--white-color);
  }

  .app-main .bootom-sec .header-nav li {
    min-width: 33.33%;
    text-align: center;
    padding-right: 0;
  }

  .app-main .bootom-sec .header-nav li a {
    width: 100%;
    font-size: 18px;
    padding: 27px 10px;
  }

  .app-main .content-right-main {
    padding: 39px 20px 42px 60px;
  }

  .app-main .content-right-heading {
    font-size: 18pt;
    margin-top: -20px;
  }

  .app-main .content-right-heading-adbilder {
    font-size: 18pt;
    margin-top: -30px;
  }

  .app-main .content-heading-view {
    margin-top: -10px;
  }

  .app-main .right-icons ul li {
    padding-right: 30px;
  }

  .app-main .site-header .header-nav ul li {
    display: none;
  }

  .app-main .site-header .header-nav ul li:last-child {
    display: block;
  }

  .app-main .header-left {
    width: 17%;
    padding: 10px 10px 10px 20px;
  }

  .app-main .header-right {
    width: 83%;
  }

  .app-main .site-logo a {
    max-width: 155px;
    width: 90px;
  }

  .app-main .MuiButton-label {
    padding-right: 17px;
  }

  .app-main .MuiButton-label:after {
    background-size: 10px;
    background-position: center right;
  }

  .app-main .header-nav ul li a {
    padding: 11px 0;
    font-size: 0px;
  }

  .app-main .MuiButton-label img {
    max-width: 30px;
  }

  .app-main .right-icons {
    padding: 1px 28px 1px 19px;
  }

  .app-main .right-icons ul li a img {
    max-width: 30px;
  }

  .app-main .header-nav {
    padding-right: 10px;
  }

  .app-main .header-nav ul li svg {
    max-width: none;
    margin-right: 5px;
  }

  .app-main .header-nav ul li .icon-status {
    top: 20px;
    left: 16px;
  }

  .app-main #simple-popover .MuiPaper-root.MuiPopover-paper {
    top: 50px !important;
    right: -20px;
  }

  .app-main #location-popover .MuiPaper-root.MuiPopover-paper {
    top: 188px !important;
    right: -20px;
  }

  .app-main .site-logo {
    padding-left: 0;
  }

  .app-main .left-sidebar {
    top: 80px;
    bottom: 0;
    min-height: calc(100vh - 80px);
    transition: all 0.5s ease-in-out;
    width: 50px;
  }

  .app-main .left-sidebar.active {
    left: 0;
  }

  .app-main .left-sidebar li a {
    padding: 17px 30px 16px;
  }

  .app-main .left-sidebar li a img {
    margin-right: 16px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 17px 21px 16px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 41px;
  }

  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding-right: 30px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 80px;
  }

  .app-main .humburger-menu {
    padding-right: 11px;
  }

  .app-main .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
    padding: 12px 20px 12px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 12px 20px;
  }

  .app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 35px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 70px;
  }

  .app-main .hide-scroll {
    overflow: auto;
    margin-right: 0;
  }

  .app-main .hide-scroll .site-header {
    width: 100%;
  }

  /*     Resource    991px */
  .app-main .box-image {
    padding: 21.5% 15px;
  }

  .app-main .box-content-info h4 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .app-main .box-content-info {
    padding: 18px 19px;
  }

  .app-main .box-content-info p {
    margin-bottom: 18px;
  }

  .app-main .content-box-feed {
    margin-bottom: 20px;
  }

  .app-main .content-box-feed,
  .app-main .content-box-feed:nth-child(3n) {
    margin-bottom: 20px;
  }

  .app-main .content-box {
    margin-bottom: 20px;
  }

  .app-main .content-box,
  .app-main .content-box:nth-child(3n) {
    margin-bottom: 20px;
  }

  .app-main .main_tab_content {
    padding: 30px 30px 0px;
  }

  .app-main .ecp_resource .tab-listing ul li {
    font-size: 24px;
    padding-right: 39px;
  }

  .app-main .ecp_resource .tab-listing ul li a {
    padding-right: 2px;
  }

  /*      Clinic    991px */
  .app-main .tab-listing ul li {
    font-size: 24px;
    padding: 10px 42px 0 0;
  }

  .app-main .tab-listing .slick-slide {
    padding-right: 35px;
  }

  .app-main .tab-listing {
    padding: 0 0 0;
    position: relative;
  }

  .app-main .tab-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .app-main .tab-content {
    padding: 0;
  }

  .app-main .tab-listing ul li a:after {
    bottom: 0;
  }

  .app-main .tab-content .form-main form {
    max-width: 477px;
  }

  .app-main .tab-content .field-left {
    width: 34%;
  }

  .app-main .tab-content .field-right {
    width: 66%;
  }

  .app-main .tab-content .form-main {
    padding-bottom: 140px;
  }

  .app-main .data-popup-box {
    min-width: 650px;
    max-width: 650px;
    margin: 100px 0;
  }

  .app-main .data-popup-box .box-heading {
    padding: 30px 15px;
  }

  .app-main .data-popup-box .close-btn {
    top: 20px;
    right: 10px;
  }

  .app-main .data-popup-box .box-heading .close-btn img {
    width: 15px;
  }

  .app-main .data-popup-box .box-content form {
    width: 100%;
    max-width: 510px;
  }

  .data-popup-box .box-content form .field-right {
    width: 60%;
  }

  .app-main .data-popup-box .box-content form .field-left {
    width: 40%;
  }

  .app-main .data-popup-box .box-content {
    padding: 50px 40px 60px;
  }

  /*        product-selection       991px */
  .app-main .three-block-sec {
    margin-top: 27px;
  }

  .app-main .block-col p {
    font-size: 16px;
  }

  .app-main .block-col h2 {
    font-size: 22px;
  }

  .app-main .block-col img {
    max-width: 80px;
  }

  .app-main .sec-left {
    width: 100%;
    padding: 0;
  }

  .app-main .sec-right {
    width: 100%;
    order: -1;
    padding: 0;
    margin-bottom: 47px;
  }

  .app-main .sec-right-calender {
    width: 100%;
    order: -1;
    padding: 0;
    margin-bottom: 47px;
  }

  .app-main .two-col-sec {
    margin-top: 27px;
    padding: 40px 30px 23px;
  }

  .app-main .sec-right img {
    max-width: 420px;
  }

  .app-main .two-col-sec p {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 32px;
  }

  .app-main .detail-welcome .sec-right {
    order: inherit;
    padding-top: 12px;
    margin-bottom: 0;
  }

  .app-main .detail-welcome {
    padding-top: 24px;
    padding-bottom: 29px;
    margin-bottom: 30px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .app-main .two-col-sec ul li {
    padding-left: 17px;
    margin-bottom: 13px;
  }

  .app-main .two-col-sec ul li:after {
    top: 7px;
    width: 3px;
    height: 3px;
  }

  .app-main .detail-welcome .full-field {
    display: inline-block;
    vertical-align: top;
    width: 46%;
    margin-right: 2.5%;
  }

  .app-main .full-field p small {
    font-size: 14px;
  }

  .app-main .full-field p {
    margin-left: -105%;
    margin-top: 13px;
    width: calc(100vw - 100px);
  }

  .app-main .blue-link a {
    font-size: 24px;
  }

  .app-main .blue-link {
    margin-top: 14px;
  }

  .app-main .button-field.next-step-btn {
    min-width: 228px;
  }

  .app-main .button-field.next-step-btn input {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }

  /*         adbreak        991px */
  .app-main .step-col h3 {
    font-size: 12px;
  }

  .app-main .step-col {
    margin: 0 2%;
  }

  .app-main .white-box-image {
    margin-bottom: 30px;
  }

  .app-main .white-box-content {
    padding: 30px 30px 20px;
    margin-top: 0;
  }

  .app-main .white-box-wrapper {
    padding-top: 39px;
  }

  .app-main .step-row {
    margin-top: 67px;
  }

  .app-main .white-box-image img {
    margin: 0;
  }

  .app-main .content_col_small {
    width: 24.2%;
    margin-right: 6%;
  }

  .app-main .content_col_middle {
    width: 45.2%;
  }

  .app-main .content-info {
    padding: 0px 10px;
  }

  .app-main .content_col_small .content-info h6 {
    font-size: 18px;
  }

  .app-main .content-info h6 {
    font-weight: 400;
    font-size: 18px;
  }

  .app-main .content_col_small .content-info p {
    font-size: 18px;
  }

  .app-main .content_col_small .content-info {
    padding-right: 10px;
  }

  .app-main .content-info p {
    font-size: 18px;
  }

  .app-main .content_img_row {
    margin-bottom: 30px;
  }

  .app-main .row-300-width {
    width: 45%;
  }

  .app-main .content_img_row {
    margin-right: 6%;
  }

  .app-main .row-250-width {
    width: 37.5%;
    margin-right: 0;
  }

  .app-main .row-320-width {
    max-width: 48%;
  }

  .app-main .content_img_row:last-child {
    margin-bottom: 0;
  }

  .app-main .step-col:after {
    width: 28px;
    height: 28px;
    font-size: 18px;
    line-height: 30px;
  }

  /* 991px */

  .app-main .library-list-main {
    grid-template-columns: 210px 210px 210px;
  }

  .app-main .tab-listing ul {
    margin: 0 0 10px 0;
  }

}

@media (max-width: 991px) and (max-height: 740px) {
  .app-main .left-column {
    padding: 30px;
  }

  .app-main .right-column {
    padding: 30px 30px 40px;
  }

  .app-main .left-column h1 {
    margin-bottom: 20px;
  }

  .app-main .logo {
    margin-bottom: 30px;
  }

  .app-main .middle-content {
    padding-bottom: 0;
  }

  .app-main .form-field {
    margin-bottom: 20px;
  }


}

@media (max-width: 768px) {
  .app-main .login-from {
    width: 50% !important;
  }

  .app-main .app-main .login_page .main {
    min-height: 1px;
  }

  .app-main .inner-screen {
    display: block;
    min-height: 1px;
  }

  .app-main .left-column {
    width: 100%;
    padding: 30px 30px;
    display: block;
  }

  .app-main .left-column h1 {
    margin-bottom: 23px;
  }

  .app-main .logo {
    margin-bottom: 24px;
  }

  .app-main .logo img {
    height: 50px;
  }

  .app-main .middle-content {
    margin: 0;
    padding-bottom: 0;
  }

  .app-main .app-main .login_page .form-field.custom-checkbox {
    margin-bottom: 16px;
  }

  .app-main .custom-checkbox input[type="checkbox"]+label {
    padding-left: 25px;
    line-height: 22px;
  }

  .app-main .custom-checkbox input[type="checkbox"]+label:after,
  .app-main .custom-checkbox input[type="checkbox"]+label:before {
    width: 16px;
    height: 16px;
  }

  .app-main .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 12px;
  }

  .app-main .user-field {
    background-size: 13px;
  }

  .app-main .bottom-links {
    padding-top: 20px;
    margin-left: 8px;
  }

  .app-main .bottom-links button {
    font-size: 14px;
  }

  .app-main .bottom-links ul li {
    padding-right: 16px;
  }

  .app-main .right-column {
    width: 100%;
    display: block;
    padding: 31px 30px 15px;
  }

  .app-main .col-head h2 {
    padding-right: 0;
    margin-bottom: 14px;
  }

  .app-main .image-item {
    padding: 0 40px 30px;
  }

  .app-main .image-slider .slick-slider {
    height: auto;
  }

  .app-main .col-head h2 {
    font-size: 20px;
  }

  .app-main .button-field a {
    font-size: 14px;
    line-height: 16px;
    min-width: 130px;
    padding: 11px 20px;
  }

  .app-main .button-field.button-field-country button {
    font-size: 12px;
    padding: 8px 20px 8px;
    min-width: 108px;
  }

  .app-main .sec-left .button-field {
    margin-top: -8px;
    min-width: 115px;
    width: 115px;
  }

  .app-main .form-field {
    margin-bottom: 21px;
  }

  h1,
  .h1 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .app-main body,
  .app-main p {
    font-size: 14px;
  }

  .app-main h2 {
    font-size: 18px;
  }

  /* 767pxx   */
  .app-main .confirmation-message {
    font-size: 20px;
    text-align: center;
  }

  .app-main h3 {
    font-size: 16px;
  }

  .app-main h4 {
    font-size: 14px;
  }

  .app-main h5 {
    font-size: 12px;
  }

  .app-main h6 {
    font-size: 12px;
  }

  .app-main input[type="date"],
  .app-main input[type="email"],
  .app-main input[type="password"],
  .app-main input[type="search"],
  .app-main input[type="tel"],
  .app-main input[type="text"],
  .app-main select,
  .app-main textarea {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 15px 15px;
  }

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    font-size: 16px;
    line-height: 5px;
    padding: 17px 23px 16px;
  }

  .app-main select {
    background-size: 9px;
    padding-right: 25px;
  }

  .app-main .slick-slider .slick-dots li {
    padding: 0 2px;
  }

  .app-main .slick-slider .slick-dots li button {
    width: 5px;
    height: 5px;
  }

  .app-main .slick-slider .slick-dots .slick-active button {
    width: 13px;
  }

  /* verify otp */
  .app-main .popup-box {
    min-width: 165px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
  }

  .app-main .box-content {
    padding: 17px 33px 30px;
  }

  .app-main .box-heading>img {
    max-width: 25px;
    margin-right: 10px;
  }

  .app-main .box-content form {
    width: 100%;
  }

  .app-main .multiple-field {
    margin-bottom: 0;
  }

  .app-main .box-content p {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  /* verify otp */
  /* forgot username */
  .app-main .close-btn {
    top: 0;
    right: 0;
  }

  .app-main .forgot-popup-box {
    padding-bottom: 0;
  }

  .app-main .changepwd-popup-box {
    padding-bottom: 0;
  }

  /* forgot username */
  .app-main .main-sec-wrap {
    padding-top: 50px;
  }

  .app-main .bootom-sec .header-nav li a {
    font-size: 12px;
    padding: 14px 10px;
  }

  .app-main .bootom-sec .header-nav ul li svg {
    max-width: 15px;
    margin-right: 5px;
    margin-top: -3px;
  }

  .app-main .content-right-main {
    padding: 27px 15px 29px 70px;
  }

  .app-main .content-right-heading {
    font-size: 17pt;
    margin-top: 0px;
  }

  .app-main .content-right-heading-adbilder {
    font-size: 17pt;
    margin-top: -3px;
  }

  .app-main .content-heading-view {
    margin-top: -10px;
  }

  .app-main .site-header .header-nav ul li a {
    font-size: 0;
    padding: 11px 0;
  }

  .app-main .site-header .header-nav ul li a svg {
    margin-right: 0;
    max-width: 17px;
  }

  .app-main .right-icons {
    border-left: none;
    padding: 1px 16px 1px 0px;
  }

  .app-main .site-logo a {
    max-width: 103px;
    width: 75px;
  }

  .app-main .account-info {
    padding: 0;
    background: transparent;
  }

  .app-main .right-icons ul li a img {
    max-width: 18px;
    max-height: 15px;
  }

  .app-main .MuiButton-label {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #f5f7fa;
    background: var(--white-background-color);
    padding: 0;
    margin-right: 10px;
  }

  .app-main .MuiButton-label img {
    margin: 0;
  }

  .app-main .MuiButtonBase-root.MuiButton-text {
    min-width: 1px;
  }

  .app-main .MuiButton-label:after {
    right: -10px;
    background-size: 6px;
  }

  .app-main .header-left {
    padding: 5px 0px 5px 15px;
    width: 30%;
  }

  .app-main .header-right {
    padding-right: 15px;
    width: 70%;
  }

  .app-main .right-icons {
    height: 23px;
  }

  .app-main .humburger-menu {
    margin-right: 16px;
  }

  .app-main .right-icons ul li {
    padding-right: 19px;
  }

  .app-main .header-nav {
    padding-right: 20px;
  }

  .app-main .header-nav ul li .icon-status {
    top: 15px;
    left: 10px;
  }

  .app-main #simple-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 20px !important;
    right: -30px;
  }

  .app-main #theme-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 202px !important;
    right: -30px;
  }

  .app-main .left-img {
    width: 30px;
  }

  .app-main .right-content {
    width: calc(100% - 30px);
    padding-left: 9px;
  }

  /*     left-sidebar     767px */
  .app-main .left-sidebar {
    top: 55px;
    height: calc(100vh - 55px);
  }

  .app-main .humburger-menu span {
    height: 2px;
    width: 27px;
  }

  .app-main .humburger-menu {
    padding-right: 12px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    font-size: 14px;
    padding: 13px 15px 13px;
  }

  .app-main .app-main .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 31px;
  }

  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding-right: 25px;
  }

  .app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 30px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 40px;
    padding-right: 30px;
  }

  /*     Resource   767px  */
  .app-main .content-box-feed,
  .app-main .content-box-feed:nth-child(3n) {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-main .content-box-feed:last-child {
    margin-bottom: 0;
  }

  .app-main .content-box,
  .app-main .content-box:nth-child(3n) {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-main .content-box:last-child {
    margin-bottom: 0;
  }

  .app-main .main_tab_content {
    padding: 20px 15px;
  }

  .app-main .box-content-info p {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .app-main .box-content-info h4 {
    font-size: 14px;
  }

  .app-main .ecp_resource .tab-listing ul li {
    font-size: 16px;
    padding-right: 30px;
  }

  .app-main .tab-arrow:after {
    background-size: 6px;
    background-position: center right 10px;
    width: 30px;
    top: -12px;
  }

  .app-main .box-image {
    padding: 23.5% 15px;
  }

  .app-main .box-content-info {
    padding: 13px 14px;
  }

  /*        Clinic     */
  .app-main .tab-listing ul li {
    font-size: 20px;
  }

  .app-main .data-popup-box {
    min-width: 345px;
    max-width: 345px;
    margin: 80px 0;
  }

  .app-main .data-popup-box .box-content form .field-left {
    width: 100%;
    padding: 0;
  }

  .app-main .data-popup-box .box-content {
    padding: 31px 15px 15px;
  }

  .app-main .data-popup-box .box-content form .field-right {
    width: 100%;
  }

  .app-main .data-popup-box .form-submit .button-field input {
    padding-left: 13px;
    padding-right: 13px;
  }

  .app-main .data-popup-box .box-heading .close-btn img {
    width: 10px;
  }

  .app-main .data-popup-box .close-btn {
    top: 10px;
    right: 10px;
    width: 10px;
  }

  .app-main .tab-listing .slick-next {
    top: -16px;
  }

  /*          product-selection       767px */
  .app-main .block-col {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-main .block-col:nth-child(4n) {
    margin-right: 0;
  }

  .app-main .block-col p {
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .app-main .block-col h2 {
    font-size: 16px;
  }

  .app-main .block-col img {
    max-width: 70px;
    margin-bottom: 22px;
  }

  .app-main .three-block-sec {
    margin-top: 20px;
  }

  .app-main .sec-right img {
    max-width: 250px;
  }

  .app-main .two-col-sec {
    margin-top: 17px;
    padding: 20px 20px 14px;
  }

  .app-main .two-col-sec p {
    font-size: 16px;
    margin-bottom: 28px;
  }

  .app-main .sec-right {
    margin-bottom: 14px;
  }

  .app-main .sec-right-calender {
    margin-bottom: 14px;
  }

  /*        budgetDetail       767px  */
  .app-main .two-col-sec ul li,
  .app-main .blue-link a,
  .app-main .two-col-sec h3 {
    font-size: 15px;
  }

  .app-main .detail-welcome {
    padding-top: 15px;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 33px;
    margin-bottom: 7px;
  }

  .app-main .two-col-sec ul li {
    padding-left: 15px;
    margin-bottom: 6px;
    line-height: 1.7;
  }

  .app-main .detail-welcome .full-field {
    display: block;
    width: 100%;
    margin: 0 0 19px;
  }

  .app-main .two-col-sec ul {
    margin-bottom: 38px;
  }

  .app-main .full-field label {
    margin-bottom: 9px;
  }

  .app-main .full-field p {
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
  }

  .app-main .full-field p small {
    font-size: 12px;
  }

  .app-main .detail-welcome .sec-right {
    padding-top: 22px;
  }

  .app-main .detail-welcome .full-field:last-child {
    margin-bottom: 0;
  }

  .app-main .detail-welcome .sec-right img {
    margin-left: 0;
  }

  .app-main .blue-link {
    text-align: left;
    margin-top: 11px;
  }

  .app-main .button-field.next-step-btn input,
  .app-main .button-field.back-btn input {
    font-size: 12px;
    line-height: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .app-main .button-field.next-step-btn {
    min-width: 184px;
  }

  /*         adbreak      767px */
  .app-main .white-box-content {
    padding: 20px 15px 20px;
    margin-top: 10px;
  }

  .app-main .white-box-image {
    padding: 0 15px;
  }

  .app-main .full-page-wrap h1 {
    width: calc(100% - 130px);
  }

  .app-main .step-col {
    margin: 0 2.5%;
  }

  .app-main .step-col:before {
    width: 115px;
  }

  .app-main .step-col h3 {
    font-size: 15px;
  }

  .app-main .step-col:after {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 21px;
  }

  .app-main .library-list-main {
    grid-template-columns: 210px 210px;
  }


  .content-right-header {
    display: block;
    justify-content: center;
  }

  .content-right-heading {
    margin-bottom: 10px;
  }

  .report-select-box {
    width: 100%;
  }

  .location-select-box {
    width: 100%;
  }

  .paid-chart-inner {
    width: 100%;
  }

  .paid-chart-tabbedCarts {
    width: 100%;
  }

  .paid-chart {
    display: block;
  }

  .social-chart-main {
    display: block;
  }

  .budget-spant-chart-data {
    margin-bottom: 20px !important;
  }

  .budget-chart {
    height: 200px !important;
  }

  .facebook-chart {
    height: 200px !important;
  }

  .card-main {
    display: block;
  }

  .paid-card {
    width: 101%;
  }

  .react-google-flight-datepicker .dialog-date-picker {
    width: 70% !important;
    height: 326px !important;
    left: -195px !important;
  }

}

@media (max-width: 639px) {

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    font-size: 12px;
    min-width: 108px;
    padding: 7px 20px;
  }

  .app-main .button-field a {
    font-size: 13px;
    line-height: 15px;
    min-width: 100px;
    padding: 10px 15px;
  }

  .app-main .form-submit .button-field input {
    min-width: 108px;
  }

  .app-main .form-submit .button-field+.button-field {
    margin-left: 0;
    margin-right: 15px;
  }

  .app-main .form-submit .button-field {
    margin-bottom: 15px;
  }

  /*        Clinic       639px */
  .app-main .tab-content .form-main {
    padding-bottom: 6px;
  }

  .app-main .tab-listing ul li {
    font-size: 16px;
    padding-right: 30px;
  }

  .app-main .tab-listing .slick-slide {
    padding-right: 5px;
  }

  .app-main .tab-content .field-left {
    width: 100%;
    padding: 0;
  }

  .app-main .tab-content .field-right {
    width: 100%;
  }

  .app-main .tab-listing ul li:last-child {
    padding-right: 0;
  }

  .app-main .tab-listing {
    padding: 8px 0 0px;
  }

  .app-main .ecp_resource .tab-listing ul li a {
    padding-bottom: 18px;
  }

  .app-main .tab-listing ul li a:after {
    bottom: 0px;
  }

  .app-main .tab-listing ul li a {
    padding-right: 2px;
    padding-bottom: 11px;
  }

  .app-main .tab-content {
    padding: 0px 0 0;
  }

  .app-main .tab-content .form-main form {
    max-width: 315px;
  }

  .app-main .tab-content .form-main .form_field {
    padding-right: 0;
  }

  .app-main .tab-listing+.table-main-wrap {
    margin-top: 7px;
  }

  .app-main .table-link {
    padding-bottom: 14px;
  }

  .app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 60px;
  }

  .app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 96px;
  }

  .app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 95px;
  }

  /*      adbreak      639px */
  .app-main .full-page-wrap h1 {
    width: calc(100% - 100px);
  }

  .app-main .step-col {
    margin: 25px 8px;
  }

  .app-main .step-col h3 {
    font-size: 12px;
    line-height: 1.35;
    margin-top: -25px;
  }

  .app-main .step-col:before {
    width: 82px;
    top: -41px;
  }

  .app-main .white-box-image {
    padding: 0 0;
  }

  .app-main .white-box-wrapper {
    padding-top: 33px;
  }

  .app-main .content-info h6 {
    font-size: 14px;
  }

  .app-main .content-info {
    padding: 0px 10px;
  }

  .app-main .content-info p {
    font-size: 14px;
  }

  .app-main .content_col_small {
    max-width: 162px;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .app-main .content_col_middle {
    max-width: 302px;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .app-main .content_col_small .content-info h6 {
    font-size: 13px;
    margin-bottom: 7px;
  }

  .app-main .content_col_small .content-info p {
    font-size: 14px;
  }

  .app-main .content_img_col.content_col_large {
    margin-top: 0;
    display: block;
  }

  .app-main .content_img_row {
    margin-bottom: 20px;
  }

  .app-main .row-300-width {
    max-width: 302px;
    margin-right: 0;
    width: 100%;
  }

  .app-main .row-250-width {
    max-width: 252px;
    margin-right: 0;
    width: 100%;
  }

  .app-main .content_img_row.row-320-width {
    width: 100%;
    max-width: 320px;
  }

  .app-main .white-box-image {
    display: block;
  }
}

@media (max-width: 479px) {
  .app-main .button-field.fullview-btn {
    margin: 0;
    display: table;
  }

  .app-main .right-continue-btn .button-field.next-step-btn {
    min-width: 108px;
  }

  .app-main .right-content {
    padding-right: 43px;
  }

  /*     Resource     */
  .app-main .gray-close-ic {
    top: 6px;
    right: 6px;
    max-width: 10px;
  }

  .app-main .button-field.next-step-btn {
    min-width: 250px;
  }

  .app-main .select {
    font-size: 14px;
    color: rgba(4, 12, 5, 0.5);
  }

  /*    adbreak    479px */
  .app-main .page-img .next-step-btn {
    margin-top: 15px;
  }

  .app-main .full-page-wrap h1 {
    width: 100%;
    line-height: 1.2;
  }

  .app-main .white-box-image {
    margin-bottom: 4px;
  }

  .app-main .content_img_col:only-child {
    margin: 0 auto;
  }

  .app-main .step-row {
    min-width: 415px;
    margin-top: 10px;
    justify-content: flex-start;
    padding: 0 4px;
  }

  .app-main .step-row-wrapper {
    overflow: auto;
  }

  .app-main .step-col {
    max-width: 65px;
    margin: 0 14px;
  }

  .app-main .step-col:after {
    top: -35px;
  }

  .app-main .step-col:before {
    top: -25px;
    height: 2px;
  }
}
:root {
  --main-app-bg-color: #858458;
  --disable-font-color: #000;
  --disable-button-bg-color: #225151;
  --button-border-radius_5: 5px;
  --hover-button-bg-color: rgb(109, 110, 113);
  --font-color: #ffffff;
  --font-size_10: 10px;
  --font-family: "Raleway", sans-serif;
  --hover-font-color: #fff;
  --main-black-color: rgb(0, 0, 0);
  --main-grey-color: rgb(109, 110, 113);
  --main-orange-color: #f57e20;
  --dark-orange-color: #ff5a00;
  --light-orange-color: #fa8930;
}

.app-yomi {
  height: 100%;
  width: 100%;
}

/* 
.app-yomi body {
  font-family: "Raleway", sans-serif;
  color: #646464;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  line-height: 1.3;
  background: #f5f7fa;
} */

.app-yomi ::selection {
  background: #fa8930;
  background: var(--light-orange-color);
  color: #fff;
}

.app-yomi ::-moz-selection {
  background: #fa8930;
  background: var(--light-orange-color);
  color: #fff;
}

.app-yomi #page {
  overflow-x: hidden;
}

.app-yomi .login_page {
  padding: 13px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(/static/media/bg-pattern.8e456223.jpg);
}

.app-yomi .login_page .main {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.app-yomi .button-field {
  position: relative;
  z-index: 1;
  background: #fa8930;
  background: var(--light-orange-color);
  background: linear-gradient(180deg, #fa8930 0, #f57e20 50%, #ff5a00 100%);
  background: linear-gradient(180deg, var(--light-orange-color) 0, var(--main-orange-color) 50%, var(--dark-orange-color) 100%);
  border-radius: 5px;
  border-radius: var(--button-border-radius_5);
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
}

.app-yomi .disable-btn {
  background: rgb(109, 110, 113);
  background: var(--hover-button-bg-color);
  pointer-events: none;
}

.app-yomi .color-disable {
  color: #fff;
  background: rgb(109, 110, 113);
  background: var(--hover-button-bg-color);
  pointer-events: none;
  text-transform: none;
  border: 1px solid transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 14px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-yomi .button-field a {
  font-weight: 700;
  color: #fff;
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  background: transparent;
  line-height: 20px;
  padding: 16px 20px;
  font-size: 18px;
}

.app-yomi .button-field.fullview-btn {
  display: inline-block;
  vertical-align: middle;
  margin-top: -10px;
}

.app-yomi .button-field.button-field-country {
  display: block;
  float: right;
  margin-top: 2px;
}

.app-yomi .right-continue-btn .button-field.next-step-btn {
  min-width: 184px;
  margin-left: 0;
}

.app-yomi .right-continue-btn .button-field.next-step-btn:last-child {
  margin-left: auto;
}

.app-yomi .sec-left .button-field {
  display: inline-block;
  vertical-align: middle;
  min-width: 184px;
  margin-top: 15px;
  margin-left: 0px;
}

.app-yomi .sec-left .button-field button {
  width: 100%;
}

.app-yomi label {
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

/* .app-yomi p {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.4;
  font-family: var(--font-family);
} */

.app-yomi .form-field {
  margin-bottom: 30px;
  position: relative;
}

.app-yomi .form-field:last-child {
  margin-bottom: 0;
}

.app-yomi .form-field label {
  margin-bottom: 0;
  display: block;
}

.app-yomi .form-field input {
  width: 100%;
}

.app-yomi h1 {
  font-size: 48px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  margin-bottom: 20px;
  color: #4b4c4c;
}

.app-yomi .h1 {
  font-size: 36px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  margin-bottom: 20px;
  color: #4b4c4c;
}

.app-yomi h2 {
  font-size: 40px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  margin-bottom: 20px;
  color: inherit;
}

.app-yomi h3 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi h4 {
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi h5 {
  font-size: 16px;
  margin-bottom: 18px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi h6 {
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi a:focus, .app-yomi a:hover {
  color: #f27d1b;
}

.app-yomi input[type="date"], .app-yomi input[type="email"], .app-yomi input[type="password"], .app-yomi input[type="search"], .app-yomi input[type="tel"], .app-yomi input[type="text"], .app-yomi select, .app-yomi textarea {
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  color: #646464;
  background-color: #f5f7fa;
  line-height: 20px;
  padding: 20px 20px 19px;
  border-radius: 10px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi input[type="date"]:focus, .app-yomi input[type="email"]:focus, .app-yomi input[type="password"]:focus, .app-yomi input[type="search"]:focus, .app-yomi input[type="tel"]:focus, .app-yomi input[type="text"]:focus, .app-yomi .app-yomi textarea:focus {
  /* border-color: #449fe6; */
  color: #646464;
}

.app-yomi textarea {
  height: 109px;
  resize: none;
  border: 1px solid #fcfcfc;
}

.app-yomi select {
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
  background-position: center right 13px;
  background-repeat: no-repeat;
  background-size: 12px;
  padding-right: 30px;
  color: #cccccc;
}

.app-yomi select::-ms-expand {
  display: none;
}

.app-yomi select option, .app-yomi select:focus {
  color: #646464;
}

.app-yomi button, html input[type="button"], input[type="reset"], input[type="submit"] {
  font-weight: 400;
  color: #fff;
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: none;
  border: 1px solid transparent;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 14px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-yomi .file-upload-btn label {
  font-weight: 400;
  color: #fff;
  border-radius: 50px;
  min-width: 133px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: unset;
  border: 1px solid transparent;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.app-yomi .file-upload-btn-cust {
  text-align: center;
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.file-upload-cost-lable {
  padding: 5px;
  /* border: 1px solid #6d6e71; */
  width: 15%;
}

.file-upload-cost-data {
  padding: 5px;
  /* border-top: 1px solid #6d6e71; */
  border-bottom: 1px solid #6d6e71;
  /* border-right: 1px solid #6d6e71; */
}

.file-upload-cost-lable1 {
  padding: 5px;
  /* width: 100%; */
  /* border-bottom: 1px solid #6d6e71; */
  /* border-right: 1px solid #6d6e71; */
  /* border-left: 1px solid #6d6e71; */
}

.file-upload-cost-data1 {
  padding: 5px;
  /* border-bottom: 1px solid #6d6e71; */
  /* border-right: 1px solid #6d6e71; */
}

.file-upload-cost-total {
  font-size: 22px;
  color: rgb(109, 110, 113);
  color: var(--main-grey-color);
  font-weight: 700;
}

.app-yomi .contactus-username {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 0.8;
  color: #fa8930;
  color: var(--light-orange-color);
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi .theme-option div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-yomi .theme-option {
  font-weight: 550;
  color: #fa8930;
  color: var(--light-orange-color);
  line-height: 1.2;
  font-size: 13pt;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
  align-self: center;
  font-family: "Raleway", sans-serif;
  width: 57%;
}

.app-yomi .button-field:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(109, 110, 113);
  background: var(--hover-button-bg-color);
  border-radius: 5px;
  border-radius: var(--button-border-radius_5);
  z-index: -1;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.app-yomi .left-column {
  width: 50%;
  padding: 80px;
}

.app-yomi .logo {
  margin-bottom: 59px;
}

.app-yomi .logo img {
  display: inline-block;
  vertical-align: middle;
  max-width: 228px;
}

.app-yomi .logo a img {
  display: block;
}

.app-yomi .left-column h1 {
  margin-bottom: 40px;
}

.app-yomi .user-field {
  background-image: url(/static/media/user-field.449ff928.svg);
  background-repeat: no-repeat;
  background-position: center right 18px;
}

.app-yomi .pwd-field {
  background-image: url(/static/media/lock-ic.d52f2d96.svg);
  background-repeat: no-repeat;
  background-position: center right 18px;
}

.app-yomi .custom-checkbox {
  position: relative;
}

.app-yomi .custom-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -999999px;
}

.app-yomi .custom-checkbox input[type="checkbox"]+label {
  position: relative;
  padding-left: 31px;
  line-height: 23px;
  cursor: pointer;
}

.app-yomi .custom-checkbox input[type="checkbox"]+label:after, .app-yomi .custom-checkbox input[type="checkbox"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.app-yomi .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAAZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkSoWkOQAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.3s ease-in-out;
  background-size: 14px;
  border-color: transparent;
}

.app-yomi .custom-checkbox input[type="checkbox"]:checked+label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.app-yomi .bottom-links {
  padding-top: 40px;
}

.app-yomi .bottom-links ul {
  margin: 0;
}

.app-yomi .bottom-links ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #1073c6;
  padding-right: 27px;
}

.app-yomi .bottom-links ul li:last-child {
  padding-right: 0;
}

.app-yomi .bottom-links ul li a {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}

.app-yomi .bottom-links button {
  font-size: 16px;
  background: 0 0;
  padding: 0;
  line-height: 1;
  color: #1073c6;
  text-transform: none;
  font-weight: 400;
  text-decoration: underline;
  min-width: 1px;
}

.app-yomi .bottom-links button:hover, .app-yomi .bottom-links button:focus {
  color: #646464;
}

.app-yomi .right-column {
  width: 50%;
  color: #fff;
  padding: 0px 20px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.app-yomi .col-head {
  text-align: center;
  padding: 0 40px;
  position: absolute;
  top: 50px;
  z-index: 5;
}

.app-yomi .col-head h2 {
  text-transform: uppercase;
  font-size: 40px;
}

.app-yomi .col-head p {
  font-size: 22px;
  padding: 0 32px;
  line-height: 1.55;
  color: #ff5a00;
  color: var(--dark-orange-color);
  margin-top: 470px;
}

.app-yomi .image-slider {
  position: relative;
  width: 100%;
  margin-top: auto;
}

.app-yomi .image-slider .slick-dots {
  bottom: 20px;
}

.app-yomi .image-item {
  padding: 10px 2px 100px;
}

.app-yomi .image-item img {
  display: block;
  margin: 0 auto;
  max-height: 355px;
}

.app-yomi .error-msg {
  display: block;
  color: #dc3545;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-right: 50px;
}

.app-yomi .error-msg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url(/static/media/alert-ic.eb2ffdf6.svg);
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
}

.app-yomi input::-moz-placeholder {
  opacity: 1 !important;
}

.app-yomi input:-moz-placeholder {
  opacity: 1 !important;
}

.app-yomi input::-webkit-input-placeholder {
  color: #cccccc !important;
  opacity: 1 !important;
}

.app-yomi input:-ms-input-placeholder {
  opacity: 1 !important;
}

.app-yomi textarea::-moz-placeholder {
  opacity: 1 !important;
}

.app-yomi textarea:-moz-placeholder {
  opacity: 1 !important;
}

.app-yomi textarea::-webkit-input-placeholder {
  color: #cccccc !important;
  opacity: 1 !important;
}

.app-yomi textarea:-ms-input-placeholder {
  opacity: 1 !important;
}

.app-yomi .btn {
  transition: all 0.5s;
  font-size: 14px;
  background: #e83352;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
  padding: 25px 20px 25px;
}

.app-yomi .btn:focus, .app-yomi .btn:hover {
  box-shadow: none;
  color: #fff;
  background: #212529;
}

.app-yomi label {
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi .slick-slider .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0 5px;
}

.app-yomi .slick-slider .slick-dots li button {
  padding: 0;
  background: #fff;
  opacity: 0.5;
  min-width: 1px;
  width: 10px;
  height: 10px;
  transition: all 0.5s ease-in-out;
}

.app-yomi .slick-slider .slick-dots li button:before {
  display: none;
}

.app-yomi .slick-slider .slick-dots .slick-active button {
  width: 25px;
  opacity: 1;
}

.app-yomi .form-field {
  margin-bottom: 30px;
  position: relative;
}

.app-yomi .form-field:last-child {
  margin-bottom: 0;
}

.app-yomi .form-field label {
  margin-bottom: 0;
  display: block;
}

.app-yomi .form-field input {
  width: 100%;
}

.app-yomi .error input {
  /* border-color: #dc3545; */
  background-image: none !important;
}

.app-yomi .error select {
  /* border-color: #dc3545; */
  background-image: none !important;
}

.app-yomi .field_main {
  position: relative;
}

.app-yomi .custom-radio {
  position: relative;
}

.app-yomi .custom-radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: -999999px;
}

.app-yomi .field-right .custom-radio {
  display: inline-block;
  vertical-align: middle;
}

.app-yomi .custom-radio input[type="radio"]+label {
  position: relative;
  padding-left: 40px;
  visibility: visible !important;
  line-height: 30px;
  cursor: pointer;
}

.app-yomi .custom-radio input[type="radio"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 100%;
}

.app-yomi .custom-radio input[type="radio"]+label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border: 6px solid #fa8930;
  border: 6px solid var(--light-orange-color);
  border-radius: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.app-yomi .custom-radio input[type="radio"]:checked+label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.app-yomi .custom-radio+.custom-radio {
  margin-left: 40px;
}

.app-yomi .MuiBackdrop-root {
  background: rgba(75, 76, 76, 0.85);
}

.app-yomi-popup-box {
  min-width: 800px;
  max-width: 960px;
  margin: auto;
  background: #fff;
  border-radius: 5px;
  margin: 20px 0;
}

.app-yomi .box-heading {
  /* background: #0a5c7f;
  background: -moz-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: -webkit-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: linear-gradient(180deg, #0a5c7f 0, #1588ba 100%); */
  background: #f57e20;
  background: var(--main-orange-color);
  background: linear-gradient(180deg, #fa8930 0, #ff5a00 50%, #f57e20 100%);
  background: linear-gradient(180deg, var(--light-orange-color) 0, var(--dark-orange-color) 50%, var(--main-orange-color) 100%);
  text-align: center;
  padding: 41px 15px;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.app-yomi .file-lable-name-main {
  margin-bottom: 10;
  border: 1px solid rgb(109, 110, 113);
  border: 1px solid var(--main-grey-color);
  border-radius: 3px;
  padding: 10px;
}

.app-yomi .file-name-lable {
  font-size: 10px;
}

.app-yomi .box-heading img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}

.app-yomi .box-heading h2 {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  color: #fff;
}

.app-yomi .close-btn {
  background: 0 0;
  min-width: 1px;
  padding: 0;
  line-height: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  width: 35px;
  margin: 0;
}

.app-yomi .box-heading .close-btn img {
  margin: 0 auto;
  display: block;
  padding: 10px;
}

.app-yomi .box-content {
  padding: 30px 0px;
  text-align: center;
}

.app-yomi .box-content form {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 650px;
  /* text-align: left; */
}

.app-yomi .multiple-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.app-yomi .multiple-field input[type="text"] {
  border-radius: 4px;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  text-align: center;
}

.app-yomi .verify-otp-popup .box-content form {
  text-align: center;
}

.app-yomi .box-content p {
  font-size: 24px;
  margin-bottom: 26px;
}

.app-yomi .box-content a {
  color: #fa8930;
  color: var(--light-orange-color);
  border-bottom: 1px solid #fa8930;
  border-bottom: 1px solid var(--light-orange-color);
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  line-height: 1;
}

.app-yomi .main-sec-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 75px;
  min-height: 100vh;
  padding-top: 101px;
  transition: all 0.5s ease-in-out;
}

.app-yomi .main-sec-wrap.active-main {
  padding-left: 250px;
}

.app-yomi .main-content {
  width: 100%;
}

.app-yomi .content-right-main {
  padding: 35px 40px;
}

.app-yomi .content-right-heading {
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  font-weight: 500;
  color: #646464;
  line-height: 1.2;
  font-size: 21pt;
  margin-top: 5px;
}

.app-yomi .content-right-heading-adbilder {
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  font-weight: 500;
  color: rgb(109, 110, 113);
  color: var(--main-grey-color);
  line-height: 1.2;
  font-size: 21pt;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-yomi .content-heading-view {
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  display: flex;
}

.app-yomi .form_field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.app-yomi .form_field:last-child {
  margin-bottom: 0;
}

.app-yomi .form_field label {
  font-weight: 700;
  display: flex;
}

.app-yomi .field-left {
  width: 25%;
  padding-right: 15px;
}

.app-yomi .field-right {
  width: 75%;
  margin-bottom: 0px;
  position: relative;
}

.app-yomi .form-submit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-yomi .form-submit .button-field input {
  min-width: 196px;
}

.app-yomi .form-submit .button-field+.button-field {
  margin-left: 0px;
}

.app-yomi .bootom-sec .header-nav {
  display: none;
}

.app-yomi .bootom-sec {
  width: 100%;
  margin-top: auto;
}

.app-yomi .not_found {
  justify-content: center;
  width: 100%;
}

.app-yomi .main-sec-wrap {
  padding-top: 80px;
  /* padding: 80px 190px;
  text-align: center; */
}

.app-yomi .bootom-sec .header-nav li a {
  font-size: 12px;
  /* padding: 14px 10px; */
}

.app-yomi .bootom-sec .header-nav ul li svg {
  max-width: 15px;
  margin-right: 5px;
  margin-top: -3px;
}

.app-yomi .custom-border-table {
  margin-top: 30px;
}

.app-yomi .title-wrap {
  margin-bottom: -20px;
}

.app-yomi .title-wrap1 {
  margin-bottom: -5px;
}

.app-yomi .site-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.12);
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background: #fff;
}

.app-yomi .header-left {
  width: 26%;
  padding: 0px 25px 0px 55px;
}

.app-yomi .site-logo {
  display: inline-block;
  vertical-align: middle;
}

.app-yomi .site-logo a {
  display: inline-block;
  max-width: 205px;
  width: 142px;
}

.app-yomi .site-logo a img {
  display: block;
  max-width: 205px;
  width: 142px;
}

.app-yomi .header-right {
  width: 74%;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.app-yomi .header-nav {
  padding-right: 30px;
}

.app-yomi .header-nav ul {
  margin: 0;
}

.app-yomi .header-nav ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding-right: 60px;
}

.app-yomi .header-nav ul li:last-child {
  padding-right: 0;
}

.app-yomi .header-nav ul li a {
  display: inline-block;
  vertical-align: middle;
  font-size: 17px;
  font-weight: 500;
  color: #646464;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  text-transform: capitalize;
  position: relative;
  padding: 38px 0;
}

.app-yomi .header-nav ul li a:hover, .header-nav ul li a:focus {
  color: #fa8930;
  color: var(--light-orange-color);
}

.app-yomi .header-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #fa8930;
  background: var(--light-orange-color);
  transition: all 0.5s ease-in-out;
}

.app-yomi .header-nav ul li a:hover:after, .header-nav ul li a:focus:after {
  width: 100%;
}

.app-yomi .header-nav ul li .icon-status {
  top: 28px;
  left: 19px;
}

.app-yomi .header-nav ul li a svg path {
  transition: all 0.5s ease-in-out;
}

.app-yomi .header-nav ul li a:hover svg path, .header-nav ul li a:hover svg image, .header-nav ul li a:focus svg path, .header-nav ul li a:focus svg image {
  fill: #fa8930;
  fill: var(--light-orange-color);
}

.app-yomi .header-nav ul li svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 11px;
}

.app-yomi .account-info {
  /* background: rgb(255, 255, 255); */
  padding: 20px;
  cursor: pointer;
}

.app-yomi .MuiButton-label {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  padding: 0 0px 0 0;
  position: relative;
  align-items: center;
}

.app-yomi .MuiButton-label:after {
  content: "";
  position: absolute;
  top: -32px;
  right: 5px;
  bottom: 0;
  background: url(/static/media/dropdown-arrow.edeff98c.svg) no-repeat center center;
  width: 15px;
  background-size: 15px;
}

.app-yomi #simple-popover .MuiPaper-root.MuiPopover-paper {
  top: 72px !important;
  min-width: 270px;
  box-shadow: none;
  border-radius: 5px;
  padding: 40px;
  background: transparent;
  margin-left: 0;
  right: 0;
  left: auto !important;
}

.app-yomi #theme-popover .MuiPaper-root.MuiPopover-paper {
  top: 72px !important;
  min-width: 270px;
  /* box-shadow: none; */
  border-radius: 5px;
  /* padding: 40px; */
  background: transparent;
  margin-left: 0;
  right: 0;
  left: auto !important;
}

.app-yomi #location-popover .MuiPaper-root.MuiPopover-paper {
  top: 72px !important;
  min-width: 270px;
  /* box-shadow: none; */
  border-radius: 5px;
  /* padding: 40px; */
  background: transparent;
  margin-left: 0;
  right: 90px;
  left: auto !important;
}

.app-yomi .MuiButton-label img {
  margin-right: 30px;
}

.app-yomi .MuiButtonBase-root.MuiButton-text {
  padding: 0;
}

.app-yomi .MuiButton-root:hover, .MuiButton-root:focus {
  background-color: transparent !important;
}

.app-yomi .right-icons {
  height: 31px;
  padding: 4px 48px 4px 39px;
  border-left: 2px solid #dddddd;
}

.app-yomi .right-icons ul {
  margin: 0;
}

.app-yomi .right-icons ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding-right: 52px;
}

.app-yomi .right-icons ul li:last-child {
  padding-right: 0;
}

.app-yomi .right-icons ul li a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.app-yomi .right-icons ul li a img {
  max-width: 25px;
  display: block;
}

.app-yomi .icon-status {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background: #fa8930;
  background: var(--light-orange-color);
  border-radius: 100%;
  position: absolute;
  top: -7px;
  right: -6px;
  color: #ffff;
  text-align: center;
  line-height: 13px;
  font-size: 8px;
  border: 1px solid #fff;
  font-weight: 500;
}

.app-yomi .left-img {
  width: 40px;
}

.app-yomi .right-content {
  width: calc(100% - 40px);
  padding-left: 14px;
  position: relative;
}

.app-yomi .right-content p {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi .right-content p strong {
  font-weight: 500;
}

.app-yomi .right-content p small {
  color: #cccccc;
  font-size: 14px;
  display: block;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
}

.app-yomi .noti-time {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  color: #cccccc;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  position: absolute;
  top: 0;
  right: 0;
}

/* .sidebar { overflow: hidden; } */

.app-yomi .hide-scroll .site-header {
  width: calc(100% - 17px);
}

.app-yomi .hide-scroll {
  overflow: hidden;
  margin-right: 17px;
}

.app-yomi .mac_device {
  margin-right: 0;
}

.app-yomi .mac_device.hide-scroll {
  margin-right: 0;
}

.app-yomi .mac_device .site-header {
  width: 100%;
}

.app-yomi .mac_device.hide-scroll .site-header {
  width: 100%;
}

/*     left-sidebar      */

.app-yomi .left-sidebar {
  background: rgb(0, 0, 0);
  background: var(--main-black-color);
  width: 75px;
  padding: 25px 0 0;
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 0;
  top: 102px;
  height: calc(100vh - 102px);
  overflow-y: auto;
  z-index: 10;
}

.app-yomi .left-sidebar.active {
  left: 0px;
  width: 250px;
}

.app-yomi .humburger-menu {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 0;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 5px;
}

.app-yomi .humburger-menu span {
  display: block;
  background: #cccccc;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  margin-left: auto;
}

.app-yomi .humburger-menu span:last-child {
  margin-bottom: 0;
}

.app-yomi .left-sidebar.MuiExpansionPanelSummary-content span {
  display: block;
  width: 100%;
}

.app-yomi .left-sidebar nav {
  overflow: hidden;
  position: relative;
  left: -250px;
  width: 250px;
  transition: all 0.5s ease-in-out;
}

.app-yomi .left-sidebar.active nav {
  left: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root a {
  padding-left: 35px;
  border: none;
}

.app-yomi .left-sidebar-active {
  font-weight: 600;
}

.app-yomi .left-sidebar ul {
  margin: 0;
}

.app-yomi .left-sidebar li {
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  color: #ffffff;
  color: var(--font-color);
  font-weight: 300;
  font-size: 16px;
}

.app-yomi .left-sidebar li a, .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
  display: block;
  width: 100%;
  line-height: 1;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.app-yomi .left-sidebar li a:hover, .app-yomi .left-sidebar li a:focus, .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span:hover, .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span:focus {
  color: #fa8930;
  color: var(--light-orange-color);
  font-weight: 600;
  /* text-decoration: underline; */
}

.app-yomi .left-sidebar li a:hover, .app-yomi .left-sidebar li a:focus, .app-yomi sup {
  display: inline-block;
  text-decoration: none;
}

.app-yomi .left-sidebar li a img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 26px;
  opacity: 0.7;
  transition: all 0.5s ease-in-out;
}

.app-yomi .left-sidebar li a:hover img, .app-yomi .left-sidebar li a:focus img {
  opacity: 1;
}

.app-yomi .left-sidebar .MuiPaper-root.MuiExpansionPanel-root {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  box-shadow: none;
}

.app-yomi .left-sidebar .MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 1px;
  padding: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0;
  padding: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root {
  padding: 0;
  flex-wrap: wrap;
  /* background: rgba(10, 92, 127, 0.5); */
}

.app-yomi .left-sidebar .MuiExpansionPanelSummary-content {
  margin: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelSummary-root {
  min-height: 1px;
  padding: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
  position: relative;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root>div {
  width: 100%;
}

.app-yomi .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
  padding-left: 25px;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
  padding: 0px 0px 0px 35px;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
  position: relative;
  z-index: 1;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav:after {
  content: "";
  position: absolute;
  top: -2px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  /* background: #01afb0; */
  color: #f57e20;
  color: var(--main-orange-color);
  font-weight: 600;
  /* text-decoration: underline; */
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav:hover:after, .app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav:focus:after {
  opacity: 1;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  display: block;
  line-height: 1;
  width: 100%;
  padding: 14px 0px 14px;
  transition: border 0.5s ease-in-out;
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  -moz-transition: border 0.5s ease-in-out;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav:hover span, .app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav:focus span {
  border-color: transparent;
  transition: none;
}

.app-yomi .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
  color: #fff;
  padding: 10px;
  font-weight: 600;
  margin-left: 15px;
}

.app-yomi .left-sidebar .MuiCollapse-container.MuiCollapse-entered {
  margin-top: 3px;
}

.app-yomi .left-sidebar .subparent-nav .MuiCollapse-container.MuiCollapse-entered {
  margin-top: 0;
}

.app-yomi .left-sidebar .MuiExpansionPanelSummary-root.active {
  /* background: #01afb0; */
  color: #fa8930;
  color: var(--light-orange-color);
  font-weight: 600;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active {
  /* background: #01afb0; */
  color: #fa8930;
  color: var(--light-orange-color);
  font-weight: 600;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active:after {
  opacity: 1;
}

.app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active span {
  border-color: #fa8930;
  border-color: var(--light-orange-color);
  font-weight: 600;
}

/*     Resource     */

.app-yomi .main_tab_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  padding: 40px 40px 5px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}

.app-yomi .content-box {
  background: #fff;
  margin-right: 2.6%;
  border: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 15px;
  width: 300px;
  /* height: 300px; */
}

.app-yomi .one-col-content {
  background: #fff;
  border: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.app-yomi .one-col-content-box {
  background: #fff;
  border: 2px solid #fa8930;
  border: 2px solid var(--light-orange-color);
  position: relative;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.app-yomi .content-box:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-yomi .content-box:hover:after, .app-yomi .content-box:focus:after {
  opacity: 1;
}

.app-yomi .content-box:nth-child(3n) {
  margin-right: 2.6%;
}

.app-yomi .ecp_resource .tab-listing ul li {
  padding-right: 0px;
}

.app-yomi .popup-box {
  min-width: 800px;
  max-width: 960px;
  margin: auto;
  background: #fff;
  border-radius: 5px;
  margin: 20px 0;
}

.app-yomi .box-image {
  background-repeat: no-repeat;
  border-bottom: 1px solid #ebebeb;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  padding: 17% 15px;
  height: 300px;
}

.app-yomi .free-asset-content-box {
  height: 490px;
  width: 300px;
  align-items: center;
  justify-content: center;
}

.app-yomi .free-asset-image-box {
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: cover;
  height: 298px;
  width: 298px;
  position: relative;
}

.app-yomi .box-image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  background: #0a0a0a;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.app-yomi .button-hover {
  opacity: 0;
}

.app-yomi .content-box:hover .box-image:after, .app-yomi .content-box:focus .box-image:after {
  opacity: 0.7;
}

.app-yomi .content-box:hover .button-hover, .app-yomi .content-box:focus .button-hover {
  opacity: 1;
}

.app-yomi .box-content-info p {
  color: #fa8930;
  color: var(--light-orange-color);
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  margin-bottom: 5px;
}

.app-yomi .box-content-info h4 {
  margin: 0 0 0px;
}

.app-yomi .box-lable {
  font-size: 12px;
  font-weight: 500;
}

.app-yomi .box-content-info {
  padding: 20px 20px 22px;
}

.app-yomi .box-content-info p:last-child {
  margin-bottom: 0;
}

.app-yomi .box-image .button-field {
  display: table;
  margin: 0 auto 30px;
}

.app-yomi .box-image .button-field:last-child {
  margin-bottom: 0;
}

.app-yomi .popup-wrap {
  padding: 37px;
  background: #fff;
  margin: 100px 0;
  position: relative;
  max-width: 1120px;
  min-width: 1120px;
  display: flex;
  justify-content: center;
}

.app-yomi .MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.9) !important;
}

.app-yomi .gray-close-ic {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 30px;
}

/*        Clinic       */

.app-yomi .tab-listing ul {
  margin: 0;
}

.app-yomi .tab-listing ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #646464;
  font-weight: 700;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  text-transform: uppercase;
  padding-right: 52px;
}

.app-yomi .MuiTableRow-root .MuiTableCell-head.center {
  text-align: center;
}

.app-yomi .tab-content {
  padding: 0px 0 0;
}

.app-yomi .tab-content .form-main {
  margin-top: 0;
}

.app-yomi .tab-listing {
  padding: 22px 0 0px 0;
}

.app-yomi .tab-listing ul li a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  padding-bottom: 15px;
  padding-right: 12px;
}

.app-yomi .tab-listing ul li a:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 3px;
  background: #fa8930;
  background: var(--light-orange-color);
  width: 0;
  transition: all 0.5s ease-in-out;
}

.app-yomi .tab-listing ul li a:hover, .app-yomi .tab-listing ul li a:focus, .app-yomi .tab-listing ul .active {
  color: #fa8930;
  color: var(--light-orange-color);
}

.app-yomi .tab-listing ul .active:after, .app-yomi .tab-listing ul li a:hover:after, .app-yomi .tab-listing ul li a:focus:after {
  width: 100%;
}

.app-yomi .tab-content .field-left {
  width: 23%;
}

.app-yomi .tab-content .field-right {
  width: 77%;
}

.app-yomi .tab-content .form-main form {
  max-width: 843px;
}

.app-yomi .table-link a {
  color: #fa8930;
  color: var(--light-orange-color);
  text-decoration: underline;
}

.app-yomi .table-main-wrap {
  text-align: right;
}

.app-yomi .tab-listing+.table-main-wrap {
  margin: 0;
}

.app-yomi .table-link {
  padding-bottom: 20px;
}

.app-yomi .table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
  min-width: 80px;
}

.app-yomi .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 263px;
}

.app-yomi .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 185px;
}

.app-yomi .MuiTableRow-root th.MuiTableCell-root {
  font-weight: 700;
}

.app-yomi .data-popup-box {
  min-width: 1120px;
  max-width: 1120px;
  margin: 160px 0;
}

.app-yomi .data-popup-box .box-heading {
  padding: 38px 15px;
}

.app-yomi .data-popup-box .box-heading .close-btn img {
  padding: 0;
  width: 20px;
}

.app-yomi .data-popup-box .close-btn {
  top: 30px;
  right: 24px;
}

.app-yomi .data-popup-box .box-content form {
  max-width: 845px;
}

.app-yomi .data-popup-box .box-content {
  padding: 50px 40px;
}

.app-yomi .data-popup-box .box-content form .field-right {
  width: 77%;
}

.app-yomi .data-popup-box .box-content form .field-left {
  width: 23%;
}

.app-yomi .data-popup-box .form-submit {
  justify-content: center;
}

.app-yomi .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 133px;
}

.app-yomi .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 135px;
}

.app-yomi .MuiTableRow-root th.MuiTableCell-root {
  border-bottom-color: transparent;
  border-bottom-width: 0;
}

.app-yomi .tab-listing .slick-next {
  position: absolute;
  right: 0;
  top: -15px;
  bottom: 0;
  height: auto;
  margin: 0;
  -webkit-transform: none;
          transform: none;
}

.app-yomi .tab-listing .slick-next:before {
  display: none;
}

.app-yomi .tab-listing .slick-next:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url(/static/media/right-arrow.57d8ae5a.svg);
  background-repeat: no-repeat;
  background-position: center right 30px;
  width: 80px;
  background-color: #f5f7fa;
  background-size: 7px;
}

.app-yomi .tab-listing .slick-next.slick-disabled {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.app-yomi .tab-listing .slick-prev {
  display: none !important;
}

.app-yomi .tab-listing .slick-slide {
  /* width: auto !important; */
  padding-right: 52px;
}

.app-yomi .tab-listing .slick-track {
  margin-left: 0;
}

.app-yomi .content-heading-dropdown {
  direction: ltr;
  justify-content: flex-end;
  /* align-items: flex-end; */
  display: flex;
  margin-top: 15px;
  width: 48.5%;
}

/*        product-selection        */

.app-yomi .three-block-sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-right: 4px;
}

.app-yomi .block-col {
  background: #ffffff;
  background: var(--font-color);
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 8px;
  width: 23%;
  margin-right: 2.05%;
  margin-bottom: 2.05%;
  text-align: center;
}

.app-yomi .block-col:nth-child(4n) {
  margin-right: 0;
}

.app-yomi .hover_link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0px 15px;
}

.app-yomi .hover_link:hover, .app-yomi .hover_link:focus {
  background: #fa8930;
  background: var(--light-orange-color);
  color: #fff;
  border-radius: 8px;
}

.app-yomi .block-col p {
  margin: 0 0 7px;
  font-size: 13px;
  white-space: pre;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  color: rgb(109, 110, 113);
  color: var(--main-grey-color);
  letter-spacing: 0.5px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  height: 30px;
}

.app-yomi .hover_link:hover p, .app-yomi .hover_link:focus p {
  color: rgba(255, 255, 255, 0.7);
}

.app-yomi .hover_link:hover h2, .app-yomi .hover_link:focus h2 {
  color: rgba(255, 255, 255, 0.7);
}

.app-yomi .block-col img {
  display: block;
  margin: 0 auto 15px;
  width: 80px;
  height: 80px;
}

.app-yomi .block-col h2 {
  margin: 0;
  bottom: 0;
  font-size: 27px;
  font-weight: 500;
  color: #fa8930;
  color: var(--light-orange-color);
}

.app-yomi .block-col.active .hover_link {
  background: #fa8930;
  background: var(--light-orange-color);
  color: #fff;
  border-radius: 8px;
}

.app-yomi .block-col.active .hover_link p {
  color: rgba(255, 255, 255, 0.7);
}

.app-yomi .block-col.active .hover_link h2 {
  color: rgba(255, 255, 255, 0.7);
}

.app-yomi .two-col-sec {
  /* background: #fff; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 31px 40px 60px; */
  margin-top: 20px;
  /* box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06); */
}

.app-yomi .two-col-sec p {
  font-size: 24px;
  letter-spacing: 0;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  line-height: 2;
  margin-bottom: 20px;
}

.app-yomi .textparagraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  line-height: 2;
  color: #334455;
}

.app-yomi .textheading {
  font-size: 22px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  line-height: 2;
  color: #334455;
}

.app-yomi .textlist {
  letter-spacing: 0;
  line-height: 0;
}

.app-yomi .two-col-sec p:last-child {
  margin-bottom: 0;
}

.app-yomi .sec-left {
  width: 50%;
  padding-right: 40px;
}

.app-yomi .sec-right {
  width: 50%;
  padding: 10px 0 0 50px;
}

.app-yomi .sec-right img {
  display: block;
  margin: 0 auto;
}

/*         budgetDetail          */

.app-yomi .two-col-sec h3 {
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  font-weight: 400;
}

.app-yomi .confirmation-message {
  font-size: 27px;
  text-align: center;
}

.app-yomi .two-col-sec p+h3 {
  margin-top: 56px;
  margin-bottom: 21px;
}

.app-yomi .two-col-sec li {
  font-size: 24px;
}

.app-yomi .two-col-sec ul {
  margin: -10 0 50px;
}

.app-yomi .two-col-sec ul li {
  list-style: none;
  display: block;
  position: relative;
  padding-left: 26px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.app-yomi .two-col-sec ul li:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 1px;
  width: 5px;
  height: 5px;
  background: #4b4c4c;
  border-radius: 100%;
}

.app-yomi .detail-welcome {
  margin-top: 28px;
  padding-bottom: 12px;
  margin-bottom: 40px;
}

.app-yomi .detail-welcome .sec-right {
  padding-top: 9px;
}

.app-yomi .full-field label {
  display: block;
  margin-bottom: 15px;
}

.app-yomi .full-field {
  margin-bottom: 2px;
}

.app-yomi .full-field p small {
  font-size: 14px;
  color: #55555580;
}

.app-yomi .full-field p {
  line-height: 1;
  margin-top: 22px;
}

.app-yomi .blue-link {
  text-align: center;
  margin-top: 40px;
}

.app-yomi .blue-link a {
  color: #1073c6;
  font-size: 24px;
  text-decoration: underline;
  display: inline-block;
  vertical-align: middle;
}

.app-yomi .blue-link a:hover, .app-yomi .blue-link a:focus {
  text-decoration: none;
}

.app-yomi .full-field.budget_detail_field {
  max-width: 650px;
}

.app-yomi .table-body .custom-checkbox input[type="checkbox"]+label:after, .app-yomi .location-name .custom-checkbox input[type="checkbox"]+label:after, .app-yomi .map-sec-left .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAA////////////////////////////////600+wgAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-color: #fa8930;
  background-color: var(--light-orange-color);
  background-size: 12px;
}

.app-yomi .box-head h4 {
  font-size: 20px;
  color: #fa8930;
  color: var(--light-orange-color);
  margin: 0;
  text-transform: capitalize;
}

.app-yomi .theme-option-location {
  width: 25%;
  text-align: end;
}

.app-yomi .MuiSvgIcon-fontSizeLarge {
  font-size: 1em;
}

/*        adbreak       */

.app-yomi .page-img .next-step-btn {
  margin-top: 30px;
}

.app-yomi .page-img .back-btn {
  margin-top: 30px;
}

.app-yomi .button-field a {
  font-weight: 700;
  color: #fff;
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Raleway", sans-serif;
  font-family: var(--font-family);
  background: transparent;
  line-height: 20px;
  padding: 16px 20px;
  font-size: 18px;
}

.app-yomi .full-page-wrap h1 {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 169px);
}

.app-yomi .button-field.fullview-btn {
  display: inline-block;
  vertical-align: middle;
  margin-top: -10px;
}

.app-yomi .page-img {
  margin-top: 15px;
}

.app-yomi .white-box-wrapper {
  padding-top: 57px;
}

.app-yomi .white-box-content {
  background: #fff;
  padding: 40px;
  margin-top: 15px;
  padding-bottom: 20px;
}

.app-yomi .step-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  counter-reset: section;
  margin-top: 48px;
}

.app-yomi .step-col h3 {
  line-height: 1.25;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: #ccc;
  font-size: 18px;
}

.app-yomi .step-col h3 span {
  display: block;
}

.app-yomi .right-continue-btn {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}

.app-yomi .step-col {
  position: relative;
  counter-increment: section;
  max-width: 180px;
  margin: 0 5.3%;
}

.app-yomi .step-col:first-child {
  margin-left: 0;
}

.app-yomi .step-col:last-child {
  margin-right: 0;
}

.app-yomi .step-col:after {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  color: #ccc;
  border-radius: 100%;
  margin: 0 auto;
  content: counter(section);
  text-align: center;
  background: #ebebeb;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.app-yomi .active-step:after {
  /* content: ""; */
  /* background-image: url("../../../assets/images/blue-check-ic.svg"); */
  /* background: var(--light-orange-color); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.app-yomi .step-col.active:after {
  /* background: var(--light-orange-color); */
  background-image: url(/static/media/orange-check-ic.a6721477.svg);
  content: "";
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.app-yomi .active-step h3, .app-yomi .step-col.active h3 {
  color: rgb(109, 110, 113);
  color: var(--main-grey-color);
}

.app-yomi .white-box-image {
  text-align: center;
  margin-top: 39px;
  margin-bottom: 37px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 38px 0 46px;
  align-items: flex-start;
}

.app-yomi .white-box-image img {
  display: block;
  margin: 0 auto;
}

.app-yomi .step-col:before {
  content: "";
  height: 3px;
  position: absolute;
  top: -36px;
  margin: auto 0;
  background: #ebebeb;
  width: 260px;
  left: 55%;
}

.app-yomi .step-col:last-child:before {
  display: none;
}

.app-yomi .content_col_557 {
  max-width: 559px;
}

.app-yomi .content_img_col {
  border: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
}

.app-yomi .content_col_small {
  margin-right: 4.5%;
  width: 12.32%;
}

.app-yomi .content_col_middle {
  margin-right: 4.5%;
  width: 22.95%;
}

.app-yomi .content_col_large {
  width: 55.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
}

.app-yomi .content_img_row {
  margin-bottom: 36px;
  margin-right: 13.2%;
  display: flex;
  flex-wrap: wrap;
}

.app-yomi .content_img_row1 {
  border: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
}

.app-yomi .row-300-width {
  width: 41.35%;
}

.app-yomi .row-250-width {
  width: 34.51%;
  margin-right: 0;
}

.app-yomi .content_img_row:first-child, .content_img_row:last-child {
  width: 100%;
  margin-right: 0;
}

.app-yomi .content-info h6 {
  color: #ccc;
  margin-bottom: 0px;
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  text-align: left;
  font-size: 18px;
}

.app-yomi .content_col_small .content-info h6 {
  margin-bottom: 7px;
  width: 100%;
  font-size: 16px;
}

.app-yomi .content-info p {
  color: #ccc;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  text-align: right;
  font-size: 18px;
}

.app-yomi .content_col_small .content-info p {
  width: 100%;
  text-align: left;
}

.app-yomi .image_content {
  width: 100%;
}

.app-yomi .content-info {
  padding: 19px 19px 18px;
  margin-top: auto;
  width: 100%;
}

.app-yomi .row-320-width {
  max-width: 44.1%;
}

.app-yomi .right-continue-btn .button-field.next-step-btn {
  min-width: 184px;
  margin-left: 0;
}

.app-yomi .right-continue-btn .button-field.next-step-btn:last-child {
  margin-left: auto;
}

.app-yomi .content_img_col:only-child {
  margin: 0 auto;
}

.app-yomi .image_content img {
  width: 100%;
}


.app-yomi .cart-list-content {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1%;
}

.app-yomi .cart-label-heading {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-yomi .cart-label-subdata {
  color: #0A0A0A;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-yomi .cart-label-location {
  color: #646464;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-yomi .cart-label-subdata-link {
  color: #ff5a00;
  color: var(--dark-orange-color);
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 5px;
}

.app-yomi .cart-art-proof-link {
  color: #ff5a00;
  color: var(--dark-orange-color);
  font-size: 13px;
  cursor: pointer;
}

.app-yomi .cart-art-proof-link-icon {
  color: #ff5a00;
  color: var(--dark-orange-color);
  font-size: 13px;
  text-align: center;
}

.app-yomi .cart-price-main {
  width: 100%;
  margin-top: 1%;
  align-items: center;
}

.app-yomi .cart-total-div {
  margin-top: 2%;
  justify-content: space-between;
  display: flex;
}

.cart-total-div-button {
  justify-content: space-between;
}

.app-yomi .cart-fund-text {
  color: rgb(0, 0, 0);
  color: var(--main-black-color);
  font-size: 14px;
  font-weight: 400;
}

.app-yomi .cart-fund-amount {
  color: rgb(0, 0, 0);
  color: var(--main-black-color);
  font-size: 14px;
  font-weight: 700;
}

.app-yomi .cart-subtotal-text {
  color: #000;
  color: var(--disable-font-color);
  font-size: 18px;
  font-weight: 700;
}

.app-yomi .cart-total-text {
  color: #ff5a00;
  color: var(--dark-orange-color);
  font-size: 18px;
  font-weight: 700;
}

.app-yomi .cart-terms-conditions {
  color: #1073c6;
  font-size: 14px;
  font-weight: 400;
  text-align: end;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}

.app-yomi .check-input-coop {
  accent-color: #ff5a00;
  accent-color: var(--dark-orange-color);
  height: 18px;
  width: 18px;
}

.app-yomi .red-input {
  accent-color: #ff5a00;
  accent-color: var(--dark-orange-color);
}

.app-yomi .Suggetion-wrap {
  position: absolute;
  top: 60;
  right: 0;
  left: 0;
  z-index: 10;
  border: 2px solid #fa8930;
  border: 2px solid var(--light-orange-color);
  border-radius: 10px;
  padding: 10px;
  color: #646464;
  background-color: #fff;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-yomi .DirectMail-file-label {
  color: #f57e20;
  color: var(--main-orange-color);
}

.app-yomi .DirectMail-download-file-label {
  color: #f57e20;
  color: var(--main-orange-color);
  display: flex;
  justify-content: end;
  margin-bottom: 2%;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.app-yomi .arrow-popup.notification h6 {
  font-size: 16px;
  color: #f57e20;
  color: var(--main-orange-color);
  font-weight: 700;
  font-family: "Raleway Condensed", sans-serif;
  margin: 0;
  border-bottom: 1px solid #f5f7fa;
  padding: 23px 16px 19px;
}

@media (min-width: 1720px) {
  .app-yomi .main {
    max-width: 1620px;
    margin: 0 auto;
  }
}

@media (max-width: 1700px) {
  .app-yomi .step-col {
    margin: 0 1%;
  }

  .app-yomi .step-col:before {
    width: 210px;
  }

  .app-yomi .white-box-image {
    padding: 0;
  }
}

@media (min-width: 1501px) and (max-height: 800px) {
  .app-yomi .login_page {
    padding: 30px;
    height: calc(100vh);
    /* overflow: hidden; */
  }

  .app-yomi .col-head {
    padding: 0 20px;
  }
}

@media (max-width: 1500px) {
  .app-yomi .login_page {
    padding: 40px;
  }

  .app-yomi .login_page .main {
    min-height: calc(100vh - 80px);
  }

  .app-yomi .left-column {
    padding: 40px;
  }

  .app-yomi .main {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
  }

  .app-yomi .right-column {
    padding: 60px 20px 40px;
  }

  .app-yomi .col-head {
    padding: 0;
  }

  .app-yomi .col-head p {
    padding: 0 35px;
    margin-top: 410px;
    font-size: 20px;
  }

  .app-yomi .col-head h2 {
    margin-bottom: 12px;
    font-size: 36px;
  }

  .app-yomi .image-item {
    padding: 0px 32px 100px;
  }

  .app-yomi .box-heading {
    padding: 15px 0px;
  }

  .app-yomi .content-right-main {
    padding: 20px 25px;
  }

  .app-yomi .main-sec-wrap.active-main {
    padding-left: 250px;
  }

  .app-yomi .content-right-heading {
    margin-top: 0px;
  }

  .app-yomi .content-right-heading-adbilder {
    margin-top: 0px;
  }

  .app-yomi .content-heading-view {
    margin-top: 0px;
  }

  .app-yomi .right-icons ul li {
    padding-right: 30px;
  }

  .app-yomi .right-icons ul li img {
    max-width: 28px;
    max-height: 23px;
  }

  .app-yomi .header-nav ul li {
    padding-right: 50px;
  }

  /*     Resource    1500px */
  .app-yomi .ecp_resource .tab-listing ul li {
    padding-right: 12px;
    font-size: 18px;
  }

  .app-yomi .ecp_resource .tab-listing ul li:last-child {
    padding-right: 0;
  }

  .app-yomi .main_tab_content {
    padding: 30px 30px 30px;
  }

  .app-yomi .box-image .button-field {
    margin-bottom: 10px;
  }

  .app-yomi .box-image {
    padding: 25% 15px;
    height: 300px;
  }

  .app-yomi .box-content-info {
    padding: 10px 10px;
  }

  .app-yomi .popup-wrap {
    margin: 10px 0;
    max-width: 1050px;
    min-width: 950px;
    padding: 35px;
  }

  .app-yomi .free-asset-content-box {
    height: 430px;
    width: 300px;
  }

  .app-yomi .free-asset-image-box {
    height: 298px;
    width: 298px;
  }

  /*        Clinic       */
  .app-yomi .tab-listing ul li {
    font-size: 20px;
  }

  .app-yomi .form-main {
    padding: 45px 40px;
  }

  .app-yomi .tab-listing .slick-slide {
    padding-right: 30px;
  }

  /*        product-selection     1500px */
  .app-yomi .three-block-sec {
    padding-right: 0;
  }

  .app-yomi .hover_link {
    padding: 10px 10px;
  }

  .app-yomi .block-col h2 {
    font-size: 22px;
  }

  .app-yomi .two-col-sec p {
    font-size: 15px;
    margin-bottom: 0;
    line-height: 1.8;
    font-weight: 400;
  }

  .app-yomi .sec-left {
    width: 55%;
  }

  .app-yomi .sec-right {
    width: 45%;
    padding-left: 15px;
    padding-top: 60px;
  }

  .app-yomi .two-col-sec ul li, .app-yomi .blue-link a, .app-yomi .two-col-sec h3 {
    font-size: 15px;
  }

  .app-yomi .two-col-sec p+h3 {
    margin-top: 40px;
  }

  .app-yomi .blue-link {
    margin-top: 30px;
  }

  .app-yomi .content-heading-dropdown {
    margin-top: 0px;
  }

  /*        location         1500px */
  .app-yomi .box-head {
    padding: 20px 20px 0px;
  }

  /*        adbreak        1500px */
  .app-yomi .step-col h3 {
    font-size: 18px;
    color: #ccc;
  }

  .app-yomi .step-col:before {
    width: 168px;
  }

  .app-yomi .white-box-content {
    padding: 25px 25px;
  }

  .app-yomi .white-box-image {
    padding: 0;
  }

  .app-yomi .content_img_row {
    margin-bottom: 20px;
  }

  .app-yomi .content_img_row {
    margin-right: 10%;
  }

  .app-yomi .row-300-width {
    width: 44%;
  }

  .app-yomi .row-250-width {
    width: 42%;
    margin-right: 0;
  }

  .app-yomi .content-info h6 {
    font-size: 14px;
    width: 65%;
  }

  .app-yomi .content-info p {
    font-size: 15px;
    width: 35%;
  }

  .app-yomi .content-info {
    padding: 9px 10px;
  }

  .app-yomi .content_col_small {
    width: 13%;
    margin-right: 2%;
  }

  .app-yomi .content_col_middle {
    width: 25%;
    margin-right: 2%;
  }

  .app-yomi .content_img_col.content_col_large {
    width: 58%;
  }

  .app-yomi .content_col_small .content-info h6 {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .app-yomi .content_col_small .content-info p {
    font-size: 13px;
  }

  /* 1500pxx */
  .app-yomi .confirmation-message {
    font-size: 27px;
    text-align: center;
  }
}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {
  .app-yomi .main-sec-wrap {
    padding-top: 90px;
  }

  .app-yomi .header-left {
    padding: 5px 20px 0px 45px;
  }

  .app-yomi .header-nav ul li {
    padding-right: 40px;
  }

  .app-yomi .right-icons {
    padding: 4px 30px;
  }

  .app-yomi .account-info {
    padding: 0px 0px;
  }

  .app-yomi .header-nav ul li a {
    padding: 32px 0;
  }

  .app-yomi #simple-popover .MuiPaper-root.MuiPopover-paper {
    top: 62px !important;
    margin-left: 0px;
  }

  .app-yomi #theme-popover .MuiPaper-root.MuiPopover-paper {
    top: 172px !important;
    margin-left: 15px;
  }

  .app-yomi #location-popover .MuiPaper-root.MuiPopover-paper {
    top: 172px !important;
    margin-left: 15px;
  }

  /*    left-sidebar   */
  .app-yomi .left-sidebar {
    height: calc(100vh - 90px);
    top: 90px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 35px;
  }
}

@media (max-width: 1199px) {
  .app-yomi .login_page {
    padding: 13px;
  }

  .app-yomi .login_page .main {
    min-height: calc(100vh - 100px);
  }

  .app-yomi .left-column {
    padding: 0px 0px;
  }

  .app-yomi .left-column h1 {
    margin-bottom: 0px;
  }

  .app-yomi .logo {
    margin-bottom: 0px;
  }

  .app-yomi .logo img {
    max-width: 170px;
  }

  .app-yomi .user-field {
    background-size: 15px;
    background-position: center right 15px;
  }

  .app-yomi .pwd-field {
    background-size: 13px;
    background-position: center right 15px;
  }

  .app-yomi .bottom-links {
    padding-top: 10px;
    margin-top: -22px;
  }

  .app-yomi .right-column {
    padding: 30px 20px 30px;
    background-color: antiquewhite;
    height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-yomi .col-head p {
    font-size: 20px;
    padding: 0;
    margin-top: 440px;
  }

  .app-yomi .image-item {
    padding: 10px 20px 80px;
  }

  .app-yomi .error-msg {
    font-size: 14px;
    padding-right: 35px;
  }

  .app-yomi .error-msg:after {
    background-size: 15px;
    width: 15px;
    height: 15px;
    right: 15px;
  }

  .app-yomi .col-head h2 {
    font-size: 34px;
  }

  .app-yomi h1, .app-yomi .h1 {
    font-size: 38px;
  }

  .app-yomi h2 {
    font-size: 34px;
  }

  .app-yomi input[type="date"], input[type="email"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], select, textarea {
    padding: 15px;
  }

  .app-yomi button, html input[type="button"], input[type="reset"], input[type="submit"] {
    padding: 17px 25px 17px;
    font-size: 14px;
    line-height: 5px;
  }

  .app-yomi .form-field {
    margin-bottom: 20px;
  }

  .app-yomi .form-field label {
    margin-bottom: 10px;
  }

  .app-yomi .button-field.button-field-country {
    margin-top: -4px;
  }

  /*      verify otp      1199px*/
  .app-yomi .popup-box {
    min-width: 700px;
    max-width: 700px;
  }

  .app-yomi .box-heading img {
    margin-right: 20px;
  }

  .app-yomi .box-content p {
    font-size: 20px;
    margin-bottom: 17px;
  }

  .app-yomi .multiple-field {
    margin-top: 15px;
  }

  .app-yomi .box-content {
    padding: 20px 80px;
  }

  .app-yomi .box-content form {
    max-width: 100%;
  }

  .app-yomi .main-sec-wrap.active-main {
    padding-left: 250px;
  }

  .app-yomi .content-right-main {
    padding: 15px 25px 40px;
  }

  .app-yomi .content-right-heading {
    font-size: 19pt;
    margin-top: 0px;
  }

  .app-yomi .content-right-heading-adbilder {
    font-size: 19pt;
    margin-top: -5px;
  }

  .app-yomi .content-heading-view {
    margin-top: 0px;
  }

  .app-yomi .content-heading-dropdown {
    margin-top: -19px;
  }

  .file-upload-cost-lable {
    font-size: 14px;
  }

  .file-upload-cost-lable1 {
    font-size: 14px;
  }

  .app-yomi .site-logo a {
    max-width: 160px;
    width: 115px;
  }

  .app-yomi .MuiButton-label {
    font-size: 16px;
  }

  .app-yomi .right-content p {
    font-size: 14px;
  }

  .app-yomi .header-nav ul li a {
    font-size: 16px;
  }

  .app-yomi .header-nav ul li svg {
    margin-right: 9px;
    max-width: 20px;
  }

  .app-yomi .header-nav ul li {
    padding-right: 22px;
  }

  .app-yomi .right-icons {
    padding: 1px 20px;
  }

  .app-yomi .account-info {
    padding: 0px 0px;
  }

  .app-yomi .header-nav {
    padding-right: 24px;
  }

  .app-yomi .header-left {
    padding: 0px 10px 0px 40px;
    width: 24%;
  }

  .app-yomi .header-right {
    width: 76%;
  }

  .app-yomi .header-nav ul li .icon-status {
    left: 15px;
  }

  /*    left-sidebar  1199px    */
  .app-yomi .left-sidebar {
    top: 86px;
    height: calc(100vh - 90px);
  }

  .app-yomi .left-sidebar li {
    font-size: 16px;
  }

  .app-yomi .left-sidebar li a {
    padding: 16px 30px;
  }

  .app-yomi .left-sidebar li a img {
    margin-right: 20px;
    max-width: 20px;
    margin-top: -3px;
  }

  .app-yomi .subparent-nav {
    margin-bottom: 10px;
  }

  .app-yomi .left-sidebar .subparent-nav .MuiCollapse-container.MuiCollapse-entered {
    margin-top: 0;
  }

  .app-yomi .left-sidebar.active {
    width: 250px;
  }

  .app-yomi .humburger-menu {
    margin-bottom: 0x;
  }

  .app-yomi .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
    padding: 10px;
  }

  .app-yomi .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 25px;
  }

  .app-yomi .left-sidebar li a, .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 6px;
  }

  .app-yomi .subparent-nav:last-child {
    margin-bottom: 0;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 80px;
    padding-right: 40px;
  }

  .app-yomi .left-sidebar nav {
    width: 250px;
  }

  /*       Resource      1199px */
  .app-yomi .content-box:nth-child(2n) {
    margin-right: 0;
  }

  .app-yomi .popup-wrap {
    margin: 10px 0;
    max-width: 1000px;
    min-width: 1000px;
    padding: 30px;
  }

  .app-yomi .gray-close-ic {
    max-width: 20px;
  }

  .app-yomi .box-image .button-field {
    margin-bottom: 10px;
  }

  .app-yomi .box-image {
    padding: 15% 15px;
    /* background-size: auto; */
  }

  .app-yomi .free-asset-content-box {
    height: 425px;
    width: 300px;
  }

  .app-yomi .free-asset-image-box {
    height: 298px;
    width: 298px;
  }

  /*        Clinic    1199px */
  .app-yomi .data-popup-box {
    min-width: 800px;
    max-width: 800px;
    margin: 60px 0;
  }

  .app-yomi .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 190px;
  }

  .app-yomi .table-main-wrap .MuiTableRow-root .MuiTableCell-root {
    min-width: 130px;
  }

  .app-yomi .tab-listing .slick-slide {
    padding-right: 5px;
  }

  /*      product-selection        */
  .app-yomi .hover_link {
    padding: 15px 15px;
  }

  .app-yomi .block-col h2 {
    font-size: 22px;
  }

  .app-yomi .three-block-sec {
    padding-right: 0;
    margin-top: 30px;
  }

  .app-yomi .block-col {
    width: 48.47%;
  }

  .app-yomi .block-col:nth-child(4n) {
    margin-right: 3.05%;
  }

  .app-yomi .block-col:nth-child(2n) {
    margin-right: 0;
  }

  .app-yomi .sec-left {
    width: 60%;
    padding-right: 2px;
  }

  .app-yomi .sec-right {
    width: 45%;
    padding-left: 15px;
    padding-top: 60px;
  }

  .app-yomi .two-col-sec {
    margin-top: 20px;
    padding: 30px;
  }

  .app-yomi .two-col-sec p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  /*        budgetDetail       1199px */
  .app-yomi .two-col-sec ul li, .app-yomi .blue-link a, .app-yomi .two-col-sec h3 {
    font-size: 16px;
  }

  .app-yomi .two-col-sec ul li {
    margin-bottom: 15px;
  }

  .app-yomi .two-col-sec p+h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .app-yomi .two-col-sec ul {
    margin-bottom: 40px;
  }

  .app-yomi .two-col-sec {
    margin-top: 0px;
  }

  .app-yomi .blue-link {
    margin-top: 20px;
  }

  .app-yomi .full-field p small {
    font-size: 12px;
  }

  .app-yomi .full-field p {
    margin-top: 17px;
  }

  .app-yomi .button-field.next-step-btn input {
    font-size: 16px;
    line-height: 5px;
  }

  /*        adbreak     1199px */
  .app-yomi .button-field a {
    font-size: 16px;
    line-height: 18px;
    min-width: 150px;
    padding: 14px 20px;
  }

  .app-yomi .full-page-wrap h1 {
    width: calc(100% - 150px);
  }

  .app-yomi .step-col h3 {
    font-size: 15px;
  }

  .app-yomi .step-col {
    margin: 0 1%;
  }

  .app-yomi .step-col:before {
    width: 130px;
  }

  .app-yomi .content_img_col.content_col_large {
    width: 100%;
    margin-top: 30px;
  }

  .app-yomi .content_col_small {
    width: 19%;
    margin-right: 4%;
  }

  .app-yomi .content_col_middle {
    width: 33%;
  }

  /* 1199px */
  .app-yomi .confirmation-message {
    font-size: 25px;
    text-align: center;
  }
}

@media (min-width: 992px) and (max-height: 810px) {
  .app-yomi .logo {
    margin-bottom: 15px;
  }

  .app-yomi .left-column h1 {
    margin-bottom: 15px;
  }

  .app-yomi .right-column {
    padding: 0px 0px 0px;
  }

  .app-yomi .image-item {
    padding-bottom: 60px;
  }

  .app-yomi .bottom-links {
    padding-top: 30px;
  }

  .app-yomi .logo img {
    max-width: 150px;
  }

  .app-yomi .form-field {
    margin-bottom: 20px;
  }

  .login_page input[type="text"] {
    padding: 17px 20px;
  }

  /* login_page h1, */
  .app-yomi .h1 {
    font-size: 36px;
  }

  .app-yomi .left-column {
    padding: 25px;
  }

  .app-yomi .image-slider {
    margin-top: 0;
    padding: 0;
  }

  .app-yomi .image-slider .slick-slider {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .app-yomi .login_page {
    padding: 0;
    background-image: none !important;
  }

  .app-yomi .login_page .main {
    min-height: 100vh;
  }

  .app-yomi .inner-screen {
    box-shadow: none;
    min-height: 100vh;
  }

  .app-yomi .left-column {
    display: flex;
    flex-direction: column;
    padding: 80px 40px;
  }

  .app-yomi .left-column h1 {
    margin-bottom: 40px;
  }

  .app-yomi .error input::-moz-placeholder {
    opacity: 0 !important;
  }

  .app-yomi .error input:-moz-placeholder {
    opacity: 0 !important;
  }

  .app-yomi .error input::-webkit-input-placeholder {
    opacity: 0 !important;
  }

  .app-yomi .error input:-ms-input-placeholder {
    opacity: 0 !important;
  }

  .app-yomi .middle-content {
    /* margin-top: auto;
    margin-bottom: auto; */
    padding-bottom: 2px;
  }

  .app-yomi .right-column {
    padding: 86px 30px 75px;
  }

  .app-yomi .col-head {
    text-align: left;
  }

  .app-yomi .col-head h2 {
    padding-right: 17px;
    margin-bottom: 17px;
  }

  .app-yomi .col-head h2 {
    font-size: 32px;
  }

  .app-yomi .col-head p {
    font-size: 16px;
  }

  .app-yomi .right-column {
    align-items: inherit;
  }

  .app-yomi .image-slider {
    margin-top: 0;
  }

  .app-yomi .image-slider .slick-slider {
    height: 100%;
  }

  .app-yomi h1, .app-yomi .h1 {
    font-size: 36px;
  }

  .app-yomi h2 {
    font-size: 22px;
    line-height: 1.2;
  }

  .app-yomi h3 {
    font-size: 24px;
  }

  .app-yomi h4 {
    font-size: 16px;
  }

  .app-yomi h5 {
    font-size: 14px;
  }

  .app-yomi h6 {
    font-size: 14px;
  }

  .app-yomi input[type="date"], .app-yomi input[type="email"], .app-yomi input[type="password"], .app-yomi input[type="search"], .app-yomi input[type="tel"], .app-yomi input[type="text"], .app-yomi select, .app-yomi textarea {
    padding: 14px 15px;
  }

  .app-yomi .form-field {
    margin-bottom: 30px;
  }

  .app-yomi button, html input[type="button"], input[type="reset"], input[type="submit"] {
    padding: 11px 23px;
    min-width: 143px;
  }

  .app-yomi .button-field.button-field-country {
    margin-top: 0;
  }

  .app-yomi .right-continue-btn .button-field.next-step-btn {
    min-width: 151px;
  }

  .sec-left .button-field {
    min-width: 145px;
    margin-top: 5px;
  }

  .app-yomi .right-icons ul li {
    padding-right: 30px;
  }

  .app-yomi .site-header .header-nav ul li {
    display: none;
  }

  .app-yomi .site-header .header-nav ul li:last-child {
    display: block;
  }

  .app-yomi .header-left {
    width: 17%;
    padding: 0px 10px 0px 20px;
  }

  .app-yomi .header-right {
    width: 83%;
  }

  .app-yomi .site-logo a {
    max-width: 155px;
    width: 90px;
  }

  .app-yomi .MuiButton-label {
    padding-right: 17px;
  }

  .app-yomi .MuiButton-label:after {
    background-size: 10px;
    background-position: center right;
  }

  .app-yomi .header-nav ul li a {
    padding: 26px 0;
  }

  .app-yomi .MuiButton-label img {
    max-width: 111px;
  }

  .app-yomi .right-icons {
    padding: 1px 28px 1px 19px;
  }

  .app-yomi .right-icons ul li a img {
    max-width: 30px;
  }

  .app-yomi .header-nav {
    padding-right: 22px;
  }

  .app-yomi .header-nav ul li svg {
    max-width: none;
    margin-right: 11px;
  }

  .app-yomi .header-nav ul li .icon-status {
    top: 20px;
    left: 16px;
  }

  .app-yomi #simple-popover .MuiPaper-root.MuiPopover-paper {
    top: 50px !important;
    right: -20px;
  }

  .app-yomi #theme-popover .MuiPaper-root.MuiPopover-paper {
    top: 188px !important;
    right: -20px;
  }

  .app-yomi #location-popover .MuiPaper-root.MuiPopover-paper {
    top: 188px !important;
    right: -20px;
  }

  .app-yomi .site-logo {
    padding-left: 0;
  }

  /*     left-sidebar  991px */
  .app-yomi .left-sidebar {
    top: 80px;
    bottom: 0;
    min-height: calc(100vh - 80px);
    transition: all 0.5s ease-in-out;
    width: 50px;
  }

  .app-yomi .left-sidebar.active {
    left: 0;
  }

  .app-yomi .left-sidebar li a {
    padding: 10px 30px 16px;
  }

  .app-yomi .left-sidebar li a img {
    margin-right: 16px;
  }

  .app-yomi .left-sidebar li a, .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 17px 21px 16px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 45px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
    padding-right: 30px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 80px;
  }

  .app-yomi .humburger-menu {
    padding-right: 11px;
  }

  .app-yomi .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
    padding: 12px 20px 12px;
  }

  .app-yomi .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 35px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 70px;
  }

  .app-yomi .hide-scroll {
    overflow: auto;
    margin-right: 0;
  }

  .app-yomi .hide-scroll .site-header {
    width: 100%;
  }

  /*     Resource    991px*/
  .app-yomi .box-image .button-field {
    margin-bottom: 10px;
  }

  .app-yomi .box-image {
    padding: 17% 15px;
  }

  .app-yomi .box-content-info h4 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .app-yomi .box-content-info {
    padding: 18px 19px;
  }

  .app-yomi .box-content-info p {
    margin-bottom: 18px;
  }

  .app-yomi .content-box {
    margin-bottom: 20px;
  }

  .app-yomi .content-box, .app-yomi .content-box:nth-child(3n) {
    margin-bottom: 20px;
  }

  .app-yomi .main_tab_content {
    padding: 30px 30px 10px;
  }

  .app-yomi .ecp_resource .tab-listing ul li {
    font-size: 24px;
    padding-right: 39px;
  }

  .app-yomi .ecp_resource .tab-listing ul li a {
    padding-right: 2px;
  }

  /*          Clinic     991px  */
  .app-yomi .tab-listing ul li {
    font-size: 24px;
    padding-right: 42px;
  }

  .app-yomi .tab-listing .slick-slide {
    padding-right: 42px;
  }

  .app-yomi .tab-listing {
    padding: 11px 0 0;
  }

  .app-yomi .tab-listing {
    padding-right: 50px;
    position: relative;
  }

  .app-yomi .tab-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .app-yomi .tab-content {
    padding: 0;
  }

  .app-yomi .tab-listing ul li a:after {
    bottom: 0;
  }

  .app-yomi .tab-content .form-main form {
    max-width: 477px;
  }

  .app-yomi .tab-content .field-left {
    width: 34%;
  }

  .app-yomi .tab-content .field-right {
    width: 66%;
  }

  .app-yomi .tab-content .form-main {
    padding-bottom: 140px;
  }

  .app-yomi .data-popup-box {
    min-width: 650px;
    max-width: 650px;
    margin: 100px 0;
  }

  .app-yomi .data-popup-box .box-heading {
    padding: 30px 15px;
  }

  .app-yomi .data-popup-box .close-btn {
    top: 20px;
    right: 10px;
  }

  .app-yomi .data-popup-box .box-heading .close-btn img {
    width: 15px;
  }

  .app-yomi .data-popup-box .box-content form {
    width: 100%;
    max-width: 510px;
  }

  .app-yomi .data-popup-box .box-content form .field-right {
    width: 60%;
  }

  .app-yomi .data-popup-box .box-content form .field-left {
    width: 40%;
  }

  .app-yomi .data-popup-box .box-content {
    padding: 50px 40px 60px;
  }

  /*        product-selection       991px */
  .app-yomi .three-block-sec {
    margin-top: 27px;
  }

  .app-yomi .block-col p {
    font-size: 14px;
  }

  .app-yomi .block-col h2 {
    font-size: 22px;
  }

  .app-yomi .block-col img {
    max-width: 80px;
  }

  .app-yomi .sec-left {
    width: 100%;
    padding: 0;
  }

  .app-yomi .sec-right {
    width: 100%;
    order: -1;
    padding: 0;
    margin-bottom: 47px;
  }

  .app-yomi .two-col-sec {
    margin-top: 27px;
    padding: 40px 30px 23px;
  }

  .app-yomi .sec-right img {
    max-width: 420px;
  }

  .app-yomi .two-col-sec p {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 32px;
  }

  /*        budgetDetail       991px */
  .app-yomi .detail-welcome .sec-right {
    order: inherit;
    padding-top: 12px;
    margin-bottom: 0;
  }

  .app-yomi .detail-welcome {
    padding-top: 24px;
    padding-bottom: 29px;
    margin-bottom: 30px;
  }

  .app-yomi .two-col-sec p+h3 {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .app-yomi .two-col-sec ul li {
    padding-left: 17px;
    margin-bottom: 13px;
  }

  .app-yomi .two-col-sec ul li:after {
    top: 7px;
    width: 3px;
    height: 3px;
  }

  .app-yomi .detail-welcome .full-field {
    display: inline-block;
    vertical-align: top;
    width: 46%;
    margin-right: 2.5%;
  }

  .app-yomi .full-field p small {
    font-size: 14px;
  }

  .app-yomi .full-field p {
    margin-left: -105%;
    margin-top: 13px;
    width: calc(100vw - 100px);
  }

  .app-yomi .blue-link a {
    font-size: 24px;
  }

  .app-yomi .blue-link {
    margin-top: 14px;
  }

  .app-yomi .button-field.next-step-btn {
    min-width: 228px;
  }

  .app-yomi .button-field.next-step-btn input {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }

  /* 991px  */
  .app-yomi .content-right-heading {
    font-size: 18pt;
    margin-top: -20px;
  }

  .app-yomi .content-right-heading-adbilder {
    font-size: 18pt;
    margin-top: -20px;
  }

  .app-yomi .content-heading-view {
    margin-top: -10;
  }

  /*        adbreak        991px */
  .app-yomi .step-col h3 {
    font-size: 18px;
  }

  .app-yomi .step-col {
    margin: 0 -1%;
  }

  .app-yomi .white-box-image {
    margin-bottom: 30px;
  }

  .app-yomi .white-box-content {
    padding: 30px 30px 20px;
    margin-top: 0;
  }

  .app-yomi .white-box-wrapper {
    padding-top: 39px;
  }

  .app-yomi .step-row {
    margin-top: 67px;
  }

  .app-yomi .white-box-image img {
    margin: 0;
  }

  .app-yomi .right-continue-btn .button-field.next-step-btn {
    min-width: 151px;
  }

  .app-yomi .content_col_small {
    width: 24.2%;
    margin-right: 6%;
  }

  .app-yomi .content_col_middle {
    width: 45.2%;
  }

  .app-yomi .content-info {
    padding: 19px 19px 17px;
  }

  .app-yomi .content_col_small .content-info h6 {
    font-size: 18px;
  }

  .app-yomi .content-info h6 {
    font-weight: 400;
    font-size: 18px;
  }

  .app-yomi .content_col_small .content-info p {
    font-size: 18px;
  }

  .app-yomi .content_col_small .content-info {
    padding-right: 10px;
  }

  .app-yomi .content-info p {
    font-size: 18px;
  }

  .app-yomi .content_img_row {
    margin-bottom: 30px;
  }

  .app-yomi .row-300-width {
    width: 45%;
  }

  .app-yomi .content_img_row {
    margin-right: 6%;
  }

  .app-yomi .row-250-width {
    width: 37.5%;
    margin-right: 0;
  }

  .app-yomi .row-320-width {
    max-width: 48%;
  }

  .app-yomi .content_img_row:last-child {
    margin-bottom: 0;
  }

  .app-yomi .step-col:after {
    width: 28px;
    height: 28px;
    font-size: 18px;
    line-height: 30px;
  }

  /* 991pxx */
  .app-yomi .confirmation-message {
    font-size: 22px;
    text-align: center;
  }
}

@media (max-width: 991px) and (max-height: 740px) {
  .app-yomi .left-column {
    padding: 30px;
  }

  .app-yomi .right-column {
    padding: 30px 30px 40px;
  }

  .app-yomi .left-column h1 {
    margin-bottom: 20px;
  }

  .app-yomi .logo {
    margin-bottom: 30px;
  }

  .app-yomi .middle-content {
    padding-bottom: 0;
  }

  .app-yomi .form-field {
    margin-bottom: 30px;
  }

  .app-yomi h1, .app-yomi .h1 {
    font-size: 36px;
  }

  .app-yomi h2 {
    font-size: 22px;
    line-height: 1.2;
  }

  .app-yomi h3 {
    font-size: 24px;
  }

  .app-yomi h4 {
    font-size: 16px;
  }

  .app-yomi h5 {
    font-size: 14px;
  }

  .app-yomi h6 {
    font-size: 14px;
  }

  .app-yomi input[type="date"], .app-yomi input[type="email"], .app-yomi input[type="password"], .app-yomi input[type="search"], .app-yomi input[type="tel"], .app-yomi input[type="text"], .app-yomi select, .app-yomi textarea {
    padding: 14px 15px;
  }

  .app-yomi button, html input[type="button"], input[type="reset"], input[type="submit"] {
    padding: 11px 23px;
    min-width: 143px;
  }

  .app-yomi .main-sec-wrap {
    padding-left: 0px;
    padding-top: 80px;
  }

  .app-yomi .main-sec-wrap.active-main {
    padding-left: 0px;
  }

  /* verify otp*/
  .app-yomi .popup-box {
    min-width: 650px;
    max-width: 650px;
  }

  .app-yomi .box-heading {
    padding: 21px 15px;
  }

  .app-yomi .box-heading>img {
    margin-right: 20px;
    max-width: 47px;
  }

  .app-yomi .box-content p {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .app-yomi .multiple-field {
    margin-top: 0;
  }

  .app-yomi .multiple-field input[type="text"] {
    margin: 0 5px;
    width: 49px;
    height: 49px;
  }

  .app-yomi .box-content form {
    width: 62%;
  }

  /* verify otp */
  /* forgot username */
  .app-yomi .forgot-popup-box {
    padding-bottom: 50px;
  }

  .app-yomi .changepwd-popup-box {
    padding-bottom: 50px;
  }

  /* forgot username */
  .app-yomi .bootom-sec .header-nav {
    display: block;
    background: #fff;
  }

  .app-yomi .bootom-sec .header-nav li {
    min-width: 33.33%;
    text-align: center;
    padding-right: 0;
  }

  .app-yomi .bootom-sec .header-nav li a {
    width: 100%;
    font-size: 18px;
    padding: 27px 10px;
  }

  .app-yomi .content-right-main {
    padding: 39px 20px 42px 60px;
  }

  .app-yomi .content-right-heading {
    font-size: 18pt;
    margin-top: -20px;
  }
}

@media (max-width: 767px) {
  .app-yomi .site-logo a img {
    width: 100px;
  }

  .app-yomi .login_page .main {
    min-height: 1px;
  }

  .app-yomi .inner-screen {
    display: block;
    min-height: 1px;
  }

  .app-yomi .left-column {
    width: 100%;
    padding: 30px 30px;
    display: block;
  }

  .app-yomi .left-column h1 {
    margin-bottom: 23px;
  }

  .app-yomi .logo {
    margin-bottom: 24px;
  }

  .app-yomi .logo img {
    max-width: 125px;
  }

  .app-yomi .middle-content {
    margin: 0;
    padding-bottom: 0;
  }

  .app-yomi .login_page .form-field.custom-checkbox {
    margin-bottom: 16px;
  }

  .app-yomi .custom-checkbox input[type="checkbox"]+label {
    padding-left: 25px;
    line-height: 22px;
  }

  .app-yomi .custom-checkbox input[type="checkbox"]+label:after, .app-yomi .custom-checkbox input[type="checkbox"]+label:before {
    width: 16px;
    height: 16px;
  }

  .app-yomi .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 12px;
  }

  .app-yomi .user-field {
    background-size: 13px;
  }

  .app-yomi .bottom-links {
    padding-top: 20px;
    margin-left: 8px;
  }

  .app-yomi .bottom-links button {
    font-size: 14px;
  }

  .app-yomi .bottom-links ul li {
    padding-right: 16px;
  }

  .app-yomi .right-column {
    width: 100%;
    display: block;
    padding: 31px 30px 15px;
  }

  .app-yomi .col-head h2 {
    padding-right: 0;
    margin-bottom: 14px;
  }

  .app-yomi .image-item {
    padding: 0 40px 30px;
  }

  .app-yomi .image-slider .slick-slider {
    height: auto;
  }

  .app-yomi .col-head h2 {
    font-size: 20px;
  }

  .app-yomi h1, .app-yomi .h1 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .app-yomi body, .app-yomi p {
    font-size: 14px;
  }

  .app-yomi input[type="date"], .app-yomi input[type="email"], .app-yomi input[type="password"], .app-yomi input[type="search"], .app-yomi input[type="tel"], .app-yomi input[type="text"], .app-yomi select, .app-yomi textarea {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 15px 15px;
  }

  .app-yomi .button-field.button-field-country button {
    font-size: 12px;
    padding: 8px 20px 8px;
    min-width: 108px;
  }

  .app-yomi button, html input[type="button"], input[type="reset"], input[type="submit"] {
    font-size: 16px;
    line-height: 18px;
    padding: 17px 23px 16px;
  }

  .app-yomi h2 {
    font-size: 18px;
  }

  .app-yomi h3 {
    font-size: 16px;
  }

  .app-yomi h4 {
    font-size: 14px;
  }

  .app-yomi h5 {
    font-size: 12px;
  }

  .app-yomi h6 {
    font-size: 12px;
  }

  .app-yomi select {
    background-size: 9px;
    padding-right: 25px;
  }

  .app-yomi .form-field {
    margin-bottom: 21px;
  }

  .app-yomi .slick-slider .slick-dots li {
    padding: 0 2px;
  }

  .app-yomi .slick-slider .slick-dots li button {
    width: 5px;
    height: 5px;
  }

  .app-yomi .slick-slider .slick-dots .slick-active button {
    width: 13px;
  }

  .app-yomi .sec-left .button-field {
    margin-top: -8px;
    min-width: 115px;
  }

  /* verify otp  767px*/
  .app-yomi .popup-box {
    min-width: 335px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
  }

  .app-yomi .box-content {
    padding: 17px 33px 30px;
  }

  .app-yomi .box-heading>img {
    max-width: 25px;
    margin-right: 10px;
  }

  .app-yomi .box-content form {
    width: 100%;
  }

  .app-yomi .multiple-field {
    margin-bottom: 0;
  }

  .app-yomi .box-content p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  /* verify otp */
  /* forgot username */
  .app-yomi .close-btn {
    top: 0;
    right: 0;
  }

  .app-yomi .forgot-popup-box {
    padding-bottom: 0;
  }

  .app-yomi .changepwd-popup-box {
    padding-bottom: 0;
  }

  /* forgot username */
  .app-yomi .main-sec-wrap {
    padding-top: 50px;
  }

  .app-yomi .bootom-sec .header-nav li a {
    font-size: 12px;
    padding: 14px 10px;
  }

  .app-yomi .bootom-sec .header-nav ul li svg {
    max-width: 15px;
    margin-right: 5px;
    margin-top: -3px;
  }

  .app-yomi .content-right-main {
    padding: 27px 15px 29px 70px;
  }

  .app-yomi .content-right-heading {
    font-size: 17pt;
    margin-top: -32px;
  }

  .app-yomi .content-right-heading-adbilder {
    font-size: 17pt;
    margin-top: 0px;
  }

  .app-yomi .content-heading-view {
    margin-top: -10;
  }

  .app-yomi .site-header .header-nav ul li a {
    font-size: 0;
    padding: 12px 0;
  }

  .app-yomi .site-header .header-nav ul li a svg {
    margin-right: 0;
    max-width: 17px;
  }

  .app-yomi .right-icons {
    border-left: none;
    padding: 1px 16px 1px 0px;
  }

  .app-yomi .site-logo a {
    max-width: 103px;
    width: 75px;
  }

  .app-yomi .account-info {
    padding: 0;
    background: transparent;
  }

  .app-yomi .right-icons ul li a img {
    max-width: 18px;
    max-height: 15px;
  }

  .app-yomi .MuiButton-label {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #f5f7fa;
    padding: 0;
    margin-right: 10px;
  }

  .app-yomi .MuiButton-label img {
    margin: 0;
  }

  .app-yomi .MuiButtonBase-root.MuiButton-text {
    min-width: 1px;
  }

  .app-yomi .MuiButton-label:after {
    right: -10px;
    background-size: 6px;
  }

  .app-yomi .header-left {
    padding: 0px 0px 0px 15px;
    width: 40%;
  }

  .app-yomi .header-right {
    padding-right: 15px;
    width: 60%;
  }

  .app-yomi .humburger-menu {
    margin-right: 16px;
  }

  .app-yomi .right-icons ul li {
    padding-right: 19px;
  }

  .app-yomi .header-nav {
    padding-right: 20px;
  }

  .app-yomi .header-nav ul li .icon-status {
    top: 15px;
    left: 10px;
  }

  .app-yomi #simple-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 20px !important;
    right: -30px;
  }

  .app-yomi #theme-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 202px !important;
    right: -30px;
  }

  .app-yomi #location-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 202px !important;
    right: -30px;
  }

  .app-yomi .left-img {
    width: 30px;
  }

  .app-yomi .right-content {
    width: calc(100% - 30px);
    padding-left: 9px;
  }

  /*   left-sidebar  */
  .app-yomi .left-sidebar {
    top: 55px;
    height: calc(100vh - 55px);
  }

  .app-yomi .humburger-menu span {
    height: 2px;
    width: 27px;
  }

  .app-yomi .humburger-menu {
    padding-right: 12px;
  }

  .app-yomi .left-sidebar li a, .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
    font-size: 14px;
    padding: 13px 15px 13px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 31px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelSummary-content span {
    padding-right: 25px;
  }

  .app-yomi .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 30px;
  }

  .app-yomi .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 40px;
    padding-right: 30px;
  }

  /*     Resource    */
  .app-yomi .popup-wrap {
    margin: 10px 0;
    max-width: 500px;
    min-width: 500px;
    padding: 10px;
  }

  .app-yomi .gray-close-ic {
    top: 5px;
    right: 5px;
    max-width: 17px;
  }

  .app-yomi .content-box, .app-yomi .content-box:nth-child(3n) {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-yomi .content-box:last-child {
    margin-bottom: 0;
  }

  .app-yomi .main_tab_content {
    padding: 20px 15px;
  }

  .app-yomi .box-content-info p {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .app-yomi .box-content-info h4 {
    font-size: 14px;
  }

  .app-yomi .ecp_resource .tab-listing ul li {
    font-size: 16px;
    padding-right: 30px;
  }

  .app-yomi .tab-arrow:after {
    background-size: 6px;
    background-position: center right 10px;
    width: 30px;
    top: -12px;
  }

  .app-yomi .box-image {
    padding: 23.5% 15px;
  }

  .app-yomi .box-content-info {
    padding: 13px 14px;
  }

  /*      Clinic      767px */
  .app-yomi .tab-listing ul li {
    font-size: 20px;
  }

  .app-yomi .data-popup-box {
    min-width: 345px;
    max-width: 345px;
    margin: 80px 0;
  }

  .app-yomi .data-popup-box .box-content form .field-left {
    width: 100%;
    padding: 0;
  }

  .app-yomi .data-popup-box .box-content {
    padding: 31px 15px 15px;
  }

  .app-yomi .data-popup-box .box-content form .field-right {
    width: 100%;
  }

  .app-yomi .data-popup-box .form-submit .button-field input {
    padding-left: 13px;
    padding-right: 13px;
  }

  .app-yomi .data-popup-box .box-heading .close-btn img {
    width: 10px;
  }

  .app-yomi .data-popup-box .close-btn {
    top: 10px;
    right: 10px;
    width: 10px;
  }

  .app-yomi .tab-listing .slick-next {
    top: -16px;
  }

  /*        product-selection      767px */
  .app-yomi .block-col {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-yomi .block-col:nth-child(4n) {
    margin-right: 0;
  }

  .app-yomi .block-col p {
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .app-yomi .block-col h2 {
    font-size: 16px;
  }

  .app-yomi .block-col img {
    max-width: 70px;
    margin-bottom: 22px;
  }

  .app-yomi .three-block-sec {
    margin-top: 20px;
  }

  .app-yomi .sec-right img {
    max-width: 250px;
  }

  .app-yomi .two-col-sec {
    margin-top: 17px;
    padding: 20px 20px 14px;
  }

  .app-yomi .two-col-sec p {
    font-size: 14px;
    margin-bottom: 28px;
  }

  .app-yomi .sec-right {
    margin-bottom: 14px;
  }

  /*         budgetDetail       767px */
  .app-yomi .two-col-sec ul li, .app-yomi .blue-link a, .app-yomi .two-col-sec h3 {
    font-size: 14px;
  }

  .app-yomi .detail-welcome {
    padding-top: 15px;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .app-yomi .two-col-sec p+h3 {
    margin-top: 33px;
    margin-bottom: 7px;
  }

  .app-yomi .two-col-sec ul li {
    padding-left: 15px;
    margin-bottom: 6px;
    line-height: 1.7;
  }

  .app-yomi .detail-welcome .full-field {
    display: block;
    width: 100%;
    margin: 0 0 19px;
  }

  .app-yomi .two-col-sec ul {
    margin-bottom: 38px;
  }

  .app-yomi .full-field label {
    margin-bottom: 9px;
  }

  .app-yomi .full-field p {
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
  }

  .app-yomi .full-field p small {
    font-size: 12px;
  }

  .app-yomi .detail-welcome .sec-right {
    padding-top: 22px;
  }

  .app-yomi .detail-welcome .full-field:last-child {
    margin-bottom: 0;
  }

  .app-yomi .detail-welcome .sec-right img {
    margin-left: 0;
  }

  .app-yomi .blue-link {
    text-align: left;
    margin-top: 11px;
  }

  .app-yomi .button-field.next-step-btn input, .app-yomi .button-field.back-btn input {
    font-size: 12px;
    line-height: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .app-yomi .button-field.next-step-btn {
    min-width: 184px;
  }

  /*        adbreak      767px */
  .app-yomi .button-field a {
    font-size: 14px;
    line-height: 16px;
    min-width: 130px;
    padding: 11px 20px;
  }

  .app-yomi .white-box-content {
    padding: 20px 15px 20px;
  }

  .app-yomi .white-box-image {
    padding: 0 15px;
  }

  .app-yomi .full-page-wrap h1 {
    width: calc(100% - 130px);
  }

  .app-yomi .step-col {
    margin: 0 -0.8%;
  }

  .app-yomi .step-col:before {
    width: 115px;
    top: -41px;
  }

  .app-yomi .step-col h3 {
    font-size: 15px;
  }

  .app-yomi .step-col:after {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 21px;
  }

  /* 767pxx   */
  .app-yomi .confirmation-message {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: 639px) {
  .app-yomi .site-logo a img {
    width: 83px;
  }

  .app-yomi .button-field a {
    font-size: 13px;
    line-height: 15px;
    min-width: 100px;
    padding: 10px 15px;
  }

  .app-yomi .full-page-wrap h1 {
    width: calc(100% - 100px);
  }

  .app-yomi .step-col {
    margin: 0 5px;
  }

  .app-yomi .step-col h3 {
    font-size: 12px;
    line-height: 1.35;
  }

  .app-yomi .step-col:before {
    width: 105px;
  }

  .app-yomi .white-box-image {
    padding: 0 0;
  }

  .app-yomi .white-box-wrapper {
    padding-top: 33px;
  }

  .app-yomi .content-info h6 {
    font-size: 14px;
  }

  .app-yomi .content-info {
    padding: 13px 14px 12px;
  }

  .app-yomi .content-info p {
    font-size: 14px;
  }

  .app-yomi .content_col_small {
    max-width: 162px;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .app-yomi .content_col_middle {
    max-width: 302px;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .app-yomi .content_col_small .content-info h6 {
    font-size: 13px;
    margin-bottom: 7px;
  }

  .app-yomi .content_col_small .content-info p {
    font-size: 14px;
  }

  .app-yomi .content_img_col.content_col_large {
    margin-top: 0;
    display: block;
  }

  .app-yomi .content_img_row {
    margin-bottom: 20px;
  }

  .app-yomi .row-300-width {
    max-width: 302px;
    margin-right: 0;
    width: 100%;
  }

  .app-yomi .row-250-width {
    max-width: 252px;
    margin-right: 0;
    width: 100%;
  }

  .app-yomi .content_img_row.row-320-width {
    width: 100%;
    max-width: 320px;
  }

  .app-yomi .white-box-image {
    display: block;
  }
}

@media (max-width: 479px) {
  .app-yomi .right-content {
    padding-right: 43px;
  }

  .app-yomi .gray-close-ic {
    top: 6px;
    right: 6px;
    max-width: 10px;
  }

  .app-yomi .button-field.fullview-btn {
    margin: 0;
    display: table;
  }

  .app-yomi .right-continue-btn .button-field.next-step-btn {
    min-width: 108px;
  }

  .app-yomi .button-field.next-step-btn {
    min-width: 250px;
  }

  .app-yomi .select {
    font-size: 14px;
    color: rgba(4, 12, 5, 0.5);
  }

  /*        adbreak      479px */
  .app-yomi .page-img .next-step-btn {
    margin-top: 15px;
  }

  .app-yomi .full-page-wrap h1 {
    width: 100%;
    line-height: 1.2;
  }

  .app-yomi .button-field.fullview-btn {
    margin: 0;
    display: table;
  }

  .app-yomi .white-box-image {
    margin-bottom: 4px;
  }

  .app-yomi .content_img_col:only-child {
    margin: 0 auto;
  }

  .app-yomi .step-row {
    min-width: 415px;
    margin-top: 10px;
    justify-content: flex-start;
    padding: 0 4px;
  }

  .app-yomi .step-row-wrapper {
    overflow: auto;
  }

  .app-yomi .step-col {
    max-width: 65px;
    margin: 0 14px;
  }

  .app-yomi .step-col:after {
    top: -35px;
  }

  .app-yomi .step-col:before {
    top: -25px;
    height: 2px;
  }

  .app-yomi .right-continue-btn .button-field.next-step-btn {
    min-width: 108px;
  }
}
.form_field_wrap {
  background: #fff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  padding: 30px 30px;
  margin-bottom: 38px;
}

.configure-form-sec {
  padding: 20px 0 87px;
}

.form_field_wrap .form_field {
  max-width: 845px;
}

.users-info-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.users-info-col {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  width: 30.6%;
  margin-right: 4.1%;
}

.users-info-col:nth-child(3n),
.users-info-col:last-child {
  margin-right: 0;
}

.info-head {
  border-bottom: 2px solid #f5f7fa;
  padding: 37px 20px 31px;
}

.info-head .custom-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.info-head h4 {
  margin: 0;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
}

.info-head .custom-checkbox label {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  color: #1073c6;
  text-transform: uppercase;
}

.info-head .custom-checkbox input[type="checkbox"] + label {
  padding-left: 41px;
  line-height: 20px;
}

.info-body-row .custom-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.user-pic {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 10px;
}

.info-body-row {
  border-bottom: 1px solid #f5f7fa;
  padding: 10px 0;
}

.info-body {
  padding: 0 20px;
}

.info-body-row .custom-checkbox input[type="checkbox"] + label {
  padding-left: 41px;
  font-weight: 400;
}

.messages-text {
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  padding: 18px 20px 15px;
  width: 44%;
  margin-right: 30px;
  max-width: calc(50% - 35px);
  position: relative;
}

.messages-text p strong {
  font-weight: 700;
}

.error-msg-text {
  background: #f2dede;
  color: #a94442;
}

.success-msg-text {
  color: #3c763d;
  background: #dff0d8;
}

.messages-text p {
  margin: 0;
  font-family: Raleway, sans-serif;
}

.messages-text p img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -3px;
}

.form-messages {
  margin-top: 30px;
}
.users-info-wrap .custom-checkbox input[type="checkbox"] + label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAA////////////////////////////////600+wgAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-color: #1073c6;
  background-size: 12px;
}
.info-head .custom-checkbox input[type="checkbox"] + label:before,
.info-head .custom-checkbox input[type="checkbox"] + label:after {
  top: 5px;
}
.configure-form-sec .form-submit .button-field input {
  min-width: 185px;
}
.form_field_wrap .field-right select {
  background-size: 10px;
  background-position: center right 20px;
}
.form-messages {
  position: fixed;
  top: 10%;
  left: 27%;
  background-position: center;
  align-self: center;
  width: 100%;
  margin: 0;
  z-index: 10000;
  padding: 10px 40px;
}
.form-messages_Bottom {
  position: fixed;
  bottom: 20px;
  right: 0;
  width: 100%;
  z-index: 1000;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}
.form-messages_BottomLogin {
  position: fixed;
  top: 115px;
  left: 2.8%;
  width: 102%;
  z-index: 1000;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}

.form-messages_Bottom {
  position: fixed;
  bottom: 20px;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}
.form-messages_BottomLogin {
  position: fixed;
  top: 115px;
  left: 2.8%;
  width: 102%;
  margin: 0;
  padding: 10px 40px;
  z-index: 500;
}

.main-content {
  position: relative;
}
.alert-close-ic {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
}

@media (max-width: 1500px) {
  .info-head .custom-checkbox input[type="checkbox"] + label:before,
  .info-head .custom-checkbox input[type="checkbox"] + label:after {
    top: 5px;
  }

  .info-head {
    padding: 0px;
  }

  .info-body-row {
    padding: 7px 0;
  }
  .form-messages {
    padding: 10px 25px;
  }
}

@media (max-width: 1366px) {
  .users-info-col {
    width: 31.33%;
    margin-right: 3%;
  }
}
@media (max-width: 1199px) {
  .users-info-col {
    width: 100%;
    margin: 0 0 30px;
  }

  .configure-form-sec {
    padding: 10px 0 100px;
    max-width: 990px;
  }

  .form_field_wrap {
    margin-bottom: 30px;
  }

  .users-info-col:last-child {
    margin-bottom: 0;
  }
  .messages-text p img {
    max-width: 23px;
    margin-right: 6px;
  }

  .messages-text.success-msg-text {
    padding: 18px 15px 15px 10px;
  }

  .messages-text p {
    font-size: 14px;
  }

  .alert-close-ic {
    right: 15px;
  }
  .messages-text {
    max-width: calc(50% - 25px);
    margin-right: 20px;
    width: 47%;
  }
  .messages-text:last-child,
  .messages-text:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .configure-form-sec {
    padding: 8px 0 100px;
  }

  .form_field_wrap .form_field {
    max-width: 480px;
  }

  .form_field_wrap .form_field .field-left {
    width: 36%;
  }

  .form_field_wrap .form_field .field-right {
    width: 64%;
  }

  .info-head {
    padding: 34px 20px 31px;
  }

  .info-body-row {
    padding: 20px 0 19px;
  }

  .configure-form-sec .form-submit .button-field input {
    min-width: 143px;
  }
}
@media (max-width: 767px) {
  .form_field_wrap .form_field .field-left {
    width: 100%;
  }

  .form_field_wrap .form_field .field-right {
    width: 100%;
  }

  .info-head .custom-checkbox label {
    font-size: 6px;
  }

  .configure-form-sec {
    padding: 0 0 110px;
  }

  .form_field_wrap {
    padding: 14px 15px 16px;
    margin-bottom: 20px;
  }

  .info-head {
    padding: 19px 16px 20px;
  }

  .info-head .custom-checkbox input[type="checkbox"] + label {
    padding-left: 34px;
  }

  .users-info-wrap .custom-checkbox input[type="checkbox"] + label:before {
    width: 18px;
    height: 18px;
    top: 0;
  }

  .info-body {
    padding: 0 14px;
  }

  .info-body-row {
    padding: 19px 0;
  }

  .user-pic {
    width: 20px;
    height: 20px;
  }

  .info-body-row .custom-checkbox input[type="checkbox"] + label {
    padding-left: 35px;
  }

  .users-info-col {
    margin-bottom: 20px;
  }

  .users-info-wrap {
    margin-bottom: 30px;
  }

  .configure-form-sec .form-submit .button-field input {
    min-width: 108px;
  }
  .form_field_wrap .form_field {
    max-width: 315px;
  }
  .form-messages {
    bottom: 0px;
  }
  .messages-text {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    padding: 13px 15px;
  }

  .messages-text p img {
    max-width: 20px;
  }
}

.arrow-up {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-bottom: 5px solid black;
}



@media (max-width: 1500px) {
  
}

@media (max-width: 1199px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
 }


/** campaign form **/
.campaign-page {
    padding-top: 10px;
}

.campaign-page .tab-listing ul li:first-child a {
    padding-left: 0px;
    padding-right: 0px;
}

.campaign-page .form-submit {
    margin-top: 0px;
    margin-bottom: 10px;
}


/** campaign form **/

/** upload assets form **/
.asset-tab-wrapper .MuiExpansionPanelDetails-root {
    padding: 0;
}

.asset-tab-wrapper .MuiExpansionPanel-root {
    margin: 0 !important;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #EBEBEB;
}

.asset-tab-wrapper .MuiExpansionPanel-root {
    margin: 0;
}

.asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
    font-size: 24px;
    color: #177BC9;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    font-family: "Raleway", sans-serif;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content, .asset-tab-wrapper .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-root {
    padding: 30px;
    min-height: 1px;
}

.tab-content.asset-tab-wrapper {
    padding: 30px;
    background: #fff;
    padding-bottom: 40px;
}

.asset-tab-content {
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.asset-tab-content form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.asset-tab-left {
    width: 32%;
    margin-right: 2%;
}

.asset-tab-right {
    width: 66%;
    border: 1px solid #EBEBEB;
}

.tab_field {
    margin-bottom: 20px;
}

.asset-table-head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #F5F7FA;
}

.asset-table-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #EBEBEB;
}

.asset-table-head>div h5, .asset-table-body>div p {
    margin: 0;
    line-height: 1;
    color: #646464;
}

.asset-table-head>div {
    padding: 10px;
}

.asset-tab-wrapper .MuiExpansionPanel-root.Mui-expanded+.MuiExpansionPanel-root.Mui-expanded {
    margin-top: -10px;
}

.MuiExpansionPanel-root.Mui-expanded:before {
    opacity: 1 !important;
}

.MuiExpansionPanel-root.Mui-expanded:after {
    opacity: 1 !important;
}

.MuiExpansionPanel-rounded:first-child {
    border-radius: 0;
}

.asset-tab-wrapper .MuiExpansionPanel-root+.MuiExpansionPanel-root {
    margin-top: 40px !important;
}

.asset-tab-wrapper .MuiExpansionPanel-root:before {
    display: none;
}

.asset-tab-wrapper .Mui-expanded .MuiExpansionPanelSummary-root {
    min-height: 1px;
    border-bottom: 1px solid #EBEBEB;
}

.asset-table-body>div {
    padding: 24px 20px 13px;
}

.asset-table-width-1 {
    width: 11%;
}

.asset-table-width-17 {
    width: 17%;
}

.asset-table-width-22 {
    width: 22%;
}


.asset-table-width-2 {
    width: 24%;
}

.asset-table-width-3 {
    width: 60%;
}

.asset-table-body p, .asset-table-body a {
    font-family: Raleway, sans-serif;
}

.asset-table-body .asset-table-width-1 {
    text-align: center;
}

.small-ic {
    display: inline-block;
    vertical-align: middle;
    margin-right: 11px;
}

.small-ic:last-child {
    margin-right: 0;
}

.asset-table-body:last-child {
    border-bottom: none;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 3px;
    background: #646464;
    transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    margin-right: 26px;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
    height: 24px;
    width: 3px;
    right: 10px;
}

.asset-tab-wrapper .MuiExpansionPanelSummary-content.Mui-expanded:after {
    opacity: 0;
}


.asset-table-width-3 a {
    color: #177BC9;
    text-decoration: underline;
}

.asset-table-width-4 {
    width: 14.5%;
}

.asset-table-width-5 {
    width: 85.5%;
}

.asset-link {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px 0 5px;
}

.asset-link a {
    color: #177BC9;
    text-decoration: underline;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-left: 25px;
    margin-top: -5px;
}

.asset-link a img {
    position: absolute;
    top: 2px;
    left: 0;
}

.asset-tab-right:only-child {
    width: 100%;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 130px;
    padding-left: 40px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    width: 433px;
    min-width: 433px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    width: 466px;
    min-width: 466px;
    padding-left: 50px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(4) {
    width: 151px;
    min-width: 151px;
    padding-left: 48px;
    text-align: center;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(5) {
    padding-left: 38px;
    width: 240px;
    min-width: 240px;
}

.table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-body {
    padding: 32px 12px 26px 12px;
}


.MuiTableRow-root .MuiTableCell-body.yellow,
.MuiTableRow-root .MuiTableCell-body.purple,
.MuiTableRow-root .MuiTableCell-body.blue {
    text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.yellow span,
.MuiTableRow-root .MuiTableCell-body.purple span,
.MuiTableRow-root .MuiTableCell-body.blue span {
    background: #FFF3CD;
    border-radius: 2px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #856404;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding: 3px 5px;
    min-width: 36px;
}

.MuiTableRow-root .MuiTableCell-body.purple span {
    color: #175961;
    background: #D1ECF1;
}

.MuiTableRow-root .MuiTableCell-body.blue span {
    color: #028689;
    background: #02ABAE33;

}

.upload-asset-main {
    background: #fff;
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.asset-left {
    width: 46.5%;
    padding: 20px 0 0;
    margin-right: 7.5%;
}

.asset-right {
    width: 46%;
    margin-bottom: 0;
    position: relative;
}


.asset-full {
    width: 100%;
    background: #F5F7FA;
    border: 1px solid #EBEBEB;
    padding: 30px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.asset-img {
    width: 100%;
    background: #F5F7FA;
    border: 1px solid #EBEBEB;
    padding: 30px;
}

.asset-img img {
    display: block;
}

.asset-left label {
    display: block;
    line-height: 1;
    margin-bottom: 15px;
}

.asset-left input[type="text"] {
    margin-bottom: 30px;
}



.left-or-text {
    position: absolute;
    top: 20px;
    left: -11.5%;
    bottom: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 42px;
    z-index: 1;
}

.left-or-text:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #CCCCCC;
    width: 1px;
    margin: 0 auto;
    z-index: -1;
}

.left-or-text span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #CCCCCC;
    text-transform: uppercase;
    background: #fff;
    padding: 10px;
}

.tab-content .field-right select {
    color: #CCCCCC;
}

.tab-content .field-right select:focus {
    color: #646464;
}

@media (max-width: 1700px) {
    .asset-table-body>div {
        padding: 10px 0px 10px 5px;
    }

    .asset-table-body>div p, .asset-table-body a {
        font-size: 15px;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
    }

    .small-ic {
        margin-right: 10px;
    }

    .asset-table-width-1 {
        width: 12%;
    }

    .asset-table-width-3 {
        width: 59%;
    }

    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 22px;
    }

    .asset-tab-content {
        padding: 30px 20px;
    }
}

@media (max-width: 1500px) {
    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(4) {
        padding-left: 20px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
        padding-left: 20px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
        width: 340px;
        min-width: 340px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
        width: 320px;
        min-width: 320px;
    }

    .upload-asset-main {
        padding: 0px;
    }

    .asset-full, .asset-img {
        padding: 20px;
    }
}

@media (max-width: 1440px) {
    .tab-content.asset-tab-wrapper {
        padding: 20px;
        padding-bottom: 40px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-root {
        padding: 25px 20px;
    }

    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 20px;
    }

    .asset-table-width-2 {
        width: 30%;
    }

    .asset-table-width-3 {
        width: 53%;
    }

    .asset-tab-wrapper .MuiExpansionPanel-root+.MuiExpansionPanel-root {
        margin-top: 30px !important;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before {
        width: 20px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        height: 20px;
        right: 8px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        margin-right: 20px;
    }

    .asset-left {
        width: 45%;
        margin-right: 11%;
    }

    .asset-right {
        width: 44%;
    }

    .left-or-text {
        left: -19%;
    }
}

@media (max-width: 1199px) {
    .main-sec-wrap.active-main .asset-tab-left {
        width: 100%;
        margin: 0;
    }

    .main-sec-wrap.active-main .asset-tab-right {
        width: 100%;
    }

    .tab_field {
        margin-bottom: 10px;
    }

    .asset-tab-content {
        padding: 20px;
    }

    .asset-table-width-4 {
        width: 20%;
    }

    .asset-table-width-5 {
        width: 80%;
    }


    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-body {
        padding: 25px 12px 20px 12px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:first-child {
        padding-left: 20px;
    }

    .campaign-page {
        padding-top: 40px;
    }

    .upload-asset-main {
        padding: 20px;
        margin-top: 25px;
    }

    .asset-left {
        padding: 10px 0 0;
    }

    .asset-right .form-file-input {
        margin-top: 10px;
    }

    .form_field .input-file-trigger {
        font-size: 16px;
        height: 150px;
    }

    .form_field .input-file-trigger img {
        max-width: 15px;
    }

    .asset-left input[type="text"] {
        margin-bottom: 20px;
    }


    .left-or-text span {
        font-size: 15px;
    }

    .left-or-text {
        top: 15px;
        bottom: 0;
    }

    .asset-full, .asset-img {
        padding: 10px;
    }
}

@media (max-width: 991px) {
    .asset-tab-left {
        width: 100%;
        margin: 0;
    }

    .asset-tab-right {
        width: 100%;
    }


    .campaign-page {
        padding-top: 25px;
    }

}

@media (max-width: 767px) {
    .asset-table-body>div p, .asset-table-body a {
        font-size: 12px;
    }

    .asset-table-body .asset-table-width-3 {
        padding-right: 10px;
    }

    .asset-table-body>div {
        padding: 15px 0px 10px 15px;
    }

    .asset-table-head>div {
        padding: 15px 20px;
    }



    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 18px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-root {
        padding: 20px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before {
        height: 2px;
        width: 18px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        height: 18px;
        width: 2px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        margin-right: 14px;
    }

    .campaign-page .tab-listing ul li:first-child a {
        padding-left: 4px;
        padding-right: 2px;
    }

    .upload-asset-main {
        padding: 30px 20px;
    }

    .asset-left {
        width: 100%;
        margin: 0 0 50px;
        padding: 0;
    }

    .asset-right {
        width: 100%;
    }

    .left-or-text {
        top: -40px;
        left: 0;
        right: 0;
        width: 100%;
        bottom: auto;
    }

    .left-or-text:after {
        height: 1px;
        width: 100%;
        margin: auto;
    }

    .asset-full {
        margin-top: 30px;
    }

    .asset-button {
        margin-top: 30px;
    }

}

@media (max-width: 639px) {
    .campaign-page .tab-listing ul li:first-child a {
        padding-left: 4px;
        padding-right: 2px;
    }

    .asset-tab-wrapper .makeStyles-heading-111, .asset-tab-wrapper .makeStyles-heading-90, .asset-tab-wrapper .MuiExpansionPanelSummary-content>div {
        font-size: 16px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-root {
        padding: 16px 25px 14px 15px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        height: 16px;
        right: 7px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before {
        width: 16px;
    }

    .asset-tab-wrapper .MuiExpansionPanelSummary-content:before, .asset-tab-wrapper .MuiExpansionPanelSummary-content:after {
        margin-right: 10px;
    }

    .asset-tab-content {
        padding: 15px;
    }

    .tab-content.asset-tab-wrapper {
        padding: 15px;
        padding-bottom: 30px;
    }

    .asset-tab-wrapper .MuiExpansionPanel-root+.MuiExpansionPanel-root {
        margin-top: 20px !important;
    }

    .asset-table-head {
        min-width: 400px;
    }

    .asset-tab-right {
        overflow: auto;
    }

    .asset-table-body {
        min-width: 400px;
    }

    .asset-table-head>div {
        padding: 15px;
    }

    .asset-table-width-1 {
        width: 15%;
    }

    .asset-table-width-3 {
        width: 50%;
    }

    .asset-table-width-4 {
        width: 25%;
    }

    .asset-table-width-5 {
        width: 75%;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-body {
        padding: 21px 12px 15px 12px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:first-child {
        min-width: 80px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
        width: 160px;
        min-width: 160px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
        width: 160px;
        min-width: 160px;
    }

    .table-main-wrap.campaign-table .MuiTableRow-root .MuiTableCell-root:nth-child(5) {
        width: 190px;
        min-width: 190px;
    }


    .left-or-text span {
        font-size: 14px;
    }

    .form_field .input-file-trigger {
        font-size: 12px;
        height: 100px;
    }

    .form_field .input-file-trigger img {
        max-width: 13px;
    }

    .asset-full {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .asset-button {
        margin-top: 20px;
    }

    .upload-asset-main {
        margin-top: 15px;
    }


}

@media (max-width: 374px) {

    .asset-full, .asset-img {
        padding: 10px;
    }

    .upload-asset-main {
        padding: 20px 10px;
    }
}
.table-main-wrap {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  width: 100%;
  overflow: auto;
  padding: 30px 30px 17px;
  margin-top: 10px;
}
.MuiTablePagination-root {
  overflow: inherit;
}
.MuiTableHead-root {
  background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
  text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
  background: #dff0d8;
  border-radius: 2px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3c763d;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 5px;
  min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
  color: #a94442;
  background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
  padding: 19px 0 19px 12px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
  color: #646464;
  font-family: "Raleway", sans-serif;
}
.MuiTableRow-root .MuiTableCell-head.center {
  text-align: center;
}
.MuiTableRow-root .MuiTableCell-root {
  border-bottom: 1px solid #f5f7fa;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #646464;
  padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
  background-size: 7px;
  background-position: center right 6px;
  padding-right: 10px;
}


.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
  background-color: #fff;
  border-radius: 2px;
  color: #646464;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
  padding-top: 0;
  padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
  width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
  background-size: 7px;
  background-position: center right 6px;
  padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  width: 122px;
  min-width: 1px;
}
.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 150px;
}

.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 250px;
}


.MuiTableRow-root .MuiTableCell-root:first-child {
  min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
  min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
  padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
  display: inline-block;
  vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
  margin-left: 5px;
}

.table-pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  align-items: center;
}

.pagination-info {
  margin-left: auto;
  padding-left: 404px;
}

.pagination-control {
  margin-left: auto;
}

.pagination-control > * {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
}

.pagination-control span {
  font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
  background: #fff;
  width: 50px;
  padding: 0;
  min-width: 1px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  text-align: center;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  padding: 5px 6px;
  color: #4b4c4c;
}

.pagination-control select {
  background-color: #fff;
  width: 54px;
  border-radius: 3px;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  padding: 6px;
  color: #4b4c4c;
}

.pagination-control input + span {
  margin-left: 10px;
}

.pagination-info p {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin: 0;
  color: #4b4c4c;
}

select {
  background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}
.form-main {
  background: #fff;
  margin-top: 43px;
  padding: 47px 50px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
  max-width: 813px;
}

@media (max-width: 1500px) {
  .form-main {
    padding: 35px 35px;
  }
  .pagination-control > * {
    margin-left: 20px;
  }

  .pagination-info {
    padding-left: 352px;
  }
  .table-main-wrap {
    padding: 20px 20px 10px;
  }
}

@media (max-width: 1199px) {
 

  .MuiTableRow-root .MuiTableCell-root {
    font-size: 15px;
    padding: 14px 10px 14px 10px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 100px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    min-width: 120px;
  }
  .MuiTableRow-root .MuiTableCell-body.green span,
  .MuiTableRow-root .MuiTableCell-body.red span {
    font-size: 15px;
  }

  .pagination-info {
    padding-left: 0;
    margin: 0;
  }

  .table-pagination {
    margin-top: 30px;
  }
  .field-left {
    width: 30%;
  }

  .field-right {
    width: 70%;
  }


  .form_field {
    margin-bottom: 35px;
  }

  .form-main {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {

  .table-main-wrap {
    margin-top: 28px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    font-size: 16px;
    padding: 18px 12px 18px 12px;
  }

  .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 80px;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 27px 12px 26px 12px;
  }

  .table-pagination {
    margin-top: 22px;
  }

  .pagination-control > * {
    margin-left: 30px;
  }
  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 127px;
  }
  .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 111px;
  }
  .form-main {
    margin-top: 28px;
    padding: 30px 30px 195px;
    margin-bottom: 0;
  }

  .field-left {
    width: 32%;
  }

  .field-right {
    width: 68%;
  }

  .form-main form {
    max-width: 511px;
  }

  .form_field {
    margin-bottom: 49px;
  }



  .form-main .form_field {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .MuiTableRow-root .MuiTableCell-root {
    font-size: 14px;
  }



  .table-main-wrap {
    margin-top: 25px;
    padding: 14px 15px 10px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    padding: 12px 12px;
  }

  .MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
    padding-bottom: 29px;
  }

  .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 60px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 95px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 109px;
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 15px 12px 14px;
  }

  .pagination-info {
  }

  .pagination-info p {
    font-size: 12px;
  }

  .pagination-control > * {
    margin-left: 14px;
  }

  .pagination-control span {
    font-size: 12px;
  }

  .pagination-control a img {
    max-height: 8px;
  }

  .pagination-control select {
    font-size: 12px;
    background-size: 7px;
    background-position: center right 5px;
    padding: 5px 14px 5px 5px;
    width: 35px;
  }

  .pagination-control input[type="text"] {
    font-size: 12px;
    width: 35px;
    text-align: left;
    padding: 5px;
  }

  .pagination-control > *:first-child {
    margin-left: 0;
  }

  .table-pagination {
    margin-top: 17px;
    margin-bottom: 20px;
  }

  .pagination-control input + span {
    margin-left: 5px;
  }

  .pagination-control input[type="text"] {
    margin-left: 5px;
  }
}

@media (max-width: 639px) {
  .form-main {
    padding-bottom: 30px;
  }
  .field-left {
    width: 100%;
    margin-bottom: 7px;
  }

  .field-right {
    width: 100%;
  }

  .form-main {
    padding: 15px 15px 0px;
    margin-top: 0;
  }

  .form_field {
    padding-right: 0;
    margin-bottom: 21px;
  }

  .custom-radio input[type="radio"] + label:before {
    width: 18px;
    height: 18px;
  }

  .custom-radio input[type="radio"] + label {
    line-height: 23px;
    padding-left: 25px;
  }

  .field-right .custom-radio {
    margin-top: 7px;
    margin-bottom: 5px;
  }
  .custom-radio input[type="radio"] + label:after {
    width: 12px;
    height: 12px;
    border-width: 4px;
  }
}

.table-main-wrap {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
	width: 100%;
	overflow: auto;
	padding: 30px 30px 17px;
	margin-top: 10px;
}
.MuiTablePagination-root {
	overflow: inherit;
}
.MuiTableHead-root {
	background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
	text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
	background: #dff0d8;
	border-radius: 2px;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #3c763d;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 3px 5px;
	min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
	color: #a94442;
	background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
	padding: 19px 0 19px 12px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
	color: #646464;
	font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-root {
	border-bottom: 1px solid #f5f7fa;
	letter-spacing: 0;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #646464;
	padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
	border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.button-field.button-field-user {
	display: inline-block;
	vertical-align: middle;
	float: right;
	margin-top: 2px;
}
.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
	background-color: #fff;
	border-radius: 2px;
	color: #646464;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
	padding-top: 0;
	padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
	width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
	width: 122px;
	min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
	min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
	min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
	min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
	background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
	padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
	display: inline-block;
	vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
	margin-left: 5px;
}

.table-pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 40px;
	align-items: center;
}

.pagination-info {
	margin-left: auto;
	padding-left: 404px;
}

.pagination-control {
	margin-left: auto;
}

.pagination-control > * {
	display: inline-block;
	vertical-align: middle;
	margin-left: 30px;
}

.pagination-control span {
	font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
	background: #fff;
	width: 50px;
	padding: 0;
	min-width: 1px;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	text-align: center;
	border-radius: 3px;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin-left: 10px;
	padding: 5px 6px;
	color: #4b4c4c;
}

.pagination-control select {
	background-color: #fff;
	width: 54px;
	border-radius: 3px;
	padding: 0;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	padding: 6px;
	color: #4b4c4c;
}

.pagination-control input + span {
	margin-left: 10px;
}

.pagination-info p {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin: 0;
	color: #4b4c4c;
}

select {
	background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}
.form-main {
	background: #fff;
	margin-top: 43px;
	padding: 47px 50px;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
	max-width: 813px;
}

@media (max-width: 1500px) {
	.form-main {
		padding: 35px 35px;
	}
	.pagination-control > * {
		margin-left: 20px;
	}

	.pagination-info {
		padding-left: 352px;
	}
	.table-main-wrap {
		padding: 20px 20px 10px;
	}
}

@media (max-width: 1199px) {
	.button-field.button-field-user {
		margin-top: -4px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 15px;
		padding: 14px 10px 14px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 100px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		min-width: 120px;
	}
	.MuiTableRow-root .MuiTableCell-body.green span,
	.MuiTableRow-root .MuiTableCell-body.red span {
		font-size: 15px;
	}

	.pagination-info {
		padding-left: 0;
		margin: 0;
	}

	.table-pagination {
		margin-top: 30px;
	}
	.field-left {
		width: 30%;
	}

	.field-right {
		width: 70%;
	}

	.form-submit .button-field input {
		min-width: 175px;
	}

	.form_field {
		margin-bottom: 35px;
	}

	.form-main {
		margin-top: 30px;
	}
}

@media (max-width: 991px) {
	.button-field.button-field-user {
		margin-top: 0;
	}

	.table-main-wrap {
		margin-top: 28px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 16px;
		padding: 18px 12px 18px 12px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 80px;
	}
	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 27px 12px 26px 12px;
	}

	.table-pagination {
		margin-top: 22px;
	}

	.pagination-control > * {
		margin-left: 30px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 127px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 111px;
	}
	.form-main {
		margin-top: 28px;
		padding: 30px 30px 195px;
		margin-bottom: 0;
	}

	.field-left {
		width: 32%;
	}

	.field-right {
		width: 68%;
	}

	.form-main form {
		max-width: 511px;
	}

	.form_field {
		margin-bottom: 49px;
	}

	.form-submit .button-field input {
		min-width: 143px;
	}

	.form-main .form_field {
		padding-right: 16px;
	}
}

@media (max-width: 767px) {
	.MuiTableRow-root .MuiTableCell-root {
		font-size: 14px;
	}

	.button-field.button-field-user button {
		font-size: 12px;
		padding: 8px 20px 8px;
		min-width: 108px;
	}

	.table-main-wrap {
		margin-top: 25px;
		padding: 14px 15px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		padding: 12px 12px;
	}

	.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
		padding-bottom: 29px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 60px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 95px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 109px;
	}

	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 15px 12px 14px;
	}

	.pagination-info {
	}

	.pagination-info p {
		font-size: 12px;
	}

	.pagination-control > * {
		margin-left: 14px;
	}

	.pagination-control span {
		font-size: 12px;
	}

	.pagination-control a img {
		max-height: 8px;
	}

	.pagination-control select {
		font-size: 12px;
		background-size: 7px;
		background-position: center right 5px;
		padding: 5px 14px 5px 5px;
		width: 35px;
	}

	.pagination-control input[type="text"] {
		font-size: 12px;
		width: 35px;
		text-align: left;
		padding: 5px;
	}

	.pagination-control > *:first-child {
		margin-left: 0;
	}

	.table-pagination {
		margin-top: 17px;
		margin-bottom: 20px;
	}

	.pagination-control input + span {
		margin-left: 5px;
	}

	.pagination-control input[type="text"] {
		margin-left: 5px;
	}
}

@media (max-width: 639px) {
	.form-main {
		padding-bottom: 30px;
	}
	.field-left {
		width: 100%;
		margin-bottom: 7px;
	}

	.field-right {
		width: 100%;
	}

	.form-main {
		padding: 15px 15px 0px;
		margin-top: 0;
	}

	.form_field {
		padding-right: 0;
		margin-bottom: 21px;
	}

	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		font-size: 12px;
		min-width: 108px;
		padding: 7px 20px;
	}

	.form-submit .button-field input {
		min-width: 108px;
	}

	.form-submit .button-field + .button-field {
		margin-left: 0;
		margin-right: 15px;
	}

	.form-submit .button-field {
		margin-bottom: 15px;
	}

	.form-submit .button-field {
		margin-right: 15px;
	}

	.custom-radio input[type="radio"] + label:before {
		width: 18px;
		height: 18px;
	}

	.custom-radio input[type="radio"] + label {
		line-height: 23px;
		padding-left: 25px;
	}

	.field-right .custom-radio {
		margin-top: 7px;
		margin-bottom: 5px;
	}
	.custom-radio input[type="radio"] + label:after {
		width: 12px;
		height: 12px;
		border-width: 4px;
	}
}

.header-logo {
  text-align: center;
  background: #fff;
  padding: 40px 20px;
  box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.12);
}

.header-logo a {
  display: inline-block;
  vertical-align: middle;
  max-width: 308px;
}

.header-logo a img {
  display: block;
}

.error-show {
  color: #dc3545;
  position: absolute;
  top: 60%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-right: 50px;
}

.error-show:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url(/static/media/alert-ic.eb2ffdf6.svg);
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
}

.inner-banner {
  background: rgb(10, 92, 127);
  background: linear-gradient(175deg,
      rgba(10, 92, 127, 1) 0%,
      rgba(21, 136, 186, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a5c7f", endColorstr="#1588ba", GradientType=1);
  color: #fff;
  padding: 35px 30px 120px;
  text-align: center;
}

.inner-banner h1 {
  color: #fff;
  font-size: 40px;
  text-transform: uppercase;
}

.inner-banner p {
  font-size: 26px;
}

.registration-form-main {
  padding-bottom: 100px;
}

.registration-form-main form {
  /* background: #cccccc; */
  background: #ececec;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.2);
  border-radius: 5px;
  max-width: 1320px;
  width: 100%;
  margin: -90px auto 0;
  padding: 30px 50px;
}

.registration-form-wrap {
  border-bottom: 1px solid #ebebeb;
  padding: 0 10px;
  margin-bottom: 50px;
  padding-bottom: 22px;
}

.registration-form-wrap select {
  color: #646464;
}

.header-logo a img {
  display: block;
}

.registration-form-wrap .field-left {
  width: 36%;
  padding-top: 20px;
}

.registration-form-wrap .field-right {
  width: 64%;
  position: relative;
}

.registration-form-wrap .field-left label {
  font-size: 18px;
}

.registration-form-wrap .form_field label span {
  color: #ff0000;
  margin-left: 3px;
}

.registration-form-wrap .form_field {
  margin-bottom: 27px;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
}

.textField {
  /* background-color:#3C763D; */
  border-radius: 4px;
  border: "0px solid #f1f1f5";
  /* color: '#32373c',
    fontFamily:'Nunito Sans' ,
    fontSize: AppConstants.FONTSIZE.FS16,
    fontWeight: 600,
    height:'62px',
    lineHeight: 1.57 */
}

.form_test {
  color: #ff0000;
  background-color: #ff0000;
  margin-left: 3px;
}

.form_field p small {
  color: rgba(100, 100, 100, 0.5);
  font-size: 14px;
  font-weight: 400;
  font-family: Raleway, sans-serif;
  display: block;
  margin-top: 9px;
}

.registration-form-wrap .form_field p {
  margin: 0;
}

.space-p {
  margin-left: 20px;
}

.field-field-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0;
}

.field-field-col {
  width: 50%;
  padding: 0 10px 0 0;
  margin-bottom: 10px;
}

.field-field-col1 {
  width: 33.33%;
  padding: 0 10px;
  margin-bottom: 30px;
}

.field-field-row {
  margin-bottom: 15px;
}

.field-field-label {
  margin-bottom: 8px;
  font-family: Raleway, sans-serif;
  font-weight: 900;
}

.registration-form-wrap .form_field:only-child {
  margin-bottom: 0;
}

.form_field .input-file-trigger {
  display: flex;
  border: 1px dashed #dddddd;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  height: 150px;
  background: #fff;
  font-size: 16px;
  font-weight: 400;
  color: rgba(100, 100, 100, 0.5);
  align-items: center;
  justify-content: center;
}

.form-file-input {
  position: relative;
}

#fileupload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.form_field .input-file-trigger img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.registration-form-wrap .field-right>p {
  font-family: Raleway, sans-serif;
  margin: 11px 0 0;
}

.registration-form-wrap .field-right:only-child {
  text-align: center;
}

.registration-form-wrap .field-right:only-child .button-field {
  display: inline-block;
  vertical-align: middle;
  min-width: 184px;
}

.registration-form-wrap .field-right .custom-checkbox {
  margin-bottom: 30px;
}

.registration-form-wrap .custom-checkbox input[type="checkbox"]+label:after,
.registration-form-wrap .custom-checkbox input[type="checkbox"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.registration-form-wrap .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAAAquuAquuAquuAquuAquuAquuAquuAquuYZ/SigAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-size: 15px;
}

.registration-form-wrap .form_field .custom-checkbox label {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  padding-left: 35px;
}

.registration-form-wrap:last-child {
  border: none;
  padding-bottom: 0;
  margin: 0;
}

.registration-form-wrap:last-child .form_field:last-child {
  margin-bottom: 0;
  margin-top: 20px;
}

.field-field-row-wrap:only-child .field-field-col {
  margin-bottom: 0;
}

.field-field-row:only-child {
  margin-bottom: 0;
}

.registration-form-wrap .field-right .form-file-input+p {
  margin-top: 18px;
}

.logo-preview {
  margin-top: 30px;
  position: relative;
  background: #f5f7fa;
  padding: 15px;
}

.close-ic {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-ic img {
  display: block;
}

.footer-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1420px;
  margin: 0 auto;
}

.thank-you-page {
  background: #fff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  max-width: 1320px;
  margin: 30px auto;
  text-align: center;
  padding: 60px 150px 55px;
}

.thank-you-page img {
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}

.thank-you-page h1 {
  font-size: 50px;
  color: #3c763d;
  margin-bottom: 20px;
}

.thank-you-page p {
  font-size: 24px;
  font-family: Raleway, sans-serif;
  line-height: 1.5;
}

.thank-you-page p:last-child {
  margin-bottom: 0;
}

.thank-you-page p a {
  color: #3c763d;
  text-decoration: underline;
}

@media (max-width: 1500px) {
  .registration-form-main {
    padding: 0 50px 100px;
  }

  .registration-form-main form {
    max-width: 100%;
  }

  .thank-you-page {
    max-width: calc(100% - 100px);
    padding: 25px 50px;
  }

  .thank-you-page h1,
  .inner-banner h1 {
    font-size: 38px;
  }

  .thank-you-page p {
    font-size: 22px;
  }

  .inner-banner p {
    font-size: 22px;
  }
}

@media (max-width: 1199px) {
  .header-logo {
    padding: 30px 20px;
  }

  .header-logo a {
    max-width: 270px;
  }

  .inner-banner p {
    font-size: 26px;
  }

  .inner-banner {
    padding: 70px 30px 135px;
  }

  .inner-banner h1 {
    margin-bottom: 10px;
  }

  .registration-form-wrap {
    padding: 0 50px;
    padding-bottom: 20px;
  }

  .registration-form-main form {
    padding: 80px 30px 80px;
  }

  .registration-form-wrap .field-left label {
    font-size: 16px;
  }

  .registration-form-wrap .field-right>p {
    margin: 8px 0 0;
  }

  .registration-form-wrap .field-right .form-file-input+p {
    margin-top: 15px;
  }

  .thank-you-page {
    margin: 50px auto;
    padding: 50px 80px;
  }

  .thank-you-page p {
    font-size: 20px;
  }

  .thank-you-page img {
    max-width: 120px;
    width: 80px;
  }

  .thank-you-page h1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .inner-banner h1 {
    font-size: 50px;
  }

  .inner-banner h1 {
    font-size: 40px;
  }

  .inner-banner p {
    font-size: 20px;
  }

  .registration-form-wrap {
    padding: 0 20px;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  .registration-form-main form {
    padding: 60px 30px;
  }

  .registration-form-wrap .field-left {
    width: 40%;
  }

  .registration-form-wrap .field-right {
    width: 60%;
  }

  .registration-form-wrap .form_field label span {
    margin-left: 0;
  }

  .form_field p small {
    font-size: 13px;
    margin: 2px 0 0;
  }

  .registration-form-wrap .field-right>p {
    margin: 2px 0 0;
  }

  .field-field-col {
    padding: 0 5px;
  }

  .field-field-row-wrap {
    margin: 0 -5px;
  }

  .field-field-col,
  .field-field-row,
  .registration-form-wrap .form_field {
    margin-bottom: 25px;
  }

  .form_field .input-file-trigger {
    font-size: 16px;
    height: 120px;
  }

  .form_field .input-file-trigger img {
    max-width: 14px;
    margin-right: 5px;
  }

  .registration-form-wrap .field-right>p {
    font-size: 13px;
  }

  .registration-form-wrap .field-right .form-file-input+p {
    margin-top: 10px;
  }

  .registration-form-wrap:last-child .form_field:last-child {
    margin-top: 40px;
  }

  .registration-form-main {
    padding: 0 20px 60px;
  }

  .thank-you-page h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .thank-you-page {
    padding: 50px;
  }

  .thank-you-page p {
    font-size: 18px;
  }

  .thank-you-page img {
    max-width: 100px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .registration-form-wrap .field-left {
    width: 100%;
    padding-top: 0;
    margin-bottom: 7px;
    padding-right: 0;
  }

  .registration-form-wrap .field-right {
    width: 100%;
  }

  .registration-form-main form {
    padding: 40px 30px;
  }

  .registration-form-wrap .field-left label {
    font-size: 14px;
  }

  .form_field .input-file-trigger {
    font-size: 14px;
    padding: 10px;
  }

  .thank-you-page p {
    font-size: 16px;
  }
}

@media (max-width: 639px) {
  .header-logo a {
    max-width: 200px;
  }

  .header-logo {
    padding: 15px;
  }

  .inner-banner h1,
  .thank-you-page h1 {
    font-size: 30px;
  }

  .inner-banner {
    padding: 40px 30px 75px;
  }

  .inner-banner p {
    font-size: 16px;
  }

  .registration-form-main form {
    margin: -60px auto 0;
    padding: 30px 20px;
  }

  .registration-form-wrap {
    padding: 0;
  }

  .field-field-col {
    width: 100%;
    padding: 0;
  }

  .field-field-row-wrap {
    margin: 0;
  }

  .field-field-row-wrap:only-child .field-field-col {
    margin-bottom: 25px;
  }

  .field-field-row-wrap:only-child .field-field-col:last-child {
    margin-bottom: 0;
  }

  .thank-you-page {
    padding: 30px;
    margin: 30px auto;
    max-width: calc(100% - 30px);
  }

  .thank-you-page img {
    max-width: 70px;
  }
}

@media (max-width: 479px) {
  .captcha-code {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-left: -12%;
  }
}

.digital-welcome {
    padding-bottom: 124px;
}

.digital-welcome .sec-left { 
    margin: 0 0;
    padding-bottom: 20px;
}


.foo::before {
    background: var(--background);
    content: '';
    display: block;
    width: 200px;
    height: 300px;
  }


@media (max-width: 1199px){
    .digital-welcome {
        padding-bottom: 10px;
    }
}
@media (max-width: 991px){
 

    .digital-welcome .sec-left {
        padding-bottom: 0;
    }

    .digital-welcome {
        padding-bottom: 38px;
    }
}
@media (max-width: 767px){
    .digital-welcome .sec-left {
        margin-top: 10px;
    }

 


    .digital-welcome {
        padding-bottom: 20px;
    }
}
.two-block-sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0 0px 0;
}

.block-column {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  width: 48.7%;
  margin-right: 2.6%;
  margin-bottom: 40px;
}

.block-column:nth-child(2n) {
  margin-right: 0;
}

.column-head h3 {
  margin: 0;
}

.column-head {
  padding: 29px 30px 27px;
  border-bottom: 3px solid #f5f7fa;
}
.column-body .custom-radio {
  width: 100%;
  margin: 0 0 31px;
}

.column-body {
  padding: 28px 30px;
}
.column-body-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column-body .custom-radio input[type="radio"] + label {
  font-weight: 400;
  padding-left: 50px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.column-body-col {
  width: 47%;
  padding-right: 15px;
}

.button-field.next-step-btn {
  min-width: 255px;
}
/* 
.button-field.next-step-btn input {
    font-size: 18px;
    line-height: 20px;
} */
.column-body img {
  margin-top: 9px;
  display: block;
  /* max-height: 310px; */
}
.block-column:only-child {
  margin-right: 0;
  width: 100%;
}
.block-column:only-child .column-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.block-column:only-child .column-body-row {
  width: 50%;
  display: block;
  padding-top: 20px;
}

.block-column:only-child .column-body-img {
  width: 50%;
}

.column-body img {
  margin-top: 9px;
  display: block;
}

@media (max-width: 1500px) {
  .column-body-col {
    width: 50%;
  }

  .column-body .custom-radio input[type="radio"] + label {
    padding-left: 40px;
  }

  .column-body {
    padding: 28px 25px;
  }
}

@media (max-width: 1350px) {
  .column-body-col {
    padding-right: 0;
  }

  .column-body {
    padding: 28px 20px;
  }

  .column-body .custom-radio input[type="radio"] + label {
    font-size: 15px;
    padding-left: 36px;
  }
}
@media (max-width: 1199px) {
  .block-column {
    width: 100%;
    margin: 0 0 30px;
  }

  .two-block-sec {
    padding-top: 5px;
  }
}
@media (max-width: 991px) {
  .two-block-sec {
    padding-top: 8px;
  }

  .column-head {
    padding: 29px 20px 27px;
  }

  .column-head h3 {
    text-transform: capitalize;
  }

  .column-body {
    padding: 30px 40px 34px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .column-body .custom-radio input[type="radio"] + label {
    padding-left: 50px;
    font-size: 16px;
  }

  .column-body .custom-radio {
    margin: 0 0 31px;
  }

  .column-body-row {
    width: calc(100% - 220px);
  }

  .column-body-img {
    width: 220px;
    padding: 0 0 0;
  }

  .column-body img {
    margin: 0;
    max-width: 100%;
  }

  .column-body-col {
    width: 100%;
    margin-bottom: 31px;
  }

  .column-body .custom-radio:last-child {
    margin-bottom: 0;
  }

  .column-body-col:last-child {
    margin-bottom: 0;
  }
  .block-column:only-child .column-body-img {
    width: 250px;
  }

  .block-column:only-child .column-body-row {
    width: calc(100% - 250px);
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .column-body-row {
    width: 100%;
    margin-bottom: 19px;
  }

  .two-block-sec {
    padding: 0;
  }

  .column-head {
    padding: 20px 20px 19px;
  }

  .column-body {
    padding: 18px 30px 20px 20px;
  }

  .column-body .custom-radio input[type="radio"] + label {
    padding-left: 30px;
    font-size: 14px;
    line-height: 21px;
  }

  .column-body .custom-radio {
    margin: 0 0 19px;
  }

  .column-body-img {
    width: 146px;
  }

  .block-column {
    margin-bottom: 15px;
  }

  .column-body-col {
    margin-bottom: 19px;
  }

  .block-column + .button-field.next-step-btn {
    margin-top: 4px;
  }
  .column-body .custom-radio input[type="radio"] + label:before {
    width: 16px;
    height: 16px;
  }

  .column-body .custom-radio input[type="radio"] + label:after {
    width: 12px;
    height: 12px;
    border-width: 4px;
  }
  .block-column:only-child .column-body-row {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .button-field.next-step-btn {
    min-width: 240px;
  }
}

:root {
  --main-blue-color: #007b88;
  --light-blue-color: #005e68;
  --dark-blue-color: #043b41;
  --blue-color: #1073c6;
  /* 177bc9 */
}

.loc-content-main {
  padding: 0px 40px 0px 40px;
  display: block;
  background: #f5f7fa;
  box-shadow: 0px 0px 16px rgba(10, 10, 10, 0.06);
}

.location-inner-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 28px 0px 28px 0;
  justify-content: space-between;
}

.location-screen-sec {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.location-screen-sec .MuiExpansionPanelDetails-root {
  padding: 0;
  display: block;
  background: #f5f7fa;
}

.location-screen-sec .MuiPaper-rounded {
  background: transparent;
  box-shadow: none;
}

.location-name {
  /* width: 60.3%; */
}

.location-info {
  width: 25%;
}

.location-link {
  width: 19%;
}

.location-arrow-down {
  background: url(/static/media/gray-down-arrow.da53a753.svg) no-repeat right;
}

.location-arrow-up {
  background: url(/static/media/gray-down-arrow.da53a753.svg) no-repeat right;
}

.location-name .custom-checkbox label {
  font-weight: 400;
}

.app-main .table-body .custom-checkbox input[type="checkbox"]+label:after,
.location-name .custom-checkbox input[type="checkbox"]+label:after,
.map-sec-left .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRFAAAA////////////////////////////////600+wgAAAAl0Uk5TAByADqrx/+NVPuvzCwAAAJ5JREFUeJxtkjEOgzAMRUO5AKo4ACgqXaveIRfokAMg1ewdUHYuTpLfIn8Xj1+WX54d56iu3plqwqsz0SUug2maROYHRX0UeY8U3STXh3BioyaUZNkIVyI9vuByk35EX5uSfkPFiVdeGgcvwsFL4+BFOHgRDl4a92WT3XP9JYcdBrMd8GTX3hFpO6gInet/vxVKyzy91clFMzSZJvM7dkpdSqVX+c4WAAAAAElFTkSuQmCC);
  background-color: #043b41;
  background-color: var(--dark-blue-color);
  background-size: 12px;
}

.app-main .location-inner-content .custom-checkbox label:hover,
.app-main .location-inner-content .custom-checkbox label:active,
.app-main .location-inner-content .custom-checkbox input[type="checkbox"]+label:hover,
.app-main .location-inner-content .custom-checkbox input[type="checkbox"]+label:active {
  font-weight: 700;
}

.location-link a {
  color: #646464;
  text-align: center;
  padding: 5px 15px 5px 15px;
}

.red-link {
  color: #ff0000;
}

.location-link a:hover,
.location-link a:focus {
  text-decoration: none;
}

.location-info p {
  margin: 0;
  font-family: "Raleway", sans-serif;
}

.location-inner-content .custom-checkbox input[type="checkbox"]+label {
  padding-left: 30px;
  display: inline-block;
  vertical-align: middle;
}

.app-main .MuiExpansionPanelSummary-content label:hover,
.app-main .MuiExpansionPanelSummary-content label:active {
  font-weight: 700;
  font-size: 16px;
  /* background: #ff0000; */
}

.location-info p img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}

.location-screen-sec .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0;
  cursor: auto;
}

.location-screen-sec .MuiExpansionPanelSummary-content {
  margin: 0;
  cursor: auto;
}

.location-screen-sec .MuiExpansionPanelSummary-root {
  min-height: 1px;
  padding: 0px;
  position: relative;
}

.accordian_title:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  /* background: url("../../../assets/images/gray-down-arrow.svg") no-repeat center
    center; */
  width: 16px;
}

.location-screen-sec .MuiExpansionPanelSummary-content span {
  display: block;
  line-height: 1;
}

.loc-content-main .location-inner-content:last-child {
  border-bottom: none;
  align-content: center;
}

.business-info-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 -25px;
  padding: 0 10px;
}

.info-col-left {
  width: 53%;
  padding: 0 25px;
}

.info-col-right {
  width: 47%;
  padding: 0 25px;
}

.app-main .white-box {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 25px;
}

.white-box:last-child {
  margin-bottom: 0;
}

.box-head {
  padding: 30px;
  border-bottom: 3px solid #f5f7fa;
}

.app-main .box-head h4 {
  font-size: 20px;
  color: #043b41;
  color: var(--dark-blue-color);
  margin: 0;
  text-transform: capitalize;
}

.box-head h4 i {
  font-weight: 400;
}

.box-body {
  padding: 14px 20px 14px 14px;
}

.field-full {
  margin-bottom: 15px;
  position: relative;
}

.box-body>.field-full:last-child {
  margin-bottom: 0px;
}

.field-double {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.field-double .field-full {
  width: 50%;
  padding: 0 10px;
}

.preview-img {
  display: table;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  max-width: 300px;
}

.preview-img img {
  display: block;
}

.box-body .button-field button {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 22px;
  min-width: 133px;
}

.box-body .button-field+.button-field {
  margin-left: 20px;
}

.info-col-full {
  width: 100%;
  margin-top: 50px;
  padding: 0 10px;
}

.table-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.hours-table {
  padding-bottom: 7px;
}

.table-body-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.table-body-row:last-child {
  margin-bottom: 0;
}

.table-data h5 {
  margin: 0;
}

.table-width-1 {
  width: 20%;
}

.table-width-2 {
  width: 30%;
}

.table-width-3 {
  width: 30%;
}

.table-width-4 {
  width: 20%;
}

.head-col h5 {
  margin: 0;
}

.body-field {
  padding: 10px 0 0;
}

.body-field input[type="text"],
.body-field select {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  min-width: 315px;
}

.body-field select {
  margin-left: 20px;
}

.business-info-form {
  width: 100%;
  padding: 30px 0 30px;
}

.table-body .MuiInput-underline:before {
  display: none;
}

.table-body .MuiInput-underline:after {
  display: none;
}

.table-body .MuiInputBase-input {
  background: #f5f7fa;
  font-size: 16px;
  font-weight: 400;
  color: #646464;
  font-family: "Raleway", sans-serif;
  width: auto;
  height: auto;
  border-radius: 2px;
  padding: 13px 9px;
}

.location-screen-sec .MuiPaper-root.MuiExpansionPanel-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  margin: 0;
}

.accordian_title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  position: relative;
  margin-bottom: 8px;
}

.title-left h3 {
  color: #646464;
  margin: 0;
  line-height: 1;
}

.title-right p {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  color: #646464;
}

.title-left {
  width: 86.5%;
}

.title-right {
  width: 13.5%;
}

#upload-btn {
  display: none;
}

#upload-btn1 {
  display: none;
}

.file-upload-btn label {
  font-weight: 700;
  color: #fff;
  border-radius: 50px;
  min-width: 140px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: "Raleway", sans-serif;
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.map-sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0px 0px 0;
}

.map-sec-left {
  width: 20%;
  padding-right: 15px;
}

.map-sec-right {
  width: 80%;
  padding: 3px 0 0;
}

.map-sec-left .custom-checkbox {
  margin-bottom: 27px;
}

.map-sec-left .custom-checkbox:last-child {
  margin-bottom: 0;
}

.map-sec-left .custom-checkbox input[type="checkbox"]+label {
  padding-left: 42px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
}

.confirm-update {
  margin-top: 20px;
  margin-left: 0px;
  min-width: 160px;
  position: relative;
  z-index: 1;
  background: #007b88;
  background: var(--main-blue-color);
  background: linear-gradient(180deg, #007b88 0, #005e68 50%, #043b41 100%);
  background: linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
}

.table-data .custom-checkbox input[type="checkbox"]+label:after,
.table-data .custom-checkbox input[type="checkbox"]+label:before {
  width: 28px;
  height: 28px;
}

.table-data .custom-checkbox input[type="checkbox"]+label {
  margin-top: -5px;
}

.field-full {
  position: relative;
}

.field-full .error-msg {
  font-size: 14px;
  padding-right: 45px;
}

.field-full .error-msg:after {
  width: 15px;
  background-size: 15px;
}

@media (max-width: 1500px) {
  .location-name {
    /* width: 70.5%; */
  }

  .location-info {
    width: 14%;
  }

  .location-link {
    text-align: center;
    width: 26%;
  }

  .business-info-wrap {
    margin: 0 -10px 20px;
    padding: 0;
  }

  .info-col-left {
    width: 50%;
    padding: 0 10px;
  }

  .info-col-right {
    width: 50%;
    padding: 0 10px;
  }

  .business-info-form {
    padding: 0px 0 0;
  }

  .box-head {
    padding: 18px;
  }

  .box-body {
    padding: 20px;
  }

  .field-double .field-full {
    width: 100%;
  }

  .table-width-1 {
    width: 15%;
  }

  .table-width-2 {
    width: 34%;
  }

  .table-width-3 {
    width: 34%;
  }

  .table-width-4 {
    width: 17%;
    text-align: center;
  }

  .white-box {
    margin-bottom: 30px;
  }

  .info-col-full {
    padding: 0;
    margin-top: 25px;
  }

  .table-body .MuiInputBase-input {
    font-size: 13px;
    padding: 13px 5px;
  }

  .map-sec-right {
    width: 65%;
  }

  .map-sec-left {
    width: 35%;
  }
}

@media (max-width: 1199px) {
  .location-name {
    /* width: 70%; */
  }

  .location-info {
    width: 19%;
  }

  .location-link {
    width: 32%;
  }

  .accordian_title {
    padding: 20px 40px 20px 25px;
  }

  .title-left h3,
  .title-right p {
    font-size: 20px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label,
  .location-info p,
  .location-link a {
    font-size: 14px;
  }

  .loc-content-main {
    padding: 0 30px;
  }

  .location-inner-content {
    padding: 26px 0;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label {
    padding-left: 34px;
  }

  .info-col-left {
    width: 100%;
    margin-bottom: 30px;
  }

  .info-col-right {
    width: 100%;
  }

  .field-double .field-full {
    width: 50%;
  }

  .body-field input[type="text"],
  .body-field select {
    min-width: 255px;
  }

  .map-sec-left {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .map-sec-right {
    width: 100%;
  }

  .map-sec-left .custom-checkbox {
    width: 33.33%;
    padding-right: 10px;
    margin-bottom: 15px;
  }

  .map-sec-left .custom-checkbox input[type="checkbox"]+label {
    font-size: 14px;
  }

  .map-sec-right>div {
    height: 350px !important;
  }
}

@media (max-width: 991px) {

  .title-left h3,
  .title-right p {
    font-size: 24px;
  }

  .accordian_title {
    padding: 19px 20px;
    margin-bottom: 0;
  }

  .location-screen-sec {
    margin-top: 26px;
  }

  .loc-content-main {
    padding: 0 20px;
  }

  .location-inner-content {
    padding: 28px 0 27px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label,
  .location-info p,
  .location-link a {
    font-size: 16px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label {
    padding-left: 30px;
  }

  .location-name {
    /* width: 70%; */
  }

  .location-info {
    width: 18.5%;
  }

  .location-link {
    width: 32.5%;
  }

  .title-left {
    width: 82.5%;
  }

  .title-right {
    width: 17.5%;
  }

  .box-head {
    padding: 20px 20px 18px;
  }

  .field-full {
    margin-bottom: 19px;
  }

  .white-box {
    margin-bottom: 28px;
  }

  .preview-img {
    width: 150px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .preview-img img {
    max-width: 150px;
  }

  .image-link-btn {
    display: inline-block;
    vertical-align: top;
    padding-left: 40px;
  }

  .image-link-btn .button-field {
    display: table;
    margin-bottom: 19px;
  }

  .box-body .button-field+.button-field {
    margin-left: 0;
  }

  .file-upload-btn label,
  .box-body .button-field button {
    font-size: 16px;
    line-height: 18px;
    min-width: 145px;
    padding: 12px 23px;
  }

  .head-col h5 {
    font-size: 16px;
    letter-spacing: 0;
  }

  .head-col {
    padding: 0 0 0;
  }

  .table-width-1 {
    width: 19.5%;
  }

  .table-width-2 {
    width: 28%;
  }

  .table-width-3 {
    width: 28.5%;
  }

  .table-width-4 {
    width: 24%;
    text-align: left;
  }

  .table-head {
    margin-bottom: 30px;
  }

  .table-data h5 {
    font-size: 16px;
  }

  .table-body .MuiInputBase-input {
    font-size: 16px;
    padding: 13px 9px;
  }

  .table-body-row {
    margin-bottom: 7px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label:after,
  .table-body .custom-checkbox input[type="checkbox"]+label:before {
    width: 28px;
    height: 28px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label {
    margin-top: -30px;
    display: inline-block;
    vertical-align: middle;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 13px;
  }

  .hours-table {
    padding-bottom: 0;
  }

  /* .body-field input[type="text"],
	.body-field select {
		min-width: 305px;
	} */
  .map-sec {
    padding-bottom: 0;
    padding-right: 0;
  }

  .map-sec-left .custom-checkbox input[type="checkbox"]+label {
    font-size: 15px;
    padding-left: 30px;
  }

  .map-sec-left .custom-checkbox {
    width: 31.5%;
    margin-bottom: 28px;
  }

  .map-sec-right {
    padding: 4px 0 0;
  }

  .map-sec-right>div {
    height: 370px !important;
  }

  .confirm-update {
    margin-top: 30px;
    min-width: 190px;
  }
}

@media (max-width: 767px) {
  .location-screen-sec .MuiExpansionPanelDetails-root {
    overflow: auto;
  }

  .title-left h3,
  .title-right p {
    font-size: 16px;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label,
  .location-info p,
  .location-link a {
    font-size: 14px;
  }

  .location-name {
    /* width: 65%; */
  }

  .location-info {
    width: 15%;
  }

  .location-link {
    width: 31%;
  }

  .custom-checkbox input[type="checkbox"]+label:after,
  .custom-checkbox input[type="checkbox"]+label:before {
    width: 13px;
    height: 13px;
  }

  .location-name .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 8px;
  }

  .location-screen-sec {
    margin-top: 0;
  }

  .accordian_title {
    padding: 14px 16px;
  }

  .location-screen-sec .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 1px;
  }

  .loc-content-main {
    padding: 0 15px;
    min-width: 620px;
  }

  .location-inner-content {
    padding: 18px 0;
  }

  .location-inner-content .custom-checkbox input[type="checkbox"]+label {
    padding-left: 24px;
    line-height: 15px;
  }

  .title-left {
    width: 79%;
  }

  .title-right {
    width: 21%;
  }

  .accordian_title:after {
    right: 12px;
  }

  .business-info-form {
    width: calc(100vw - 60px);
  }

  .box-head h4 {
    font-size: 16px;
  }

  .business-info-form {
    padding: 20px 0 0;
  }

  .box-head {
    padding: 15px 15px;
  }

  .box-body {
    padding: 12px 15px;
  }

  .field-double .field-full {
    width: 100%;
  }

  .white-box {
    margin-bottom: 22px;
  }

  .info-col-left {
    margin-bottom: 22px;
  }

  .file-upload-btn label,
  .box-body .button-field button {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 16px;
    min-width: 115px;
  }

  .preview-img img {
    max-width: 100px;
  }

  .preview-img {
    width: 100px;
  }

  .image-link-btn {
    padding-left: 20px;
  }

  .image-link-btn .button-field {
    margin-bottom: 15px;
  }

  .head-col h5,
  .table-data h5 {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .table-width-1 {
    width: 14%;
  }

  .table-width-2 {
    width: 35%;
  }

  .table-width-3 {
    width: 36%;
  }

  .table-width-4 {
    width: 15%;
  }

  .table-head {
    margin-bottom: 20px;
  }

  .table-body .MuiInputBase-input {
    font-size: 12px;
    padding: 7px 0px 6px 6px;
    width: 87px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label:after,
  .table-body .custom-checkbox input[type="checkbox"]+label:before {
    width: 18px;
    height: 18px;
  }

  .table-body .custom-checkbox input[type="checkbox"]+label {
    margin-top: -22px;
  }

  .table-body-row {
    margin-bottom: 5px;
  }

  .hours-table {
    padding-bottom: 9px;
  }

  .info-col-full {
    margin-top: 20px;
  }

  .loc-content-wrap {
    overflow: auto;
  }

  .map-sec-left .custom-checkbox {
    width: 50%;
    margin-bottom: 10px;
  }

  .map-sec-left .custom-checkbox input[type="checkbox"]+label {
    font-size: 12px;
    padding-left: 25px;
    line-height: 19px;
  }

  .body-field input[type="text"],
  .body-field select {
    width: 100%;
    margin: 0 0 20px;
    min-width: 1px;
    max-width: 285px;
  }

  .body-field select {
    margin-bottom: 0;
    display: block;
  }

  .map-sec-right>div {
    height: 160px !important;
  }

  .map-sec-right {
    padding: 6px 0 0;
  }

  .confirm-update {
    margin-top: 24px;
    min-width: 182px;
  }

  .confirm-update+.button-field.confirm-update {
    margin-left: 10px;
  }
}

@media (max-width: 639px) {
  .confirm-update {
    min-width: 170px;
  }
}

@media (max-width: 479px) {
  .confirm-update+.button-field.confirm-update {
    margin-top: 15px;
    margin-left: 0;
  }

  .confirm-update {
    display: table;
    margin-left: 0;
  }
}

@media (max-width: 370px) {
  .map-sec-left .custom-checkbox {
    width: 100%;
    padding: 0;
  }

  .map-sec {
    padding-top: 25px;
  }

  .map-sec-left {
    padding-bottom: 15px;
  }

  .table-body .MuiInputBase-input {
    padding: 7px 3px;
    font-size: 10.5px;
    width: 75px;
  }

  .table-width-2 {
    width: 38%;
  }

  .table-width-3 {
    width: 38%;
  }

  .table-width-4 {
    width: 10%;
  }

  .head-col h5,
  .table-data h5 {
    letter-spacing: 0;
    font-size: 11px;
  }

  .table-data.table-width-4 {
    text-align: right;
    padding-left: 6px;
  }

  .field-full .error-msg {
    font-size: 12px;
    padding-right: 25px;
  }

  .field-full .error-msg:after {
    right: 10px;
    width: 12px;
    background-size: 12px;
    background-position: center center;
  }
}



@media (max-width: 1500px) {
   
}

@media (max-width: 1199px) {
   
}

@media (max-width: 991px) {
    
}

@media (max-width: 767px) {
   
}

@media (max-width: 639px) {
  
}

@media (max-width: 479px) {
    
}
.download-btn {
  position: absolute;
  top: -5px;
  right: -15px;
  width: 10px;
  height: 10px;
  color: #ccc;
  min-width: 169px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Raleway, sans-serif;
}

.table-row-even {
  background: #EBEBEB;
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: lighter;
}

.table-row-odd {
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: lighter;
}

.table-hed {
  background: #CCCCCC;
  width: 100%;
  font-weight: 900;
  color: #000;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  line-height: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-column {
  width: 100%;
  padding: 7px;
  letter-spacing: 0px;
  color: #0a0a0a;
  font: normal normal bold 18px/22px Raleway;
}

.table-column1 {
  width: 100%;
  padding: 10px;
  color: #4b4c4c;
  font: normal normal 16px/19px Raleway;
  letter-spacing: 0px;
}

.title-content {
  font: normal normal normal 16px/19px Raleway;
  letter-spacing: 0.4px;
  color: #646464;
}

.title {
  font: normal normal bold 25px/30px Raleway;
  letter-spacing: 0px;
  color: #0a5c7f;
  opacity: 1;
}

.title-main {
  font: normal normal bold 18px/22px Raleway;
  letter-spacing: 0px;
  color: #4b4c4c;
}

.donut-title {
  font: normal normal normal 16px/19px Raleway;
  letter-spacing: 0.32px;
  color: #646464;
  opacity: 0.9;
}
.tabbed-chart-widget_portal-chart-tabs-container__1Aovg{border-bottom:1px solid rgba(0,0,0,0.12)}.tabbed-chart-widget_portal-chart-tabs-root__2UJlm{text-transform:none;height:95px}.tabbed-chart-widget_portal-chart-tabs-wrapper__2zuC5{flex-direction:column-reverse}.tabbed-chart-widget_portal-trend-up__CHsHD{color:#4caf50}.tabbed-chart-widget_portal-trend-down__nfqoS{color:#f44336}.tabbed-chart-widget_portal-trend-icon__fu5ua{width:40px;height:40px;padding-right:5px}.tabbed-chart-widget_portal-tab-chart__cGQdS{padding:24px;position:relative;height:400px}

.digital-welcome {
  padding-bottom: 14px;
}

.form-info {
  width: 150%;
}

.digital-welcome .sec-left {
  margin: 0 0;
  padding-bottom: 20px;
}




@media (max-width: 1199px) {
  .digital-welcome {
    padding-bottom: 10px;
  }

  .form-info {
    width: 150%;
  }
}
@media (max-width: 991px) {


  .digital-welcome .sec-left {
    padding-bottom: 0;
  }

  .digital-welcome {
    padding-bottom: 38px;
  }

  .form-info {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .digital-welcome .sec-left {
    margin-top: 10px;
  }

 
  .digital-welcome {
    padding-bottom: 20px;
  }
}

.table-main-wrap {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
	width: 100%;
	overflow: auto;
	padding: 30px 30px 17px;
	margin-top: 10px;
}
.MuiTablePagination-root {
	overflow: inherit;
}
.MuiTableHead-root {
	background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
	text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
	background: #dff0d8;
	border-radius: 2px;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #3c763d;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 3px 5px;
	min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
	color: #a94442;
	background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
	padding: 19px 0 19px 12px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
	color: #646464;
	font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-root {
	border-bottom: 1px solid #f5f7fa;
	letter-spacing: 0;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #646464;
	padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
	border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.button-field.button-field-assettype {
	display: inline-block;
	vertical-align: middle;
	float: right;
	margin-top: 2px;
}
.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
	background-color: #fff;
	border-radius: 2px;
	color: #646464;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
	padding-top: 0;
	padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
	width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
	width: 122px;
	min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
	min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
	min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
	min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
	background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
	padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
	display: inline-block;
	vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
	margin-left: 5px;
}

.table-pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 40px;
	align-items: center;
}

.pagination-info {
	margin-left: auto;
	padding-left: 404px;
}

.pagination-control {
	margin-left: auto;
}

.pagination-control > * {
	display: inline-block;
	vertical-align: middle;
	margin-left: 30px;
}

.pagination-control span {
	font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
	background: #fff;
	width: 50px;
	padding: 0;
	min-width: 1px;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	text-align: center;
	border-radius: 3px;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin-left: 10px;
	padding: 5px 6px;
	color: #4b4c4c;
}

.pagination-control select {
	background-color: #fff;
	width: 54px;
	border-radius: 3px;
	padding: 0;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	padding: 6px;
	color: #4b4c4c;
}

.pagination-control input + span {
	margin-left: 10px;
}

.pagination-info p {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin: 0;
	color: #4b4c4c;
}

select {
	background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}
.form-main {
	background: #fff;
	margin-top: 43px;
	padding: 47px 50px;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
	max-width: 813px;
}

@media (max-width: 1500px) {
	.form-main {
		padding: 35px 35px;
	}
	.pagination-control > * {
		margin-left: 20px;
	}

	.pagination-info {
		padding-left: 352px;
	}
	.table-main-wrap {
		padding: 20px 20px 10px;
	}
}

@media (max-width: 1199px) {
	.button-field.button-field-assettype {
		margin-top: -4px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 15px;
		padding: 14px 10px 14px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 100px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		min-width: 120px;
	}
	.MuiTableRow-root .MuiTableCell-body.green span,
	.MuiTableRow-root .MuiTableCell-body.red span {
		font-size: 15px;
	}

	.pagination-info {
		padding-left: 0;
		margin: 0;
	}

	.table-pagination {
		margin-top: 30px;
	}
	.field-left {
		width: 30%;
	}

	.field-right {
		width: 70%;
	}

	.form-submit .button-field input {
		min-width: 175px;
	}

	.form_field {
		margin-bottom: 35px;
	}

	.form-main {
		margin-top: 30px;
	}
}

@media (max-width: 991px) {
	.button-field.button-field-assettype {
		margin-top: 0;
	}

	.table-main-wrap {
		margin-top: 28px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 16px;
		padding: 18px 12px 18px 12px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 80px;
	}
	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 27px 12px 26px 12px;
	}

	.table-pagination {
		margin-top: 22px;
	}

	.pagination-control > * {
		margin-left: 30px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 127px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 111px;
	}
	.form-main {
		margin-top: 28px;
		padding: 30px 30px 195px;
		margin-bottom: 0;
	}

	.field-left {
		width: 32%;
	}

	.field-right {
		width: 68%;
	}

	.form-main form {
		max-width: 511px;
	}

	.form_field {
		margin-bottom: 49px;
	}

	.form-submit .button-field input {
		min-width: 143px;
	}

	.form-main .form_field {
		padding-right: 16px;
	}
}

@media (max-width: 767px) {
	.MuiTableRow-root .MuiTableCell-root {
		font-size: 14px;
	}

	.button-field.button-field-assettype button {
		font-size: 12px;
		padding: 8px 20px 8px;
		min-width: 108px;
	}

	.table-main-wrap {
		margin-top: 25px;
		padding: 14px 15px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		padding: 12px 12px;
	}

	.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
		padding-bottom: 29px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 60px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 95px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 109px;
	}

	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 15px 12px 14px;
	}

	.pagination-info {
	}

	.pagination-info p {
		font-size: 12px;
	}

	.pagination-control > * {
		margin-left: 14px;
	}

	.pagination-control span {
		font-size: 12px;
	}

	.pagination-control a img {
		max-height: 8px;
	}

	.pagination-control select {
		font-size: 12px;
		background-size: 7px;
		background-position: center right 5px;
		padding: 5px 14px 5px 5px;
		width: 35px;
	}

	.pagination-control input[type="text"] {
		font-size: 12px;
		width: 35px;
		text-align: left;
		padding: 5px;
	}

	.pagination-control > *:first-child {
		margin-left: 0;
	}

	.table-pagination {
		margin-top: 17px;
		margin-bottom: 20px;
	}

	.pagination-control input + span {
		margin-left: 5px;
	}

	.pagination-control input[type="text"] {
		margin-left: 5px;
	}
}

@media (max-width: 639px) {
	.form-main {
		padding-bottom: 30px;
	}
	.field-left {
		width: 100%;
		margin-bottom: 7px;
	}

	.field-right {
		width: 100%;
	}

	.form-main {
		padding: 15px 15px 0px;
		margin-top: 0;
	}

	.form_field {
		padding-right: 0;
		margin-bottom: 21px;
	}

	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		font-size: 12px;
		min-width: 108px;
		padding: 7px 20px;
	}

	.form-submit .button-field input {
		min-width: 108px;
	}

	.form-submit .button-field + .button-field {
		margin-left: 0;
		margin-right: 15px;
	}

	.form-submit .button-field {
		margin-bottom: 15px;
	}

	.form-submit .button-field {
		margin-right: 15px;
	}

	.custom-radio input[type="radio"] + label:before {
		width: 18px;
		height: 18px;
	}

	.custom-radio input[type="radio"] + label {
		line-height: 23px;
		padding-left: 25px;
	}

	.field-right .custom-radio {
		margin-top: 7px;
		margin-bottom: 5px;
	}
	.custom-radio input[type="radio"] + label:after {
		width: 12px;
		height: 12px;
		border-width: 4px;
	}
}

.table-main-wrap {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
	width: 100%;
	overflow: auto;
	padding: 30px 30px 17px;
	margin-top: 10px;
}
.MuiTablePagination-root {
	overflow: inherit;
}
.MuiTableHead-root {
	background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
	text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
	background: #dff0d8;
	border-radius: 2px;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #3c763d;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 3px 5px;
	min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
	color: #a94442;
	background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
	padding: 19px 0 19px 12px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
	color: #646464;
	font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-root {
	border-bottom: 1px solid #f5f7fa;
	letter-spacing: 0;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #646464;
	padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
	border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.button-field.button-field-assetsize {
	display: inline-block;
	vertical-align: middle;
	float: right;
	margin-top: 2px;
}
.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
	background-color: #fff;
	border-radius: 2px;
	color: #646464;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
	padding-top: 0;
	padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
	width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
	width: 122px;
	min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
	min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
	min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
	min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
	background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
	padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
	display: inline-block;
	vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
	margin-left: 5px;
}

.table-pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 40px;
	align-items: center;
}

.pagination-info {
	margin-left: auto;
	padding-left: 404px;
}

.pagination-control {
	margin-left: auto;
}

.pagination-control > * {
	display: inline-block;
	vertical-align: middle;
	margin-left: 30px;
}

.pagination-control span {
	font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
	background: #fff;
	width: 50px;
	padding: 0;
	min-width: 1px;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	text-align: center;
	border-radius: 3px;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin-left: 10px;
	padding: 5px 6px;
	color: #4b4c4c;
}

.pagination-control select {
	background-color: #fff;
	width: 54px;
	border-radius: 3px;
	padding: 0;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	padding: 6px;
	color: #4b4c4c;
}

.pagination-control input + span {
	margin-left: 10px;
}

.pagination-info p {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin: 0;
	color: #4b4c4c;
}

select {
	background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}
.form-main {
	background: #fff;
	margin-top: 43px;
	padding: 47px 50px;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
	max-width: 813px;
}

@media (max-width: 1500px) {
	.form-main {
		padding: 35px 35px;
	}
	.pagination-control > * {
		margin-left: 20px;
	}

	.pagination-info {
		padding-left: 352px;
	}
	.table-main-wrap {
		padding: 20px 20px 10px;
	}
}

@media (max-width: 1199px) {
	.button-field.button-field-assetsize {
		margin-top: -4px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 15px;
		padding: 14px 10px 14px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 100px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		min-width: 120px;
	}
	.MuiTableRow-root .MuiTableCell-body.green span,
	.MuiTableRow-root .MuiTableCell-body.red span {
		font-size: 15px;
	}

	.pagination-info {
		padding-left: 0;
		margin: 0;
	}

	.table-pagination {
		margin-top: 30px;
	}
	.field-left {
		width: 30%;
	}

	.field-right {
		width: 70%;
	}

	.form-submit .button-field input {
		min-width: 175px;
	}

	.form_field {
		margin-bottom: 35px;
	}

	.form-main {
		margin-top: 30px;
	}
}

@media (max-width: 991px) {
	.button-field.button-field-assetsize {
		margin-top: 0;
	}

	.table-main-wrap {
		margin-top: 28px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 16px;
		padding: 18px 12px 18px 12px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 80px;
	}
	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 27px 12px 26px 12px;
	}

	.table-pagination {
		margin-top: 22px;
	}

	.pagination-control > * {
		margin-left: 30px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 127px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 111px;
	}
	.form-main {
		margin-top: 28px;
		padding: 30px 30px 195px;
		margin-bottom: 0;
	}

	.field-left {
		width: 32%;
	}

	.field-right {
		width: 68%;
	}

	.form-main form {
		max-width: 511px;
	}

	.form_field {
		margin-bottom: 49px;
	}

	.form-submit .button-field input {
		min-width: 143px;
	}

	.form-main .form_field {
		padding-right: 16px;
	}
}

@media (max-width: 767px) {
	.MuiTableRow-root .MuiTableCell-root {
		font-size: 14px;
	}

	.button-field.button-field-assetsize button {
		font-size: 12px;
		padding: 8px 20px 8px;
		min-width: 108px;
	}

	.table-main-wrap {
		margin-top: 25px;
		padding: 14px 15px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		padding: 12px 12px;
	}

	.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
		padding-bottom: 29px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 60px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 95px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 109px;
	}

	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 15px 12px 14px;
	}

	.pagination-info {
	}

	.pagination-info p {
		font-size: 12px;
	}

	.pagination-control > * {
		margin-left: 14px;
	}

	.pagination-control span {
		font-size: 12px;
	}

	.pagination-control a img {
		max-height: 8px;
	}

	.pagination-control select {
		font-size: 12px;
		background-size: 7px;
		background-position: center right 5px;
		padding: 5px 14px 5px 5px;
		width: 35px;
	}

	.pagination-control input[type="text"] {
		font-size: 12px;
		width: 35px;
		text-align: left;
		padding: 5px;
	}

	.pagination-control > *:first-child {
		margin-left: 0;
	}

	.table-pagination {
		margin-top: 17px;
		margin-bottom: 20px;
	}

	.pagination-control input + span {
		margin-left: 5px;
	}

	.pagination-control input[type="text"] {
		margin-left: 5px;
	}
}

@media (max-width: 639px) {
	.form-main {
		padding-bottom: 30px;
	}
	.field-left {
		width: 100%;
		margin-bottom: 7px;
	}

	.field-right {
		width: 100%;
	}

	.form-main {
		padding: 15px 15px 0px;
		margin-top: 0;
	}

	.form_field {
		padding-right: 0;
		margin-bottom: 21px;
	}

	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		font-size: 12px;
		min-width: 108px;
		padding: 7px 20px;
	}

	.form-submit .button-field input {
		min-width: 108px;
	}

	.form-submit .button-field + .button-field {
		margin-left: 0;
		margin-right: 15px;
	}

	.form-submit .button-field {
		margin-bottom: 15px;
	}

	.form-submit .button-field {
		margin-right: 15px;
	}

	.custom-radio input[type="radio"] + label:before {
		width: 18px;
		height: 18px;
	}

	.custom-radio input[type="radio"] + label {
		line-height: 23px;
		padding-left: 25px;
	}

	.field-right .custom-radio {
		margin-top: 7px;
		margin-bottom: 5px;
	}
	.custom-radio input[type="radio"] + label:after {
		width: 12px;
		height: 12px;
		border-width: 4px;
	}
}

.table-main-wrap {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
	width: 100%;
	overflow: auto;
	padding: 30px 30px 17px;
	margin-top: 10px;
}
.MuiTablePagination-root {
	overflow: inherit;
}
.MuiTableHead-root {
	background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
	text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
	background: #dff0d8;
	border-radius: 2px;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #3c763d;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 3px 5px;
	min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
	color: #a94442;
	background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
	padding: 19px 0 19px 12px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
	color: #646464;
	font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-root {
	border-bottom: 1px solid #f5f7fa;
	letter-spacing: 0;
	font-family: Raleway, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #646464;
	padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
	border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.button-field.button-field-assettype {
	display: inline-block;
	vertical-align: middle;
	float: right;
	margin-top: 2px;
}
.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
	background-color: #fff;
	border-radius: 2px;
	color: #646464;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
	padding-top: 0;
	padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
	width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
	background-size: 7px;
	background-position: center right 6px;
	padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
	width: 122px;
	min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
	min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
	min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
	min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
	background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
	padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
	display: inline-block;
	vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
	margin-left: 5px;
}

.table-pagination {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 40px;
	align-items: center;
}

.pagination-info {
	margin-left: auto;
	padding-left: 404px;
}

.pagination-control {
	margin-left: auto;
}

.pagination-control > * {
	display: inline-block;
	vertical-align: middle;
	margin-left: 30px;
}

.pagination-control span {
	font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
	background: #fff;
	width: 50px;
	padding: 0;
	min-width: 1px;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	text-align: center;
	border-radius: 3px;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin-left: 10px;
	padding: 5px 6px;
	color: #4b4c4c;
}

.pagination-control select {
	background-color: #fff;
	width: 54px;
	border-radius: 3px;
	padding: 0;
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	padding: 6px;
	color: #4b4c4c;
}

.pagination-control input + span {
	margin-left: 10px;
}

.pagination-info p {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	margin: 0;
	color: #4b4c4c;
}

select {
	background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}
.form-main {
	background: #fff;
	margin-top: 43px;
	padding: 47px 50px;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
	max-width: 813px;
}

@media (max-width: 1500px) {
	.form-main {
		padding: 35px 35px;
	}
	.pagination-control > * {
		margin-left: 20px;
	}

	.pagination-info {
		padding-left: 352px;
	}
	.table-main-wrap {
		padding: 20px 20px 10px;
	}
}

@media (max-width: 1199px) {
	.button-field.button-field-assettype {
		margin-top: -4px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 15px;
		padding: 14px 10px 14px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 100px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		min-width: 120px;
	}
	.MuiTableRow-root .MuiTableCell-body.green span,
	.MuiTableRow-root .MuiTableCell-body.red span {
		font-size: 15px;
	}

	.pagination-info {
		padding-left: 0;
		margin: 0;
	}

	.table-pagination {
		margin-top: 30px;
	}
	.field-left {
		width: 30%;
	}

	.field-right {
		width: 70%;
	}

	.form-submit .button-field input {
		min-width: 175px;
	}

	.form_field {
		margin-bottom: 35px;
	}

	.form-main {
		margin-top: 30px;
	}
}

@media (max-width: 991px) {
	.button-field.button-field-assettype {
		margin-top: 0;
	}

	.table-main-wrap {
		margin-top: 28px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		font-size: 16px;
		padding: 18px 12px 18px 12px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 80px;
	}
	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 27px 12px 26px 12px;
	}

	.table-pagination {
		margin-top: 22px;
	}

	.pagination-control > * {
		margin-left: 30px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 127px;
	}
	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 111px;
	}
	.form-main {
		margin-top: 28px;
		padding: 30px 30px 195px;
		margin-bottom: 0;
	}

	.field-left {
		width: 32%;
	}

	.field-right {
		width: 68%;
	}

	.form-main form {
		max-width: 511px;
	}

	.form_field {
		margin-bottom: 49px;
	}

	.form-submit .button-field input {
		min-width: 143px;
	}

	.form-main .form_field {
		padding-right: 16px;
	}
}

@media (max-width: 767px) {
	.MuiTableRow-root .MuiTableCell-root {
		font-size: 14px;
	}

	.button-field.button-field-assettype button {
		font-size: 12px;
		padding: 8px 20px 8px;
		min-width: 108px;
	}

	.table-main-wrap {
		margin-top: 25px;
		padding: 14px 15px 10px;
	}

	.MuiTableRow-root .MuiTableCell-root {
		padding: 12px 12px;
	}

	.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
		padding-bottom: 29px;
	}

	.MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 60px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 95px;
	}

	.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 109px;
	}

	.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
		padding: 15px 12px 14px;
	}

	.pagination-info {
	}

	.pagination-info p {
		font-size: 12px;
	}

	.pagination-control > * {
		margin-left: 14px;
	}

	.pagination-control span {
		font-size: 12px;
	}

	.pagination-control a img {
		max-height: 8px;
	}

	.pagination-control select {
		font-size: 12px;
		background-size: 7px;
		background-position: center right 5px;
		padding: 5px 14px 5px 5px;
		width: 35px;
	}

	.pagination-control input[type="text"] {
		font-size: 12px;
		width: 35px;
		text-align: left;
		padding: 5px;
	}

	.pagination-control > *:first-child {
		margin-left: 0;
	}

	.table-pagination {
		margin-top: 17px;
		margin-bottom: 20px;
	}

	.pagination-control input + span {
		margin-left: 5px;
	}

	.pagination-control input[type="text"] {
		margin-left: 5px;
	}
}

@media (max-width: 639px) {
	.form-main {
		padding-bottom: 30px;
	}
	.field-left {
		width: 100%;
		margin-bottom: 7px;
	}

	.field-right {
		width: 100%;
	}

	.form-main {
		padding: 15px 15px 0px;
		margin-top: 0;
	}

	.form_field {
		padding-right: 0;
		margin-bottom: 21px;
	}

	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		font-size: 12px;
		min-width: 108px;
		padding: 7px 20px;
	}

	.form-submit .button-field input {
		min-width: 108px;
	}

	.form-submit .button-field + .button-field {
		margin-left: 0;
		margin-right: 15px;
	}

	.form-submit .button-field {
		margin-bottom: 15px;
	}

	.form-submit .button-field {
		margin-right: 15px;
	}

	.custom-radio input[type="radio"] + label:before {
		width: 18px;
		height: 18px;
	}

	.custom-radio input[type="radio"] + label {
		line-height: 23px;
		padding-left: 25px;
	}

	.field-right .custom-radio {
		margin-top: 7px;
		margin-bottom: 5px;
	}
	.custom-radio input[type="radio"] + label:after {
		width: 12px;
		height: 12px;
		border-width: 4px;
	}
}

.table-main-wrap {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  width: 100%;
  overflow: auto;
  padding: 30px 30px 17px;
  margin-top: 10px;
}
.MuiTablePagination-root {
  overflow: inherit;
}
.MuiTableHead-root {
  background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
  text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
  background: #dff0d8;
  border-radius: 2px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3c763d;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 5px;
  min-width: 36px;
}
.MuiTableRow-root .MuiTableCell-body.red span {
  color: #a94442;
  background: #f2dede;
}

.MuiTableRow-root .MuiTableCell-head {
  padding: 19px 0 19px 12px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
  color: #646464;
  font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-root {
  border-bottom: 1px solid #f5f7fa;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #646464;
  padding: 19px 12px 19px 12px;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}
.MuiTableRow-root .MuiTableCell-root select {
  background-size: 7px;
  background-position: center right 6px;
  padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
  background-color: #fff;
  border-radius: 2px;
  color: #646464;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
  padding-top: 0;
  padding-bottom: 16px;
}
.MuiTableRow-root .MuiTableCell-root:first-child {
  width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
  background-size: 7px;
  background-position: center right 6px;
  padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  width: 122px;
  min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
  min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
  min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
  padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
  display: inline-block;
  vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a + a {
  margin-left: 5px;
}

.table-pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  align-items: center;
}

.pagination-info {
  margin-left: auto;
  padding-left: 404px;
}

.pagination-control {
  margin-left: auto;
}

.pagination-control > * {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
}

.pagination-control span {
  font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
  background: #fff;
  width: 50px;
  padding: 0;
  min-width: 1px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  text-align: center;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  padding: 5px 6px;
  color: #4b4c4c;
}

.pagination-control select {
  background-color: #fff;
  width: 54px;
  border-radius: 3px;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  padding: 6px;
  color: #4b4c4c;
}

.pagination-control input + span {
  margin-left: 10px;
}

.pagination-info p {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin: 0;
  color: #4b4c4c;
}

select {
  background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}
.form-main {
  background: #fff;
  margin-top: 43px;
  padding: 47px 50px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.form-main form {
  max-width: 813px;
}

@media (max-width: 1500px) {
  .form-main {
    padding: 35px 35px;
  }
  .pagination-control > * {
    margin-left: 20px;
  }

  .pagination-info {
    padding-left: 352px;
  }
  .table-main-wrap {
    padding: 20px 20px 10px;
  }
}

@media (max-width: 1199px) {
 

  .MuiTableRow-root .MuiTableCell-root {
    font-size: 15px;
    padding: 14px 10px 14px 10px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 100px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    min-width: 120px;
  }
  .MuiTableRow-root .MuiTableCell-body.green span,
  .MuiTableRow-root .MuiTableCell-body.red span {
    font-size: 15px;
  }

  .pagination-info {
    padding-left: 0;
    margin: 0;
  }

  .table-pagination {
    margin-top: 30px;
  }
  .field-left {
    width: 30%;
  }

  .field-right {
    width: 70%;
  }

  .form-submit .button-field input {
    min-width: 175px;
  }

  .form_field {
    margin-bottom: 35px;
  }

  .form-main {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
 
  .table-main-wrap {
    margin-top: 28px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    font-size: 16px;
    padding: 18px 12px 18px 12px;
  }

  .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 80px;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 27px 12px 26px 12px;
  }

  .table-pagination {
    margin-top: 22px;
  }

  .pagination-control > * {
    margin-left: 30px;
  }
  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 127px;
  }
  .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 111px;
  }
  .form-main {
    margin-top: 28px;
    padding: 30px 30px 195px;
    margin-bottom: 0;
  }

  .field-left {
    width: 32%;
  }

  .field-right {
    width: 68%;
  }

  .form-main form {
    max-width: 511px;
  }

  .form_field {
    margin-bottom: 49px;
  }

  .form-submit .button-field input {
    min-width: 143px;
  }

  .form-main .form_field {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .MuiTableRow-root .MuiTableCell-root {
    font-size: 14px;
  }

 

  .table-main-wrap {
    margin-top: 25px;
    padding: 14px 15px 10px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    padding: 12px 12px;
  }

  .MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
    padding-bottom: 29px;
  }

  .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 60px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 95px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 109px;
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 15px 12px 14px;
  }

  .pagination-info {
  }

  .pagination-info p {
    font-size: 12px;
  }

  .pagination-control > * {
    margin-left: 14px;
  }

  .pagination-control span {
    font-size: 12px;
  }

  .pagination-control a img {
    max-height: 8px;
  }

  .pagination-control select {
    font-size: 12px;
    background-size: 7px;
    background-position: center right 5px;
    padding: 5px 14px 5px 5px;
    width: 35px;
  }

  .pagination-control input[type="text"] {
    font-size: 12px;
    width: 35px;
    text-align: left;
    padding: 5px;
  }

  .pagination-control > *:first-child {
    margin-left: 0;
  }

  .table-pagination {
    margin-top: 17px;
    margin-bottom: 20px;
  }

  .pagination-control input + span {
    margin-left: 5px;
  }

  .pagination-control input[type="text"] {
    margin-left: 5px;
  }
}

@media (max-width: 639px) {
  .form-main {
    padding-bottom: 30px;
  }
  .field-left {
    width: 100%;
    margin-bottom: 7px;
  }

  .field-right {
    width: 100%;
  }

  .form-main {
    padding: 15px 15px 0px;
    margin-top: 0;
  }

  .form_field {
    padding-right: 0;
    margin-bottom: 21px;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    font-size: 12px;
    min-width: 108px;
    padding: 7px 20px;
  }

  .form-submit .button-field input {
    min-width: 108px;
  }

  .form-submit .button-field + .button-field {
    margin-left: 0;
    margin-right: 15px;
  }

  .form-submit .button-field {
    margin-bottom: 15px;
  }

  .form-submit .button-field {
    margin-right: 15px;
  }

  .custom-radio input[type="radio"] + label:before {
    width: 18px;
    height: 18px;
  }

  .custom-radio input[type="radio"] + label {
    line-height: 23px;
    padding-left: 25px;
  }

  .field-right .custom-radio {
    margin-top: 7px;
    margin-bottom: 5px;
  }
  .custom-radio input[type="radio"] + label:after {
    width: 12px;
    height: 12px;
    border-width: 4px;
  }
}

.table-main-wrap {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  width: 100%;
  overflow: auto;
  padding: 30px 30px 17px;
  margin-top: 10px;
}

.MuiTablePagination-root {
  overflow: inherit;
}

.MuiTableHead-root {
  background: #f5f7fa;
}

.MuiTableRow-root .MuiTableCell-body.green,
.MuiTableRow-root .MuiTableCell-body.red {
  text-align: center;
}

.MuiTableRow-root .MuiTableCell-body.green span,
.MuiTableRow-root .MuiTableCell-body.red span {
  background: #dff0d8;
  border-radius: 2px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3c763d;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 5px;
  min-width: 36px;
}

.MuiTableRow-root .MuiTableCell-body.red span {
  color: #a94442;
  background: #f2dede;
}

.paypal-button-label-container {
  height: 150px;
  width: 150px;
  background-color: #a94442 !important;
}

.paypal-button-container {
  background-color: #0070ba;
  vertical-align: middle;
  display: flex;
  position: relative;
  height: 42px;
  min-width: 169px;
  font-family: "Raleway", sans-serif;
  text-transform: none;
  font-weight: 500;
  z-index: 0;
  padding: 0px 10px;
  font-size: 0;
  border: 1px solid transparent;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  margin: 1%;
}

.MuiTableRow-root .MuiTableCell-head {
  padding: 19px 0 19px 12px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
  color: #646464;
  font-family: "Raleway", sans-serif;
}

.MuiTableRow-root .MuiTableCell-head.center {
  text-align: center;
}

.MuiTableRow-root .MuiTableCell-root {
  border-bottom: 1px solid #f5f7fa;
  letter-spacing: 0;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #646464;
  padding: 10px;
}

.MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}

.MuiTableRow-root .MuiTableCell-root select {
  background-size: 7px;
  background-position: center right 6px;
  padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root select,
.MuiTableRow-root .MuiTableCell-root input[type="text"] {
  background-color: #fff;
  border-radius: 2px;
  color: #646464;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 12px 6px;
}

.MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
  padding-top: 0;
  padding-bottom: 16px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
  width: 80px;
}

.MuiTableRow-root .MuiTableCell-root select {
  background-size: 7px;
  background-position: center right 6px;
  padding-right: 10px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  width: 122px;
  min-width: 1px;
}

.MuiTableRow-root .MuiTableCell-root:first-child {
  min-width: 50px;
}

.MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 126px;
}

.MuiTableRow-root .MuiTableCell-root {
  min-width: 144px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background: transparent !important;
}

.MuiTableRow-root .MuiTableCell-body {
  padding: 10px 12px 10px 12px;
}

.MuiTableRow-root .MuiTableCell-body a {
  display: inline-block;
  vertical-align: middle;
}

.MuiTableRow-root .MuiTableCell-body a+a {
  margin-left: 5px;
}

.table-pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  align-items: center;
}

.pagination-info {
  margin-left: auto;
  padding-left: 404px;
}

.pagination-control {
  margin-left: auto;
}

.pagination-control>* {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
}

.pagination-control span {
  font-family: "Raleway", sans-serif;
}

.pagination-control input[type="text"] {
  background: #fff;
  width: 50px;
  padding: 0;
  min-width: 1px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  text-align: center;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  padding: 5px 6px;
  color: #4b4c4c;
}

.pagination-control select {
  background-color: #fff;
  width: 75px;
  border-radius: 3px;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  padding: 6px;
  color: #4b4c4c;
}

.pagination-control input+span {
  margin-left: 10px;
}

.pagination-info p {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin: 0;
  color: #4b4c4c;
}

select {
  background-image: url(/static/media/down-arrow-select.8fe8a475.svg);
}

.form-main {
  background: #fff;
  margin-top: 43px;
  padding: 47px 50px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}

.form-main form {
  max-width: 813px;
}

@media (max-width: 1500px) {
  .form-main {
    padding: 35px 35px;
  }

  .pagination-control>* {
    margin-left: 20px;
  }

  .pagination-info {
    padding-left: 352px;
  }

  .table-main-wrap {
    padding: 20px 20px 10px;
  }
}

@media (max-width: 1199px) {
  .MuiTableRow-root .MuiTableCell-root {
    font-size: 15px;
    padding: 14px 10px 14px 10px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 100px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    min-width: 120px;
  }

  .MuiTableRow-root .MuiTableCell-body.green span,
  .MuiTableRow-root .MuiTableCell-body.red span {
    font-size: 15px;
  }

  .pagination-info {
    padding-left: 0;
    margin: 0;
  }

  .table-pagination {
    margin-top: 30px;
  }

  .field-left {
    width: 30%;
  }

  .field-right {
    width: 70%;
  }

  .form_field {
    margin-bottom: 35px;
  }

  .form-main {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .table-main-wrap {
    margin-top: 28px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    font-size: 16px;
    padding: 18px 12px 18px 12px;
  }

  .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 80px;
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 27px 12px 26px 12px;
  }

  .table-pagination {
    margin-top: 22px;
  }

  .pagination-control>* {
    margin-left: 30px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 127px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 111px;
  }

  .form-main {
    margin-top: 28px;
    padding: 30px 30px 195px;
    margin-bottom: 0;
  }

  .field-left {
    width: 32%;
  }

  .field-right {
    width: 68%;
  }

  .form-main form {
    max-width: 511px;
  }

  .form_field {
    margin-bottom: 49px;
  }

  .form-main .form_field {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .MuiTableRow-root .MuiTableCell-root {
    font-size: 14px;
  }

  .table-main-wrap {
    margin-top: 25px;
    padding: 14px 15px 10px;
  }

  .MuiTableRow-root .MuiTableCell-root {
    padding: 12px 12px;
  }

  .MuiTableRow-root.MuiTableRow-head:nth-child(2) th {
    padding-bottom: 29px;
  }

  .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 60px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 95px;
  }

  .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 109px;
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 15px 12px 14px;
  }

  .pagination-info p {
    font-size: 12px;
  }

  .pagination-control>* {
    margin-left: 14px;
  }

  .pagination-control span {
    font-size: 12px;
  }

  .pagination-control a img {
    max-height: 8px;
  }

  .pagination-control select {
    font-size: 12px;
    background-size: 7px;
    background-position: center right 5px;
    padding: 5px 14px 5px 5px;
    width: 35px;
  }

  .pagination-control input[type="text"] {
    font-size: 12px;
    width: 35px;
    text-align: left;
    padding: 5px;
  }

  .pagination-control>*:first-child {
    margin-left: 0;
  }

  .table-pagination {
    margin-top: 17px;
    margin-bottom: 20px;
  }

  .pagination-control input+span {
    margin-left: 5px;
  }

  .pagination-control input[type="text"] {
    margin-left: 5px;
  }
}

@media (max-width: 639px) {
  .form-main {
    padding-bottom: 30px;
  }

  .field-left {
    width: 100%;
    margin-bottom: 7px;
  }

  .field-right {
    width: 100%;
  }

  .form-main {
    padding: 15px 15px 0px;
    margin-top: 0;
  }

  .form_field {
    padding-right: 0;
    margin-bottom: 21px;
  }

  .custom-radio input[type="radio"]+label:before {
    width: 18px;
    height: 18px;
  }

  .custom-radio input[type="radio"]+label {
    line-height: 23px;
    padding-left: 25px;
  }

  .field-right .custom-radio {
    margin-top: 7px;
    margin-bottom: 5px;
  }

  .custom-radio input[type="radio"]+label:after {
    width: 12px;
    height: 12px;
    border-width: 4px;
  }
}
