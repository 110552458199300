:root {
  --button-border-radius_50: 50px;
  --hover-button-bg-color: #cccccc;
  --white-color: #ffffff;
  --white-background-color: #f5f7fa;
  --font-size_10: 10px;
  --font-family: "Barlow", sans-serif;
  --font-family-new: "Barlow", sans-serif;
  --font-family-new-lora: "Lora", sans-serif;
  --hover-font-color: #fff;
  --main-black-color: #000000;
  --main-grey-color: #636363;
  --bark-grey-color: #4b4c4c;

  --main-blue-color: #007b88;
  --light-blue-color: #005e68;
  --dark-blue-color: #043b41;
  --blue-color: #1073c6;
  --border-color: #dddddd;
  --nav-hover-color: #367E88;
}

#iframe video {
  min-width: 0px !important;
}

:host(.media-document.video.mac) {
  min-width: 0px !important;
}


.app-main .display-none {
  display: none;
}

.app-main .login-page-section {
  height: 75% !important;

}

.app-main .subscription-submenu {
  display: block;
}

.app-main .pratice-submenu {
  display: block;
}

.app-main .subscription-li {

  padding-left: 80px;
}

.app-main .subscription-li a {
  padding: 20px 50px !important;
  padding-right: 30px !important;
}

.second-step {
  display: flex;
  display: block;
  text-align: center;
}

.second-step li a.active {
  color: red !important;
  /* Change to your desired active navlink color */
}

.second-step a:focus svg path,
.second-step a:hover svg path {
  fill: var(--main-grey-color) !important;
}


.app-main {
  height: 100%;
  width: 100%;
}

@media print {
  .hide-on-print {
    display: none;
  }
}

.app-main .footer-links a {
  text-decoration: underline;
  color: white;
  font-size: 13px;
  font-family: "Lora";
}

.app-main .footer-link-main {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
}

.app-main .foot-links a {
  font-family: "Lora";
  text-decoration: underline;
  color: #fff;
  font-size: 14px;
}

.app-main .fc-theme-standard td,
.fc-theme-standard th {
  border: 0;
  padding: 5px;
}

.app-main .fc .fc-daygrid-day-frame {
  height: 75%;
  text-align: center;
  flex-direction: row-reverse !important;
  border: 1px solid #ebebeb;
}

.app-main .stripes-lines {
  background: repeating-linear-gradient(135deg,
      #ebebeb,
      #f9f9f9 4px);
  height: 100%;
}

.app-main .daygrid-day-top {
  margin-top: 0px;
  margin-bottom: 0;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  color: var(--main-grey-color);
  font-weight: bold;
  cursor: pointer;
}

.app-main .daygrid-day-top-active {
  background: #449fe6;
  color: var(--white-color);
}

.app-main .login-from {
  width: 30%;
  margin-left: 13%;
  margin-top: 30px;
}

.app-main .dayDate-dote {
  margin-top: 0.8em;
  margin-left: 0.3em;
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: #FF0000;
  border-radius: 50%;
  vertical-align: middle;
  font-weight: bold;
  cursor: pointer;
}

.app-main .dayDate-dote-active {
  background: #449fe6;
}

.app-main .fc .fc-daygrid-day-number {
  padding: 0;
}

.app-main .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 8em;
}

.app-main .fc-h-event {
  border: 0;
  background-color: var(--white-color);
}

.app-main .fc-h-event .fc-event-main {
  color: var(--main-grey-color);
}

.app-main .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0;
}

.app-main ::selection {
  background: var(--dark-blue-color);
  color: var(--white-color);
}

.app-main ::-moz-selection {
  background: var(--dark-blue-color);
  color: var(--white-color);
}

.app-main a:focus,
a:hover {
  color: var(--dark-blue-color);
}

.app-main input[type="date"],
.app-main input[type="email"],
.app-main input[type="password"],
.app-main input[type="search"],
.app-main input[type="tel"],
.app-main input[type="text"],
.app-main input[type="number"],
.app-main select,
.app-main textarea {
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  color: var(--main-grey-color);
  background-color: var(--white-background-color);
  line-height: 20px;
  padding: 15px 20px 15px;
  border-radius: 10px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  font-family: var(--font-family-new);
}

.app-main input[type="text"]:disabled {
  background-color: var(--white-color);
}

.app-main input[type="date"]:focus,
.app-main input[type="email"]:focus,
.app-main input[type="password"]:focus,
.app-main input[type="search"]:focus,
.app-main input[type="tel"]:focus,
.app-main input[type="text"]:focus,
.app-main textarea:focus {
  color: var(--main-grey-color);
}

.app-main textarea {
  height: 280px;
  resize: none;
  border: 1px solid #fcfcfc;
  padding: 10px 15px 10px;
}

.app-main .title-content-select {
  border-radius: 3px;
  border: 1px solid var(--hover-button-bg-color);
  padding: 8px;
  font-size: 13px;
  text-overflow: ellipsis;
  background-color: var(--white-color);
}

.app-main select {
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url("../src/assets/images/down-arrow-select.svg");
  background-position: center right 13px;
  background-repeat: no-repeat;
  background-size: 12px;
  padding-right: 30px;
  color: var(--hover-button-bg-color);
}

.app-main .select1 {
  background-image: url("../src/assets/images/down-arrow-select.svg");
  padding: 0 30px 0 3px;
  color: var(--dark-blue-color);
  font-size: 14pt;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

.select-style {
  width: 15%;
}

.app-main select::-ms-expand {
  display: none;
}

.app-main select option,
.app-main select:focus {
  color: var(--main-grey-color);
  font-size: 12pt;
}

.app-main input::-moz-placeholder {
  opacity: 1 !important;
}

.app-main input:-moz-placeholder {
  opacity: 1 !important;
}

.app-main input::-webkit-input-placeholder {
  color: var(--hover-button-bg-color) !important;
  opacity: 1 !important;
}

.app-main input:-ms-input-placeholder {
  opacity: 1 !important;
}

.app-main textarea::-moz-placeholder {
  opacity: 1 !important;
}

.app-main textarea:-moz-placeholder {
  opacity: 1 !important;
}

.app-main textarea::-webkit-input-placeholder {
  color: var(--hover-button-bg-color) !important;
  opacity: 1 !important;
}

.app-main textarea:-ms-input-placeholder {
  opacity: 1 !important;
}

.app-main .btn {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 14px;
  background: #e83352;
  color: var(--white-color);
  border-radius: 2px;
  font-weight: 700;
  padding: 25px 20px 25px;
}

.app-main .btn:focus,
.app-main .btn:hover {
  box-shadow: none;
  color: var(--white-color);
  background: #212529;
}

.app-main .login_page {}

.app-main .login_page .main {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.app-main .rdw-link-modal {
  height: 270px
}


.app-main .rdw-link-modal-btn {
  background: var(--main-blue-color);
  background: linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  margin: 1%;
  height: 40px;
}

.app-main .button-field {
  position: relative;
  z-index: 1;
  background: var(--main-blue-color);
  /* background: -moz-linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  background: -webkit-linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%);
  background: linear-gradient(180deg, var(--main-blue-color) 0, var(--light-blue-color) 50%, var(--dark-blue-color) 100%); */
  background: -webkit-linear-gradient(linear, left top, right top, color-stop(4%, rgb(0, 123, 136)), to(rgb(0, 94, 104)));
  background: linear-gradient(90deg, rgb(0, 123, 136) 4%, rgb(0, 94, 104) 100%);
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
}

.app-main .button-field :hover {
  background: #16416f;
}

.app-main .button-field-status-redcolor {
  position: relative;
  z-index: 1;
  background: #ff0000;
  border-radius: 50px;
  display: inline-block;
  vertical-align: middle;
  margin: 1%;
}

.app-main .disable-btn {
  background: var(--hover-button-bg-color);
  pointer-events: none;
}

.app-main .color-disable {
  font-weight: 700;
  background: var(--main-grey-color);
  pointer-events: none;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 18px;
  -webkit-appearance: none;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  font-family: var(--font-family-new);
}

.app-main .button-field a {
  font-weight: 700;
  color: var(--hover-font-color);
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 16px 20px;
  font-size: 18px;
}

.app-main .button-field.fullview-btn {
  display: inline-block;
  vertical-align: middle;
  margin-top: -10px;
}

.app-main .button-field.button-field-country {
  display: block;
  float: right;
  margin-top: 1%;
}

.app-main .right-continue-btn .button-field.next-step-btn {
  min-width: 150px;
  margin-left: 0;
}

.app-main .right-continue-btn .button-field.next-step-btn:last-child {
  margin-left: auto;
}

.app-main .sec-left .button-field {
  display: inline-block;
  vertical-align: middle;
  min-width: 150px;
  width: 150px;
}

.app-main .sec-left .button-field button {
  width: 100%;
}

.app-main label {
  font-weight: 700;
  font-family: var(--font-family-new);
}

/* .app-main p {
font-size: 16px;
margin-bottom: 20px;
font-weight: 400;
line-height: 1.4;
font-family: var(--font-family-new);
} */

.app-main h1 {
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-family-new);
  margin-bottom: 20px;
  color: var(--bark-grey-color);
}

.app-main .h1 {
  font-size: 36px;
  font-weight: 700;
  font-family: var(--font-family-new);
  margin-bottom: 20px;
  color: var(--bark-grey-color);
}

.app-main h2 {
  font-size: 40px;
  font-weight: 700;
  font-family: var(--font-family-new);
  margin-bottom: 20px;
  color: inherit;
}

.app-main h3 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: var(--font-family-new);
}

.app-main h4 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: var(--font-family-new);
}

.app-main h5 {
  font-size: 16px;
  margin-bottom: 18px;
  font-weight: 700;
  font-family: var(--font-family-new);
}

.app-main h6 {
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: 700;
  font-family: var(--font-family-new);
}

.app-main button,
html .app-main input[type="button"],
.app-main input[type="reset"],
.app-main input[type="submit"] {
  font-weight: 700;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 150px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 16px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-main .fileuploadbutton {
  font-weight: 700;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 169px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 10px 20px;
  font-size: 18px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-main .button-field:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--main-grey-color);
  border-radius: 50px;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  padding: 0px 0px;
}

.app-main .inner-screen {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: var(--hover-font-color);
  box-shadow: 25px 25px 99px rgba(10, 10, 10, 0.16);
  width: 100%;
  min-height: 100vh;
}

.app-main .theme-option div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-main .theme-option {
  font-weight: 550;
  color: var(--dark-blue-color);
  line-height: 1.2;
  font-size: 11pt;
  margin: 0px 5px 0px 5px;
  align-self: center;
  font-family: var(--font-family-new);
  /* cursor: pointer; */
  /* width: 57%; */
}

.app-main .MuiSvgIcon-fontSizeLarge {
  font-size: 1em;
}

.app-main .theme-option-location {
  /* width: 25%; */
  /* text-align: end; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-main .form-field {
  margin-bottom: 30px;
  position: relative;
}

.app-main .form-field:last-child {
  margin-bottom: 0;
}

.app-main .form-field label {
  margin-bottom: 0;
  display: block;
}

.app-main .form-field input {
  width: 100%;
}

.app-main .left-column {
  width: 50%;
  padding: 80px;
}

.app-main .logo {
  margin-bottom: 59px;
  display: flex;
}

.app-main .logo img {
  display: block;
  vertical-align: middle;
  height: 50px;
}

.app-main .logo a img {
  display: block;
}

.app-main .left-column h1 {
  margin-bottom: 40px;
}

.app-main .user-field {
  background-image: url(./assets/images/user-field.svg);
  background-repeat: no-repeat;
  background-position: center right 18px;
}

.app-main .pwd-field {
  background-image: url(./assets/images/lock-ic.svg);
  background-repeat: no-repeat;
  background-position: center right 18px;
}

.app-main .custom-checkbox {
  position: relative;
}

.app-main .custom-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -999999px;
}

.app-main .custom-checkbox input[type="checkbox"]+label {
  position: relative;
  padding-left: 31px;
  line-height: 23px;
  cursor: pointer;
}

.app-main .custom-checkbox input[type="checkbox"]+label:after,
.app-main .custom-checkbox input[type="checkbox"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.app-main .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url(./assets/images/check-ic.png);
  background-repeat: no-repeat;
  background-position: center center;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  background-size: 14px;
  border-color: transparent;
}

.app-main .box-body .custom-checkbox input[type="checkbox"]+label:after,
.sec-left .custom-checkbox input[type="checkbox"]+label:after {
  background-image: url("./assets/images/white-check-ic.png");
  background-color: var(--dark-blue-color);
  background-size: 12px;
}


.app-main .custom-checkbox input[type="checkbox"]:checked+label:after {
  transform: scale(1);
}

.app-main .bottom-links {
  padding-top: 40px;
}

.app-main .bottom-links ul {
  margin: 0;
}

.app-main .bottom-links ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: var(--dark-blue-color);
  padding-right: 27px;
}

.app-main .bottom-links ul li:last-child {
  padding-right: 0;
}

.app-main .bottom-links ul li a {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}

.app-main .bottom-links button {
  font-size: 16px;
  background: 0 0;
  padding: 0;
  line-height: 1;
  color: var(--dark-blue-color);
  text-transform: none;
  font-weight: 400;
  text-decoration: underline;
  min-width: 1px;
}

.app-main .bottom-links button:hover,
.app-main .bottom-links button:focus {
  color: var(--main-grey-color);
}

.app-main .right-column {
  width: 50%;
  /* background: #0a5c7f;
  background: -moz-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: -webkit-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: linear-gradient(180deg, #0a5c7f 0, #1588ba 100%); */
  color: var(--hover-font-color);
  padding: 90px 20px 40px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.app-main .col-head {
  text-align: center;
  padding: 50px 40px;
  position: absolute;
  top: 50px;
  z-index: 5;
}

.app-main .col-head h2 {
  text-transform: uppercase;
  font-size: 36px;
}

.app-main .col-head p {
  font-size: 20px;
  padding: 0 32px;
  line-height: 1.55;
}

.app-main .image-slider {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: auto;
}

.app-main .image-slider .slick-dots {
  bottom: 20px;
}

.app-main .image-item {
  padding: 10px 2px 100px;
}

.app-main .image-item img {
  display: block;
  margin: 0 auto;
  max-height: 355px;
}

.app-main .error-msg {
  display: block;
  color: #dc3545;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding-right: 50px;
}

.app-main .error-msg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url("./assets/images/alert-ic.svg");
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
}

.app-main .slick-slider {
  /* display: flex; */
}

.app-main .slick-slider .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0 5px;
}

.app-main .slick-slider .slick-dots li button {
  padding: 0;
  background: var(--white-color);
  opacity: 0.5;
  min-width: 1px;
  width: 10px;
  height: 10px;
  transition: all 0.5s ease-in-out;
}

.app-main .slick-slider .slick-dots li button:before {
  display: none;
}

.app-main .slick-slider .slick-dots .slick-active button {
  width: 25px;
  opacity: 1;
}

.app-main .error input {
  /* border-color: #dc3545; */
  background-image: none !important;
}

.app-main .error select {
  /* border-color: #dc3545; */
  background-image: none !important;
}

.app-main .custom-radio {
  position: relative;
}

.app-main .custom-radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: -999999px;
}

.app-main .field-right .custom-radio {
  display: inline-block;
  vertical-align: middle;
}

.app-main .loc-content-main .location-inner-content .location-name .custom-radio label:hover,
.app-main .loc-content-main .location-inner-content .location-name .custom-radio label:active,
.app-main .loc-content-main .location-inner-content .location-name .custom-radio input[type="radio"]+label:hover,
.app-main .loc-content-main .location-inner-content .location-name .custom-radio input[type="radio"]+label:active {
  font-weight: 700;
}

.app-main .custom-radio input[type="radio"]+label {
  position: relative;
  padding-left: 40px;
  visibility: visible !important;
  line-height: 30px;
  cursor: pointer;
  font-weight: 400;
}

.app-main .custom-radio input[type="radio"]+label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 100%;
}

.app-main .custom-radio input[type="radio"]+label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border: 6px solid var(--dark-blue-color);
  border-radius: 100%;
  transform: scale(0);
}

.app-main .custom-radio input[type="radio"]:checked+label:after {
  transform: scale(1);
}

.app-main .custom-radio+.custom-radio {
  margin-left: 40px;
}

.app-main .MuiBackdrop-root {
  background: rgba(75, 76, 76, 0.85);
}

.app-main .popup-box {
  min-width: 550px;
  max-width: 700px;
  margin: auto;
  background: var(--white-color);
  border-radius: 5px;
  margin: 20px 0;
}

.app-main .box-heading {
  background: #0a5c7f;
  background: -moz-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: -webkit-linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  background: linear-gradient(180deg, #0a5c7f 0, #1588ba 100%);
  text-align: center;
  padding: 15px 15px;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.app-main .box-heading img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}

.app-main .box-heading h2 {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  color: var(--white-color);
  font-size: 25px;
}

.app-main .close-btn {
  background: 0 0;
  min-width: 1px;
  padding: 0;
  line-height: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  width: 35px;
  margin: 0;
}

.app-main .box-heading .close-btn img {
  margin: 0 auto;
  display: block;
  padding: 10px;
}

.app-main .box-content {
  padding: 15px;
  text-align: center;
}

.app-main .box-content form {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 650px;
  /* text-align: left; */
}

.app-main .multiple-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.app-main .multiple-field input[type="text"] {
  border-radius: 4px;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  text-align: center;
}

.app-main .verify-otp-popup .box-content form {
  text-align: center;
}

.app-main .box-content p {
  font-size: 24px;
  margin-bottom: 26px;
}

.app-main .box-content a {
  color: var(--dark-blue-color);
  border-bottom: 1px solid var(--dark-blue-color);
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  line-height: 1;
}

.app-main .main-sec-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 75px;
  min-height: 100vh;
  padding-top: 62px;
  transition: all 0.5s ease-in-out;
}

.app-main .content-heading-view {
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  display: flex;
  margin-top: 12px;
}

.app-main .content-heading-view-adbilder {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.app-main .main-sec-wrap.active-main {
  padding-left: 250px;
}

.app-main .main-content {
  width: 100%;
}

.app-main .content-right-main {
  padding: 35px 56px;
}

.app-main .content-right-heading {
  font-family: var(--font-family-new);
  font-weight: 550;
  color: var(--main-grey-color);
  line-height: 1.2;
  padding-left: 5px;
  padding-top: 13px;
  font-size: 18pt;
  width: 100%;
}

.app-main .content-right-heading-adbilder {
  font-family: var(--font-family-new);
  font-weight: 550;
  color: var(--main-grey-color);
  line-height: 1.2;
  font-size: 18pt;
  margin-top: -15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-main .form_field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.app-main .form_field label {
  font-weight: 700;
  display: block;
}

.app-main .field-left {
  width: 25%;
  padding-right: 15px;
}

.app-main .field-right {
  width: 75%;
  margin-bottom: 0px;
  position: relative;
}

.app-main .form-submit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-main .form-submit .button-field input {
  min-width: 196px;
}

.app-main .form-submit .button-field+.button-field {
  margin-left: 0px;
}

.app-main .head-top-data {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
  padding: 5px 0px;
  text-align: end;
  color: white;
  font-size: 13px;
  width: 100%;
  padding: 3px 20px;
}

.app-main .bootom-sec .header-nav {
  display: none;
}

.app-main .bootom-sec {
  width: 100%;
  margin-top: auto;
}

.app-main .not_found {
  justify-content: center;
  width: 100%;
}

.app-main .main-sec-wrap {
  padding-top: 62px;
  /* padding: 80px 190px;
text-align: center; */
}

.app-main .bootom-sec .header-nav li a {
  font-size: 12px;
  /* padding: 14px 10px; */
}

.app-main .bootom-sec .header-nav ul li svg {
  max-width: 15px;
  margin-right: 5px;
  margin-top: -3px;
}

.app-main .custom-border-table {
  margin-top: 30px;
}

.app-main .title-wrap {
  margin-bottom: -20px;
}

.app-main .title-wrap1 {
  margin-bottom: -5px;
}

.app-main .site-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.12);
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background: var(--hover-font-color);
}

.app-main .header-left {
  width: 18%;
  padding: 0px 25px 0px 10px;
}

.app-main .site-logo {
  display: inline-block;
  vertical-align: middle;
  padding-left: 44px;
}

.app-main .site-logo a {
  display: inline-block;
  max-width: 205px;
  width: 136px;
}

.app-main .site-logo a img {
  display: block;
}

.app-main .header-right {
  width: 82%;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.app-main .header-nav {
  padding: 0 15px;
}

.app-main .header-nav ul {
  margin: 0;
}

.app-main .header-nav ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding-right: 60px;
}

.app-main .header-nav ul li a.active {
  color: var(--nav-hover-color) !important;
  font-weight: 600 !important;
}

/* .app-main .header-nav ul li div a.active {
  color: var(--nav-hover-color); !important;
} */

.submenu-active {
  /* color: var(--nav-hover-color) !important; */
  display: block !important;
  background-color: #f5f7fa;

}

.supply-active {
  color: var(--nav-hover-color) !important;
  display: block !important;
  background-color: #f5f7fa;

}

.second-step a.active svg path,
.second-step a.active svg path {
  fill: var(--nav-hover-color) !important;
  color: var(--nav-hover-color) !important;
  display: block !important;
  background-color: #f5f7fa;
}


.pratice-submenu-active {
  color: var(--nav-hover-color) !important;
  display: block !important;
  background-color: #f5f7fa;

}

.active-dropdown {
  cursor: pointer !important;
}

.app-main .header-nav ul li:last-child {
  padding-right: 0;
}

.app-main .header-nav ul li a {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: var(--main-grey-color);
  font-family: var(--font-family-new);
  text-transform: capitalize;
  position: relative;
  padding: 11px 0;
}

.app-main .header-nav ul li a:hover,
.app-main .header-nav ul li a:focus {
  color: var(--nav-hover-color) !important;
  font-weight: 600;
}

.app-main .header-nav ul li div:hover,
.app-main .header-nav ul li div:focus {
  color: var(--nav-hover-color) !important;
  font-weight: 600;
}

.app-main .header-nav ul li div:hover a,
.app-main .header-nav ul li div:focus {
  color: var(--nav-hover-color) !important;
  font-weight: 600;
}

.app-main .header-nav-no ul li a:hover,
.app-main .header-nav-no ul li a:focus {
  color: var(--nav-hover-color);
}

/* .app-main .header-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: var(--nav-hover-color);;
  transition: all 0.5s ease-in-out;
}

.app-main .header-nav ul li div :after  {
  content: "";
  position: absolute !important;
  bottom: 0 !important;
  left: 0;
  width: 0;
  height: 3px;
  background: var(--nav-hover-color); !important;
  transition: all 0.5s ease-in-out !important;
} */

.app-main .header-nav-no ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  background: var(--main-grey-color);
  transition: all 0.5s ease-in-out;
}


.app-main .header-nav-no ul li a:hover svg path,
.app-main .header-nav-no ul li a:focus svg path {
  fill: #ccc !important;
}

.app-main .header-nav ul li a:hover:after,
.app-main .header-nav ul li a:focus:after {
  width: 100%;

}

.app-main .header-nav ul li div:hover:after,
.app-main .header-nav ul li div:focus:after {
  width: 100% !important;

}

.app-main .header-nav ul li .icon-status {
  top: 28px;
  left: 19px;
}

.app-main .header-nav ul li a svg path {
  transition: all 0.5s ease-in-out;
}

.app-main .header-nav ul li a:hover svg path,
.app-main .header-nav ul li a:focus svg path {
  fill: var(--nav-hover-color) !important;
}

.app-main .header-nav ul li div:hover svg path,
.app-main .header-nav ul li div:focus svg path {
  fill: var(--nav-hover-color);
}

.app-main .header-nav ul li svg {
  display: inline-block;
  vertical-align: middle;
  /* margin-right: 11px; */
  color: var(--main-grey-color) !important;
}

.app-main .account-info {
  padding: 20px;
  cursor: pointer;
}

.app-main .MuiButton-label {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  padding: 0 23px 0 0;
  position: relative;
}

.app-main .MuiButton-label:after {
  content: "";
  position: absolute;
  top: -32px;
  right: 5px;
  bottom: 0;
  background: url("./assets/images/dropdown-arrow.svg") no-repeat center center;
  width: 15px;
  background-size: 15px;
}

.app-main .MuiButton-label {
  align-items: center;
}

.app-main .MuiButton-label img {
  margin-right: 8px;
}

.app-main .MuiButtonBase-root.MuiButton-text {
  padding: 0;
}

.app-main .MuiButton-root:hover,
.app-main .MuiButton-root:focus {
  background-color: transparent !important;
}

.app-main .right-icons {
  height: 31px;
  padding: 4px 20px;
  border-left: 2px solid #dddddd;
  margin-right: 10px;
}

.app-main .right-icons ul {
  margin: 0;
}

.app-main .right-icons ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding-right: 25px;
}

.app-main .right-icons ul li:last-child {
  padding-right: 0;
}

.app-main .right-icons ul li a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.app-main .right-icons ul li a img {
  max-width: 24px;
  display: block;
}

.app-main .icon-status {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background: var(--nav-hover-color);
  border-radius: 100%;
  position: absolute;
  top: -7px;
  right: -6px;
  color: #ffff;
  text-align: center;
  line-height: 13px;
  font-size: 8px;
  border: 1px solid var(--hover-font-color);
  font-weight: 500;
}

.app-main .left-img {
  width: 40px;
}

.app-main .right-content {
  width: calc(100% - 40px);
  padding-left: 14px;
  position: relative;
}

.app-main .right-content p {
  margin: 0;
  font-family: var(--font-family-new);
}

.app-main .right-content p strong {
  font-weight: 500;
}

.app-main .right-content p small {
  color: #cccccc;
  font-size: 14px;
  display: block;
  font-weight: 700;
  font-family: var(--font-family-new);
}

.app-main .noti-time {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  color: #cccccc;
  font-family: var(--font-family-new);
  position: absolute;
  top: 0;
  right: 0;
}

/* .sidebar { overflow: hidden; } */

.app-main .hide-scroll .site-header {
  width: calc(100% - 17px);
}

.app-main .hide-scroll {
  overflow: hidden;
  margin-right: 17px;
}

.app-main .mac_device {
  margin-right: 0;
}

.app-main .mac_device.hide-scroll {
  margin-right: 0;
}

.app-main .mac_device .site-header {
  width: 100%;
}

.app-main .mac_device.hide-scroll .site-header {
  width: 100%;
}

/*  left-sidebar  */

.app-main .left-sidebar {
  background: rgb(10, 92, 127);
  background: -moz-linear-gradient(180deg, rgba(10, 92, 127, 1) 0%, rgba(21, 136, 186, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(10, 92, 127, 1) 0%, rgba(21, 136, 186, 1) 100%);
  background: linear-gradient(180deg, rgba(10, 92, 127, 1) 0%, rgba(21, 136, 186, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0a5c7f", endColorstr="#1588ba", GradientType=1);
  width: 75px;
  padding: 25px 0 0;
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 0;
  top: 85px;
  height: calc(100vh - 85px);
  overflow-y: auto;
  z-index: 10;
}

.app-main .left-sidebar.active {
  left: 0px;
  width: 250px;
}

.app-main .humburger-menu {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 0;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 15px;
}

.app-main .humburger-menu .menu-text {
  text-align: end;
  color: #cccccc;
  font-size: x-small;
  margin-bottom: 2px;
  font-weight: bold;
}

.app-main .humburger-menu span {
  display: block;
  background: #cccccc;
  width: 30px;
  height: 3px;
  margin-bottom: 5px;
  margin-left: auto;
}

.app-main .humburger-menu span:last-child {
  margin-bottom: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content span {
  display: block;
  width: 100%;
}

.app-main .left-sidebar nav {
  overflow: hidden;
  position: relative;
  left: -250px;
  width: 250px;
  transition: all 0.5s ease-in-out;
}

.app-main .left-sidebar.active nav {
  left: 0px;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root a {
  padding-left: 50px;
  border: none;
}

.app-main .left-sidebar ul {
  margin: 0;
}

.app-main .left-sidebar li {
  font-family: var(--font-family-new);
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 16px;
  margin-left: 0px;
}

.app-main .left-sidebar li a,
.app-main .left-sidebar .MuiExpansionPanelSummary-content span {
  display: block;
  width: 100%;
  line-height: 1;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.app-main .left-sidebar li a:hover,
.app-main .left-sidebar li a:focus,
.app-main .left-sidebar .MuiExpansionPanelSummary-content span:hover,
.app-main .left-sidebar .MuiExpansionPanelSummary-content span:focus {
  color: var(--hover-font-color);
  font-weight: 600;
  /* text-decoration: underline; */
}

.app-main .left-sidebar li a:hover,
.app-main .left-sidebar li a:focus,
.app-main sup {
  display: inline-block;
  text-decoration: none;
}

.app-main .left-sidebar li a img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 26px;
  opacity: 0.7;
  transition: all 0.5s ease-in-out;
}

.app-main .left-sidebar li a:hover img,
.app-main .left-sidebar li a:focus img {
  opacity: 1;
}

.app-main .left-sidebar .MuiPaper-root.MuiExpansionPanel-root {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  box-shadow: none;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 1px;
  padding: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0;
  padding: 0;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root {
  padding: 0;
  flex-wrap: wrap;
  /* background: rgba(10, 92, 127, 0.5); */
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content {
  margin: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-root {
  min-height: 1px;
  padding: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-content span {
  position: relative;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root>div {
  width: 100%;
}

.app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
  padding-left: 30px;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
  padding: 0px 0px 0px 45px;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
  position: relative;
  z-index: 1;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:after {
  content: "";
  position: absolute;
  top: -2px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  text-decoration: underline;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:hover:after,
.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:focus:after {
  opacity: 1;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  display: block;
  line-height: 1;
  width: 100%;
  padding: 10px 0px 10px;
  transition: border 0.5s ease-in-out;
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  -moz-transition: border 0.5s ease-in-out;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:hover span,
.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav:focus span {
  border-color: transparent;
  transition: none;
}

.app-main .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
  color: var(--hover-font-color);
  padding: 10px;
}

.app-main .left-sidebar .MuiCollapse-container.MuiCollapse-entered {
  margin-top: 0px;
}

.app-main .left-sidebar .subparent-nav .MuiCollapse-container.MuiCollapse-entered {
  margin-top: 0;
}

.app-main .left-sidebar .MuiExpansionPanelSummary-root.active {
  /* background: #01afb0; */
  color: var(--hover-font-color);
  font-weight: bold;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active {
  /* background: #01afb0; */
  color: var(--hover-font-color);
  font-weight: bold;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active:after {
  opacity: 1;
}

.app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav.active span {
  border-color: transparent;
  transition: none;
}

/*     Resource     */

.app-main .main_tab_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: white;
  padding: 40px 40px 0px;

}

.app-main .box-feed-desc {
  white-space: break-spaces;
  overflow-y: scroll;
  max-height: 150px;
  text-overflow: ellipsis;
  margin: 10px 5px 0;
}

.app-main .box-feed-desc::-webkit-scrollbar {
  display: none;
}

.app-main .content-box-feed {
  background: var(--hover-font-color);
  padding: 5px;
  margin-right: 1.6%;
  border: 2px solid #ebebeb;
  border-radius: 7px;
  position: relative;
  margin-bottom: 35px;
  width: 250px;
  /* height: 300px; */
}

.app-main .content-box {
  background: var(--hover-font-color);
  margin-right: 2.6%;
  border: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 35px;
  width: 255px;
  /* height: 300px; */
}

.app-main .landing-page-content-box {
  width: 205px;
  margin-bottom: 25px;
  margin-right: 1%;
  border-radius: 10px 10px 0 0
}

.app-main .content-box-calendar {
  /* background: var(--hover-font-color); */
  /* border: 1px solid #ebebeb; */
  position: relative;
  margin-bottom: 5px;
  /* width: 110px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.app-main .two-col-sec-calendar {
  background: #fcfcfc;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}

.app-main .free-asset-content-box {
  height: 315px;
  /* width: 250px; */
  align-items: center;
  justify-content: center;
}

.app-main .one-col-content {
  background: var(--hover-font-color);
  border: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.app-main .one-col-content-box {
  background: var(--hover-font-color);
  border: 2px solid var(--dark-blue-color);
  position: relative;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.app-main .content-box:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-main .content-box-calendar:after {
  content: "";
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.app-main .content-box:hover:after,
.app-main .content-box:focus:after {
  opacity: 1;
}

.app-main .content-box-calendar:hover:after,
.app-main .content-box-calendar:focus:after {
  opacity: 1;
}

.app-main .content-box:nth-child(3n) {
  margin-right: 2.6%;
}

.app-main .ecp_resource .tab-listing ul li {
  padding-right: 30px;
}

.app-main .box-feed-image {
  background-repeat: no-repeat;
  border-bottom: 1px solid #ebebeb;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  height: 230px;
  display: grid;
  align-content: space-around;
  align-items: center;
}

.app-main .box-image {
  background-repeat: no-repeat;
  border-bottom: 1px solid #ebebeb;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  padding: 17% 15px;
  height: 300px;
  display: grid;
  align-content: space-evenly;
  align-items: center;
}

.app-main .landing-page-box-image {
  background-repeat: no-repeat !important;
  background-origin: padding-box !important;
  height: 200px !important;
  border: 4px solid #000000 !important;
  border-radius: 10px !important;
}

.app-main .free-asset-image-box {
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: cover;
  height: 255px;
  position: relative;
}

.app-main .box-image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  background: #0a0a0a;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.app-main .button-hover {
  opacity: 0;
}

.app-main .content-box:hover .box-image:after,
.app-main .content-box:focus .box-image:after {
  opacity: 0.7;
}

.app-main .content-box:hover .button-hover,
.app-main .content-box:focus .button-hover {
  opacity: 1;
}

.app-main .box-image-calendar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  background: #0a0a0a;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  /* z-index: -1; */
}

.app-main .content-box-calendar:hover .box-image-calendar:after,
.app-main .content-box-calendar:focus .box-image-calendar:after {
  opacity: 0.7;
}

.app-main .content-box-calendar:hover .button-hover,
.app-main .content-box-calendar:focus .button-hover {
  opacity: 1;
}

.app-main .hover-button-calendar {
  padding: 65% 1% 1% 1%;
}

.app-main .calendar-image-box {
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: 160px;
  height: 165px;
  width: 165px;
  /* position: relative; */
  display: grid;
  justify-items: center;
}

.app-main .calendar-hover-button {
  font-weight: 500;
  color: var(--white-color);
  border-radius: 50px;
  min-width: 100px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-family: var(--font-family-new);
  background: transparent;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 14px;
  -webkit-appearance: none;
  cursor: pointer;
}

.app-main .calendar-grid-main {
  /* display: grid;
  height: 1225px;
  grid-template-columns: 13.95% 13.93% 13.91% 13.89% 13.95% 13.95% 13.91%;
  grid-template-rows: 200px 200px 200px 200px 200px 200px;
  grid-gap: 5px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.app-main .box-content-info p {
  color: rgba(100, 100, 100, 0.6);
  font-family: var(--font-family-new);
  margin-bottom: 5px;
}

.app-main .box-content-info h4 {
  margin: 0 0 0px;
  white-space: pre;
  max-height: 50px;
  overflow: clip;
}

.app-main .box-label-feed {
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.app-main .box-lable {
  font-size: 16px;
}

.app-main .box-content-info {
  padding: 20px 20px 22px;
}

.app-main .box-content-info p:last-child {
  margin-bottom: 0;
}

.app-main .box-image .button-field {
  display: table;
  margin: 0 auto 15px;
}

.app-main .box-image .button-field:last-child {
  margin-bottom: 0;
}

.app-main .MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.5) !important;
}

/*        Clinic       */

.app-main .tab-listing ul {
  margin: 0 0 -19px 0;
}

.app-main .tab-listing ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: var(--main-grey-color);
  font-weight: 500;
  font-size: 18px;
  font-family: var(--font-family-new);
  text-transform: uppercase;
  padding-right: 30px;
}

.app-main .MuiTableRow-root .MuiTableCell-head.center {
  text-align: center;
}

.app-main .tab-content {
  padding: 0px 0 0;
}

.app-main .tab-content .form-main {
  margin-top: 0;
}

.app-main .tab-listing {
  padding: 0 0 20px 0;
}

.app-main .tab-listing ul li a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  padding-bottom: 10px;
  padding-right: 0;
}

.app-main .tab-listing ul li a:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 3px;
  background: var(--blue-color);
  width: 0;
  transition: all 0.5s ease-in-out;
}

.app-main .tab-listing ul li a:hover,
.app-main .tab-listing ul li a:focus,
.app-main .tab-listing ul .active {
  color: var(--blue-color);
  font-weight: 700;
}

.app-main .tab-listing ul .active:after,
.app-main .tab-listing ul li a:hover:after,
.app-main .tab-listing ul li a:focus:after {
  width: 100%;
}

.app-main .tab-content .field-left {
  width: 23%;
}

.app-main .tab-content .field-right {
  width: 77%;
}

.app-main .tab-content .field-right-image {
  width: 100%;
}

.app-main .tab-content .form-main form {
  max-width: 843px;
}

.app-main .table-link a {
  color: var(--dark-blue-color);
  text-decoration: underline;
}

.app-main .table-main-wrap {
  text-align: right;
}

.app-main .tab-listing+.table-main-wrap {
  margin: 0;
}

.app-main .table-link {
  padding-bottom: 20px;
}

.app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
  min-width: 80px;
}

.app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 200px;
}

.app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 175px;
}

.MuiTableRow-root .MuiTableCell-root:last-child {
  min-width: 105px;
}

.app-main .MuiTableRow-root th.MuiTableCell-root {
  font-weight: 700;
}

.app-main .data-popup-box {
  min-width: 1120px;
  max-width: 1120px;
  margin: 160px 0;
}

.app-main .data-popup-box .box-heading {
  padding: 38px 15px;
}

.app-main .data-popup-box .box-heading .close-btn img {
  padding: 0;
  width: 20px;
}

.app-main .data-popup-box .close-btn {
  top: 30px;
  right: 24px;
}

.app-main .data-popup-box .box-content form {
  max-width: 845px;
}

.app-main .data-popup-box .box-content {
  padding: 50px 40px;
}

.app-main .data-popup-box .box-content form .field-right {
  width: 77%;
}

.app-main .data-popup-box .box-content form .field-left {
  width: 23%;
}

.app-main .data-popup-box .form-submit {
  justify-content: center;
}

.app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  min-width: 133px;
}

.app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
  min-width: 135px;
}

.app-main .MuiTableRow-root th.MuiTableCell-root {
  border-bottom-color: transparent;
  border-bottom-width: 0;
}

.app-main .tab-listing .slick-next {
  position: absolute;
  right: 0;
  top: -15px;
  bottom: 0;
  height: auto;
  margin: 0;
  transform: none;
  display: none !important;
}

.app-main .tab-listing .slick-next:before {
  display: none;
}

.app-main .tab-listing .slick-next:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url("./assets/images/right-arrow.svg");
  background-repeat: no-repeat;
  background-position: center right 30px;
  width: 80px;
  background-color: var(--white-background-color);
  background-size: 7px;
  display: none;
}

.app-main .tab-listing .slick-next.slick-disabled {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.app-main .tab-listing .slick-prev {
  display: none !important;
}

.app-main .tab-listing .slick-slide {
  /* width: auto !important; */
  padding-right: 13px;
}

.app-main .tab-listing .slick-track {
  margin-left: 0;
  display: flex;
  opacity: 20 !important;
  transform: translate3d(0px, 0px, 0px) !important;
  width: auto !important;
}

.app-main .content-heading-dropdown {
  direction: ltr;
  justify-content: flex-end;
  /* align-items: flex-end; */
  display: flex;
  margin-top: 5%;
  width: 48.5%;
}

/*        product-selection        */

.app-main .three-block-sec {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-right: 4px;
  /* padding-left: 65px; */
}

.app-main .block-col {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
  border-radius: 8px;
  width: 25%;

  margin-right: 2.05%;
  margin-bottom: 2.05%;
  text-align: center;
}

.app-main .block-col:nth-child(3n) {
  margin-right: 0;
}

.app-main .hover_link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 15px 15px;
}

.app-main .hover_link:hover,
.app-main .hover_link:focus {
  background: #01afb0;
  color: var(--hover-font-color);
  border-radius: 8px;
}

.app-main .block-col p {
  margin: 0 0 7px;
  font-size: 13px;
  white-space: pre;
  font-family: var(--font-family-new);
  color: rgba(100, 100, 100, 0.6);
  letter-spacing: 0.5px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  height: 60px;
}

.app-main .hover_link:hover p,
.app-main .hover_link:focus p {
  color: rgba(255, 255, 255, 0.7);
}

.app-main .block-col img {
  display: block;
  margin: 0 auto 0px;
  width: 80px;
  /* height: 75px; */
  padding: 10px;
}

.app-main .block-col h2 {
  font-weight: 500;
  margin: 0;
  font-size: 25px;
  bottom: 0;
}

.app-main .block-col.active .hover_link {
  background: #01afb0;
  color: var(--hover-font-color);
}

.app-main .block-col.active .hover_link p {
  color: rgba(255, 255, 255, 0.7);
}

.app-main .DraftEditor-editorContainer {
  /* height: 280px !important; */
  max-height: 280px !important;
  overflow: auto, im !important;
}

.app-main .two-col-sec {
  background: var(--hover-font-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}

.app-main .two-col-sec p {
  font-size: 15px;
  letter-spacing: 0;
  font-family: var(--font-family-new);
  line-height: 1.4;
  margin-bottom: 15px;
}

.app-main .textparagraph {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  font-family: var(--font-family-new);
  line-height: 2;
  color: #334455;
}

.app-main .textheading {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--font-family-new);
  line-height: 2;
  color: #334455;
}

.app-main .textlist {
  letter-spacing: 0;
  line-height: 0;
}

.app-main .two-col-sec p:last-child {
  margin-bottom: 0;
}

.app-main .sec-left {
  width: 62%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 0px;
}

.app-main .sec-right {
  align-self: flex-start;
  width: 38%;
  /* padding: 10px 0 0 50px; */
}

.app-main .sec-right-calender {
  align-self: flex-start;
  width: 38%;
  /* padding: 10px 0 0 50px; */
}

.app-main .sec-right img {
  display: block;
  margin: 0 auto;
}

.app-main .two-col-sec h3 {
  font-family: var(--font-family-new);
  font-weight: 400;
  font-size: 19px;
}

.app-main .confirmation-message {
  font-size: 27px;
  text-align: center;
}

.app-main .two-col-sec p+h3 {
  margin-top: 56px;
  margin-bottom: 21px;
}

.app-main .two-col-sec li {
  /* font-size: 24px; */
}

.app-main .two-col-sec ul {
  margin: -10 0 50px;
}

.app-main .two-col-sec ul li {
  list-style: none;
  display: block;
  position: relative;
  padding-left: 26px;
  line-height: 1.4;
  /* margin-bottom: 20px; */
  font-size: 15px;
  text-align: justify;
}

.app-main .two-col-sec ul li:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 1px;
  width: 5px;
  height: 5px;
  background: #4b4c4c;
  border-radius: 100%;
}

.app-main .detail-welcome {
  margin-top: 28px;
  padding-bottom: 12px;
  margin-bottom: 40px;
}

.app-main .detail-welcome .sec-right {
  padding-top: 9px;
}

.app-main .full-field label {
  display: block;
  margin-bottom: 15px;
}

.app-main .full-field {
  margin-bottom: 2px;
}

.app-main .full-field p small {
  font-size: 14px;
  color: #55555580;
}

.app-main .full-field p {
  line-height: 1;
  margin-top: 22px;
}

.app-main .blue-link {
  text-align: center;
  margin-top: 40px;
}

.app-main .blue-link a {
  color: var(--dark-blue-color);
  font-size: 24px;
  text-decoration: underline;
  display: inline-block;
  vertical-align: middle;
}

.app-main .blue-link a:hover,
.app-main .blue-link a:focus {
  text-decoration: none;
}

.app-main .full-field.budget_detail_field {
  max-width: 650px;
}

/*        adbreak         */

.app-main .page-img .next-step-btn {
  margin-top: 30px;
}

.app-main .page-img .back-btn {
  margin-top: 30px;
}

.app-main .full-page-wrap h1 {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 169px);
}

.app-main .page-img {
  margin-top: 15px;
}

.app-main .white-box-wrapper {
  padding-top: 57px;
}

.app-main .white-box-content {
  background: var(--hover-font-color);
  padding: 40px;
  margin-top: 15px;
}

.app-main .step-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  counter-reset: section;
  margin-top: 48px;
  margin-bottom: 10px;
}

.app-main .step-col h3 {
  line-height: 1.25;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: #ccc;
}

.app-main .step-col h3 span {
  display: block;
}

.app-main .right-continue-btn {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}

.app-main .step-col {
  position: relative;
  counter-increment: section;
  max-width: 90px;
  margin: 0 5.3%;
}

.app-main .step-col:first-child {
  margin-left: 0;
}

.app-main .step-col:last-child {
  margin-right: 0;
}

.app-main .step-col:after {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  width: 30px;
  height: 30px;
  color: #ccc;
  border-radius: 100%;
  margin: 0 auto;
  /* content: counter(section); */
  text-align: center;
  background: #ebebeb;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.app-main .active-step:after {
  content: "";
  background-image: url("./assets/images/blue-check-ic.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.app-main .active-step:before {
  background-color: var(--dark-blue-color) !important;
}

.app-main .active-step h3 {
  color: var(--dark-blue-color);
}

.app-main .step-col.active:after {
  background: var(--dark-blue-color);
  color: #ffffff;
}

.app-main .active-step h3,
.app-main .step-col.active h3 {
  color: var(--dark-blue-color);
}

.app-main .white-box-image {
  text-align: center;
  margin-top: 39px;
  margin-bottom: 37px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 38px 0 46px;
  align-items: flex-start;
}

.app-main .white-box-image img {
  display: block;
  margin: 0 auto;
}

.app-main .step-col:before {
  content: "";
  height: 3px;
  position: absolute;
  top: -36px;
  margin: auto 0;
  background: #ebebeb;
  width: 260px;
  left: 61%;
}

.app-main .step-col:last-child:before {
  display: none;
}

.app-main .content_col_557 {
  max-width: 559px;
}

.app-main .content_img_col {
  border: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
}

.app-main .content_col_small {
  margin-right: 4.5%;
  width: 12.32%;
}

.app-main .content_col_middle {
  margin-right: 4.5%;
  width: 22.95%;
}

.app-main .content_col_large {
  width: 55.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
}

.app-main .content_img_row {
  margin-bottom: 36px;
  margin-right: 13.2%;
  display: flex;
  flex-wrap: wrap;
}

.app-main .content_img_row1 {
  border: 1px solid #ebebeb;
  display: flex;
  flex-wrap: wrap;
}

.app-main .row-300-width {
  width: 41.35%;
}

.app-main .row-250-width {
  width: 34.51%;
  margin-right: 0;
}

.app-main .content_img_row:first-child,
.app-main .content_img_row:last-child {
  width: 100%;
  margin-right: 0;
}

.app-main .content-info h6 {
  color: #ccc;
  margin-bottom: 0px;
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  text-align: left;
  font-size: 18px;
}

.app-main .content_col_small .content-info h6 {
  margin-bottom: 7px;
  width: 100%;
  font-size: 16px;
}

.app-main .content-info p {
  color: #ccc;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  text-align: right;
  font-size: 18px;
}

.app-main .content_col_small .content-info p {
  width: 100%;
  text-align: left;
}

.app-main .image_content {
  width: 100%;
}

.app-main .content-info {
  padding: 5px 10px;
  margin-top: auto;
  width: 100%;
}

.app-main .row-320-width {
  max-width: 44.1%;
}

.app-main .content_img_col:only-child {
  margin: 0 auto;
}

.app-main .image_content img {
  width: 100%;
}

.app-main .cart-list-content {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1%;
}

.app-main .cart-label-heading {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-main .cart-label-subdata {
  color: #0A0A0A;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-main .cart-label-location {
  color: var(--main-grey-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.app-main .cart-label-subdata-link {
  color: var(--dark-blue-color);
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 5px;
}

.app-main .cart-price-main {
  width: 100%;
  margin-top: 1%;
  align-items: center;
}

.app-main .cart-art-proof-link-icon {
  color: var(--dark-blue-color);
  font-size: 13px;
  text-align: center;
}

.app-main .cart-art-proof-link {
  color: var(--dark-blue-color);
  font-size: 13px;
  cursor: pointer;
}

.app-main .cart-total-div {
  margin-top: 2%;
  justify-content: space-between;
  display: flex;
}

.app-main .cart-total-div-button {
  justify-content: end;
}

.app-main .cart-subtotal-text {
  color: var(--main-black-color);
  font-size: 18px;
  font-weight: 700;
}

.app-main .cart-fund-text {
  color: var(--main-black-color);
  font-size: 14px;
  font-weight: 400;
}

.app-main .cart-fund-amount {
  color: var(--main-black-color);
  font-size: 14px;
  font-weight: 700;
}

.app-main .cart-total-text {
  color: var(--dark-blue-color);
  font-size: 18px;
  font-weight: 700;
}

.app-main .cart-terms-conditions {
  color: var(--dark-blue-color);
  font-size: 14px;
  font-weight: 400;
  text-align: end;
  text-decoration-line: underline;
  cursor: pointer;
}

.app-main .check-input-coop {
  accent-color: var(--dark-blue-color);
  height: 18px;
  width: 18px;
}

.app-main .red-input {
  accent-color: var(--dark-blue-color);
}

.app-main .Suggetion-wrap {
  position: absolute;
  top: 60;
  right: 0;
  left: 0;
  z-index: 10;
  border: 2px solid var(--main-blue-color);
  border-radius: 10px;
  padding: 10px;
  color: var(--main-grey-color);
  background-color: var(--hover-font-color);
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-main .DirectMail-file-label {
  color: var(--dark-blue-color);
}

.app-main .DirectMail-download-file-label {
  color: var(--dark-blue-color);
  display: flex;
  justify-content: end;
  margin-bottom: 2%;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.app-main .arrow-popup .submenu {
  margin: 0 0 0 200px;
}

.app-main .arrow-popup.notification h6 {
  font-size: 16px;
  color: var(--dark-blue-color);
  font-weight: 700;
  font-family: var(--font-family-new);
  margin: 0;
  border-bottom: 1px solid var(--white-background-color);
  padding: 23px 16px 19px;
}

.app-main .logo-icon-location {
  border: 2px solid var(--border-color);
  border-radius: 5px;
  padding: 2px;
  margin: 0 10px 2% 0;
  height: 24px;
}

.MuiOutlinedInput-multiline {
  padding: 0px !important;
}

.app-main .dotted-border-list {
  display: flex;
  width: 60%;
  border-bottom: 4px dotted rgb(135, 214, 238);
  color: rgb(15, 86, 140);
  padding: 3% 0px;
}

.app-main .dotted-border-main {
  width: 99%;
  border-top: 4px dotted #87d6ee;
  border-bottom: 4px dotted #87d6ee;
  margin: 2% 0px 2%;
  padding: 1% 0px;
  text-align: center;
  display: grid;
}

.app-main .dotted-border-main label {
  font-size: 23px;
  font-weight: 700;
  color: #0f568c;
}

.app-main .field-right-img {
  position: absolute;
  width: 50px;
  align-items: center;
  height: 91%;
  margin: 2px 3px 0 0;
  justify-content: center;
  right: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-image: url("../src/assets/images/icon/downwardarrow.png");
  background-position: center;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-color: var(--white-background-color);
  z-index: 1;
  display: flex;
}

.app-main .field-right-text {
  position: absolute;
  width: 50px;
  align-items: center;
  height: 100%;
  justify-content: center;
  right: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #0f568c;
  z-index: 1;
  display: flex;
  color: rgb(255, 255, 255);
  font-size: 25px;
}

.app-main .calculator-main-tabView {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 12px;
  text-align: center;
  border: 1px solid #2d88d7;
  border-radius: 5px;
  margin-left: 15px;
}

.app-main .calculator-tabView-active {
  width: 100%;
  background: #2d88d7;
  padding: 3px 0px;
  border-radius: 3px;
  color: var(--hover-font-color);
  font-weight: 600;
}

.app-main .calculator-tabView-deActive {
  width: 100%;
  background: var(--hover-font-color);
  padding: 3px 0px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.app-main .location-red-main-toggle {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 12px;
  text-align: center;
  border: 1px solid #ff0000;
  border-radius: 5px;
  margin-left: 15px;
}

.app-main .location-red-toggle-active {
  width: 100%;
  background: #ff0000;
  padding: 3px 0px;
  border-radius: 3px;
  color: var(--hover-font-color);
  font-weight: 600;
}

.app-main .location-red-toggle-deActive {
  width: 100%;
  background: var(--hover-font-color);
  color: #ff0000;
  padding: 3px 0px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.app-main .library-list-main {
  margin-top: 5px;
  padding: 15px 5px;
  display: grid;
  max-height: 675px;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-columns: 210px 210px;
  grid-gap: 4px 6px;
  box-shadow: none;
}

.app-main .flagged-icons-main {
  position: absolute;
  right: 5px;
  bottom: 0px;
  padding-left: 5px;
  background: var(--white-color);
}

.app-main .email-send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px !important;
  min-width: 100px !important;
  width: 100px !important;
}

.my-facebook-button-class {
  background: #4267b2 !important;
  border-radius: 5px !important;
  height: 40px !important;
  text-align: center !important;
  width: 200px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.my-instagram-button-class {
  background: #f09433 !important;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;

  border-radius: 5px !important;
  color: white !important;
  height: 40px !important;
  text-align: center !important;
  width: 200px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* oder card css  dashboard */


.app-main .react-google-flight-datepicker .icon-calendar {
  width: 0px;
  height: 0px;
  margin-right: 0px;
}

.app-main .react-google-flight-datepicker .date-picker-input svg {
  min-width: 0px;
  min-height: 0px;
}

/* .app-main .react-google-flight-datepicker {
  width: 310px;
} */

.app-main .react-google-flight-datepicker .dialog-header .date-picker-input {
  width: 60% !important;
}

.react-google-flight-datepicker .change-date-group .change-date-button {
  min-width: 5px;
}

.react-google-flight-datepicker .dialog-date-picker {
  top: 60px !important;
  left: -500px !important;
  z-index: 99 !important;
}

.react-google-flight-datepicker .change-date-group {
  width: 40px !important;
}

.content-right-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.content-right-data {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.report-select-box {
  width: 40%;
  margin: 0px 5px;
}

.location-select-box {
  width: 23%;
  margin: 0 10px;
}

.date-piker {
  padding: 0px !important;
  margin-left: 5px;
}

.react-google-flight-datepicker .date-picker-input {
  height: 39px !important;
}

.card-main {
  display: flex;
  justify-content: space-between;
  overflow: hidden !important;
  margin: 0px 5px;
}

.paid-card {
  width: 32%;
}

.card-item {
  background-color: var(--white-color);
  padding: 15px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.card-item:hover {
  background-color: #00010110;
}

.paid-chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paid-chart-inner {
  width: 70%;
}

.paid-chart-data {
  width: 100% !important;
}

.paid-chart-tabbedCarts {
  width: 30%;
}

.social-chart-main {
  display: flex;
  justify-content: flex-start;
}













.app-main .login-header-gradient-line {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
  padding: 17px;
  width: 100%
}

.app-main .login-logo-new {
  display: flex;
  padding: 10px 13%;
  margin-bottom: 0px;
  width: 100%
}

.app-main .login-page-background {
  border-top: 1px solid #3f90db21;
  background-image: url("../src/assets/images/background.png");
  width: 100%;
  position: relative;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover
}

.app-main .login-header-text {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 5%
}

.app-main .login-text-h2 {
  margin: 15px 0 0 0;
  color: #16416F;
  font-family: var(--font-family-new);
  font-weight: 700;
  font-size: 36px
}

.app-main .login-text-h3 {
  font-family: var(--font-family-new);
  font-weight: 600;
  font-size: 25px;
}

.app-main .login-text-h5 {
  margin: 0;
  color: #16416F;
  font-family: var(--font-family-new);
  font-weight: 500;
  font-size: 20px
}

.app-main .login-footer-gradient-line {
  background: linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  width: 100%;
}

.app-main .login-footer-text {
  margin: 0px;
  color: #e5e1e1;
  font-size: 10px;
}

.app-main .free-asset-main-content-box {
  background-color: var(--white-color);
  margin-top: 8%;
}

.app-main .free-asset-tile-heading {
  color: #16416f;
  font-size: 23px;
  font-weight: bold;
  margin: 0px 25px;
  font-family: var(--font-family-new),
}























































@media (min-width: 1720px) {
  .app-main .main {
    max-width: 1240px;
    margin: 0 auto;
  }
}

@media (max-width: 1700px) {
  .app-main .step-col {
    margin: 0 3.5%;
  }

  .app-main .step-col:before {
    width: 175px;
  }

  .app-main .white-box-image {
    padding: 0;
  }

  /* .card-item {
    width: 22vw;
  } */
}

@media (min-width: 1501px) and (max-height: 800px) {
  .app-main .login_page {

    height: calc(100vh);
  }

  .app-main .col-head {
    padding: 0 20px;
  }

  .app-main .inner-screen {
    min-height: 100vh;
  }
}

@media (max-width: 1500px) {
  .app-main .login_page {}

  .app-main .login_page .main {
    min-height: calc(100vh - 80px);
  }

  .app-main .left-column {
    padding: 40px;
  }

  .select-style {
    width: 12%;
  }

  .app-main .main {
    /* width: 100%; */

    margin: 0 auto;
  }

  .app-main .right-column {
    padding: 60px 20px 40px;
  }

  .app-main .col-head {
    padding: 0;
  }

  .app-main .col-head p {
    padding: 0 35px;
    font-size: 18px;
  }

  .app-main .col-head h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  .app-main .image-item {
    padding: 0px 32px 100px;
  }

  .app-main h1,
  .app-main .h1 {
    font-size: 40px;
  }

  .app-main h2 {
    font-size: 32px;
  }

  /* 1500pxx */
  .app-main .confirmation-message {
    font-size: 22px;
    text-align: center;
  }

  .app-main .box-heading {
    padding: 15px 0px;
  }

  .app-main .content-right-main {
    /* padding: 18px 25px; */
  }

  .app-main .main-sec-wrap.active-main {
    padding-left: 250px;
  }

  .app-main .content-right-heading {
    margin: 0 0 10px;
  }

  .app-main .content-right-heading-adbilder {
    margin-top: -5px;
  }

  .app-main .content-heading-view {
    margin-top: 17px;
  }

  .app-main .content-heading-view-adbilder {
    margin-top: 20px;
  }

  .app-main .right-icons ul li {
    padding-right: 15px;
  }

  .app-main .right-icons ul li img {
    max-width: 28px;
    max-height: 23px;
  }

  .app-main .header-nav ul li {
    padding-right: 50px;
  }

  .app-main .MuiButton-label img {
    max-width: 50px;
  }

  /*     Resource    1500px */
  .app-main .ecp_resource .tab-listing ul li {
    padding-right: 25px;
    font-size: 16px;
  }

  .app-main .ecp_resource .tab-listing ul li:last-child {
    padding-right: 0;
  }

  .app-main .main_tab_content {
    padding: 30px 30px 0px;
  }

  .app-main .box-image .button-field {
    margin-bottom: 10px;
  }

  .app-main .box-image {
    padding: 25% 15px;
    height: 300px;
  }

  .app-main .free-asset-image-box {
    height: 255px;
  }

  .app-main .box-content-info {
    padding: 10px 10px;
  }

  .app-main .box-content-info-calendar {
    padding: 0px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 100px; */
  }

  /*    clinic     */
  .app-main .tab-listing ul li {
    font-size: 16px;
    padding-right: 18px;
  }

  .app-main .form-main {
    padding: 45px 40px;
  }

  .app-main .tab-listing .slick-slide {
    padding: 0 13px 10px 0;
  }

  /*      product-selection     1500px */
  .app-main .three-block-sec {
    padding-right: 0;
  }

  .app-main .hover_link {
    padding: 10px 10px;
  }

  .app-main .block-col h2 {
    font-size: 23px;
  }

  .app-main .block-col p {
    font-size: 14px;
  }

  .app-main .two-col-sec p {
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 1.4;
  }

  .app-main .sec-left {
    width: 61%;
  }

  .app-main .sec-right {
    width: 38.5%;
  }

  .app-main .sec-right-calender {
    width: 42.5%;
  }

  .app-main .two-col-sec ul li,
  .app-main .blue-link a,
  .app-main .two-col-sec h3 {
    font-size: 15px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 40px;
  }

  .app-main .blue-link {
    margin-top: 30px;
  }

  /*          adbreak       1500px */
  .app-main .step-col h3 {
    font-size: 16px;
    width: 80px;
  }

  .app-main .step-col:before {
    width: 146px;
  }

  .app-main .white-box-content {
    padding: 25px 25px;
  }

  .app-main .white-box-image {
    padding: 0;
  }

  .app-main .content_img_row {
    margin-bottom: 20px;
  }

  .app-main .content_img_row {
    margin-right: 10%;
  }

  .app-main .row-300-width {
    width: 44%;
  }

  .app-main .row-250-width {
    width: 42%;
    margin-right: 0;
  }

  .app-main .content-info h6 {
    font-size: 14px;
    width: 65%;
  }

  .app-main .content-info p {
    font-size: 15px;
    width: 35%;
  }

  .app-main .content-info {
    padding: 5px 10px;
  }

  .app-main .content_col_small {
    width: 13%;
    margin-right: 2%;
  }

  .app-main .content_col_middle {
    width: 25%;
    margin-right: 2%;
  }

  .app-main .content_img_col.content_col_large {
    width: 58%;
  }

  .app-main .content_col_small .content-info h6 {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .app-main .content_col_small .content-info p {
    font-size: 13px;
  }

  .app-main .image-slider {
    /* height: 525px; */
  }

  .app-main .hover-button-calendar {
    padding: 60% 3% 1% 1%;
  }

  /* calendar 1500px*/
  .app-main .calendar-image-box {
    background-size: 155px;
    width: 160px;
    height: 160px;
  }

  .app-main .free-asset-main-content-box {
    margin-top: 10%;
  }

  .app-main .login-text-h2 {
    margin: 0;
  }
}



@media (max-width: 1366px) {
  .app-main .main-sec-wrap {
    padding-top: 73px;
  }

  .app-main .header-left {
    padding: 10px 20px 10px 10px;
  }

  .app-main .header-nav ul li {
    padding-right: 40px;
  }

  .app-main .right-icons {
    padding: 4px 15px 4px;
  }

  .app-main .account-info {
    padding: 0px 0px;
  }

  .app-main .header-nav ul li a {
    padding: 11px 0;
  }

  .app-main #simple-popover .MuiPaper-root.MuiPopover-paper {
    top: 51px !important;
    margin-left: 0px;

  }

  .app-main #theme-popover .MuiPaper-root.MuiPopover-paper {
    top: 172px !important;
    margin-left: 15px;
  }

  .app-main #location-popover .MuiPaper-root.MuiPopover-paper {
    top: 172px !important;
    margin-left: 15px;
  }

  .app-main .left-sidebar {
    height: calc(100vh - 90px);
    top: 90px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 45px;
  }

  .app-main .sec-left {
    padding-right: 20px;
    padding-left: 0;
    padding-top: 0;
  }

  /* .app-main .two-col-sec ul li,
.app-main .blue-link a,
.app-main .two-col-sec h3 {
  font-size: 15px;
  line-height: 1.4 !important;
  margin: 0;
} */
}

@media (max-width: 1199px) {
  .app-main .app-main .login_page {}

  .app-main .app-main .login_page .main {
    min-height: calc(100vh - 100px);
  }

  .app-main .image-slider {
    /* height: 475px; */
  }

  .app-main .left-column {
    padding: 40px 40px;
  }

  .app-main .left-column h1 {
    margin-bottom: 20px;
  }

  .app-main .logo {
    margin-bottom: 40px;
  }

  .app-main .logo img {
    height: 50px;
  }

  .app-main .user-field {
    background-size: 15px;
    background-position: center right 15px;
  }

  .app-main .pwd-field {
    background-size: 13px;
    background-position: center right 15px;
  }

  .app-main .bottom-links {
    padding-top: 30px;
    margin-left: 0;
  }

  .app-main .right-column {
    padding: 40px 30px;
  }

  .app-main .col-head p {
    font-size: 20px;
    padding: 0;
  }

  .app-main .image-item {
    padding: 10px 20px 80px;
  }

  .app-main .error-msg {
    font-size: 14px;
    padding-right: 35px;
  }

  .app-main .error-msg:after {
    background-size: 15px;
    width: 15px;
    height: 15px;
    right: 15px;
  }

  .app-main .col-head h2 {
    font-size: 26px;
  }

  .app-main .button-field a {
    font-size: 16px;
    line-height: 18px;
    min-width: 150px;
    padding: 14px 20px;
  }

  .app-main .button-field.button-field-country {
    margin-top: -4px;
  }

  .app-main .form-submit .button-field input {
    min-width: 175px;
  }

  .app-main .form-field {
    margin-bottom: 20px;
  }

  .app-main .form-field label {
    margin-bottom: 10px;
  }

  .app-main h1,
  .app-main .h1 {
    font-size: 38px;
  }

  .app-main h2 {
    font-size: 34px;
  }

  /* 1199pxx */
  .app-main .confirmation-message {
    font-size: 25px;
    text-align: center;
  }

  .app-main input[type="date"],
  .app-main input[type="email"],
  .app-main input[type="password"],
  .app-main input[type="search"],
  .app-main input[type="tel"],
  .app-main input[type="text"],
  .app-main select,
  .app-main textarea {
    padding: 15px;
  }

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    padding: 17px 25px 17px;
    font-size: 16px;
    line-height: 5px;
  }

  .app-main .popup-box {
    min-width: 530px;
    max-width: 60px;
  }

  .app-main .box-heading img {
    margin-right: 20px;
  }

  .app-main .box-content p {
    font-size: 20px;
    margin-bottom: 17px;
  }

  .app-main .multiple-field {
    margin-top: 15px;
  }

  .app-main .box-content {
    padding: 20px 80px;
  }

  .app-main .box-content form {
    max-width: 100%;
  }

  .app-main .main-sec-wrap.active-main {
    padding-left: 250px;
  }

  .app-main .content-right-main {
    padding: 40px 25px;
  }

  .app-main .content-right-heading {
    font-size: 19pt;
    margin-top: -5px;
  }

  .app-main .content-right-heading-adbilder {
    font-size: 19pt;
    margin-top: -15px;
  }

  .app-main .content-heading-view {
    margin-top: 0px;
  }

  .app-main .content-heading-dropdown {
    margin-top: -1px;
  }

  .app-main .theme-option {
    font-size: 10pt;
  }

  .app-main .site-logo {
    padding-left: 10px;
  }

  .app-main .site-logo a {
    max-width: 110px;
    width: 95px;
  }

  .app-main .MuiButton-label {
    font-size: 16px;
  }

  /* 1199px */
  .app-main .right-content p {
    font-size: 14px;
  }

  .app-main .header-nav ul li a {
    font-size: 16px;
  }

  .app-main .header-nav ul li svg {
    margin-right: 5px;
    max-width: 20px;
  }

  .app-main .header-nav ul li {
    padding-right: 22px;
  }

  .app-main .right-icons {
    padding: 1px 0px 0 8px;
  }

  .app-main .right-icons:last-child {
    padding: 0;
    margin-right: 10px;
  }

  .app-main .account-info {
    padding: 0px 0px;
  }

  .app-main .header-nav {
    padding-right: 10px;
  }

  .app-main .header-left {
    padding: 0px;
    width: 10%;
  }

  .app-main .header-right {
    width: 90%;
  }

  .app-main .header-nav ul li .icon-status {
    left: 15px;
  }

  .app-main .MuiButton-label img {
    max-width: 38px;
  }

  .app-main .left-sidebar {
    top: 91px;
    height: calc(100vh - 90px);
  }

  .app-main .left-sidebar li {
    font-size: 16px;
  }

  .app-main .left-sidebar li a {
    padding: 16px 30px;
  }

  .app-main .left-sidebar li a img {
    margin-right: 20px;
    max-width: 20px;
    margin-top: -3px;
  }

  .app-main .subparent-nav {
    margin-bottom: 10px;
  }

  .app-main .left-sidebar .subparent-nav .MuiCollapse-container.MuiCollapse-entered {
    margin-top: 0;
  }

  .app-main .left-sidebar.active {
    width: 250px;
  }

  .select-style {
    width: 17%;
  }

  .app-main .humburger-menu {
    margin-bottom: 10px;
  }

  .app-main .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
    padding: 10px;
  }

  .app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 30px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 6px;
  }

  .app-main .subparent-nav:last-child {
    margin-bottom: 0;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 45px;
    padding-right: 0px;
  }

  .app-main .left-sidebar nav {
    width: 250px;
  }

  /*     Resource     1199px*/
  .app-main .content-box-feed,
  .app-main .content-box-feed:nth-child(3n) {
    width: 275px;
    margin-right: 4%;
    margin-bottom: 30px;
  }

  .app-main .content-box-feed:nth-child(2n) {
    margin-right: 0;
  }

  .app-main .content-box,
  .app-main .content-box:nth-child(3n) {
    width: 300px;
    margin-right: 4%;
    margin-bottom: 30px;
  }

  .app-main .content-box:nth-child(2n) {
    margin-right: 0;
  }

  /*    Cliinic    */
  .app-main .data-popup-box {
    min-width: 800px;
    max-width: 800px;
    margin: 60px 0;
  }

  .app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 190px;
  }

  .app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root {
    min-width: 130px;
  }

  .app-main .tab-listing .slick-slide {
    padding-right: 10px;
  }

  /*        product-selection      1199px  */
  .app-main .hover_link {
    padding: 30px 15px;
  }

  .app-main .block-col h2 {
    font-size: 30px;
  }

  .app-main .three-block-sec {
    padding-right: 0;
    margin-top: 30px;
  }

  .app-main .block-col {
    width: 48.47%;
  }

  .app-main .block-col:nth-child(3n) {
    margin-right: 2.05%;
  }

  .app-main .block-col:nth-child(2n) {
    margin-right: 0;
  }

  .app-main .sec-left {
    width: 55%;
    padding-right: 40px;
  }

  .app-main .sec-right {
    width: 45%;
  }

  /* .app-main .sec-right-calender {
    width: 45%;
  } */

  .app-main .two-col-sec {
    margin-top: 20px;
    padding: 30px;
  }

  .app-main .two-col-sec p {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .app-main .two-col-sec ul li,
  .app-main .blue-link a,
  .app-main .two-col-sec h3 {
    font-size: 15px;
  }

  .app-main .two-col-sec ul li {
    margin-bottom: 15px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .app-main .two-col-sec ul {
    margin-bottom: 40px;
  }

  .app-main .blue-link {
    margin-top: 20px;
  }

  .app-main .full-field p small {
    font-size: 12px;
  }

  .app-main .full-field p {
    margin-top: 17px;
  }

  /*         adbreak        1199px */
  .app-main .full-page-wrap h1 {
    width: calc(100% - 150px);
  }

  .app-main .step-col h3 {
    font-size: 13px;
  }

  .app-main .step-col {
    margin: 0 1.9%;
  }

  .app-main .step-col:before {
    width: 111px;
  }

  .app-main .content_img_col.content_col_large {
    width: 100%;
    margin-top: 30px;
  }

  .app-main .content_col_small {
    width: 19%;
    margin-right: 4%;
  }

  .app-main .content_col_middle {
    width: 33%;
  }

  /* calendar  1199px*/
  .app-main .calendar-image-box {
    background-size: 126px;
    width: 131px;
    height: 131px;
  }

  .app-main .daygrid-day-top {
    margin-bottom: 0;
    height: 1.6em;
  }

  .app-main .calendar-grid-main {
    grid-template-columns: 13.80% 13.80% 13.80% 13.83% 13.83% 13.80% 13.80%;
    grid-template-rows: 160px 160px 160px 160px 160px 160px;
  }

  .app-main .library-list-main {
    grid-template-columns: 210px;
  }

  .content-right-header {
    flex-wrap: wrap;
    align-items: center;
  }

  .content-right-data {
    flex-wrap: wrap;
  }

  .report-select-box {
    width: 100%;
    margin-bottom: 10px;
  }

  .location-select-box {
    width: 100%;

    margin: 0px 5px 10px 5px;
  }

  .date-piker-box {
    width: 100%;
  }

  .date-piker {
    margin-right: 5px !important;
  }

  .react-google-flight-datepicker {
    width: 100%;
  }

  .react-google-flight-datepicker .dialog-date-picker {
    left: 0px !important;
  }
}


@media (min-width: 992px) and (max-height: 810px) {

  /* .app-main .header-nav ul li a{
    font-size: 16px;
  } */
  /* .app-main .logo {
    margin-bottom: 40px;
  } */

  .app-main .left-column h1 {
    margin-bottom: 30px;
  }

  .app-main .right-column {
    padding: 30px 20px 30px;
  }

  .app-main .image-item {
    padding-bottom: 60px;
  }

  .app-main .bottom-links {
    padding-top: 30px;
  }

  .app-main .logo img {
    height: 50px;
  }

  .app-main .form-field {
    margin-bottom: 20px;
  }

  .app-main .app-main .login_page input[type="text"] {
    padding: 15px 20px;
  }

  .app-main .login_page h1,
  .app-main .h1 {
    font-size: 30px;
  }

  .app-main .left-column {
    padding: 25px;
  }
}

@media (max-width: 991px) {
  .app-main .login-from {
    width: 40% !important;
  }

  .app-main .login_page {
    padding: 0;
    background-image: none !important;
  }

  .app-main .login_page .main {
    min-height: calc(100vh - 100px);
  }

  .app-main .inner-screen {
    box-shadow: none;
    min-height: 100vh;
  }

  .app-main .left-column {
    display: flex;
    flex-direction: column;
    padding: 80px 40px;
  }

  .app-main .left-column h1 {
    margin-bottom: 40px;
  }

  .app-main .error input::-moz-placeholder {
    opacity: 0 !important;
  }

  .app-main .error input:-moz-placeholder {
    opacity: 0 !important;
  }

  .app-main .error input::-webkit-input-placeholder {
    opacity: 0 !important;
  }

  .app-main .error input:-ms-input-placeholder {
    opacity: 0 !important;
  }

  .app-main .middle-content {
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 20px;
  }

  .app-main .right-column {
    padding: 86px 30px 75px;
  }

  .app-main .col-head {
    text-align: left;
  }

  .app-main .col-head h2 {
    padding-right: 17px;
    margin-bottom: 17px;
  }

  .app-main .col-head h2 {
    font-size: 32px;
  }

  .app-main .col-head p {
    font-size: 16px;
  }

  .app-main .right-column {
    align-items: inherit;
  }

  .app-main .image-slider {
    margin-top: 0;
  }

  .app-main .image-slider .slick-slider {
    height: 100%;
  }

  .app-main .right-continue-btn .button-field.next-step-btn {
    min-width: 151px;
  }

  .app-main .button-field.button-field-country {
    margin-top: 0;
  }

  .app-main .form-submit .button-field input {
    min-width: 143px;
  }

  .app-main .sec-left .button-field {
    min-width: 145px;
    width: 145px;
    margin-top: 5px;
  }

  .app-main .form-field {
    margin-bottom: 30px;
  }

  .app-main h1,
  .app-main .h1 {
    font-size: 36px;
  }

  .app-main h2 {
    font-size: 22px;
    line-height: 1.2;
  }

  /* 991pxx */
  .app-main .confirmation-message {
    font-size: 22px;
    text-align: center;
  }

  .app-main h3 {
    font-size: 24px;
  }

  .app-main h4 {
    font-size: 16px;
  }

  .app-main h5 {
    font-size: 14px;
  }

  .app-main h6 {
    font-size: 14px;
  }

  .app-main input[type="date"],
  .app-main input[type="email"],
  .app-main input[type="password"],
  .app-main input[type="search"],
  .app-main input[type="tel"],
  .app-main input[type="text"],
  .app-main select,
  .app-main textarea {
    padding: 14px 15px;
  }

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    padding: 11px 23px;
    min-width: 143px;
  }

  .app-main .main-sec-wrap {
    padding-left: 0px;
    padding-top: 80px;
  }

  .app-main .main-sec-wrap.active-main {
    padding-left: 0px;
  }

  /*       verify otp     991px */
  .app-main .popup-box {
    min-width: 480px;
    max-width: 550px;
  }

  .app-main .box-heading {
    padding: 21px 15px;
  }

  .app-main .box-heading>img {
    margin-right: 20px;
    max-width: 47px;
  }

  .app-main .box-content p {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .app-main .multiple-field {
    margin-top: 0;
  }

  .app-main .multiple-field input[type="text"] {
    margin: 0 5px;
    width: 49px;
    height: 49px;
  }

  .app-main .box-content form {
    width: 62%;
  }

  /* verify otp */
  /* forgot username */
  .app-main .forgot-popup-box {
    padding-bottom: 50px;
  }

  .app-main .changepwd-popup-box {
    padding-bottom: 50px;
  }

  /* forgot username */
  .app-main .bootom-sec .header-nav {
    display: block;
    background: var(--white-color);
  }

  .app-main .bootom-sec .header-nav li {
    min-width: 33.33%;
    text-align: center;
    padding-right: 0;
  }

  .app-main .bootom-sec .header-nav li a {
    width: 100%;
    font-size: 18px;
    padding: 27px 10px;
  }

  .app-main .content-right-main {
    padding: 39px 20px 42px 60px;
  }

  .app-main .content-right-heading {
    font-size: 18pt;
    margin-top: -20px;
  }

  .app-main .content-right-heading-adbilder {
    font-size: 18pt;
    margin-top: -30px;
  }

  .app-main .content-heading-view {
    margin-top: -10px;
  }

  .app-main .right-icons ul li {
    padding-right: 30px;
  }

  .app-main .site-header .header-nav ul li {
    display: none;
  }

  .app-main .site-header .header-nav ul li:last-child {
    display: block;
  }

  .app-main .header-left {
    width: 17%;
    padding: 10px 10px 10px 20px;
  }

  .app-main .header-right {
    width: 83%;
  }

  .app-main .site-logo a {
    max-width: 155px;
    width: 90px;
  }

  .app-main .MuiButton-label {
    padding-right: 17px;
  }

  .app-main .MuiButton-label:after {
    background-size: 10px;
    background-position: center right;
  }

  .app-main .header-nav ul li a {
    padding: 11px 0;
    font-size: 0px;
  }

  .app-main .MuiButton-label img {
    max-width: 30px;
  }

  .app-main .right-icons {
    padding: 1px 28px 1px 19px;
  }

  .app-main .right-icons ul li a img {
    max-width: 30px;
  }

  .app-main .header-nav {
    padding-right: 10px;
  }

  .app-main .header-nav ul li svg {
    max-width: none;
    margin-right: 5px;
  }

  .app-main .header-nav ul li .icon-status {
    top: 20px;
    left: 16px;
  }

  .app-main #simple-popover .MuiPaper-root.MuiPopover-paper {
    top: 50px !important;
    right: -20px;
  }

  .app-main #location-popover .MuiPaper-root.MuiPopover-paper {
    top: 188px !important;
    right: -20px;
  }

  .app-main .site-logo {
    padding-left: 0;
  }

  .app-main .left-sidebar {
    top: 80px;
    bottom: 0;
    min-height: calc(100vh - 80px);
    transition: all 0.5s ease-in-out;
    width: 50px;
  }

  .app-main .left-sidebar.active {
    left: 0;
  }

  .app-main .left-sidebar li a {
    padding: 17px 30px 16px;
  }

  .app-main .left-sidebar li a img {
    margin-right: 16px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 17px 21px 16px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 41px;
  }

  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding-right: 30px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 80px;
  }

  .app-main .humburger-menu {
    padding-right: 11px;
  }

  .app-main .left-sidebar .parent-nav>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content span {
    padding: 12px 20px 12px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding: 12px 20px;
  }

  .app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 35px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 70px;
  }

  .app-main .hide-scroll {
    overflow: auto;
    margin-right: 0;
  }

  .app-main .hide-scroll .site-header {
    width: 100%;
  }

  /*     Resource    991px */
  .app-main .box-image {
    padding: 21.5% 15px;
  }

  .app-main .box-content-info h4 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .app-main .box-content-info {
    padding: 18px 19px;
  }

  .app-main .box-content-info p {
    margin-bottom: 18px;
  }

  .app-main .content-box-feed {
    margin-bottom: 20px;
  }

  .app-main .content-box-feed,
  .app-main .content-box-feed:nth-child(3n) {
    margin-bottom: 20px;
  }

  .app-main .content-box {
    margin-bottom: 20px;
  }

  .app-main .content-box,
  .app-main .content-box:nth-child(3n) {
    margin-bottom: 20px;
  }

  .app-main .main_tab_content {
    padding: 30px 30px 0px;
  }

  .app-main .ecp_resource .tab-listing ul li {
    font-size: 24px;
    padding-right: 39px;
  }

  .app-main .ecp_resource .tab-listing ul li a {
    padding-right: 2px;
  }

  /*      Clinic    991px */
  .app-main .tab-listing ul li {
    font-size: 24px;
    padding: 10px 42px 0 0;
  }

  .app-main .tab-listing .slick-slide {
    padding-right: 35px;
  }

  .app-main .tab-listing {
    padding: 0 0 0;
    position: relative;
  }

  .app-main .tab-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .app-main .tab-content {
    padding: 0;
  }

  .app-main .tab-listing ul li a:after {
    bottom: 0;
  }

  .app-main .tab-content .form-main form {
    max-width: 477px;
  }

  .app-main .tab-content .field-left {
    width: 34%;
  }

  .app-main .tab-content .field-right {
    width: 66%;
  }

  .app-main .tab-content .form-main {
    padding-bottom: 140px;
  }

  .app-main .data-popup-box {
    min-width: 650px;
    max-width: 650px;
    margin: 100px 0;
  }

  .app-main .data-popup-box .box-heading {
    padding: 30px 15px;
  }

  .app-main .data-popup-box .close-btn {
    top: 20px;
    right: 10px;
  }

  .app-main .data-popup-box .box-heading .close-btn img {
    width: 15px;
  }

  .app-main .data-popup-box .box-content form {
    width: 100%;
    max-width: 510px;
  }

  .data-popup-box .box-content form .field-right {
    width: 60%;
  }

  .app-main .data-popup-box .box-content form .field-left {
    width: 40%;
  }

  .app-main .data-popup-box .box-content {
    padding: 50px 40px 60px;
  }

  /*        product-selection       991px */
  .app-main .three-block-sec {
    margin-top: 27px;
  }

  .app-main .block-col p {
    font-size: 16px;
  }

  .app-main .block-col h2 {
    font-size: 22px;
  }

  .app-main .block-col img {
    max-width: 80px;
  }

  .app-main .sec-left {
    width: 100%;
    padding: 0;
  }

  .app-main .sec-right {
    width: 100%;
    order: -1;
    padding: 0;
    margin-bottom: 47px;
  }

  .app-main .sec-right-calender {
    width: 100%;
    order: -1;
    padding: 0;
    margin-bottom: 47px;
  }

  .app-main .two-col-sec {
    margin-top: 27px;
    padding: 40px 30px 23px;
  }

  .app-main .sec-right img {
    max-width: 420px;
  }

  .app-main .two-col-sec p {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 32px;
  }

  .app-main .detail-welcome .sec-right {
    order: inherit;
    padding-top: 12px;
    margin-bottom: 0;
  }

  .app-main .detail-welcome {
    padding-top: 24px;
    padding-bottom: 29px;
    margin-bottom: 30px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .app-main .two-col-sec ul li {
    padding-left: 17px;
    margin-bottom: 13px;
  }

  .app-main .two-col-sec ul li:after {
    top: 7px;
    width: 3px;
    height: 3px;
  }

  .app-main .detail-welcome .full-field {
    display: inline-block;
    vertical-align: top;
    width: 46%;
    margin-right: 2.5%;
  }

  .app-main .full-field p small {
    font-size: 14px;
  }

  .app-main .full-field p {
    margin-left: -105%;
    margin-top: 13px;
    width: calc(100vw - 100px);
  }

  .app-main .blue-link a {
    font-size: 24px;
  }

  .app-main .blue-link {
    margin-top: 14px;
  }

  .app-main .button-field.next-step-btn {
    min-width: 228px;
  }

  .app-main .button-field.next-step-btn input {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }

  /*         adbreak        991px */
  .app-main .step-col h3 {
    font-size: 12px;
  }

  .app-main .step-col {
    margin: 0 2%;
  }

  .app-main .white-box-image {
    margin-bottom: 30px;
  }

  .app-main .white-box-content {
    padding: 30px 30px 20px;
    margin-top: 0;
  }

  .app-main .white-box-wrapper {
    padding-top: 39px;
  }

  .app-main .step-row {
    margin-top: 67px;
  }

  .app-main .white-box-image img {
    margin: 0;
  }

  .app-main .content_col_small {
    width: 24.2%;
    margin-right: 6%;
  }

  .app-main .content_col_middle {
    width: 45.2%;
  }

  .app-main .content-info {
    padding: 0px 10px;
  }

  .app-main .content_col_small .content-info h6 {
    font-size: 18px;
  }

  .app-main .content-info h6 {
    font-weight: 400;
    font-size: 18px;
  }

  .app-main .content_col_small .content-info p {
    font-size: 18px;
  }

  .app-main .content_col_small .content-info {
    padding-right: 10px;
  }

  .app-main .content-info p {
    font-size: 18px;
  }

  .app-main .content_img_row {
    margin-bottom: 30px;
  }

  .app-main .row-300-width {
    width: 45%;
  }

  .app-main .content_img_row {
    margin-right: 6%;
  }

  .app-main .row-250-width {
    width: 37.5%;
    margin-right: 0;
  }

  .app-main .row-320-width {
    max-width: 48%;
  }

  .app-main .content_img_row:last-child {
    margin-bottom: 0;
  }

  .app-main .step-col:after {
    width: 28px;
    height: 28px;
    font-size: 18px;
    line-height: 30px;
  }

  /* 991px */

  .app-main .library-list-main {
    grid-template-columns: 210px 210px 210px;
  }

  .app-main .tab-listing ul {
    margin: 0 0 10px 0;
  }

}

@media (max-width: 991px) and (max-height: 740px) {
  .app-main .left-column {
    padding: 30px;
  }

  .app-main .right-column {
    padding: 30px 30px 40px;
  }

  .app-main .left-column h1 {
    margin-bottom: 20px;
  }

  .app-main .logo {
    margin-bottom: 30px;
  }

  .app-main .middle-content {
    padding-bottom: 0;
  }

  .app-main .form-field {
    margin-bottom: 20px;
  }


}

@media (max-width: 768px) {
  .app-main .login-from {
    width: 50% !important;
  }

  .app-main .app-main .login_page .main {
    min-height: 1px;
  }

  .app-main .inner-screen {
    display: block;
    min-height: 1px;
  }

  .app-main .left-column {
    width: 100%;
    padding: 30px 30px;
    display: block;
  }

  .app-main .left-column h1 {
    margin-bottom: 23px;
  }

  .app-main .logo {
    margin-bottom: 24px;
  }

  .app-main .logo img {
    height: 50px;
  }

  .app-main .middle-content {
    margin: 0;
    padding-bottom: 0;
  }

  .app-main .app-main .login_page .form-field.custom-checkbox {
    margin-bottom: 16px;
  }

  .app-main .custom-checkbox input[type="checkbox"]+label {
    padding-left: 25px;
    line-height: 22px;
  }

  .app-main .custom-checkbox input[type="checkbox"]+label:after,
  .app-main .custom-checkbox input[type="checkbox"]+label:before {
    width: 16px;
    height: 16px;
  }

  .app-main .custom-checkbox input[type="checkbox"]+label:after {
    background-size: 12px;
  }

  .app-main .user-field {
    background-size: 13px;
  }

  .app-main .bottom-links {
    padding-top: 20px;
    margin-left: 8px;
  }

  .app-main .bottom-links button {
    font-size: 14px;
  }

  .app-main .bottom-links ul li {
    padding-right: 16px;
  }

  .app-main .right-column {
    width: 100%;
    display: block;
    padding: 31px 30px 15px;
  }

  .app-main .col-head h2 {
    padding-right: 0;
    margin-bottom: 14px;
  }

  .app-main .image-item {
    padding: 0 40px 30px;
  }

  .app-main .image-slider .slick-slider {
    height: auto;
  }

  .app-main .col-head h2 {
    font-size: 20px;
  }

  .app-main .button-field a {
    font-size: 14px;
    line-height: 16px;
    min-width: 130px;
    padding: 11px 20px;
  }

  .app-main .button-field.button-field-country button {
    font-size: 12px;
    padding: 8px 20px 8px;
    min-width: 108px;
  }

  .app-main .sec-left .button-field {
    margin-top: -8px;
    min-width: 115px;
    width: 115px;
  }

  .app-main .form-field {
    margin-bottom: 21px;
  }

  h1,
  .h1 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .app-main body,
  .app-main p {
    font-size: 14px;
  }

  .app-main h2 {
    font-size: 18px;
  }

  /* 767pxx   */
  .app-main .confirmation-message {
    font-size: 20px;
    text-align: center;
  }

  .app-main h3 {
    font-size: 16px;
  }

  .app-main h4 {
    font-size: 14px;
  }

  .app-main h5 {
    font-size: 12px;
  }

  .app-main h6 {
    font-size: 12px;
  }

  .app-main input[type="date"],
  .app-main input[type="email"],
  .app-main input[type="password"],
  .app-main input[type="search"],
  .app-main input[type="tel"],
  .app-main input[type="text"],
  .app-main select,
  .app-main textarea {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 15px 15px;
  }

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    font-size: 16px;
    line-height: 5px;
    padding: 17px 23px 16px;
  }

  .app-main select {
    background-size: 9px;
    padding-right: 25px;
  }

  .app-main .slick-slider .slick-dots li {
    padding: 0 2px;
  }

  .app-main .slick-slider .slick-dots li button {
    width: 5px;
    height: 5px;
  }

  .app-main .slick-slider .slick-dots .slick-active button {
    width: 13px;
  }

  /* verify otp */
  .app-main .popup-box {
    min-width: 165px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
  }

  .app-main .box-content {
    padding: 17px 33px 30px;
  }

  .app-main .box-heading>img {
    max-width: 25px;
    margin-right: 10px;
  }

  .app-main .box-content form {
    width: 100%;
  }

  .app-main .multiple-field {
    margin-bottom: 0;
  }

  .app-main .box-content p {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  /* verify otp */
  /* forgot username */
  .app-main .close-btn {
    top: 0;
    right: 0;
  }

  .app-main .forgot-popup-box {
    padding-bottom: 0;
  }

  .app-main .changepwd-popup-box {
    padding-bottom: 0;
  }

  /* forgot username */
  .app-main .main-sec-wrap {
    padding-top: 50px;
  }

  .app-main .bootom-sec .header-nav li a {
    font-size: 12px;
    padding: 14px 10px;
  }

  .app-main .bootom-sec .header-nav ul li svg {
    max-width: 15px;
    margin-right: 5px;
    margin-top: -3px;
  }

  .app-main .content-right-main {
    padding: 27px 15px 29px 70px;
  }

  .app-main .content-right-heading {
    font-size: 17pt;
    margin-top: 0px;
  }

  .app-main .content-right-heading-adbilder {
    font-size: 17pt;
    margin-top: -3px;
  }

  .app-main .content-heading-view {
    margin-top: -10px;
  }

  .app-main .site-header .header-nav ul li a {
    font-size: 0;
    padding: 11px 0;
  }

  .app-main .site-header .header-nav ul li a svg {
    margin-right: 0;
    max-width: 17px;
  }

  .app-main .right-icons {
    border-left: none;
    padding: 1px 16px 1px 0px;
  }

  .app-main .site-logo a {
    max-width: 103px;
    width: 75px;
  }

  .app-main .account-info {
    padding: 0;
    background: transparent;
  }

  .app-main .right-icons ul li a img {
    max-width: 18px;
    max-height: 15px;
  }

  .app-main .MuiButton-label {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: var(--white-background-color);
    padding: 0;
    margin-right: 10px;
  }

  .app-main .MuiButton-label img {
    margin: 0;
  }

  .app-main .MuiButtonBase-root.MuiButton-text {
    min-width: 1px;
  }

  .app-main .MuiButton-label:after {
    right: -10px;
    background-size: 6px;
  }

  .app-main .header-left {
    padding: 5px 0px 5px 15px;
    width: 30%;
  }

  .app-main .header-right {
    padding-right: 15px;
    width: 70%;
  }

  .app-main .right-icons {
    height: 23px;
  }

  .app-main .humburger-menu {
    margin-right: 16px;
  }

  .app-main .right-icons ul li {
    padding-right: 19px;
  }

  .app-main .header-nav {
    padding-right: 20px;
  }

  .app-main .header-nav ul li .icon-status {
    top: 15px;
    left: 10px;
  }

  .app-main #simple-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 20px !important;
    right: -30px;
  }

  .app-main #theme-popover .MuiPaper-root.MuiPopover-paper {
    min-width: 250px;
    top: 202px !important;
    right: -30px;
  }

  .app-main .left-img {
    width: 30px;
  }

  .app-main .right-content {
    width: calc(100% - 30px);
    padding-left: 9px;
  }

  /*     left-sidebar     767px */
  .app-main .left-sidebar {
    top: 55px;
    height: calc(100vh - 55px);
  }

  .app-main .humburger-menu span {
    height: 2px;
    width: 27px;
  }

  .app-main .humburger-menu {
    padding-right: 12px;
  }

  .app-main .left-sidebar li a,
  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    font-size: 14px;
    padding: 13px 15px 13px;
  }

  .app-main .app-main .left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 31px;
  }

  .app-main .left-sidebar .MuiExpansionPanelSummary-content span {
    padding-right: 25px;
  }

  .app-main .left-sidebar .subparent-nav .MuiExpansionPanelSummary-content span {
    padding-left: 30px;
  }

  .app-main .left-sidebar .MuiExpansionPanelDetails-root .child-nav {
    padding-left: 40px;
    padding-right: 30px;
  }

  /*     Resource   767px  */
  .app-main .content-box-feed,
  .app-main .content-box-feed:nth-child(3n) {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-main .content-box-feed:last-child {
    margin-bottom: 0;
  }

  .app-main .content-box,
  .app-main .content-box:nth-child(3n) {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-main .content-box:last-child {
    margin-bottom: 0;
  }

  .app-main .main_tab_content {
    padding: 20px 15px;
  }

  .app-main .box-content-info p {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .app-main .box-content-info h4 {
    font-size: 14px;
  }

  .app-main .ecp_resource .tab-listing ul li {
    font-size: 16px;
    padding-right: 30px;
  }

  .app-main .tab-arrow:after {
    background-size: 6px;
    background-position: center right 10px;
    width: 30px;
    top: -12px;
  }

  .app-main .box-image {
    padding: 23.5% 15px;
  }

  .app-main .box-content-info {
    padding: 13px 14px;
  }

  /*        Clinic     */
  .app-main .tab-listing ul li {
    font-size: 20px;
  }

  .app-main .data-popup-box {
    min-width: 345px;
    max-width: 345px;
    margin: 80px 0;
  }

  .app-main .data-popup-box .box-content form .field-left {
    width: 100%;
    padding: 0;
  }

  .app-main .data-popup-box .box-content {
    padding: 31px 15px 15px;
  }

  .app-main .data-popup-box .box-content form .field-right {
    width: 100%;
  }

  .app-main .data-popup-box .form-submit .button-field input {
    padding-left: 13px;
    padding-right: 13px;
  }

  .app-main .data-popup-box .box-heading .close-btn img {
    width: 10px;
  }

  .app-main .data-popup-box .close-btn {
    top: 10px;
    right: 10px;
    width: 10px;
  }

  .app-main .tab-listing .slick-next {
    top: -16px;
  }

  /*          product-selection       767px */
  .app-main .block-col {
    width: 100%;
    margin: 0 0 15px;
  }

  .app-main .block-col:nth-child(4n) {
    margin-right: 0;
  }

  .app-main .block-col p {
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .app-main .block-col h2 {
    font-size: 16px;
  }

  .app-main .block-col img {
    max-width: 70px;
    margin-bottom: 22px;
  }

  .app-main .three-block-sec {
    margin-top: 20px;
  }

  .app-main .sec-right img {
    max-width: 250px;
  }

  .app-main .two-col-sec {
    margin-top: 17px;
    padding: 20px 20px 14px;
  }

  .app-main .two-col-sec p {
    font-size: 16px;
    margin-bottom: 28px;
  }

  .app-main .sec-right {
    margin-bottom: 14px;
  }

  .app-main .sec-right-calender {
    margin-bottom: 14px;
  }

  /*        budgetDetail       767px  */
  .app-main .two-col-sec ul li,
  .app-main .blue-link a,
  .app-main .two-col-sec h3 {
    font-size: 15px;
  }

  .app-main .detail-welcome {
    padding-top: 15px;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .app-main .two-col-sec p+h3 {
    margin-top: 33px;
    margin-bottom: 7px;
  }

  .app-main .two-col-sec ul li {
    padding-left: 15px;
    margin-bottom: 6px;
    line-height: 1.7;
  }

  .app-main .detail-welcome .full-field {
    display: block;
    width: 100%;
    margin: 0 0 19px;
  }

  .app-main .two-col-sec ul {
    margin-bottom: 38px;
  }

  .app-main .full-field label {
    margin-bottom: 9px;
  }

  .app-main .full-field p {
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
  }

  .app-main .full-field p small {
    font-size: 12px;
  }

  .app-main .detail-welcome .sec-right {
    padding-top: 22px;
  }

  .app-main .detail-welcome .full-field:last-child {
    margin-bottom: 0;
  }

  .app-main .detail-welcome .sec-right img {
    margin-left: 0;
  }

  .app-main .blue-link {
    text-align: left;
    margin-top: 11px;
  }

  .app-main .button-field.next-step-btn input,
  .app-main .button-field.back-btn input {
    font-size: 12px;
    line-height: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .app-main .button-field.next-step-btn {
    min-width: 184px;
  }

  /*         adbreak      767px */
  .app-main .white-box-content {
    padding: 20px 15px 20px;
    margin-top: 10px;
  }

  .app-main .white-box-image {
    padding: 0 15px;
  }

  .app-main .full-page-wrap h1 {
    width: calc(100% - 130px);
  }

  .app-main .step-col {
    margin: 0 2.5%;
  }

  .app-main .step-col:before {
    width: 115px;
  }

  .app-main .step-col h3 {
    font-size: 15px;
  }

  .app-main .step-col:after {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 21px;
  }

  .app-main .library-list-main {
    grid-template-columns: 210px 210px;
  }


  .content-right-header {
    display: block;
    justify-content: center;
  }

  .content-right-heading {
    margin-bottom: 10px;
  }

  .report-select-box {
    width: 100%;
  }

  .location-select-box {
    width: 100%;
  }

  .paid-chart-inner {
    width: 100%;
  }

  .paid-chart-tabbedCarts {
    width: 100%;
  }

  .paid-chart {
    display: block;
  }

  .social-chart-main {
    display: block;
  }

  .budget-spant-chart-data {
    margin-bottom: 20px !important;
  }

  .budget-chart {
    height: 200px !important;
  }

  .facebook-chart {
    height: 200px !important;
  }

  .card-main {
    display: block;
  }

  .paid-card {
    width: 101%;
  }

  .react-google-flight-datepicker .dialog-date-picker {
    width: 70% !important;
    height: 326px !important;
    left: -195px !important;
  }

}

@media (max-width: 639px) {

  .app-main button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    font-size: 12px;
    min-width: 108px;
    padding: 7px 20px;
  }

  .app-main .button-field a {
    font-size: 13px;
    line-height: 15px;
    min-width: 100px;
    padding: 10px 15px;
  }

  .app-main .form-submit .button-field input {
    min-width: 108px;
  }

  .app-main .form-submit .button-field+.button-field {
    margin-left: 0;
    margin-right: 15px;
  }

  .app-main .form-submit .button-field {
    margin-bottom: 15px;
  }

  /*        Clinic       639px */
  .app-main .tab-content .form-main {
    padding-bottom: 6px;
  }

  .app-main .tab-listing ul li {
    font-size: 16px;
    padding-right: 30px;
  }

  .app-main .tab-listing .slick-slide {
    padding-right: 5px;
  }

  .app-main .tab-content .field-left {
    width: 100%;
    padding: 0;
  }

  .app-main .tab-content .field-right {
    width: 100%;
  }

  .app-main .tab-listing ul li:last-child {
    padding-right: 0;
  }

  .app-main .tab-listing {
    padding: 8px 0 0px;
  }

  .app-main .ecp_resource .tab-listing ul li a {
    padding-bottom: 18px;
  }

  .app-main .tab-listing ul li a:after {
    bottom: 0px;
  }

  .app-main .tab-listing ul li a {
    padding-right: 2px;
    padding-bottom: 11px;
  }

  .app-main .tab-content {
    padding: 0px 0 0;
  }

  .app-main .tab-content .form-main form {
    max-width: 315px;
  }

  .app-main .tab-content .form-main .form_field {
    padding-right: 0;
  }

  .app-main .tab-listing+.table-main-wrap {
    margin-top: 7px;
  }

  .app-main .table-link {
    padding-bottom: 14px;
  }

  .app-main .table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 60px;
  }

  .app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 96px;
  }

  .app-main .table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 95px;
  }

  /*      adbreak      639px */
  .app-main .full-page-wrap h1 {
    width: calc(100% - 100px);
  }

  .app-main .step-col {
    margin: 25px 8px;
  }

  .app-main .step-col h3 {
    font-size: 12px;
    line-height: 1.35;
    margin-top: -25px;
  }

  .app-main .step-col:before {
    width: 82px;
    top: -41px;
  }

  .app-main .white-box-image {
    padding: 0 0;
  }

  .app-main .white-box-wrapper {
    padding-top: 33px;
  }

  .app-main .content-info h6 {
    font-size: 14px;
  }

  .app-main .content-info {
    padding: 0px 10px;
  }

  .app-main .content-info p {
    font-size: 14px;
  }

  .app-main .content_col_small {
    max-width: 162px;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .app-main .content_col_middle {
    max-width: 302px;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .app-main .content_col_small .content-info h6 {
    font-size: 13px;
    margin-bottom: 7px;
  }

  .app-main .content_col_small .content-info p {
    font-size: 14px;
  }

  .app-main .content_img_col.content_col_large {
    margin-top: 0;
    display: block;
  }

  .app-main .content_img_row {
    margin-bottom: 20px;
  }

  .app-main .row-300-width {
    max-width: 302px;
    margin-right: 0;
    width: 100%;
  }

  .app-main .row-250-width {
    max-width: 252px;
    margin-right: 0;
    width: 100%;
  }

  .app-main .content_img_row.row-320-width {
    width: 100%;
    max-width: 320px;
  }

  .app-main .white-box-image {
    display: block;
  }
}

@media (max-width: 479px) {
  .app-main .button-field.fullview-btn {
    margin: 0;
    display: table;
  }

  .app-main .right-continue-btn .button-field.next-step-btn {
    min-width: 108px;
  }

  .app-main .right-content {
    padding-right: 43px;
  }

  /*     Resource     */
  .app-main .gray-close-ic {
    top: 6px;
    right: 6px;
    max-width: 10px;
  }

  .app-main .button-field.next-step-btn {
    min-width: 250px;
  }

  .app-main .select {
    font-size: 14px;
    color: rgba(4, 12, 5, 0.5);
  }

  /*    adbreak    479px */
  .app-main .page-img .next-step-btn {
    margin-top: 15px;
  }

  .app-main .full-page-wrap h1 {
    width: 100%;
    line-height: 1.2;
  }

  .app-main .white-box-image {
    margin-bottom: 4px;
  }

  .app-main .content_img_col:only-child {
    margin: 0 auto;
  }

  .app-main .step-row {
    min-width: 415px;
    margin-top: 10px;
    justify-content: flex-start;
    padding: 0 4px;
  }

  .app-main .step-row-wrapper {
    overflow: auto;
  }

  .app-main .step-col {
    max-width: 65px;
    margin: 0 14px;
  }

  .app-main .step-col:after {
    top: -35px;
  }

  .app-main .step-col:before {
    top: -25px;
    height: 2px;
  }
}